import React from "react";

const OutSourcedHistory = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24.95 22.24"
    xmlSpace="preserve"
  >
    <path
      fill="currentColor"
      d="M4.53 0h15.88v1.6H4.53zM20.41 22.25H4.53a2.44 2.44 0 0 1-2.38-2.49V7.01h1.6v12.75a.84.84 0 0 0 .78.89h15.88a.84.84 0 0 0 .79-.89V7.01h1.6v12.75a2.44 2.44 0 0 1-2.39 2.49Z"
    />
    <path
      fill="currentColor"
      d="M22.56 7.41H2.38A2.39 2.39 0 0 1 0 5.03V2.38A2.39 2.39 0 0 1 2.38 0h20.18a2.39 2.39 0 0 1 2.39 2.38v2.65a2.39 2.39 0 0 1-2.39 2.38ZM2.38 1.6a.78.78 0 0 0-.78.78v2.65a.78.78 0 0 0 .78.78h20.18a.79.79 0 0 0 .79-.78V2.38a.79.79 0 0 0-.79-.78ZM12.49 15.52l1.69 1.22a.49.49 0 0 0 .77-.39v-5a.49.49 0 0 0-.49-.48h-4a.47.47 0 0 0-.48.48v5a.48.48 0 0 0 .76.39Z"
    />
  </svg>
);

export default OutSourcedHistory;
