import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";

import logToSentryOrConsole from "../../../helpers/sentry";
import { getDefaultHeaders } from "../../../helpers/token";
import DashboardLayout from "../../dashboard/Presenters/Layout";
import Loading from "../../misc/Loading";
import StepHeader from "../../misc/StepHeader";
import StepLayout from "../../misc/StepLayout";

const Marketplace = () => {
  const [marketplace, setMarketplace] = useState([]);

  const fetchMarketPlace = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/integrations/marketplace/`,
        { ...getDefaultHeaders() }
      );
      if (response && response.data) {
        setMarketplace(response.data);
      }
    } catch (error) {
      logToSentryOrConsole(error);
    }
  }, []);

  useEffect(
    () => {
      fetchMarketPlace();
    },
    { fetchMarketPlace }
  );

  return (
    <DashboardLayout>
      <StepLayout>
        <StepHeader text="Marketplace" />
        {marketplace.length ? (
          <div className="marketplace">
            {marketplace.map((mp) => (
              <div className="marketplace-item">
                <div className="marketplace-item-wrap">
                  <div className="marketplace-img-wrap">
                    <img src={mp.logo} alt={mp.name} />
                  </div>
                  <div className="marketplace-copy-wrap">
                    <div className="marketplace-name">{mp.name}</div>
                    <div className="marketplace-description">
                      {mp.description}
                    </div>
                    {!!mp.link && (
                      <a
                        className="marketplace-link"
                        href={mp.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Learn more
                      </a>
                    )}
                    <div
                      className={`marketplace-live ${
                        mp.live ? "sr-only" : "soon"
                      }`}
                    >
                      {mp.live ? "Live" : "Coming Soon"}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <Loading />
        )}
      </StepLayout>
    </DashboardLayout>
  );
};

export default Marketplace;
