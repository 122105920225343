/*global richSnippetReviewsWidgets*/

import { Field as FormIkField, Formik } from "formik";
import React, { createRef, useCallback, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { toastr } from "react-redux-toastr";
import { Button } from "reactstrap";
import * as Yup from "yup";

import { CAPTCHA_ENABLE_MESSAGE } from "../../../constants/lang/en";
import { EMAIL_MSG, REQUIRED_MSG } from "../../../constants/validationHelpers";
import { trackGaEvent } from "../../../helpers/ga";
import Field from "../../form/Field";
import FormikScrollToErrors from "../../misc/FormikScrollToError";
import StepHeader from "../../misc/StepHeader";
import StepLayout from "../../misc/StepLayout";

const VALIDATION_RULES = Yup.object().shape({
  agentEmail: Yup.string().trim().required(REQUIRED_MSG).email(EMAIL_MSG),
  agencyName: Yup.string().required(REQUIRED_MSG),
  consent: Yup.bool()
    .required()
    .test(
      "consent",
      "You have to agree with our Terms and Conditions!",
      (value) => value === true
    ),
});

const getInitialValues = ({ agentEmail = "", agencyName = "" }) => ({
  agentEmail,
  agencyName,
});

const Signup = ({ signUp, fetching, agentEmail, agencyName }) => {
  const recaptchaRef = createRef();

  const handleSignUp = useCallback(
    (props) => {
      const recaptchaValue = recaptchaRef.current.getValue();
      if (recaptchaValue) {
        signUp(props);
      } else {
        toastr.error(CAPTCHA_ENABLE_MESSAGE);
      }
    },
    [signUp]
  );

  useEffect(() => {
    if (typeof richSnippetReviewsWidgets !== "undefined") {
      richSnippetReviewsWidgets("carousel-inline-widget-360", {
        store: "realyou-co-nz",
        widgetName: "carousel-inline",
        primaryClr: "#f47e27",
        neutralClr: "#f4f4f4",
        reviewTextClr: "#2f2f2f",
        ratingTextClr: "#2f2f2f",
        layout: "fullWidth",
        numReviews: 21,
      });
    }
  }, []);

  return (
    <div>
      <StepLayout>
        <StepHeader
          text={
            <span>
              Join Canada's #1 Virtual Verification Platform
              {/* Get Instant Access To <abbr title="New Zealand">NZ</abbr>'s #1{" "}
              <abbr title="Anti Money Laundering">AML</abbr> Platform */}
            </span>
          }
        />
        <Formik
          validationSchema={VALIDATION_RULES}
          onSubmit={handleSignUp}
          initialValues={getInitialValues({ agentEmail, agencyName })}
        >
          {({
            handleSubmit,
            errors,
            submitCount,
            isSubmitting,
            isValidating,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <FormikScrollToErrors
                errors={errors}
                isSubmitting={isSubmitting}
                isValidating={isValidating}
              />
              <Field
                name="agentEmail"
                error={errors.agentEmail}
                submitCount={submitCount}
                label="Email Address"
                type="email"
                autoFocus
                autoComplete="email"
              />
              <Field
                name="agencyName"
                error={errors.agencyName}
                submitCount={submitCount}
                label="Trading Name"
                autoComplete="organization"
              />
              <div className="site-form__consent-message">
                <div className="custom-control custom-checkbox">
                  <FormIkField
                    component="input"
                    type="checkbox"
                    name="consent"
                    id="consent"
                    className={`custom-control-input ${
                      errors.consent && submitCount > 0 ? "is-invalid" : ""
                    }`}
                  />
                  <label className="custom-control-label" htmlFor="consent">
                    By completing my account I agree to the{" "}
                    <a
                      href="https://realaml.com/terms-and-conditions/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={
                        errors.consent && submitCount > 0 ? "text-danger" : ""
                      }
                    >
                      Terms &amp; Conditions
                    </a>{" "}
                    and{" "}
                    <a
                      href="https://realaml.com/privacy-policy/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={
                        errors.consent && submitCount > 0 ? "text-danger" : ""
                      }
                    >
                      Privacy Policy
                    </a>
                  </label>
                </div>
              </div>
              <div className="site-form__consent-message">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                />
              </div>
              <Button
                color="primary"
                block
                size="lg"
                type="submit"
                disabled={fetching}
              >
                Join Now
              </Button>
            </form>
          )}
        </Formik>
        <p className="text-center mb-0 mt-3">
          Call us 7 days a week{" "}
          <a
            href="tel:+12267410268"
            className="link-inverse"
            onClick={() =>
              trackGaEvent("click", "phone_number", "dashboard_sign_up")
            }
          >
            <b>+1 226 741 0268</b>
          </a>
        </p>
      </StepLayout>
      <div className="container-fluid lg my-4">
        <div id="carousel-inline-widget-360" />
      </div>
    </div>
  );
};

export default Signup;
