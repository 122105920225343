export const PASS = "PASS";
export const PASS_ADDRESS_REVIEW = "PASS_ADDRESS_REVIEW";
export const FAIL = "FAIL";
export const PARTIAL = "PARTIAL";
export const REVIEW = "REVIEW";
export const NO_MATCH = "NO_MATCH";
export const MATCH = "MATCH";
export const POTENTIAL = "POTENTIAL";
export const NA = "NA";
export const PENDING = "PENDING";
export const OUTSOURCED_SUBMITTED = "submitted";
export const OUTSOURCED_FINALIZING = "finalizing";
export const OUTSOURCED_IN_PROGRESS = "in_progress";
export const OUTSOURCED_COMPLETED = "completed";

const results = [
  {
    key: PASS,
    label: "Pass",
    type: "success",
  },
  {
    key: PASS_ADDRESS_REVIEW,
    label: "Partial Pass (manual address verification required)",
    type: "warning",
  },
  {
    key: FAIL,
    label: "Fail",
    type: "danger",
  },
  {
    key: PARTIAL,
    label: "Partial Pass",
    type: "warning",
  },
  {
    key: REVIEW,
    label: "In Review",
    type: "warning",
  },
  {
    key: NO_MATCH,
    label: "Pass",
    type: "success",
  },
  {
    key: MATCH,
    label: "Potential Match",
    type: "danger",
  },
  {
    key: POTENTIAL,
    label: "Potential Match",
    type: "warning",
  },
  {
    key: NA,
    label: "N/A",
    type: "secondary",
  },
  {
    key: PENDING,
    label: "Pending",
    type: "info",
  },
  {
    key: OUTSOURCED_SUBMITTED,
    label: "Submitted",
    type: "warning",
  },
  {
    key: OUTSOURCED_FINALIZING,
    label: "Finalising",
    type: "primary",
  },
  {
    key: OUTSOURCED_IN_PROGRESS,
    label: "In Progress",
    type: "info",
  },
  {
    key: OUTSOURCED_COMPLETED,
    label: "Completed",
    type: "success",
  },
];

export default results;
