import React from "react";

const CheckHistory = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24.94 22.24"
    xmlSpace="preserve"
  >
    <path
      fill="currentColor"
      d="M4.53 0h15.88v1.6H4.53zM20.41 22.25H4.53a2.44 2.44 0 0 1-2.38-2.48V7h1.6v12.77a.84.84 0 0 0 .78.88h15.88a.85.85 0 0 0 .79-.88V7h1.62v12.77a2.44 2.44 0 0 1-2.41 2.48Z"
    />
    <path
      fill="currentColor"
      d="M22.56 7.41H2.38A2.39 2.39 0 0 1 0 5V2.38A2.39 2.39 0 0 1 2.38 0h20.18a2.39 2.39 0 0 1 2.39 2.38V5a2.39 2.39 0 0 1-2.39 2.41ZM2.38 1.6a.78.78 0 0 0-.78.78V5a.78.78 0 0 0 .78.78h20.18a.79.79 0 0 0 .79-.78V2.38a.79.79 0 0 0-.79-.78Z"
    />
    <path
      fill="currentColor"
      d="M9.75 13.85v-2.6a.58.58 0 0 1 .87-.5L12.87 12l2.24 1.3a.58.58 0 0 1 0 1l-2.24 1.3-2.25 1.4a.58.58 0 0 1-.87-.51Z"
    />
  </svg>
);

export default CheckHistory;
