import React, { useMemo } from "react";

import { getBgColor } from "../../helpers/color";

const CustomFormIcon = ({ type, width = 40, height = 40 }) => {
  const bgColor = useMemo(() => getBgColor(type), [type]);
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 1080 1080"
      xmlSpace="preserve"
      className={`text-${type || "primary"}`}
      width={width}
      height={height}
    >
      <circle
        r={15}
        fill={bgColor}
        transform="translate(540 542.14) scale(35.66)"
        vectorEffect="non-scaling-stroke"
      />
      <g transform="translate(320 310) scale(0.9)">
        <path
          fill="currentColor"
          d="m433.798 106.268-96.423-91.222C327.119 5.343 313.695 0 299.577 0H116C85.673 0 61 24.673 61 55v402c0 30.327 24.673 55 55 55h280c30.327 0 55-24.673 55-55V146.222c0-15.049-6.27-29.612-17.202-39.954zM404.661 120H330c-2.757 0-5-2.243-5-5V44.636zM396 482H116c-13.785 0-25-11.215-25-25V55c0-13.785 11.215-25 25-25h179v85c0 19.299 15.701 35 35 35h91v307c0 13.785-11.215 25-25 25z"
        />
        <path
          fill="currentColor"
          d="M363 200H143c-8.284 0-15 6.716-15 15s6.716 15 15 15h220c8.284 0 15-6.716 15-15s-6.716-15-15-15zM363 280H143c-8.284 0-15 6.716-15 15s6.716 15 15 15h220c8.284 0 15-6.716 15-15s-6.716-15-15-15zM215.72 360H143c-8.284 0-15 6.716-15 15s6.716 15 15 15h72.72c8.284 0 15-6.716 15-15s-6.716-15-15-15z"
        />
      </g>
    </svg>
  );
};

export default CustomFormIcon;
