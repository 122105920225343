import ReactGa from "react-ga4";

const gaID =
  process.env.REACT_APP_GA4_MEASUREMENT_ID !== "NA"
    ? process.env.REACT_APP_GA4_MEASUREMENT_ID
    : null;

export const trackGaEvent = (action, label, category) => {
  if (gaID) {
    ReactGa.event({ action, label, category });
  }
};

export const trackGaRecommendedEvent = (action) => {
  if (gaID) {
    ReactGa.event(action);
  }
};
