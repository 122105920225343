import queryString from "query-string";

export const getAuthToken = () => {
  let token = localStorage.getItem("accessToken");
  return token ? `Bearer ${token}` : "";
};

export const getRefreshToken = () => localStorage.getItem("refreshToken");

export const isUserLoggedIn = () => !!getAuthToken();
export const setAuthTokens = (token, refreshToken) => {
  localStorage.setItem("accessToken", token);
  localStorage.setItem("refreshToken", refreshToken);
};
export const removeAuthTokens = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
};

export const getSignatureKey = () => {
  const path = window.location.pathname;
  if (path.includes("/verify/")) {
    return path.replace("/verify/", "");
  }
};

export const getToken = () => queryString.parse(window.location.search).token;

export const getDefaultHeaders = (headers) => {
  const authToken = getAuthToken();
  const authObject = authToken ? { Authorization: authToken } : {};
  return { headers: { ...authObject, ...headers } };
};
