import React, { useMemo } from "react";

import { getBgColor } from "../../helpers/color";

const FaceIDV = ({ type, width = 40, height = 40 }) => {
  const bgColor = useMemo(() => getBgColor(type), [type]);
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 48.91 48.91"
      xmlSpace="preserve"
      className={`text-${type || "primary"}`}
      width={width}
      height={height}
    >
      <circle cx={24.45} cy={24.45} r={24.45} fill={bgColor} />
      <path
        fill="currentColor"
        d="M24.47 24.37a6.23 6.23 0 1 1 6.23-6.23 6.23 6.23 0 0 1-6.23 6.23Zm0-10.86a4.63 4.63 0 1 0 4.63 4.63 4.64 4.64 0 0 0-4.63-4.63ZM32.3 34.45a.79.79 0 0 1-.8-.8v-2.12a4.4 4.4 0 0 0-4.3-4.48h-5.4a4.16 4.16 0 0 0-2.95 1.24 4.51 4.51 0 0 0-1.33 3.22v2.16a.8.8 0 0 1-1.6 0V31.5a6.1 6.1 0 0 1 1.8-4.35 5.75 5.75 0 0 1 4.08-1.7h5.4a6 6 0 0 1 5.9 6.08v2.15a.8.8 0 0 1-.8.77Z"
      />
    </svg>
  );
};

export default FaceIDV;
