import React from "react";

const Info = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 23.44 23.44"
    xmlSpace="preserve"
    width="16"
  >
    <path
      fill="currentColor"
      d="M11.72 23.44a11.72 11.72 0 1 1 11.72-11.72 11.74 11.74 0 0 1-11.72 11.72Zm0-21.84a10.12 10.12 0 1 0 10.12 10.12A10.13 10.13 0 0 0 11.72 1.6Z"
    />
    <path
      fill="currentColor"
      d="M11.72 17.49a.8.8 0 0 1-.8-.8V10.5a.8.8 0 1 1 1.6 0v6.19a.8.8 0 0 1-.8.8Z"
    />
    <circle cx={11.72} cy={7.07} r={1.22} fill="currentColor" />
  </svg>
);

export default Info;
