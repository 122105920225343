import { Formik } from "formik";
import PropTypes from "prop-types";
import RcIf, { RcElse } from "rc-if";
import React from "react";
import { FaPaperPlane, FaSpinner, FaTimes } from "react-icons/fa";
import { isValidPhoneNumber } from "react-phone-number-input";
import { Button } from "reactstrap";
import * as Yup from "yup";

import { PEP_COPY } from "../../../constants/checkTypes";
import { MOBILE_LABEL } from "../../../constants/lang/en";
import {
  NA,
  PARTIAL,
  PASS,
  PASS_ADDRESS_REVIEW,
} from "../../../constants/results";
import { MOBILE_MSG, REQUIRED_MSG } from "../../../constants/validationHelpers";
import PhoneField from "../../form/PhoneField";
import FormikScrollToErrors from "../../misc/FormikScrollToError";
import StepHeader from "../../misc/StepHeader";
import Result from "./Result";

const Results = ({
  overallResult,
  nameResult,
  birthDateResult,
  addressResult,
  pepResult,
  pdfUrl,
  onVerificationComplete,
  handleResetVerifyState,
  updateCheckUserAndResend,
  subHeader,
  noHeader,
  ctaText,
  checkFetchError,
  fetchingCheck,
  check,
  includeFaceMatch,
}) => {
  const mobileErrorMsg = MOBILE_MSG;
  const mobileError = checkFetchError === "Invalid phone" ? mobileErrorMsg : "";
  const sendFacematch =
    includeFaceMatch &&
    [PASS, PASS_ADDRESS_REVIEW, PARTIAL].includes(overallResult);

  React.useEffect(() => {
    if (sendFacematch) {
      updateCheckUserAndResend(null, "1 FaceMatch sent successfully.");
    }
  }, [sendFacematch, updateCheckUserAndResend]);

  const submitHandler = (data, { resetForm, setFieldError }) => {
    if (!isValidPhoneNumber(data.phone)) {
      setFieldError("phone", mobileErrorMsg);
      return;
    }
    updateCheckUserAndResend({ ...data }, "1 FaceMatch sent successfully.");
    resetForm();
  };
  return (
    <>
      <RcIf if={!noHeader}>
        <StepHeader text="ID Verification Results" subHeader={subHeader} />
      </RcIf>

      <div className="text-center mb-4">
        <Result size="lg" result={overallResult} />
      </div>

      <ul className="verify-result">
        <li className="verify-result__item">
          <div className="verify-result__content">Name</div>
          <div className="verify-result__actions">
            <Result result={nameResult} />
          </div>
        </li>

        <li className="verify-result__item">
          <div className="verify-result__content">Date of Birth</div>
          <div className="verify-result__actions">
            <Result result={birthDateResult} />
          </div>
        </li>

        <li className="verify-result__item">
          <div className="verify-result__content">Address</div>
          <div className="verify-result__actions">
            <Result result={addressResult} />
          </div>
        </li>

        {pepResult !== NA && (
          <li className="verify-result__item">
            <div className="verify-result__content">
              <abbr title="Politically Exposed Persons">{PEP_COPY}</abbr>
            </div>
            <div className="verify-result__actions">
              <Result result={pepResult} />
            </div>
          </li>
        )}

        <RcIf if={includeFaceMatch}>
          <li className="verify-result__item">
            <div className="verify-result__content">FaceMatch</div>
            {sendFacematch ? (
              <div className="verify-result__actions">
                {fetchingCheck ? (
                  <FaSpinner className="spin text-secondary" />
                ) : mobileError ? (
                  <span className="badge badge-danger badge-outline">
                    <span
                      className="mr-2"
                      style={{ position: "relative", top: "-1px" }}
                    >
                      <FaTimes />
                    </span>
                    Failed
                  </span>
                ) : (
                  <span className="badge badge-success badge-outline">
                    <span
                      className="mr-2"
                      style={{ position: "relative", top: "-1px" }}
                    >
                      <FaPaperPlane />
                    </span>
                    Sent
                  </span>
                )}
              </div>
            ) : (
              <div className="verify-result__actions">
                <Result result="NA" />
              </div>
            )}
          </li>
        </RcIf>
      </ul>

      <Formik
        validationSchema={Yup.object().shape({
          phone: Yup.string().trim().required(REQUIRED_MSG),
        })}
        onSubmit={submitHandler}
        initialValues={{ phone: check ? check.phone : "" }}
        enableReinitialize={true}
      >
        {({
          handleSubmit,
          errors,
          submitCount,
          setFieldValue,
          values,
          isSubmitting,
          isValidating,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <FormikScrollToErrors
              errors={errors}
              isSubmitting={isSubmitting}
              isValidating={isValidating}
            />
            <RcIf if={!!mobileError}>
              <PhoneField
                name="phone"
                value={values.phone}
                setFieldValue={setFieldValue}
                forceShowErrors={!!mobileError}
                error={errors.phone || mobileError}
                submitCount={submitCount}
                label={`${MOBILE_LABEL} Number`}
              />
            </RcIf>

            <RcIf if={!!onVerificationComplete}>
              <RcIf if={!!pdfUrl}>
                <div className="row align-items-center mt-5">
                  <div className="col-6">
                    <Button
                      color="primary"
                      outline
                      tag="a"
                      href={pdfUrl}
                      rel="noopener noreferrer"
                      target="_blank"
                      block
                    >
                      Download
                    </Button>
                  </div>
                  <div className="col-6 text-right">
                    <RcIf if={!!mobileError}>
                      <Button
                        color="primary"
                        type="submit"
                        className="px-5"
                        block
                        disabled={fetchingCheck}
                      >
                        Send
                      </Button>
                      <RcElse>
                        <Button
                          color="primary"
                          type="button"
                          className="px-5"
                          onClick={() => {
                            handleResetVerifyState();
                            onVerificationComplete();
                          }}
                          block
                        >
                          {ctaText}
                        </Button>
                      </RcElse>
                    </RcIf>
                  </div>
                </div>
                <RcElse>
                  <Button
                    color="primary"
                    type="button"
                    className="px-5"
                    onClick={() => {
                      handleResetVerifyState();
                      onVerificationComplete();
                    }}
                    block
                  >
                    {ctaText}
                  </Button>
                </RcElse>
              </RcIf>
              <RcElse>
                <p className="mt-3 m-0 small text-muted text-center mt-5">
                  Your results will be emailed to you. You can now close this
                  browser tab.
                </p>
              </RcElse>
            </RcIf>
          </form>
        )}
      </Formik>
    </>
  );
};

Results.propTypes = {
  overallResult: PropTypes.string,
  birthDateResult: PropTypes.string,
  addressResult: PropTypes.string,
  pepResult: PropTypes.string,
  pdfUrl: PropTypes.string,
  onVerificationComplete: PropTypes.func,
  handleResetVerifyState: PropTypes.func,
  updateCheckUserAndResend: PropTypes.func,
  subHeader: PropTypes.node,
  noHeader: PropTypes.bool,
  ctaText: PropTypes.string,
  includeFaceMatch: PropTypes.bool.isRequired,
  checkFetchError: PropTypes.string,
  fetchingCheck: PropTypes.bool,
};

Results.defaultProps = {
  includeFaceMatch: false,
};

export default Results;
