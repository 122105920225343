import React from "react";

const Trash = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 22.14 23.42"
    xmlSpace="preserve"
    width="16"
  >
    <path
      fill="currentColor"
      d="M17.38 23.42H4.75a2.43 2.43 0 0 1-2.38-2.48V8.19h1.6v12.75a.83.83 0 0 0 .78.88h12.63a.84.84 0 0 0 .79-.88V8.19h1.6v12.75a2.44 2.44 0 0 1-2.39 2.48Z"
    />
    <path
      fill="currentColor"
      d="M19.76 9.17H2.38A2.34 2.34 0 0 1 0 6.88V5.62a2.34 2.34 0 0 1 2.38-2.29h17.38a2.34 2.34 0 0 1 2.38 2.29v1.26a2.34 2.34 0 0 1-2.38 2.29ZM2.38 4.93a.74.74 0 0 0-.78.69v1.26a.74.74 0 0 0 .78.69h17.38a.74.74 0 0 0 .78-.69V5.62a.74.74 0 0 0-.78-.69Z"
    />
    <path
      fill="currentColor"
      d="M16.12 3.42h-1.6V1.6H7.61v1.82h-1.6v-2A1.46 1.46 0 0 1 7.47 0h7.2a1.45 1.45 0 0 1 1.45 1.45ZM8.85 19.52a.8.8 0 0 1-.8-.8v-6.6a.8.8 0 0 1 .8-.8.8.8 0 0 1 .8.8v6.6a.8.8 0 0 1-.8.8ZM13.29 19.52a.8.8 0 0 1-.8-.8v-6.6a.8.8 0 1 1 1.6 0v6.6a.8.8 0 0 1-.8.8Z"
    />
  </svg>
);

export default Trash;
