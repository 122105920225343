import { Formik } from "formik";
import React from "react";
import { connect } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Button } from "reactstrap";
import * as Yup from "yup";

import { EMAIL_MSG, REQUIRED_MSG } from "../../../constants/validationHelpers";
import { actions } from "../../../store/slices/user";
import Field from "../../form/Field";
import FormikScrollToErrors from "../../misc/FormikScrollToError";

const { saveUser } = actions;

const VALIDATION_RULES = Yup.object().shape({
  firstName: Yup.string().trim().required(REQUIRED_MSG),
  lastName: Yup.string().trim().required(REQUIRED_MSG),
  companyEmail: Yup.string().trim().required(REQUIRED_MSG).email(EMAIL_MSG),
});

const ProfileModal = ({
  showModal,
  toggleModal,
  fetching,
  saveUser,
  firstName,
  lastName,
  companyEmail,
}) => {
  const handleSaveUser = (data) => {
    saveUser(data, undefined, toggleModal);
  };

  return (
    <Modal isOpen={showModal} toggle={toggleModal} backdrop="static">
      <ModalHeader toggle={toggleModal}>Your Profile</ModalHeader>
      <ModalBody>
        <Formik
          validationSchema={VALIDATION_RULES}
          onSubmit={handleSaveUser}
          initialValues={{
            firstName,
            lastName,
            companyEmail,
          }}
          enableReinitialize={true}
        >
          {({
            handleSubmit,
            errors,
            submitCount,
            isSubmitting,
            isValidating,
          }) => (
            <form noValidate onSubmit={handleSubmit} className="mb-4">
              <FormikScrollToErrors
                errors={errors}
                isSubmitting={isSubmitting}
                isValidating={isValidating}
              />
              <div className="form-row">
                <div className="col-6">
                  <Field
                    name="firstName"
                    error={errors.firstName}
                    submitCount={submitCount}
                    label="First Name"
                    autoComplete="given-name"
                  />
                </div>
                <div className="col-6">
                  <Field
                    name="lastName"
                    error={errors.lastName}
                    submitCount={submitCount}
                    label="Last Name"
                    autoComplete="family-name"
                  />
                </div>
              </div>
              <Field
                name="companyEmail"
                error={errors.companyEmail}
                submitCount={submitCount}
                label="Send Completed Checks To"
                type="email"
                autoComplete="organization-email"
              />
              <Button
                color="primary"
                block
                size="lg"
                disabled={fetching}
                type="submit"
              >
                Save
              </Button>
            </form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

const mapStateToProps = ({ user }) => ({ ...user });
const mapDispatchToProps = { saveUser };

export default connect(mapStateToProps, mapDispatchToProps)(ProfileModal);
