import PropTypes from "prop-types";
import React from "react";

import Field from "../../../form/Field";

const AuPassport = ({ errors, submitCount }) => {
  return (
    <Field
      name="nationalId"
      error={errors.nationalId}
      submitCount={submitCount}
      label="Passport"
      placeholder="PA1234567"
    />
  );
};

AuPassport.propTypes = {
  errors: PropTypes.object.isRequired,
  submitCount: PropTypes.number.isRequired,
};

export default AuPassport;
