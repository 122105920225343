import PropTypes from "prop-types";
import React from "react";
import { FaSpinner } from "react-icons/fa";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tippy";
import Toggle from "react-toggle";
import { Button } from "reactstrap";

import {
  MOBILE_LABEL,
  SEND_EMAIL_ATTACHMENTS_DISABLED,
  SEND_EMAIL_ATTACHMENTS_ENABLED,
  TWO_FACTOR_AUTH_DISABLED,
  TWO_FACTOR_AUTH_ENABLED,
} from "../../../constants/lang/en";
import routes from "../../../routes";
import DashboardLayout from "../../dashboard/Presenters/Layout";
import Info from "../../icons/InfoIcon";
import StepLayout from "../../misc/StepLayout";
import MobileModal from "./MobileModal";
import ProfileModal from "./ProfileModal";

const Profile = ({
  email,
  mobile,
  agencyName,
  firstName,
  lastName,
  companyEmail,
  saveUser,
  twoFactorAuthEnabled,
  sendEmailAttachment,
  fetching,
  mobileVerified,
}) => {
  const [showProfileModal, setShowProfileModal] = React.useState(false);
  const [showMobileModal, setShowMobileModal] = React.useState(false);
  const [show2FAMobileModal, setShow2FAMobileModal] = React.useState(false);

  const handleEmailAttachmentChange = () => {
    saveUser(
      { sendEmailAttachment: !sendEmailAttachment },
      sendEmailAttachment
        ? SEND_EMAIL_ATTACHMENTS_DISABLED
        : SEND_EMAIL_ATTACHMENTS_ENABLED
    );
  };

  const handle2FAChange = () => {
    if (twoFactorAuthEnabled) {
      const response = window.confirm(
        "Are you sure you want to remove two-factor authentication (2FA)? 2FA provides additional security for your account."
      );
      if (response)
        saveUser({ twoFactorAuthEnabled: false }, TWO_FACTOR_AUTH_DISABLED);
    } else {
      if (mobileVerified) {
        onMobileVerify();
      } else {
        setShow2FAMobileModal(true);
      }
    }
  };

  const onMobileVerify = () => {
    saveUser({ twoFactorAuthEnabled: true }, TWO_FACTOR_AUTH_ENABLED);
  };

  return (
    <DashboardLayout>
      <StepLayout>
        <section>
          <div className="d-flex align-items-center mb-4">
            <h4 className="mb-0">Profile</h4>
          </div>
          <div className="d-flex align-items-center mb-3">
            <span>Name:</span>
            <strong className="ml-2">
              {firstName || lastName ? `${firstName} ${lastName}` : "-"}
            </strong>
            <Button
              color="link"
              className="ml-2 mt-1 p-0 flex-shrink-0"
              onClick={() => setShowProfileModal(true)}
            >
              Change
            </Button>
          </div>
          <div className="d-flex align-items-center mb-3">
            <span>Send Completed Checks To:</span>
            <strong className="ml-2">{companyEmail}</strong>
            <Button
              color="link"
              className="ml-2 mt-1 p-0 flex-shrink-0"
              onClick={() => setShowProfileModal(true)}
            >
              Change
            </Button>
          </div>
          <div className="d-flex align-items-center">
            <span>Email:</span>
            <strong className="ml-2">{email}</strong>
          </div>
        </section>

        <hr className="my-4" />

        <section>
          <h4 className="mb-4">Security</h4>
          {/* <div className="d-flex align-items-center mb-3">
            <label className="d-flex align-items-center cursor-pointer mt-2">
              <Toggle
                onChange={handleEmailAttachmentChange}
                defaultChecked={sendEmailAttachment}
                icons={false}
                disabled={fetching}
              />
              <span className="mx-2">Receive PDF reports via email</span>
            </label>
            <Tooltip
              title="You can prevent personally identifiable information (PII) being sent via email."
              position="top"
              size="small"
              className="mt-n1 text-muted"
            >
              <Info />
            </Tooltip>
            {fetching && <FaSpinner className="ml-2 spin text-secondary" />}
          </div> */}
          <div className="d-flex align-items-center mb-3">
            <label className="d-flex align-items-center cursor-pointer mt-2">
              <Toggle
                onChange={handle2FAChange}
                checked={twoFactorAuthEnabled}
                icons={false}
                disabled={fetching}
              />
              <span className="mx-2">
                Two-factor authentication (2FA) via SMS
              </span>
            </label>
            <Tooltip
              title="You can implement an additional layer of security when you log in to your account."
              position="bottom"
              size="small"
              className="mt-n1 text-muted"
            >
              <Info />
            </Tooltip>
            {fetching && <FaSpinner className="ml-2 spin text-secondary" />}
          </div>

          <div className="d-flex align-items-center mb-3">
            <span>{MOBILE_LABEL} No:</span>
            <strong className="ml-2">
              {mobile ? formatPhoneNumberIntl(mobile) : "N/A"}
            </strong>
            <Button
              color="link"
              className="ml-2 mt-1 p-0"
              onClick={() => setShowMobileModal(true)}
            >
              {mobile ? "Change" : "Add"}
            </Button>
          </div>

          <div className="d-flex align-items-center">
            <Link to={routes.changePassword}>Change password</Link>
          </div>
        </section>
      </StepLayout>

      {showProfileModal && (
        <ProfileModal
          showModal={showProfileModal}
          toggleModal={() => setShowProfileModal((state) => !state)}
        />
      )}
      {showMobileModal && (
        <MobileModal
          showModal={showMobileModal}
          toggleModal={() => setShowMobileModal((state) => !state)}
        />
      )}
      {show2FAMobileModal && (
        <MobileModal
          showModal={show2FAMobileModal}
          toggleModal={() => setShow2FAMobileModal((state) => !state)}
          onVerify={onMobileVerify}
        />
      )}
    </DashboardLayout>
  );
};

Profile.propTypes = {
  fetching: PropTypes.bool.isRequired,
  saveUser: PropTypes.func.isRequired,
};

export default Profile;
