import PropTypes from "prop-types";
import RcIf from "rc-if";
import React from "react";

import currencyFormatter from "../../../helpers/currency";
import { timestampToSimpleDate } from "../../../helpers/date";

const UpcomingInvoice = ({
  paymentType,
  dueDate,
  nextPaymentAttempt,
  amountRemaining,
  number,
}) => (
  <RcIf if={!!number}>
    <section>
      <h2 className="h5 text-muted text-center">
        Upcoming {paymentType === "CARD" ? "Payment" : "Invoice"}
      </h2>
      <div className="site-form text-center">
        <p className="text-muted mt-4">#{number}</p>
        <p className="display-4 text-muted">
          {currencyFormatter(amountRemaining)}
        </p>
        <RcIf if={!!dueDate}>
          <p className="text-muted">Due on {timestampToSimpleDate(dueDate)}</p>
        </RcIf>
        <RcIf if={!!nextPaymentAttempt}>
          <p className="text-muted">
            On {timestampToSimpleDate(nextPaymentAttempt)}
          </p>
        </RcIf>
      </div>
    </section>
  </RcIf>
);

UpcomingInvoice.propTypes = {
  dueDate: PropTypes.number,
  nextPaymentAttempt: PropTypes.number,
  amountRemaining: PropTypes.number,
  number: PropTypes.string,
  paymentType: PropTypes.string,
};

export default UpcomingInvoice;
