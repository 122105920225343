import React from "react";

const Billing = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 23.44 23.44"
    xmlSpace="preserve"
  >
    <circle
      cx={11.72}
      cy={11.72}
      r={10.92}
      stroke="currentColor"
      fill="none"
      strokeMiterlimit="10"
      strokeWidth="1.6px"
    />
    <path
      d="M11.76 11.62c-1.71 0-3.1-1.23-3.1-2.75s1.39-2.75 3.1-2.75 3.1 1.23 3.1 2.75M11.76 11.62c1.71 0 3.1 1.23 3.1 2.75s-1.39 2.75-3.1 2.75-3.1-1.23-3.1-2.75"
      strokeLinecap="round"
      fill="none"
      strokeMiterlimit="10"
      strokeWidth="1.6px"
      stroke="currentColor"
    />
    <path
      d="M11.76 6.12V4.7M11.76 18.53v-1.41"
      strokeLinecap="round"
      fill="none"
      strokeMiterlimit="10"
      strokeWidth="1.6px"
      stroke="currentColor"
    />
  </svg>
);

export default Billing;
