import { useFormikContext } from "formik";
import React, { useCallback } from "react";
import { Button, ButtonGroup } from "reactstrap";

import {
  NATIONAL_ID_DRIVER_LICENCE,
  NATIONAL_ID_PASSPORT,
} from "../../../../constants/nationalIdType";
import { AU_LICENCE_STATES_WTIH_CARD_NUMBER } from "../../../../constants/supportedCountries";
import AuDriverLicence from "./AuDriverLicence";
import AuPassport from "./AuPassport";

const AuId = () => {
  const { values, setFieldValue, errors, submitCount } = useFormikContext();
  const { nationalIdType } = values;
  const setShowAuPassport = useCallback(
    () => setFieldValue("nationalIdType", NATIONAL_ID_PASSPORT),
    [setFieldValue]
  );
  const setShowAuLicence = useCallback(
    () => setFieldValue("nationalIdType", NATIONAL_ID_DRIVER_LICENCE),
    [setFieldValue]
  );

  return (
    <>
      <ButtonGroup className="w-100 mb-3">
        <Button
          className="w-25 border-secondary font-weight-normal btn-hover-primary"
          outline={nationalIdType !== NATIONAL_ID_DRIVER_LICENCE}
          color={
            nationalIdType !== NATIONAL_ID_DRIVER_LICENCE
              ? "secondary"
              : "primary"
          }
          onClick={setShowAuLicence}
        >
          Driver Licence
        </Button>
        <Button
          className="w-25 border-secondary font-weight-normal btn-hover-primary"
          outline={nationalIdType !== NATIONAL_ID_PASSPORT}
          color={
            nationalIdType !== NATIONAL_ID_PASSPORT ? "secondary" : "primary"
          }
          onClick={setShowAuPassport}
        >
          Passport
        </Button>
      </ButtonGroup>

      {nationalIdType === NATIONAL_ID_PASSPORT ? (
        <AuPassport errors={errors} submitCount={submitCount} />
      ) : (
        <AuDriverLicence
          errors={errors}
          submitCount={submitCount}
          showCardNumber={AU_LICENCE_STATES_WTIH_CARD_NUMBER.includes(
            values.nationalIdSecondary || ""
          )}
        />
      )}
    </>
  );
};

AuId.propTypes = {};

export default AuId;
