import React from "react";

import Layout from "./Layout";

const VerificationExpired = () => (
  <Layout
    bodyText="This check has already been completed. For any queries please get in
    touch with your agent."
  />
);
export default VerificationExpired;
