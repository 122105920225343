import PropTypes from "prop-types";
import RcIf, { RcElse } from "rc-if";
import React from "react";
import { Button } from "reactstrap";

import { PEP_COPY } from "../../../constants/checkTypes";
import Result from "../../verification/Presenters/Result";

const PepResults = ({
  pepResult,
  pdfUrl,
  onVerificationComplete,
  handleResetVerifyState,
  activeCheck,
  totalChecks,
}) => (
  <>
    <RcIf if={!!totalChecks}>
      <h3 className="text-center h6 mb-4">
        Client: {activeCheck + 1} of {totalChecks}
      </h3>
    </RcIf>
    <div className="text-center mb-4">
      <RcIf if={!!pepResult}>
        <>
          <h4>
            <abbr title="Politically Exposed Persons">{PEP_COPY}</abbr>
          </h4>
          <Result size="lg" result={pepResult} />
        </>
        <RcElse>
          <p className="lead">Your files have been successfully uploaded</p>
        </RcElse>
      </RcIf>
    </div>

    <RcIf if={!!pdfUrl}>
      <div className="row align-items-center mt-5">
        <div className="col-6">
          <Button
            color="primary"
            outline
            tag="a"
            href={pdfUrl}
            rel="noopener noreferrer"
            target="_blank"
            block
          >
            Download
          </Button>
        </div>
        <div className="col-6 text-right">
          <Button
            color="primary"
            type="button"
            className="px-5"
            onClick={() => {
              handleResetVerifyState();
              onVerificationComplete();
            }}
            block
          >
            {activeCheck + 1 === totalChecks ? "Done" : "Next"}
          </Button>
        </div>
      </div>
      <RcElse>
        <Button
          color="primary"
          type="button"
          className="px-5"
          onClick={() => {
            handleResetVerifyState();
            onVerificationComplete();
          }}
          block
        >
          {activeCheck + 1 === totalChecks ? "Done" : "Next"}
        </Button>
      </RcElse>
    </RcIf>
  </>
);

PepResults.propTypes = {
  pepResult: PropTypes.string,
  pdfUrl: PropTypes.string,
  onVerificationComplete: PropTypes.func,
  handleResetVerifyState: PropTypes.func.isRequired,
  activeCheck: PropTypes.number,
  totalChecks: PropTypes.number,
};

export default PepResults;
