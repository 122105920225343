import React from "react";
import { connect } from "react-redux";

import { actions } from "../../store/slices/auth";
import AccountUnlockPresenter from "./Presenters/AccountUnlock";

const { checkAccountUnlock, accountUnlock } = actions;

const AccountUnlock = ({ checkAccountUnlock, accountUnlock, auth }) => (
  <AccountUnlockPresenter
    {...auth}
    accountUnlock={accountUnlock}
    checkAccountUnlock={checkAccountUnlock}
  />
);

const mapStateToProps = ({ auth }) => ({ auth });
const mapDispatchToProps = { checkAccountUnlock, accountUnlock };

export default connect(mapStateToProps, mapDispatchToProps)(AccountUnlock);
