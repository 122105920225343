import { useFormikContext } from "formik";
import React, { useCallback } from "react";
import { Button, ButtonGroup } from "reactstrap";

import {
  NATIONAL_ID_BIRTH_CERTIFICATE,
  NATIONAL_ID_DRIVER_LICENCE,
  NATIONAL_ID_PASSPORT,
} from "../../../../constants/nationalIdType";
import NzDriverLicence from "./NzDriverLicence";
import NzPassport from "./NzPassport";

const NzId = () => {
  const { values, errors, submitCount, setFieldValue } = useFormikContext();
  const { nationalIdType } = values;
  const setShowNzPassport = useCallback(
    () => setFieldValue("nationalIdType", NATIONAL_ID_PASSPORT),
    [setFieldValue]
  );
  const setShowNzLicence = useCallback(
    () => setFieldValue("nationalIdType", NATIONAL_ID_DRIVER_LICENCE),
    [setFieldValue]
  );
  const setShowNzBirth = useCallback(
    () => setFieldValue("nationalIdType", NATIONAL_ID_BIRTH_CERTIFICATE),
    [setFieldValue]
  );

  return (
    <>
      <ButtonGroup className="w-100 mb-3">
        <Button
          className="w-25 border-secondary font-weight-normal btn-hover-primary"
          outline={nationalIdType !== NATIONAL_ID_DRIVER_LICENCE}
          color={
            nationalIdType !== NATIONAL_ID_DRIVER_LICENCE
              ? "secondary"
              : "primary"
          }
          onClick={setShowNzLicence}
        >
          Driver Licence
        </Button>
        <Button
          className="w-25 border-secondary font-weight-normal btn-hover-primary"
          outline={nationalIdType !== NATIONAL_ID_PASSPORT}
          color={
            nationalIdType !== NATIONAL_ID_PASSPORT ? "secondary" : "primary"
          }
          onClick={setShowNzPassport}
        >
          Passport
        </Button>
        <Button
          className="w-25 border-secondary font-weight-normal btn-hover-primary"
          outline={nationalIdType !== NATIONAL_ID_BIRTH_CERTIFICATE}
          color={
            nationalIdType !== NATIONAL_ID_BIRTH_CERTIFICATE
              ? "secondary"
              : "primary"
          }
          onClick={setShowNzBirth}
        >
          Birth Certificate
        </Button>
      </ButtonGroup>

      {nationalIdType === NATIONAL_ID_PASSPORT ? (
        <NzPassport
          values={values}
          errors={errors}
          submitCount={submitCount}
          setFieldValue={setFieldValue}
        />
      ) : nationalIdType === NATIONAL_ID_DRIVER_LICENCE ? (
        <NzDriverLicence errors={errors} submitCount={submitCount} />
      ) : nationalIdType === NATIONAL_ID_BIRTH_CERTIFICATE ? (
        <div className="text-sm mb-5">
          Please ensure your name is entered above exactly how it is displayed
          on your birth certificate.
        </div>
      ) : null}
    </>
  );
};

NzId.propTypes = {};

export default NzId;
