import PropTypes from "prop-types";
import React, { useState } from "react";
import { Button } from "reactstrap";

import Eye from "../icons/EyeIcon";
import Field from "./Field";

const PasswordField = ({
  name,
  error,
  submitCount,
  label,
  autoFocus,
  disabled,
  autoComplete = "off",
}) => {
  const [isVisible, setVisibility] = useState(false);
  return (
    <div className="form-group abs-btn-wrap">
      <Field
        name={name}
        error={error}
        submitCount={submitCount}
        label={label}
        autoComplete={autoComplete}
        type={isVisible ? "text" : "password"}
        autoFocus={autoFocus}
        disabled={disabled}
      />
      <Button
        type="button"
        color="link"
        size="sm"
        className={`abs-btn ${isVisible ? "strike-out" : ""}`}
        onClick={() => setVisibility((v) => !v)}
      >
        <Eye />
      </Button>
    </div>
  );
};

PasswordField.propTypes = {
  name: PropTypes.string.isRequired,
  error: PropTypes.string,
  submitCount: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default PasswordField;
