import PropTypes from "prop-types";
import React from "react";

import Field from "../../../form/Field";

const NzDriverLicence = ({ errors, submitCount }) => {
  return (
    <div className="form-row">
      <div className="col-8">
        <Field
          name="nationalId"
          error={errors.nationalId}
          submitCount={submitCount}
          label="Licence"
          placeholder="DS123456"
          maxLength="8"
        />
      </div>

      <div className="col-4">
        <Field
          name="nationalIdSecondary"
          error={errors.nationalIdSecondary}
          submitCount={submitCount}
          label="Version"
          placeholder="123"
          maxLength="3"
        />
      </div>
    </div>
  );
};

NzDriverLicence.propTypes = {
  errors: PropTypes.object.isRequired,
  submitCount: PropTypes.number.isRequired,
};

export default NzDriverLicence;
