import React from "react";

const Profile = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 23.44 23.44"
    xmlSpace="preserve"
  >
    <circle
      cx={11.72}
      cy={11.72}
      r={10.92}
      stroke="currentColor"
      fill="none"
      strokeMiterlimit="10"
      strokeWidth="1.6px"
    />
    <path
      d="M15.77 8.72a4.02 4.02 0 1 1-8.04 0 4.02 4.02 0 0 1 8.04 0Z"
      fill="none"
      strokeMiterlimit="10"
      strokeWidth="1.6px"
      stroke="currentColor"
    />
    <path
      d="M5.99 20.5v-1.59c0-2.15 1.7-3.9 3.77-3.89h3.99c2.07.01 3.77 1.77 3.77 3.92v1.59"
      fill="none"
      strokeMiterlimit="10"
      strokeWidth="1.6px"
      stroke="currentColor"
      strokeLinecap="round"
    />
  </svg>
);

export default Profile;
