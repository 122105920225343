import React from "react";

const Filter = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 22.25 22.31"
    xmlSpace="preserve"
    width={16}
  >
    <path
      fill="currentColor"
      d="M13.03 22.31a1.44 1.44 0 0 1-.86-.29L8.13 19a1.45 1.45 0 0 1-.59-1.16V9.66L.42 2.54a1.46 1.46 0 0 1 1-2.48L20.79 0a1.45 1.45 0 0 1 1 2.47l-7.3 7.3v11.09a1.43 1.43 0 0 1-.8 1.29 1.36 1.36 0 0 1-.66.16ZM9.1 17.76l3.78 2.8V9.71a1.4 1.4 0 0 1 .43-1l7.12-7.11-18.62.06 6.91 6.91a1.47 1.47 0 0 1 .42 1ZM7.59 9.7Z"
    />
  </svg>
);

export default Filter;
