import { createAction, createReducer } from "@reduxjs/toolkit";
import { toastr } from "react-redux-toastr";

import AuthApi from "../../api/auth";
import BillingApi from "../../api/billing";
import UserApi from "../../api/user";
import {
  CHANGE_DEFAULT_PASSWORD,
  CHANGE_EXPIRED_PASSWORD,
  DEFAULT_ERROR,
  EMAIL_VERIFIED,
  MOBILE_CODE_NOT_VALID,
  MOBILE_NOT_VALID,
  MOBILE_UPDATED,
  PASSWORDS_NO_MATCH,
  PROFILE_UPDATED,
  SETTINGS_UPDATED,
  VERIFY_EMAIL_FAILED,
  VERIFY_EMAIL_SUCCESS,
} from "../../constants/lang/en";
import { triggerNewPaymentEvent } from "../../helpers/intercom";
import logToSentryOrConsole from "../../helpers/sentry";
import { removeAuthTokens } from "../../helpers/token";
import { getToken } from "../../helpers/token";
import history from "../../history";
import routes from "../../routes";

const INITIAL_STATE = {
  fetching: false,
  fetchError: "",
  fetchCompleted: false,
  paymentInComplete: false,
  passwordInComplete: false,
  emailNotVerified: false,
  hideIntercom: true,
  paymentInReview: false,
  is_default_password: false,
  change_password: false,
  send_email_attachment: true,
  two_factor_auth_enabled: false,
  mobile: "",
  firstName: "",
  lastName: "",
  mobileVerified: false,
  defaultCountryCode: "NZ",
  defaultQuickIDFacematchCombo: "quick_id_facematch",
  quickIDAlgorithm: "2x2_strict",
  showOutsourcedMenu: false,
  showComplianceMenu: false,
  dashboardBrand: null,
  allowMixAndMatch: false,
  dashboardMenus: [],
  dashboardMenusExternalLinksTop: [],
  dashboardMenusExternalLinksBottom: [],
  dashboardShowPrice: true,
  dashboardCanDelete: true,
  dashboardCanEdit: true,
  dashboardCanApprove: true,
  dashboardCanReset: true,
  dashboardCanManageDocs: true,
  allowedCountries: [],
  sources: null,
  pepCompanyAllowed: false,
  quickIdvPricing: null,
  faceIdvPricing: null,
  facematchPricing: null,
  pepPricing: null,
  pepCompanyPricing: null,
  pepForFacematch: false,
  role: "MEMBER", // MEMBER or ADMIN or MANAGER
  industry: "Legal",
};

const fetchUserStarted = createAction("fetchUserStarted");
const fetchUserCompleted = createAction("fetchUserCompleted");
const fetchUserFailed = createAction("fetchUserFailed");
const setAgencyData = createAction("setAgencyData");
const setUserData = createAction("setUserData");
const resetUserData = createAction("resetUserData");
const setInCompleteProfile = createAction("setInCompleteProfile");

const errorHandler = (dispatch, errorTitle = "", errorMsg = DEFAULT_ERROR) => {
  toastr.error(errorTitle, errorMsg);
  dispatch(fetchUserFailed(errorMsg));
};

const fetchUser =
  ({ followDefaultPasswordRedirect = false } = {}) =>
  async (dispatch) => {
    dispatch(fetchUserStarted());
    try {
      const result = await AuthApi.checkSession();

      if (!result || !result.data) {
        return;
      }

      dispatch(fetchUserCompleted());
      dispatch(setUserData(result.data));
      if (
        followDefaultPasswordRedirect &&
        result.data.userprofile &&
        result.data.userprofile.is_default_password
      ) {
        toastr.info("", CHANGE_DEFAULT_PASSWORD);
        history.push(routes.changePassword);
      }
      if (
        followDefaultPasswordRedirect &&
        result.data.userprofile &&
        result.data.userprofile.change_password
      ) {
        toastr.info("", CHANGE_EXPIRED_PASSWORD);
        history.push(routes.changePassword);
      }
    } catch (error) {
      logToSentryOrConsole(error);
    }
  };

const saveUser =
  (data, message = PROFILE_UPDATED, onSuccess) =>
  async (dispatch) => {
    dispatch(fetchUserStarted());
    try {
      const result = await UserApi.updateUser(data);
      dispatch(fetchUserCompleted());

      if (!result || !result.data) {
        return;
      }

      toastr.success("", message);
      dispatch(setUserData(result.data));
      if (onSuccess) onSuccess();
    } catch (error) {
      if (error && error.response && error.response.status === 400) {
        errorHandler(
          dispatch,
          "",
          error.response && error.response.data
            ? error.response.data[0]
            : undefined
        );
      } else {
        logToSentryOrConsole(error);
        errorHandler(dispatch);
      }
    }
  };

const saveAgency =
  (data, message = SETTINGS_UPDATED, onSuccess) =>
  async (dispatch) => {
    dispatch(fetchUserStarted());
    try {
      const result = await UserApi.updateAgency(data);
      dispatch(fetchUserCompleted());

      if (!result || !result.data) {
        return;
      }

      toastr.success("", message);
      dispatch(setAgencyData(result.data));
      if (onSuccess) onSuccess();
    } catch (error) {
      logToSentryOrConsole(error);
      errorHandler(dispatch);
    }
  };

const sendMobileCode =
  ({ mobile }, onSuccess) =>
  async (dispatch) => {
    dispatch(fetchUserStarted());
    try {
      await UserApi.sendMobileCode({ mobile });
      dispatch(fetchUserCompleted());
      onSuccess();
    } catch (error) {
      if (error && error.response && error.response.status === 400) {
        errorHandler(dispatch, "", MOBILE_NOT_VALID);
      } else {
        logToSentryOrConsole(error);
        errorHandler(dispatch);
      }
    }
  };

const checkAndSaveMobile =
  ({ mobile, code }, onSuccess) =>
  async (dispatch) => {
    dispatch(fetchUserStarted());
    try {
      await UserApi.checkAndSaveMobile({ mobile, code });
      dispatch(fetchUserCompleted());
      toastr.success("", MOBILE_UPDATED);
      dispatch(fetchUser());
      onSuccess();
    } catch (error) {
      if (error && error.response && error.response.status === 400) {
        errorHandler(dispatch, "", MOBILE_CODE_NOT_VALID);
      } else {
        logToSentryOrConsole(error);
        errorHandler(dispatch);
      }
    }
  };

const changeUserPassword = (data) => async (dispatch) => {
  dispatch(fetchUserStarted());
  if (data.newPassword !== data.confirmPassword) {
    errorHandler(dispatch, "", PASSWORDS_NO_MATCH);
    return;
  }
  if (data.newPassword === data.oldPassword) {
    errorHandler(
      dispatch,
      "",
      "New password should be different from old password."
    );
    return;
  }
  try {
    await UserApi.changePassword({
      oldPassword: data.oldPassword?.trim(),
      password: data.newPassword?.trim(),
    });
    dispatch(fetchUserCompleted());
    history.push(routes.profile);
    toastr.success("", "Password changed successfully, please login again.");
    dispatch(signOut());
    // dispatch(fetchUser());
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      errorHandler(dispatch, "", "Please enter correct old password.");
    } else {
      logToSentryOrConsole(error);
      errorHandler(dispatch);
    }
  }
};

const completeProfile =
  ({ agentPassword, stripeTokenId }) =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchUserStarted());
      const {
        user: { paymentInComplete },
      } = getState();
      if (agentPassword) {
        await UserApi.changePassword({ password: agentPassword?.trim() });
      }
      if (paymentInComplete) {
        const result = await BillingApi.addCreditCard({
          stripeToken: stripeTokenId,
        });

        if (!result || !result.data) {
          return;
        }

        triggerNewPaymentEvent({
          paymentType: result.data.card_last4 ? "CARD" : "INVOICE",
        });
        dispatch(fetchUser());
      }
      dispatch(setInCompleteProfile({}));
      dispatch(fetchUserCompleted());
    } catch (error) {
      logToSentryOrConsole(error);
      errorHandler(dispatch);
    }
  };

const signOut = () => (dispatch) => {
  removeAuthTokens();
  dispatch(resetUserData());
  history.push(routes.signIn);
};

const handleSetInCompleteProfile =
  ({
    payment,
    email_not_verified: emailNotVerified,
    password_set: passwordSet,
  }) =>
  async (dispatch) => {
    dispatch(setInCompleteProfile({ payment, emailNotVerified, passwordSet }));
  };

const sendEmailVerificationToken = () => async (dispatch, getState) => {
  const {
    user: { email },
  } = getState();
  dispatch(fetchUserStarted());
  try {
    await UserApi.sendEmailVerificationToken({ username: email });
    dispatch(fetchUserCompleted());
    toastr.success("", VERIFY_EMAIL_SUCCESS);
  } catch (error) {
    logToSentryOrConsole(error);
    errorHandler(dispatch);
  }
};

const verifyEmail = () => async (dispatch) => {
  dispatch(fetchUserStarted());
  try {
    await UserApi.verifyEmail({
      token: getToken(),
    });
    dispatch(fetchUserCompleted());
    dispatch(fetchUser());
    toastr.success("", EMAIL_VERIFIED);
    history.push(routes.home);
  } catch (error) {
    logToSentryOrConsole(error);
    errorHandler(dispatch, "", VERIFY_EMAIL_FAILED);
  }
};

export const actions = {
  signOut,
  fetchUser,
  saveUser,
  saveAgency,
  checkAndSaveMobile,
  sendMobileCode,
  changeUserPassword,
  completeProfile,
  handleSetInCompleteProfile,
  sendEmailVerificationToken,
  verifyEmail,
};

export const reducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(fetchUserStarted, (state) => {
      state.fetching = true;
      state.fetchError = "";
      state.fetchCompleted = false;
    })
    .addCase(fetchUserCompleted, (state) => {
      state.fetching = false;
      state.fetchCompleted = true;
    })
    .addCase(fetchUserFailed, (state, { payload }) => {
      state.fetching = false;
      state.fetchError = payload;
    })
    .addCase(setAgencyData, (state, { payload }) => {
      state.agencyName = payload.name;
      state.paymentInReview = Boolean(payload.payment_in_review);
      state.completedFreeCheckCount = payload.completed_free_checks_count;
      state.availableFreeCheckCount = payload.available_free_checks_count;
      state.agencyId = payload.id;
      state.referrer = payload.referrer;
      state.deleteCompletedChecksInDays =
        payload.delete_completed_checks_in_days;
      state.sendVerificationReminders = payload.send_verification_reminders;
    })
    .addCase(setUserData, (state, { payload }) => {
      state.id = payload.id;
      state.email = payload.username;
      state.companyEmail = payload.email;
      state.dateJoined = payload.date_joined;
      state.hideIntercom = payload.hide_intercom;
      state.firstName = payload.first_name;
      state.lastName = payload.last_name;
      const agencies = payload.agency_set;
      if (agencies && agencies.length) {
        const lastAgency = agencies[agencies.length - 1];
        state.agencyName = lastAgency.name;
        state.paymentInReview = Boolean(lastAgency.payment_in_review);
        state.completedFreeCheckCount = lastAgency.completed_free_checks_count;
        state.availableFreeCheckCount = lastAgency.available_free_checks_count;
        state.agencyId = lastAgency.id;
        state.referrer = lastAgency.referrer;
        state.industry = lastAgency.industry;
        state.deleteCompletedChecksInDays =
          lastAgency.delete_completed_checks_in_days;
        state.sendVerificationReminders =
          lastAgency.send_verification_reminders;
        state.dashboardBrand = payload.dashboard_brand;
        state.showOutsourcedMenu = lastAgency.show_outsourced_menu;
        state.showComplianceMenu = lastAgency.show_compliance_menu;
        state.allowMixAndMatch = lastAgency.allow_mix_and_match;
        state.dashboardMenus = lastAgency.dashboard_menus;
        state.pepForFacematch = lastAgency.pep_for_facematch_option;
        state.dashboardShowPrice = lastAgency.dashboard_show_price;
        state.dashboardCanDelete = lastAgency.dashboard_can_delete;
        state.dashboardCanEdit = lastAgency.dashboard_can_edit;
        state.dashboardCanApprove = lastAgency.dashboard_can_approve;
        state.dashboardCanReset = lastAgency.dashboard_can_reset;
        state.dashboardCanManageDocs = lastAgency.dashboard_can_manage_docs;
        if (lastAgency.dashboard_menus_external_links) {
          const parsedExternalLinks =
            lastAgency.dashboard_menus_external_links.map((externalLinks) => ({
              link: (externalLinks.link || "")
                .trim()
                .replace("{agent_email}", encodeURIComponent(payload.username))
                .replace(
                  "{agent_first_name}",
                  encodeURIComponent(payload.first_name)
                )
                .replace(
                  "{agent_last_name}",
                  encodeURIComponent(payload.last_name)
                )
                .replace("{agency_name}", encodeURIComponent(lastAgency.name)),
              title: (externalLinks.title || "")
                .trim()
                .replace("{agent_email}", encodeURIComponent(payload.username))
                .replace(
                  "{agent_first_name}",
                  encodeURIComponent(payload.first_name)
                )
                .replace(
                  "{agent_last_name}",
                  encodeURIComponent(payload.last_name)
                )
                .replace("{agency_name}", encodeURIComponent(lastAgency.name)),
              description: (externalLinks.description || "")
                .trim()
                .replace("{agent_email}", encodeURIComponent(payload.username))
                .replace(
                  "{agent_first_name}",
                  encodeURIComponent(payload.first_name)
                )
                .replace(
                  "{agent_last_name}",
                  encodeURIComponent(payload.last_name)
                )
                .replace("{agency_name}", encodeURIComponent(lastAgency.name)),
              type: externalLinks.type,
              position: externalLinks.position,
            }));
          state.dashboardMenusExternalLinksTop = parsedExternalLinks.filter(
            (l) => l.position === "TOP"
          );
          state.dashboardMenusExternalLinksBottom = parsedExternalLinks.filter(
            (l) => l.position === "BOTTOM"
          );
        }
        state.allowedCountries = lastAgency.allowed_countries;
        state.faceMatchNumberOfDocs =
          lastAgency.facematch_number_of_docs_option;
        state.sources = lastAgency.sources;
        state.pepCompanyAllowed = lastAgency.pep_company_available;
        state.quickIdvPricing = lastAgency.quick_idv_pricing;
        state.faceIdvPricing = lastAgency.face_idv_pricing;
        state.facematchPricing = lastAgency.facematch_pricing;
        state.pepPricing = lastAgency.pep_pricing;
        state.pepCompanyPricing = lastAgency.pep_company_pricing;
      }
      const profile = payload.userprofile;
      if (profile) {
        state.isDefaultPassword = profile.is_default_password;
        state.sendEmailAttachment = profile.send_email_attachment;
        state.twoFactorAuthEnabled = profile.two_factor_auth_enabled;
        state.mobile = profile.mobile;
        state.mobileVerified = profile.mobile_verified;
        state.role = profile.user_role;
      }
      state.defaultCountryCode = payload.default_country_code;
      state.defaultQuickIDFacematchCombo =
        payload.default_quick_id_facematch_combo;
      state.defaultFacematchForOutsourced =
        payload.default_facematch_for_outsourced;
      state.quickIDAlgorithm = payload.quick_id_algorithm;
      state.eivCheckCount = payload.eiv_check_count;
      state.nzCheckCount = payload.nz_checks_count;
      state.overseasCheckCount = payload.overseas_checks_count;
      state.facematchCheckCount = payload.facematch_check_count;
      state.bankmatchCheckCount = payload.bankmatch_check_count;
      state.pepCheckCount = payload.pep_check_count;
    })
    .addCase(resetUserData, (state) => {
      state.id = "";
      state.email = "";
      state.companyEmail = "";
      state.dateJoined = "";
      state.agencyName = "";
      state.firstName = "";
      state.lastName = "";
      state.agencyId = "";
      state.defaultCountryCode = "";
      state.eivCheckCount = "";
      state.nzCheckCount = "";
      state.overseasCheckCount = "";
      state.facematchCheckCount = "";
      state.bankmatchCheckCount = "";
      state.pepCheckCount = "";
      state.completedFreeCheckCount = "";
      state.paymentInReview = false;
      state.availableFreeCheckCount = "";
      state.referrer = "";
      state.isDefaultPassword = false;
    })
    .addCase(
      setInCompleteProfile,
      (
        state,
        {
          payload: {
            payment = false,
            passwordSet = false,
            emailNotVerified = false,
          },
        }
      ) => {
        state.paymentInComplete = payment;
        state.passwordInComplete = passwordSet;
        state.emailNotVerified = emailNotVerified;
      }
    );
});
