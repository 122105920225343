import { Formik } from "formik";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import * as Yup from "yup";

import {
  PASSWORD_MSG,
  REQUIRED_MSG,
} from "../../../constants/validationHelpers";
import { PASSWORD_REGEX } from "../../../constants/validationRegex";
import routes from "../../../routes";
import PasswordField from "../../form/PasswordField";
import FormikScrollToErrors from "../../misc/FormikScrollToError";
import StepHeader from "../../misc/StepHeader";
import StepLayout from "../../misc/StepLayout";
import DashboardLayout from "./Layout";

const VALIDATION_RULES = Yup.object().shape({
  oldPassword: Yup.string().required(REQUIRED_MSG),
  newPassword: Yup.string()
    .required(REQUIRED_MSG)
    .matches(PASSWORD_REGEX, PASSWORD_MSG),
  confirmPassword: Yup.string().required(REQUIRED_MSG),
});

const INITIAL_VALUES = {
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
};

const ChangePassword = ({ fetching, changeUserPassword }) => (
  <DashboardLayout>
    <StepLayout>
      <StepHeader text="Change Password" />
      <Formik
        validationSchema={VALIDATION_RULES}
        onSubmit={changeUserPassword}
        initialValues={INITIAL_VALUES}
      >
        {({
          handleSubmit,
          errors,
          submitCount,
          isSubmitting,
          isValidating,
        }) => (
          <form noValidate id="changePasswordForm" onSubmit={handleSubmit}>
            <FormikScrollToErrors
              errors={errors}
              isSubmitting={isSubmitting}
              isValidating={isValidating}
            />
            <PasswordField
              name="oldPassword"
              error={errors.oldPassword}
              submitCount={submitCount}
              label="Old Password"
              autoComplete="current-password"
              autoFocus
            />
            <PasswordField
              name="newPassword"
              error={errors.newPassword}
              submitCount={submitCount}
              label="New Password"
              autoComplete="new-password"
            />
            <PasswordField
              name="confirmPassword"
              error={errors.confirmPassword}
              submitCount={submitCount}
              label="Confirm Password"
              autoComplete="new-password"
            />
            <Button
              color="primary"
              block
              size="lg"
              type="submit"
              form="changePasswordForm"
              disabled={fetching}
            >
              Change Password
            </Button>
            <div className="text-right mt-2">
              <Link to={routes.profile} className="btn btn-link">
                Back to profile
              </Link>
            </div>
          </form>
        )}
      </Formik>
    </StepLayout>
  </DashboardLayout>
);

ChangePassword.propTypes = {
  fetching: PropTypes.bool.isRequired,
  changeUserPassword: PropTypes.func.isRequired,
};

export default ChangePassword;
