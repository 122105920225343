import PropTypes from "prop-types";
import RcIf, { RcElse } from "rc-if";
import React from "react";
import { connect } from "react-redux";

import { FACEMATCH } from "../../constants/checkTypes";
import { actions as checksActions } from "../../store/slices/check";
import { actions as userActions } from "../../store/slices/user";
import { actions as verificationActions } from "../../store/slices/verification";
import StepHeader from "../misc/StepHeader";
import StepLayout from "../misc/StepLayout";
import Results from "../verification/Presenters/Results";
import DashboardLayout from "./Presenters/Layout";
import SelfCheckForm from "./Presenters/SelfCheckForm";

const {
  resetCheck,
  goToNextCheck,
  addCheckAndVerify,
  updateCheckUserAndResend,
} = checksActions;
const { handleResetVerifyState } = verificationActions;
const { completeProfile } = userActions;

const SelfCheck = ({
  resetCheck,
  goToNextCheck,
  addCheckAndVerify,
  updateCheckUserAndResend,
  handleResetVerifyState,
  completeProfile,
  checkType,
  defaultCountryCode,
  title,
  check: { draft, fetching: fetchingCheck, fetchError: checkFetchError },
  verification: {
    fetching: fetchingVerification,
    verifying,
    overallResult,
    nameResult,
    birthDateResult,
    addressResult,
    pepResult,
    pdfUrl,
    verifyError,
    facematchStep,
  },
  quickIDAlgorithm,
  allowMixAndMatch,
}) => {
  const includeFaceMatch = checkType.includes(FACEMATCH);
  const fetching = verifying || fetchingVerification;
  const draftReference = draft.reference ? (
    <span>
      <strong>Reference</strong>: {draft.reference}
    </span>
  ) : null;
  const check = draft.checks[draft.activeCheck];

  return (
    <DashboardLayout>
      <StepLayout>
        <StepHeader text={title} subHeader={draftReference} />

        <h3 className="text-center h6 mb-4">
          Client: {draft.activeCheck + 1} of {draft.totalChecks}
        </h3>

        <RcIf
          if={!fetching && !verifyError && !!check && !!check.resultAvailable}
        >
          <Results
            overallResult={overallResult}
            nameResult={nameResult}
            birthDateResult={birthDateResult}
            addressResult={addressResult}
            pepResult={pepResult}
            pdfUrl={pdfUrl}
            onVerificationComplete={goToNextCheck}
            handleResetVerifyState={handleResetVerifyState}
            noHeader={true}
            ctaText={
              draft.activeCheck + 1 === draft.totalChecks ? "Done" : "Next"
            }
            includeFaceMatch={
              facematchStep !== "COMPLETED" ? includeFaceMatch : false
            }
            updateCheckUserAndResend={updateCheckUserAndResend}
            checkFetchError={checkFetchError}
            fetchingCheck={fetchingCheck}
            check={check}
          />
          <RcElse>
            <SelfCheckForm
              fetching={fetching}
              completeProfile={completeProfile}
              resetCheck={resetCheck}
              addCheckAndVerify={addCheckAndVerify}
              checkType={checkType}
              defaultCountryCode={defaultCountryCode}
              quickIDAlgorithm={quickIDAlgorithm}
              allowMixAndMatch={allowMixAndMatch}
            />
          </RcElse>
        </RcIf>
      </StepLayout>
    </DashboardLayout>
  );
};

const mapStateToProps = ({ check, verification, user }) => ({
  check,
  verification,
  quickIDAlgorithm: user.quickIDAlgorithm,
  allowMixAndMatch: user.allowMixAndMatch,
});
const mapDispatchToProps = {
  resetCheck,
  goToNextCheck,
  addCheckAndVerify,
  updateCheckUserAndResend,
  completeProfile,
  handleResetVerifyState,
};

SelfCheck.propsTypes = {
  defaultCountryCode: PropTypes.string,
  title: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelfCheck);
