import React from "react";

const Sort = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24.64 22.32"
    xmlSpace="preserve"
    width={16}
  >
    <path
      fill="currentColor"
      d="M3.18 21.74a.8.8 0 0 1-.8-.8V.8a.8.8 0 0 1 1.6 0v20.11a.8.8 0 0 1-.8.83ZM23.84 3.67h-13.6a.8.8 0 1 1 0-1.6h13.6a.8.8 0 1 1 0 1.6ZM20.99 9.32H10.24a.8.8 0 0 1 0-1.6h10.75a.8.8 0 0 1 0 1.6ZM18.2 14.84h-8.09a.8.8 0 1 1 0-1.59h8.09a.8.8 0 1 1 0 1.59ZM15.61 20.37h-5.37a.8.8 0 0 1 0-1.6h5.37a.8.8 0 0 1 0 1.6Z"
    />
    <path
      fill="currentColor"
      d="M3.18 16.66h2.59a.59.59 0 0 1 .55.88l-1.3 2.25-1.3 2.24a.58.58 0 0 1-1 0l-1.3-2.24-1.34-2.25a.58.58 0 0 1 .5-.88Z"
    />
  </svg>
);

export default Sort;
