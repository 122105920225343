import { Formik } from "formik";
import PropTypes from "prop-types";
import RcIf from "rc-if";
import React, { useState } from "react";

import StripeElement from "../../form/StipeElement";
import Pricing from "../../misc/Pricing";

const BillingForm = ({
  fetching,
  addCreditCard,
  heading,
  defaultCountryCode,
  showPricing,
}) => {
  const [stripeTokenId, setStripeTokenId] = useState("");

  return (
    <div className="row">
      <div className={showPricing ? "col-lg-6" : "col-12"}>
        <h2 className="h3 text-center mb-3">{heading}</h2>
        <div className="site-form mb-3">
          <Formik
            onSubmit={(data) => addCreditCard({ ...data, stripeTokenId })}
            initialValues={{}}
          >
            {({ handleSubmit }) => (
              <form noValidate onSubmit={(e) => e.preventDefault()}>
                <div className="checkout">
                  <StripeElement
                    onSubmit={(token) => {
                      setStripeTokenId(token);
                      handleSubmit();
                    }}
                    isSubmitting={fetching}
                    ccBtnText="Add Your Payment"
                    baBtnText="Request Monthly Invoices"
                  />
                </div>
              </form>
            )}
          </Formik>
          <hr className="mt-4" />
          <p className="text-center small">
            By adding a payment method I agree to the{" "}
            <a
              href="https://realaml.com/terms-and-conditions/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms &amp; Conditions
            </a>{" "}
            and{" "}
            <a
              href="https://realaml.com/privacy-policy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
          </p>
          <p className="text-center mb-0">
            Call us 7 days a week{" "}
            {defaultCountryCode === "CA" ? (
              <a href="tel:+12267410268" className="link-inverse">
                <b>+1 226 741 0268</b>
              </a>
            ) : (
              <a href="tel:+6448894788" className="link-inverse">
                <b>+64 4 889 4788</b>
              </a>
            )}
          </p>
        </div>
      </div>
      <RcIf if={showPricing}>
        <div className="col-lg-6">
          <Pricing mini={true} />
        </div>
      </RcIf>
    </div>
  );
};

BillingForm.propTypes = {
  heading: PropTypes.string,
  showPricing: PropTypes.bool,
  addCreditCard: PropTypes.func.isRequired,
  fetching: PropTypes.bool.isRequired,
  defaultCountryCode: PropTypes.string.isRequired,
};

BillingForm.defaultProps = {
  heading: "Instantly Run Your Check",
  showPricing: true,
};

export default BillingForm;
