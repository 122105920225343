import axios from "axios";

import { getDefaultHeaders } from "../helpers/token";
import {
  SEND_VERIFICATION_URL,
  VERIFICATION_GROUP_URL,
  getOutsourcedVerificationDetailUrl,
  getOutsourcedVerificationUrl,
  getVerificationDetailUrl,
  getVerificationDisableMonitoringUrl,
  getVerificationGroupUrl,
  getVerificationResendUrl,
  getVerificationUrl,
} from "./apiUrls";

const addVerificationGroup = async ({ reference, notes = "", type }) =>
  await axios.post(
    VERIFICATION_GROUP_URL,
    {
      reference,
      notes,
      type,
    },
    getDefaultHeaders()
  );

const updateVerificationGroup = async ({ reference, notes = "", id }) =>
  await axios.put(
    getVerificationGroupUrl(id),
    { reference, notes },
    getDefaultHeaders()
  );

const addVerificationUser = async ({
  firstName,
  lastName,
  middleName = "",
  email = "",
  checkType,
  phone,
  countryCode,
  userVerificationId,
  faceMatchCollectSecondaryId = false,
  runPep = false,
  runPlusChecks = false,
}) =>
  await axios.post(
    getVerificationUrl(userVerificationId),
    {
      first_name: firstName,
      last_name: lastName,
      middle_name: middleName,
      type: checkType,
      phone,
      email,
      country_code: countryCode,
      facematch_collect_secondary_id: !!faceMatchCollectSecondaryId,
      facematch_run_pep: !!runPep,
      run_plus_checks: !!runPlusChecks,
    },
    getDefaultHeaders()
  );

const sendExternalLink = async ({
  firstName,
  lastName,
  email = "",
  phone = "",
  link,
  title,
  reference = "",
  notes = "",
}) =>
  await axios.post(
    SEND_VERIFICATION_URL,
    {
      first_name: firstName,
      last_name: lastName,
      email,
      phone,
      link,
      title,
      reference,
      notes,
    },
    getDefaultHeaders()
  );

const addOutsourcedVerification = async ({
  name,
  checkType,
  country,
  firstName,
  lastName,
  email,
  phone,
  userVerificationId,
  natureOfTransaction,
  isFacematchRequired,
  isCompanyPepRequired,
}) =>
  await axios.post(
    getOutsourcedVerificationUrl(userVerificationId),
    {
      type: checkType,
      name,
      country,
      contact_first_name: firstName,
      contact_last_name: lastName,
      email,
      phone,
      nature_of_transaction: natureOfTransaction,
      is_facematch_required: isFacematchRequired,
      is_company_pep_required: isCompanyPepRequired,
    },
    getDefaultHeaders()
  );

const updateOutsourcedCheck = async (
  userVerificationId,
  {
    name,
    checkType,
    country,
    firstName,
    lastName,
    email,
    phone,
    natureOfTransaction,
    isFacematchRequired,
    isCompanyPepRequired,
    reference,
    notes,
  }
) =>
  await axios.put(
    getOutsourcedVerificationDetailUrl(userVerificationId),
    {
      type: checkType,
      name,
      country,
      contact_first_name: firstName,
      contact_last_name: lastName,
      email,
      phone,
      nature_of_transaction: natureOfTransaction,
      is_facematch_required: isFacematchRequired,
      is_company_pep_required: isCompanyPepRequired,
      reference,
      notes,
    },
    getDefaultHeaders()
  );

const updateVerificationUser = async ({
  firstName,
  lastName,
  middleName,
  email,
  checkType,
  phone,
  countryCode,
  userVerificationDataId,
}) =>
  await axios.put(
    getVerificationDetailUrl(userVerificationDataId),
    {
      first_name: firstName,
      last_name: lastName,
      middle_name: middleName,
      type: checkType,
      phone,
      email,
      country_code: countryCode,
    },
    getDefaultHeaders()
  );

const resendCheck = async (userVerificationId) =>
  await axios.put(
    getVerificationResendUrl(userVerificationId),
    {},
    getDefaultHeaders()
  );

const deleteCheck = async (userVerificationId) =>
  await axios.delete(
    getVerificationDetailUrl(userVerificationId),
    getDefaultHeaders()
  );

const disableMonitoring = async (userVerificationId) =>
  await axios.put(
    getVerificationDisableMonitoringUrl(userVerificationId),
    getDefaultHeaders()
  );

const deleteOutsourcedCheck = async (userVerificationId) =>
  await axios.delete(
    getOutsourcedVerificationDetailUrl(userVerificationId),
    getDefaultHeaders()
  );

const approveAddress = async (userVerificationId) =>
  await axios.put(
    `${getVerificationDetailUrl(userVerificationId)}approve/`,
    {},
    getDefaultHeaders()
  );

const approveFaceMatch = async (userVerificationId) =>
  await axios.put(
    `${getVerificationDetailUrl(userVerificationId)}facematch/approve/`,
    {},
    getDefaultHeaders()
  );

const approvePep = async (userVerificationId, notes) =>
  await axios.put(
    `${getVerificationDetailUrl(userVerificationId)}pep/approve/`,
    { notes },
    getDefaultHeaders()
  );

const resetCheck = async (userVerificationId) =>
  await axios.put(
    `${getVerificationDetailUrl(userVerificationId)}reset/`,
    {},
    getDefaultHeaders()
  );

const apis = {
  addVerificationGroup,
  addVerificationUser,
  sendExternalLink,
  addOutsourcedVerification,
  updateOutsourcedCheck,
  updateVerificationUser,
  resendCheck,
  deleteCheck,
  disableMonitoring,
  deleteOutsourcedCheck,
  updateVerificationGroup,
  approveFaceMatch,
  resetCheck,
  approveAddress,
  approvePep,
};

export default apis;
