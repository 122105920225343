import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React from "react";

import {
  NATIONAL_ID_DRIVER_LICENCE,
  NATIONAL_ID_GOVT_TAX_NUMBER,
  NATIONAL_ID_PASSPORT,
} from "../../../../constants/nationalIdType";
import Field from "../../../form/Field";

const CnId = ({ quickIDAlgorithm }) => {
  const { values, errors, submitCount } = useFormikContext();
  const isSecondIdMandatory = quickIDAlgorithm !== "1x1";

  return (
    <>
      <div>
        <Field
          name="nationalIdType"
          error={errors.nationalIdType}
          submitCount={submitCount}
          label="Select Chinese document 1"
          component="select"
        >
          <Options excludedType={values.nationalIdSecondaryType || ""} />
        </Field>
        <div style={{ marginTop: "-1rem" }}>
          <Field
            name="nationalId"
            error={errors.nationalId}
            submitCount={submitCount}
            label={
              CN_ID_TYPES[values.nationalIdType]
                ? CN_ID_TYPES[values.nationalIdType].label
                : ""
            }
            placeholder={
              CN_ID_TYPES[values.nationalIdType]
                ? CN_ID_TYPES[values.nationalIdType].placeholder
                : ""
            }
          />
        </div>
      </div>
      {isSecondIdMandatory && (
        <div>
          <Field
            name="nationalIdSecondaryType"
            error={errors.nationalIdSecondaryType}
            submitCount={submitCount}
            label="Select Chinese document 2"
            component="select"
          >
            <Options excludedType={values.nationalIdType || ""} />
          </Field>
          <div style={{ marginTop: "-1rem" }}>
            <Field
              name="nationalIdSecondary"
              error={errors.nationalIdSecondary}
              submitCount={submitCount}
              label={
                CN_ID_TYPES[values.nationalIdSecondaryType]
                  ? CN_ID_TYPES[values.nationalIdSecondaryType].label
                  : ""
              }
              placeholder={
                CN_ID_TYPES[values.nationalIdSecondaryType]
                  ? CN_ID_TYPES[values.nationalIdSecondaryType].placeholder
                  : ""
              }
            />
          </div>
        </div>
      )}
    </>
  );
};

CnId.propTypes = {
  quickIDAlgorithm: PropTypes.string.isRequired,
};

export default CnId;

const Options = ({ excludedType = "" }) => (
  <>
    <option>Select an option</option>
    {Object.keys(CN_ID_TYPES).map(
      (key) =>
        excludedType !== key && (
          <option value={key} key={key}>
            {CN_ID_TYPES[key].label}
          </option>
        )
    )}
  </>
);

const CN_ID_TYPES = {
  [NATIONAL_ID_GOVT_TAX_NUMBER]: {
    label: "Resident Identity Card",
    placeholder: "123456789012345678",
  },
  // [NATIONAL_ID_DRIVER_LICENCE]: {
  //   label: "Driver Licence",
  //   placeholder: "01234567890123",
  // },
  [NATIONAL_ID_PASSPORT]: {
    label: "Passport",
    placeholder: "A12345678",
  },
};
