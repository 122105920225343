import axios from "axios";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useCallback, useMemo, useState } from "react";
import { DateRangePicker, isSameDay } from "react-dates";
import { toastr } from "react-redux-toastr";
import {
  Button,
  ButtonGroup,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";

import {
  checksStatusTypes,
  checksTypesDropdown,
  outsourcedStatusTypes,
  outsourcedTypesDropdown,
} from "../../../constants/checkTypes";
import {
  DATES_MAX_INTERVAL,
  DATES_REQUIRED,
  ERROR_REFRESH_WITH_CONTACT_INFO,
  REPORT_GENERATE_SUCCESS,
} from "../../../constants/lang/en";
import logToSentryOrConsole from "../../../helpers/sentry";
import { getDefaultHeaders } from "../../../helpers/token";
import useScreenSize from "../../../hooks/useScreenSize";
import Eye from "../../icons/EyeIcon";
import Filter from "../../icons/FilterIcon";

// const fileTypes = [
//   {
//     label: "CSV",
//     key: "CSV",
//   },
//   {
//     label: "XLSX",
//     key: "XLSX",
//   },
//   {
//     label: "PDF",
//     key: "PDF",
//   },
// ];

const ReportsModal = ({ setReportsModalOpened, type = "checks" }) => {
  const { screenWidth } = useScreenSize();
  const [loading, setLoading] = useState(false);

  const [focusedInput, setFocusedInput] = useState();
  const [startDate, setStartDate] = useState(
    moment().subtract(1, "months").startOf("month")
  );
  const [endDate, setEndDate] = useState(
    moment().subtract(1, "months").endOf("month")
  );

  const handleDatesChange = useCallback(({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
  });

  const checkTypesDropdown = useMemo(
    () => (type === "checks" ? checksTypesDropdown : outsourcedTypesDropdown),
    [type]
  );
  const [typeFilter, setTypeFilter] = React.useState();
  const [typeDropdown, setTypeDropdown] = React.useState(false);
  const typeDropdownToggle = () => setTypeDropdown((prevState) => !prevState);
  const selectedCheckType = useMemo(
    () => checkTypesDropdown.find((ct) => ct.key === typeFilter),
    [checkTypesDropdown, typeFilter]
  );

  const statusTypes = useMemo(
    () => (type === "checks" ? checksStatusTypes : outsourcedStatusTypes),
    [type]
  );
  const [statusFilter, setStatusFilter] = React.useState();
  const [statusDropdown, setStatusDropdown] = React.useState(false);
  const statusDropdownToggle = () =>
    setStatusDropdown((prevState) => !prevState);
  const selectedStatusType = useMemo(
    () => statusTypes.find((st) => st.key === statusFilter) || statusTypes[0],
    [statusTypes, statusFilter]
  );

  // const [fileFilter, setFileFilter] = React.useState();
  // const [fileDropdown, setFileDropdown] = React.useState(false);
  // const fileDropdownToggle = () => setFileDropdown((prevState) => !prevState);
  // const selectedFile = useMemo(() => fileTypes.find((st) => st.key === fileFilter) || fileTypes[0], [fileFilter]);

  const handleReportGeneration = useCallback(async () => {
    if (!startDate || !endDate) {
      toastr.error(DATES_REQUIRED);
      return;
    }

    if (endDate.diff(startDate, "days") > 366) {
      toastr.error(DATES_MAX_INTERVAL);
      return;
    }

    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/files/generate-report/`,
        {
          report_type: type,
          start_date: startDate ? startDate.format("YYYY-MM-DD") : null,
          end_date: endDate ? endDate.format("YYYY-MM-DD") : null,
          file_type: "CSV",
          status_filter: statusFilter || null,
          type_filter: typeFilter || null,
        },
        getDefaultHeaders()
      );
      if (response.status === 200) {
        toastr.success(REPORT_GENERATE_SUCCESS);
        setReportsModalOpened(false);
      } else {
        toastr.error(ERROR_REFRESH_WITH_CONTACT_INFO);
      }
    } catch (error) {
      toastr.error(ERROR_REFRESH_WITH_CONTACT_INFO);
      logToSentryOrConsole(error);
    } finally {
      setLoading(false);
    }
  }, [type, startDate, endDate, statusFilter, typeFilter]);

  const renderDatePresets = useCallback(() => {
    const presetDates = [
      { start: moment().startOf("month"), end: moment(), text: "This Month" },
      {
        start: moment().subtract(1, "months").startOf("month"),
        end: moment().subtract(1, "months").endOf("month"),
        text: "Last Month",
      },
      { start: moment().startOf("year"), end: moment(), text: "This Year" },
      {
        start: moment().subtract(1, "years").startOf("year"),
        end: moment().subtract(1, "years").endOf("year"),
        text: "Last Year",
      },
    ];
    return (
      <ButtonGroup className="mb-4 mt-0 mx-4 react-dates-preset">
        {presetDates.map(({ start, end, text }) => (
          <Button
            outline
            small
            color="primary"
            active={isSameDay(start, startDate) && isSameDay(end, endDate)}
            className="px-3 py-2 btn-sm"
            type="button"
            onClick={() => {
              handleDatesChange({ startDate: start, endDate: end });
              setFocusedInput(false);
            }}
          >
            {text}
          </Button>
        ))}
      </ButtonGroup>
    );
  }, [startDate, endDate]);

  return (
    <Modal isOpen={true} toggle={() => setReportsModalOpened((s) => !s)}>
      <ModalHeader toggle={() => setReportsModalOpened((s) => !s)}>
        Reports
      </ModalHeader>
      <ModalBody>
        <div className="row">
          <div className="col-6">
            <Dropdown
              isOpen={typeDropdown}
              toggle={typeDropdownToggle}
              size="sm"
            >
              <DropdownToggle
                caret
                color=""
                className="btn-light mx-1 w-100 d-flex justify-content-center align-items-center"
              >
                <div className="flex-shrink-0">
                  <Filter />
                </div>
                <div className="ml-2 flex-grow-1 flex-shrink-1 text-left overflow-hidden">
                  {selectedCheckType ? selectedCheckType.label : "All Checks"}
                </div>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => setTypeFilter("")}>
                  All Checks
                </DropdownItem>
                {checkTypesDropdown.map((ct) => (
                  <DropdownItem
                    key={ct.key}
                    onClick={() => setTypeFilter(ct.key)}
                    active={typeFilter === ct.key}
                  >
                    {ct.label}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </div>
          <div className="col-6">
            <Dropdown
              isOpen={statusDropdown}
              toggle={statusDropdownToggle}
              size="sm"
            >
              <DropdownToggle
                caret
                color=""
                className="btn-light mx-1 w-100 d-flex justify-content-center align-items-center"
              >
                <div className="flex-shrink-0">
                  <Eye />
                </div>
                <div className="ml-2 flex-grow-1 flex-shrink-1 text-left overflow-hidden">
                  {selectedStatusType.label}
                </div>
              </DropdownToggle>
              <DropdownMenu>
                {statusTypes.map((st) => (
                  <DropdownItem
                    key={st.key}
                    onClick={() => setStatusFilter(st.key)}
                    active={statusFilter === st.key}
                    className={
                      st.subMenu
                        ? `pl-5 text-sm sub-menu ${
                            st.key === "completed_passed" ? "first-child" : ""
                          }`
                        : undefined
                    }
                  >
                    {st.label}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </div>
          {/* <div className="col-6">
          <Dropdown
            isOpen={fileDropdown}
            toggle={fileDropdownToggle}
            size="sm"
          >
            <DropdownToggle caret color="" className="btn-light mx-1">
              <Sort />
              <span className="ml-2">{selectedFile.label}</span>
            </DropdownToggle>
            <DropdownMenu>
              {fileTypes.map((ot) => (
                <DropdownItem
                  key={ot.key}
                  onClick={() => setFileFilter(ot.key)}
                  active={fileFilter === ot.key}
                >
                  {ot.label}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        </div> */}
        </div>

        <div className="row my-4">
          <div className="col-12 col-md-6">
            <label htmlFor="start_date_id" className="text-sm">
              Start/End Dates
            </label>
            <DateRangePicker
              startDate={startDate}
              startDateId="start_date_id"
              endDate={endDate}
              endDateId="end_date_id"
              onDatesChange={handleDatesChange}
              focusedInput={focusedInput}
              onFocusChange={(input) => setFocusedInput(input)}
              displayFormat="MMM D, YYYY"
              small
              reopenPickerOnClearDates
              firstDayOfWeek={1}
              hideKeyboardShortcutsPanel
              isOutsideRange={() => false}
              maxDate={moment()}
              minDate={moment().subtract(10, "year")}
              renderCalendarInfo={renderDatePresets}
              orientation={screenWidth < 768 ? "vertical" : "horizontal"}
              withFullScreenPortal={screenWidth < 768}
            />
          </div>
        </div>
        <Button
          color="primary"
          className="px-5 mt-3"
          disabled={loading}
          block
          onClick={handleReportGeneration}
        >
          Generate and Email Report
        </Button>
      </ModalBody>
    </Modal>
  );
};

ReportsModal.propTypes = {
  setReportsModalOpened: PropTypes.func.isRequired,
  type: PropTypes.string,
};

export default ReportsModal;
