import React from "react";
import { connect } from "react-redux";

import { actions } from "../../store/slices/user";
import VerifyEmailPresenter from "./Presenters/VerifyEmail";

const { verifyEmail, sendEmailVerificationToken } = actions;

const VerifyEmail = ({ verifyEmail, sendEmailVerificationToken, user }) => (
  <VerifyEmailPresenter
    fetching={user.fetching}
    verifyEmail={verifyEmail}
    sendEmailVerificationToken={sendEmailVerificationToken}
  />
);

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = { verifyEmail, sendEmailVerificationToken };

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmail);
