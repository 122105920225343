import React from "react";

import image500 from "../../images/500.png";
import Layout from "./Layout";

const ServerError = () => (
  <Layout
    subheading={
      <>
        500. <span className="text-muted">That's an error.</span>
      </>
    }
    bodyText="The server encountered an error and could not complete your request."
    bodyText2="If the problem persists, please report your problem and mention this error message and the query that caused it."
  />
);

export default ServerError;
