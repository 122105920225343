import React, { useEffect, useState } from "react";
import Intercom from "react-intercom";
import { connect } from "react-redux";

import { setReferrer } from "../../helpers/referrer";
import { isUserLoggedIn } from "../../helpers/token";

const intercomAppId = process.env.REACT_APP_INTERCOM_ID;

const RouteTracker = ({
  id,
  email,
  agencyName,
  eivCheckCount,
  nzCheckCount,
  overseasCheckCount,
  facematchCheckCount,
  bankmatchCheckCount,
  pepCheckCount,
  completedFreeCheckCount,
  availableFreeCheckCount,
  paymentInReview,
  referrer,
  dateJoined,
  defaultCountryCode,
  hideIntercom,
}) => {
  const [intercomId] = useState(
    intercomAppId && intercomAppId !== "NA" ? intercomAppId : undefined
  );
  useEffect(() => {
    setReferrer();
  });

  let userData = {};
  if (isUserLoggedIn()) {
    userData.user_id = agencyName
      ? `${defaultCountryCode || "NA"}-${id}`
      : `NA-${id}`;
    userData.email = email;
    userData["Agency name"] = agencyName;
    userData["EIV check count"] = eivCheckCount;
    userData["QuickID NZ check count"] = nzCheckCount;
    userData["QuickID Overseas check count"] = overseasCheckCount;
    userData["Facematch check count"] = facematchCheckCount;
    userData["Bankmatch check count"] = bankmatchCheckCount;
    userData["PEP check count"] = pepCheckCount;
    userData["Completed Free check count"] = completedFreeCheckCount;
    userData["Available Free check count"] = availableFreeCheckCount;
    userData["Payment In Review"] = paymentInReview;
    userData["Referrer"] = referrer;
    if (dateJoined) {
      const parsedDateJoined = new Date(dateJoined);
      userData.created_at = parseInt(parsedDateJoined.valueOf() / 1000, 10);
    }
  }

  return intercomId && !hideIntercom ? (
    <Intercom appID={intercomId} {...userData} />
  ) : null;
};

const mapStateToProps = ({
  user: {
    id,
    email,
    agencyName,
    eivCheckCount,
    nzCheckCount,
    overseasCheckCount,
    facematchCheckCount,
    bankmatchCheckCount,
    pepCheckCount,
    completedFreeCheckCount,
    availableFreeCheckCount,
    paymentInReview,
    referrer,
    dateJoined,
    defaultCountryCode,
    hideIntercom,
  },
}) => ({
  id,
  email,
  agencyName,
  eivCheckCount,
  nzCheckCount,
  overseasCheckCount,
  facematchCheckCount,
  bankmatchCheckCount,
  pepCheckCount,
  completedFreeCheckCount,
  availableFreeCheckCount,
  paymentInReview,
  referrer,
  dateJoined,
  defaultCountryCode,
  hideIntercom,
});

export default connect(mapStateToProps)(RouteTracker);
