import axios from "axios";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { toastr } from "react-redux-toastr";
import { Tooltip } from "react-tippy";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";

import { ERROR_REFRESH_WITH_CONTACT_INFO } from "../../../constants/lang/en";
import { formatDateTimeSimple } from "../../../helpers/date";
import {
  constructAuditTrailDownloadUrl,
  constructOutsourcedAuditTrailDownloadUrl,
} from "../../../helpers/files";
import logToSentryOrConsole from "../../../helpers/sentry";
import { getDefaultHeaders } from "../../../helpers/token";
import Download from "../../icons/DownloadIcon";
import Loading from "../../misc/Loading";

const LogsModal = ({ setLogsModalOpened, id, type = "signature_key" }) => {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchLogs = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/user-event-logs/?${type}=${id}&per_page=100`,
        getDefaultHeaders()
      );
      if (response && response.data) {
        setLogs(response.data);
        setLoading(false);
      } else {
        toastr.error(ERROR_REFRESH_WITH_CONTACT_INFO);
      }
    } catch (error) {
      toastr.error(ERROR_REFRESH_WITH_CONTACT_INFO);
      logToSentryOrConsole(error);
    }
  }, [id]);

  useEffect(() => {
    fetchLogs();
  }, [fetchLogs]);

  const handleDownloadAuditTrail = useCallback(() => {
    window.open(
      type === "signature_key"
        ? constructAuditTrailDownloadUrl(id)
        : constructOutsourcedAuditTrailDownloadUrl(id)
    );
  }, [id, type]);

  return (
    <Modal isOpen={true} toggle={() => setLogsModalOpened((s) => !s)} size="xl">
      <ModalHeader toggle={() => setLogsModalOpened((s) => !s)}>
        Audit Trail
        {logs.length > 0 && (
          <Tooltip title="Download Audit Trail" position="bottom" size="small">
            <Button
              size="sm"
              color="link"
              outline
              onClick={handleDownloadAuditTrail}
              className="text-secondary mb-2"
            >
              <Download size="22" />
            </Button>
          </Tooltip>
        )}
      </ModalHeader>
      <ModalBody>
        {loading ? (
          <Loading />
        ) : logs.length < 1 ? (
          <p className="text-small">Audit trail not available</p>
        ) : (
          <div className="table-responsive">
            <table
              className="table table-sm small"
              style={{ minWidth: "650px" }}
            >
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Activity</th>
                  <th>IP Address</th>
                  <th>OS / Browser</th>
                </tr>
              </thead>
              <tbody>
                {logs.map((log) => (
                  <tr key={log.id}>
                    <td>{formatDateTimeSimple(log.created_at * 1000)}</td>
                    <td>{log.remarks}</td>
                    <td>{log.ip_address}</td>
                    <td>
                      {!!log.user_agent.os && !!log.user_agent.browser && (
                        <>
                          {log.user_agent.os} / {log.user_agent.browser}
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

LogsModal.propTypes = {
  setLogsModalOpened: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
};

export default LogsModal;
