import React from "react";

const Compliance = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 17.96 22.24"
    xmlSpace="preserve"
    className="outsourced-icon"
  >
    <path
      fill="currentColor"
      d="M15.48 22.24h-13A2.48 2.48 0 0 1 0 19.76V2.48A2.48 2.48 0 0 1 2.48 0h13a2.48 2.48 0 0 1 2.48 2.48v17.28a2.48 2.48 0 0 1-2.48 2.48ZM2.48 1.6a.88.88 0 0 0-.88.88v17.28a.89.89 0 0 0 .88.88h13a.89.89 0 0 0 .88-.88V2.48a.88.88 0 0 0-.88-.88Z"
    />
    <g fill="currentColor">
      <path d="M7.69 17.87a.82.82 0 0 1-.56-.23l-2.41-2.41a.8.8 0 1 1 1.13-1.13l2.41 2.41a.8.8 0 0 1 0 1.13.83.83 0 0 1-.57.23Z" />
      <path d="M7.83 17.77a.8.8 0 0 1-.56-1.36l4.84-4.84a.79.79 0 0 1 1.13 0 .8.8 0 0 1 0 1.13L8.4 17.54a.83.83 0 0 1-.57.23Z" />
    </g>
    <path
      fill="currentColor"
      d="M13.22 6H4.74a.8.8 0 0 1 0-1.6h8.48a.8.8 0 1 1 0 1.6ZM9.66 9.33H4.74a.8.8 0 0 1 0-1.6h4.92a.8.8 0 0 1 0 1.6Z"
    />
  </svg>
);

export default Compliance;
