export const DATE_REGEX =
  process.env.REACT_APP_UI_DATE_FORMAT === "DD/MM/YYYY"
    ? /^(0[1-9]|[12][0-9]|3[01])[/](0[1-9]|1[012])[/](19|20)\d\d$/
    : process.env.REACT_APP_UI_DATE_FORMAT === "yyyy-MM-dd"
    ? /^(19|20)\d\d[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])$/
    : /[\s\S]*/gm;
export const PASSWORD_REGEX = /^(?=.*[A-Za-z])(?=.*\d).{6,}$/;
export const PASSPORT_REGEX = /^[A-Za-z]{1,4}\d{4,10}$/;

export const NZ_LICENCE_NUMBER_REGEX = /^[A-Za-z]{2}\d{6}$/;
export const NZ_LICENCE_VERSION_REGEX = /^\d{3}$/;
export const NZ_POSTAL_CODE_REGEX = /^\d{4}$/;

export const AU_LICENCE_NUMBER_REGEX = /^[A-Za-z0-9]{5,15}$/;

export const IN_PAN_REGEX = /^[A-Za-z]{5}\d{4}[A-Za-z]{1}$/;
export const IN_LICENCE_NUMBER_REGEX =
  /^[A-Za-z]{2}\d{12,15}|[A-Za-z]{2}\d{2}\s{1}\d{11}$/;
export const IN_VOTER_ID_REGEX = /^[A-Za-z]{3}\d{7}$/;

export const CN_RESIDENT_CARD_REGEX = /^\d{15,18}$/;
export const CN_LICENCE_NUMBER_REGEX = /^\d{12,16}$/;
export const CN_PASSPORT_REGEX = /^[A-Za-z]{1,2}[0-9]{6,12}$/;
