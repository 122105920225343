import queryString from "query-string";
import React, { useEffect } from "react";
import { connect } from "react-redux";

import routes from "../../routes";
import { actions } from "../../store/slices/auth";
import Loading from "../misc/Loading";
import SignUpPresenter from "./Presenters/SignUp";

const { signUp } = actions;

const SignUp = ({ signUp, auth, location }) => {
  const { email, trade } = queryString.parse(location.search);

  useEffect(() => {
    if (window.location.href.includes(routes.signUpOld)) return;

    if (
      window.location.href === `${process.env.REACT_APP_PROD_SITE_URL}/sign-up`
    ) {
      window.location.replace(`${process.env.REACT_APP_PROD_SITE_URL}/404`);
    } else if (
      !window.location.href.startsWith(process.env.REACT_APP_PROD_CA_SITE_URL)
    ) {
      window.location.replace(
        `${process.env.REACT_APP_PROD_CA_SITE_URL}/sign-up`
      );
    }
  }, []);

  useEffect(() => {
    if (!auth.fetchError && email && trade) {
      signUp({ agentEmail: email, agencyName: trade });
    }
  }, [auth, email, trade, signUp]);

  if (!auth.fetchError && email && trade) {
    return (
      <div className="site-table--loading">
        <Loading />
      </div>
    );
  } else {
    return (
      <SignUpPresenter
        {...auth}
        agentEmail={email}
        agencyName={trade}
        signUp={signUp}
      />
    );
  }
};

const mapStateToProps = ({ auth }) => ({ auth });
const mapDispatchToProps = { signUp };

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
