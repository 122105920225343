import React from "react";
import { connect } from "react-redux";

import { actions } from "../../store/slices/billing";
import BillingFormPresenter from "./Presenters/BillingForm";

const { addCreditCard } = actions;

const BillingForm = ({
  billing,
  addCreditCard,
  defaultCountryCode,
  ...otherProps
}) => (
  <BillingFormPresenter
    {...billing}
    addCreditCard={addCreditCard}
    defaultCountryCode={defaultCountryCode}
    {...otherProps}
  />
);

const mapStateToProps = ({ billing, user }) => ({
  billing,
  defaultCountryCode: user.defaultCountryCode,
});
const mapDispatchToProps = { addCreditCard };

export default connect(mapStateToProps, mapDispatchToProps)(BillingForm);
