import { Field as FormikField, useFormikContext } from "formik";
import React, { useCallback } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

import parseAddressFromGooglePlaces from "../../../helpers/google_places";
import Field from "../../form/Field";

const GlobalAddress = () => {
  const { values, setFieldValue, submitCount, errors } = useFormikContext();

  const handleAddressChange = useCallback(
    (address) => {
      setFieldValue("addressLine1", address);
    },
    [setFieldValue]
  );

  const handleSelect = useCallback(
    async (address) => {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      const parsedAddress = parseAddressFromGooglePlaces(results[0]);
      setFieldValue("addressLine1", parsedAddress.addressLine1);
      setFieldValue("unitNo", parsedAddress.unitNo);
      setFieldValue("addressLine2", parsedAddress.addressLine2);
      setFieldValue("city", parsedAddress.city);
      setFieldValue("state", parsedAddress.state);
      setFieldValue("postalCode", parsedAddress.postalCode);
    },
    [setFieldValue]
  );

  return (
    <>
      <div id="global_address_fields">
        <div className="form-row">
          <div className="col-7 col-md-9">
            <PlacesAutocomplete
              value={values["addressLine1"]}
              onChange={handleAddressChange}
              onSelect={handleSelect}
              searchOptions={{
                types: ["address"],
                componentRestrictions: { country: values.countryCode },
              }}
              shouldFetchSuggestions={values["addressLine1"].length > 3}
            >
              {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                <div className="autocomplete-wrap">
                  <Field
                    {...getInputProps({
                      name: "addressLine1",
                      error: errors.addressLine1,
                      submitCount,
                      label: "Address Line 1",
                      autoComplete: "address-line1",
                    })}
                  />
                  <div className="autocomplete-dropdown-wrap">
                    {suggestions.map((suggestion) => (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className: suggestion.active
                            ? "autocomplete-dropdown-suggestion-item  autocomplete-dropdown-suggestion-item--active"
                            : "autocomplete-dropdown-suggestion-item",
                        })}
                        key={suggestion.id}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          </div>
          <div className="col-5 col-md-3">
            <Field
              name="unitNo"
              error={errors.unitNo}
              submitCount={submitCount}
              label="Unit Number"
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-6">
            <Field
              name="addressLine2"
              error={errors.addressLine2}
              submitCount={submitCount}
              label="Address Line 2"
              autoComplete="address-line2"
            />
          </div>
          <div className="col-6">
            <Field
              name="city"
              error={errors.city}
              submitCount={submitCount}
              label="City"
              autoComplete="address-level2"
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-6">
            <Field
              name="state"
              error={errors.state}
              submitCount={submitCount}
              label="State / Province"
              autoComplete="address-level1"
            />
          </div>
          <div className="col-6">
            <Field
              name="postalCode"
              error={errors.postalCode}
              submitCount={submitCount}
              label="Postal / Zip Code"
              autoComplete="postal-code"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default GlobalAddress;
