import RcIf, { RcElse } from "rc-if";
import React, { useEffect } from "react";
import { connect } from "react-redux";

import { actions as billingActions } from "../../store/slices/billing";
import { actions as userActions } from "../../store/slices/user";
import Loading from "../misc/Loading";
import BillingForm from "./BillingForm";
import DashboardLayout from "./Presenters//Layout";
import BillingPresenter from "./Presenters/Billing";

const { fetchUser } = userActions;
const { fetchCreditCard, setShowPaymentModal } = billingActions;

const Billing = ({
  fetchUser,
  fetchCreditCard,
  setShowPaymentModal,
  fetchedPayment,
  paymentType,
  agencyName,
  cardLast4,
  cardType,
  cardExpirationDate,
  paymentInReview,
}) => {
  useEffect(() => {
    fetchUser();
  }, [fetchUser]);
  useEffect(() => {
    fetchCreditCard();
  }, [fetchCreditCard]);

  return (
    <DashboardLayout>
      <RcIf if={!fetchedPayment}>
        <section className="container-fluid xs">
          <Loading />
        </section>
        <RcElse>
          <RcIf if={!paymentType}>
            <section className="container-fluid xs">
              <BillingForm heading="" showPricing={false} />
            </section>
            <RcElse>
              <BillingPresenter
                paymentType={paymentType}
                agencyName={agencyName}
                cardLast4={cardLast4}
                cardType={cardType}
                cardExpirationDate={cardExpirationDate}
                setShowPaymentModal={setShowPaymentModal}
                paymentInReview={paymentInReview}
              />
            </RcElse>
          </RcIf>
        </RcElse>
      </RcIf>
    </DashboardLayout>
  );
};

const mapStateToProps = ({
  user: { agencyName, paymentInReview },
  billing: {
    fetchedPayment,
    paymentType,
    card: {
      last4: cardLast4,
      type: cardType,
      expirationDate: cardExpirationDate,
    },
  },
}) => ({
  agencyName,
  fetchedPayment,
  paymentType,
  cardLast4,
  cardType,
  cardExpirationDate,
  paymentInReview,
});
const mapDispatchToProps = { fetchUser, fetchCreditCard, setShowPaymentModal };

export default connect(mapStateToProps, mapDispatchToProps)(Billing);
