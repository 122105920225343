import queryString from "query-string";
import React from "react";
import { useLocation } from "react-router";

import { FACEMATCH } from "../../../constants/checkTypes";
import ChecksForm from "../checks/Form";

const FaceMatch = () => {
  const location = useLocation();
  const queries = queryString.parse(location.search);

  return (
    <ChecksForm title="FaceMatch" checkType={FACEMATCH} type={queries.type} />
  );
};

export default FaceMatch;
