import axios from "axios";
import qs from "query-string";

import { getDefaultHeaders } from "../helpers/token";
import { VERIFICATION_GROUP_URL } from "./apiUrls";

const fetchHistory = async (queries) =>
  await axios.get(
    `${VERIFICATION_GROUP_URL}?${qs.stringify(queries)}`,
    getDefaultHeaders()
  );

const apis = { fetchHistory };
export default apis;
