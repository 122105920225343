import React from "react";
import { connect } from "react-redux";

import { actions as userActions } from "../../store/slices/user";
import ProfileCompleteFormPresenter from "./Presenters/ProfileCompleteForm";

const {
  completeProfile,
  handleSetInCompleteProfile,
  sendEmailVerificationToken,
} = userActions;

const ProfileCompleteForm = ({
  email,
  fetching,
  paymentInComplete,
  passwordInComplete,
  emailNotVerified,
  completeProfile,
  handleSetInCompleteProfile,
  sendEmailVerificationToken,
  defaultCountryCode,
}) => {
  if (!email) {
    return null;
  }

  return (
    <ProfileCompleteFormPresenter
      completeProfile={completeProfile}
      handleSetInCompleteProfile={handleSetInCompleteProfile}
      sendEmailVerificationToken={sendEmailVerificationToken}
      paymentInComplete={paymentInComplete}
      passwordInComplete={passwordInComplete}
      emailNotVerified={emailNotVerified}
      fetching={fetching}
      email={email}
      defaultCountryCode={defaultCountryCode}
    />
  );
};

const mapStateToProps = ({
  user: {
    fetching,
    email,
    paymentInComplete,
    passwordInComplete,
    emailNotVerified,
    defaultCountryCode,
  },
}) => ({
  fetching,
  email,
  paymentInComplete,
  passwordInComplete,
  emailNotVerified,
  defaultCountryCode,
});
const mapDispatchToProps = {
  completeProfile,
  handleSetInCompleteProfile,
  sendEmailVerificationToken,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileCompleteForm);
