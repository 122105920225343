import PropTypes from "prop-types";
import React from "react";
import { useDropzone } from "react-dropzone";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import { Link } from "react-router-dom";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";

import { actions as checkActions } from "../../../store/slices/check";
import Trash from "../../icons/TrashIcon";
import Upload from "../../icons/UploadIcon";

const {
  approveAddressResult,
  uploadVerificationFiles,
  deleteVerificationFiles,
} = checkActions;

const UploadModal = ({
  setUploadModalOpened,
  type,
  approveAddressResult,
  uploadVerificationFiles,
  deleteVerificationFiles,
  existingFiles,
  checkFetching,
  id,
}) => {
  const isAddressModal = React.useMemo(() => type === "address", [type]);
  const [files, setFiles] = React.useState([]);

  const onDropAccepted = React.useCallback(
    (acceptedFiles) => {
      if (
        !isAddressModal &&
        existingFiles.length + files.length + acceptedFiles.length > 5
      ) {
        alert("You can upload only 5 files per check.");
      } else {
        setFiles((files) => [
          ...files,
          ...acceptedFiles.map((file) => ({
            originalFile: file,
            id: `${Date.now()}-${Math.random()}`,
            name: file.name,
            sizeInKb: `${(file.size / 1000).toFixed(2)}kb`,
          })),
        ]);
      }
    },
    [existingFiles, files, isAddressModal]
  );

  const onDropRejected = React.useCallback(() => {
    toastr.error(
      "",
      "Please upload pdf, png or jpg file which is less than 20 MB."
    );
  }, []);

  const deleteNewFile = (id) => {
    const newFiles = files.filter((f) => f.id !== id);
    setFiles(newFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/jpg, image/png, application/pdf",
    maxSize: 25000001,
    multiple: !isAddressModal,
    onDropAccepted,
    onDropRejected,
  });

  return (
    <Modal
      isOpen={true}
      toggle={() => setUploadModalOpened((s) => (s ? false : type))}
    >
      <ModalHeader
        toggle={() => setUploadModalOpened((s) => (s ? false : type))}
      />
      <ModalBody className="pt-0">
        {!isAddressModal && (
          <>
            <p className="h5 text-center mb-3">Manage Documents</p>
            {!!existingFiles && !!existingFiles.length ? (
              <>
                {existingFiles.map((file) => (
                  <div className="mt-2" key={file.id}>
                    <div className="border rounded d-flex p-2 align-items-center small">
                      <div className="flex-grow-1">
                        <Link
                          to={`/files/download/${id}?fileName=${file.file_name}`}
                          target="_blank"
                        >
                          {file.name}
                        </Link>
                      </div>
                      <div className="pl-2">
                        <Button
                          color="link"
                          className="p-0 text-muted"
                          size="sm"
                          onClick={() => {
                            const confirm = window.confirm(
                              `Are you sure you want to delete the file ${file.name}?`
                            );
                            if (confirm) {
                              deleteVerificationFiles(id, file.id);
                            }
                          }}
                          title="Remove document"
                        >
                          <Trash />
                        </Button>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <p className="text-center text-muted">
                No documents uploaded yet
              </p>
            )}
            {(isAddressModal || existingFiles.length < 5) && (
              <hr className="mt-5 mb-4" />
            )}
          </>
        )}

        {(isAddressModal || existingFiles.length < 5) && (
          <>
            <p className="h5 text-center mb-3">
              {isAddressModal
                ? "Upload a document to attach address"
                : "Upload new document(s)"}
            </p>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <Button color="secondary" block type="button" outline>
                <span className="btn-icon">
                  <Upload />
                </span>
                Click or drop files here
              </Button>
            </div>
            {!!files && !!files.length && (
              <>
                {files.map((file) => (
                  <div className="mt-2" key={file.id}>
                    <div className="border rounded d-flex p-2 align-items-center small">
                      <div className="flex-grow-1">
                        <span>{file.name}</span>
                        <span className="text-muted small ml-2">
                          {file.sizeInKb}
                        </span>
                      </div>
                      <div className="pl-2">
                        <Button
                          color="link"
                          className="p-0 text-muted"
                          size="sm"
                          onClick={() => deleteNewFile(file.id)}
                          title="Remove document"
                        >
                          <Trash />
                        </Button>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
            <Button
              color="primary"
              className="px-5 mt-3"
              type="button"
              disabled={checkFetching}
              onClick={() => {
                if (files.length) {
                  if (isAddressModal) {
                    approveAddressResult(id, files);
                    setTimeout(() => setUploadModalOpened(null), 500);
                  } else {
                    uploadVerificationFiles(id, files);
                  }
                  setFiles([]);
                }
              }}
              block
            >
              {isAddressModal ? "Attach Address" : "Upload Documents"}
            </Button>
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

UploadModal.propTypes = {
  setUploadModalOpened: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  files: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps = ({ check }) => ({
  checkFetching: check.fetching,
});
const mapDispatchToProps = {
  approveAddressResult,
  uploadVerificationFiles,
  deleteVerificationFiles,
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadModal);
