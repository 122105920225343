import React from "react";

const Refresh = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 21.7 21.97"
    xmlSpace="preserve"
    width={16}
  >
    <path
      fill="refresh"
      d="M10.76 21.97a11 11 0 0 1-9.91-6.26.8.8 0 0 1 1.44-.69 9.38 9.38 0 1 0 0-8.08.8.8 0 1 1-1.44-.68 11 11 0 1 1 9.91 15.71Z"
    />
    <path d="m3.38 3.98 2.17 1.43a.59.59 0 0 1-.06 1L3.17 7.58.85 8.75A.59.59 0 0 1 0 8.19L.15 5.6.3 3.01a.59.59 0 0 1 .91-.45Z" />
  </svg>
);

export default Refresh;
