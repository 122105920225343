import axios from "axios";
import queryString from "query-string";
import React, { useCallback, useEffect } from "react";
import { toastr } from "react-redux-toastr";

import {
  ACTION_STEP_INTEGRATION_SUCCESS,
  ERROR_REFRESH_WITH_CONTACT_INFO,
} from "../../../../constants/lang/en";
import logToSentryOrConsole from "../../../../helpers/sentry";
import { getDefaultHeaders } from "../../../../helpers/token";
import history from "../../../../history";
import Layout from "../../../errors/Layout";

const ActionStepIntegration = () => {
  const { code } = queryString.parse(location.search);

  const redirectToActionStep = useCallback(() => {
    if (process.env.REACT_APP_ACTION_STEP_URL)
      window.location.replace(process.env.REACT_APP_ACTION_STEP_URL);
  }, []);

  const sendCodeToBackend = useCallback(async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/integrations/action-step/`,
        { code },
        getDefaultHeaders()
      );
      toastr.success("", ACTION_STEP_INTEGRATION_SUCCESS);
      history.push("/");
    } catch (error) {
      toastr.error(ERROR_REFRESH_WITH_CONTACT_INFO);
      logToSentryOrConsole(error);
    }
  }, []);

  useEffect(() => {
    if (code) {
      sendCodeToBackend();
    } else {
      redirectToActionStep();
    }
  }, [code]);

  return <Layout subheading="Loading..." showHomePageLink={false} />;
};

export default ActionStepIntegration;
