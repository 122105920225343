import axios from "axios";
import React, { useMemo } from "react";
import { toastr } from "react-redux-toastr";
import { useParams } from "react-router-dom";

import { ERROR_REFRESH_WITH_CONTACT_INFO } from "../../../constants/lang/en";
import logToSentryOrConsole from "../../../helpers/sentry";
import { getDefaultHeaders } from "../../../helpers/token";
import Layout from "../../errors/Layout";

const CheckDownload = () => {
  const isOutsourced = useMemo(
    () => location.pathname.includes("outsourced"),
    []
  );
  const { signatureKey } = useParams();
  const [downloading, setDownloading] = React.useState(true);

  React.useEffect(() => {
    const downloadFile = async () => {
      const url = `${process.env.REACT_APP_API_URL}/files/download${
        isOutsourced ? "-outsourced" : ""
      }${
        window.location.pathname.includes("audit-trail") ? "/audit-trail" : ""
      }/${signatureKey}/`;

      try {
        const response = await axios.get(url, {
          ...getDefaultHeaders(),
          maxRedirects: 0,
        });
        if (response && response.data) {
          setDownloading(false);
          window.location.href = response.data;
        } else {
          toastr.error(ERROR_REFRESH_WITH_CONTACT_INFO);
        }
      } catch (error) {
        toastr.error(ERROR_REFRESH_WITH_CONTACT_INFO);
        logToSentryOrConsole(error);
      }
    };
    downloadFile();
  }, [signatureKey]);

  return (
    <Layout
      subheading={
        downloading ? "Download in progress..." : "Download Completed"
      }
      showHomePageLink={false}
    />
  );
};

export default CheckDownload;
