import { Formik } from "formik";
import PropTypes from "prop-types";
import RcIf from "rc-if";
import React from "react";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import * as Yup from "yup";

import {
  SUPPORTED_COUNTRIES,
  getVerificationValidationSchema,
} from "../../../constants/supportedCountries";
import Field from "../../form/Field";
import IdentityFields from "../../form/IdentityFields";
import SimpleDate from "../../form/SimpleDate";
import FormikScrollToErrors from "../../misc/FormikScrollToError";
import StepHeader from "../../misc/StepHeader";
import Address from "./Address";
import ConsentCheckbox from "./ConsentCheckbox";
import Ids from "./Ids";

const getValidationRules = (quickIDAlgorithm, sources = {}) => {
  const shape = getVerificationValidationSchema(sources, quickIDAlgorithm);
  return Yup.object().shape(shape);
};

const initialValues = ({
  firstName = "",
  middleName = "",
  lastName = "",
  birthDate = "",
  addressLine1 = "",
  unitNo = "",
  addressLine2 = "",
  city = "",
  state = "",
  postalCode = "",
  consent = false,
  countryCode = "",
  nationalId = "",
  nationalIdSecondary = "",
  nationalIdType = "",
  nationalIdSecondaryType = "",
  nationalIdTertiary = "",
  nationalIdTertiaryType = "",
  nationalIdCountryCode = "",
}) => ({
  firstName,
  middleName,
  lastName,
  birthDate,
  addressLine1,
  unitNo,
  addressLine2,
  city,
  state,
  postalCode,
  consent,
  countryCode,
  nationalId,
  nationalIdSecondary,
  nationalIdType,
  nationalIdSecondaryType,
  nationalIdTertiary,
  nationalIdTertiaryType,
  nationalIdCountryCode: nationalIdCountryCode || countryCode,
});

const QuickId = ({
  firstName = "",
  middleName = "",
  lastName = "",
  birthDate = "",
  unitNo = "",
  addressLine1 = "",
  addressLine2 = "",
  city = "",
  state = "",
  postalCode = "",
  verifying,
  runQuickId,
  countryCode,
  sources,
  quickIDAlgorithm,
  allowMixAndMatch,
  allowedCountries,
  defaultCountryCode,
}) => {
  const onSubmit = (data) => {
    runQuickId(data);
  };

  const filteredSortedCountryList = React.useMemo(() => {
    return Object.values(SUPPORTED_COUNTRIES)
      .filter(
        (country) =>
          !country.hidden &&
          (!allowedCountries ||
            !allowedCountries.length ||
            allowedCountries.includes(country.code))
      )
      .map((country) => ({
        value: country.code,
        label: country.description,
      }));
  }, [sources, allowedCountries]);

  return (
    <>
      <StepHeader text="Quick IDV" />
      <Formik
        initialValues={initialValues({
          firstName,
          lastName,
          middleName,
          birthDate,
          unitNo,
          addressLine1,
          addressLine2,
          city,
          state,
          postalCode,
          countryCode,
        })}
        validationSchema={getValidationRules(quickIDAlgorithm, sources)}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({
          handleSubmit,
          errors,
          setFieldValue,
          values,
          submitCount,
          isSubmitting,
          isValidating,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <FormikScrollToErrors
              errors={errors}
              isSubmitting={isSubmitting}
              isValidating={isValidating}
            />

            <RcIf if={!countryCode}>
              <Field
                name="countryCode"
                error={errors.countryCode}
                submitCount={submitCount}
                label="Where does your client live?"
                component="select"
              >
                <option value="" hidden>
                  Choose from {filteredSortedCountryList.length} countries
                </option>

                {filteredSortedCountryList.map((country) => (
                  <option key={country.value} value={country.value}>
                    {country.label}
                  </option>
                ))}
              </Field>
            </RcIf>

            <fieldset>
              <legend>Identity</legend>
              {values.countryCode === "CN" ? (
                <>
                  <p className="small text-primary mb-2">
                    Name input must be in simplified Chinese.
                  </p>
                  <div className="form-row">
                    <div className="col-6">
                      <Field
                        name="firstName"
                        error={errors.firstName}
                        submitCount={submitCount}
                        label="Full Name"
                        autoComplete="full-name"
                        placeholder="张伟"
                      />
                    </div>
                    <div className="col-6">
                      <SimpleDate
                        name="birthDate"
                        value={values.birthDate}
                        setFieldValue={setFieldValue}
                        error={errors.birthDate}
                        submitCount={submitCount}
                        label="Date of Birth"
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <p className="small text-primary mb-2">
                    Enter name exactly as it appears on identity documents.
                  </p>
                  <IdentityFields
                    errors={errors}
                    submitCount={submitCount}
                    birthDate={values.birthDate}
                    setFieldValue={setFieldValue}
                  />
                </>
              )}
            </fieldset>

            <Address />

            <Ids
              sources={sources}
              quickIDAlgorithm={quickIDAlgorithm}
              allowMixAndMatch={allowMixAndMatch}
              defaultCountryCode={defaultCountryCode}
            />

            <ConsentCheckbox error={errors.consent} submitCount={submitCount} />

            <div className="text-center">
              <Button
                color="primary"
                className="px-5"
                type="submit"
                disabled={verifying}
                block
              >
                Run ID Check
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

QuickId.propTypes = {
  verifying: PropTypes.bool.isRequired,
  runQuickId: PropTypes.func.isRequired,
  quickIDAlgorithm: PropTypes.string.isRequired,
  allowMixAndMatch: PropTypes.bool.isRequired,
  allowedCountries: PropTypes.arrayOf(PropTypes.string),
};

const mapStateToProps = ({ user }) => ({
  allowedCountries: user.allowedCountries,
  defaultCountryCode: user.defaultCountryCode,
});

export default connect(mapStateToProps)(QuickId);
