import axios from "axios";

import { getRunVerificationUrl } from "./apiUrls";

const fetchVerification = async ({ signatureKey }) =>
  await axios.get(getRunVerificationUrl(signatureKey));

const verify = async ({
  signatureKey,
  email,
  firstName,
  middleName,
  lastName,
  birthDate,
  unitNo,
  addressLine1,
  addressLine2,
  postalCode,
  city,
  state,
  countryCode,
  nationalId,
  nationalIdSecondary,
  nationalIdTertiary,
  nationalIdType,
  nationalIdSecondaryType,
  nationalIdTertiaryType,
  nationalIdCountryCode,
}) =>
  await axios.put(getRunVerificationUrl(signatureKey), {
    email,
    first_name: firstName,
    middle_name: middleName,
    last_name: lastName,
    birth_date: birthDate,
    unit_no: unitNo,
    address_line_1: addressLine1,
    address_line_2: addressLine2,
    postal_code: postalCode,
    city,
    state,
    country_code: countryCode,
    national_id: nationalId,
    national_id_secondary: nationalIdSecondary,
    national_id_tertiary: nationalIdTertiary,
    national_id_type: nationalIdType,
    national_id_secondary_type: nationalIdSecondaryType,
    national_id_tertiary_type: nationalIdTertiaryType,
    national_id_country_code: nationalIdCountryCode,
  });

const verifyPep = async ({ signatureKey, birthDate }) =>
  await axios.put(getRunVerificationUrl(signatureKey), {
    birth_date: birthDate,
  });

const generateResult = async ({ signatureKey }) =>
  await axios.put(getRunVerificationUrl(signatureKey));

const verifyCompany = async ({ signatureKey, companyNZBN, companySearchId }) =>
  await axios.put(getRunVerificationUrl(signatureKey), {
    company_NZBN: companyNZBN,
    company_search_id: companySearchId,
  });

const apis = {
  fetchVerification,
  verify,
  verifyPep,
  generateResult,
  verifyCompany,
};
export default apis;
