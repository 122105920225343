import React from "react";
import { Redirect } from "react-router";

import { isUserLoggedIn } from "../helpers/token";
import routes from "../routes";

const Home = () =>
  isUserLoggedIn() ? (
    <Redirect to={routes.checks} />
  ) : (
    <Redirect to={routes.signIn} />
  );

export default Home;
