import React, { useEffect } from "react";
import { connect } from "react-redux";

import { actions as billingActions } from "../../store/slices/billing";
import UpcomingInvoicePresenter from "./Presenters/UpcomingInvoice";

const { fetchUpcomingInvoice } = billingActions;

const UpcomingInvoice = ({
  fetchUpcomingInvoice,
  paymentType,
  dueDate,
  nextPaymentAttempt,
  amountRemaining,
  number,
}) => {
  useEffect(() => {
    fetchUpcomingInvoice();
  }, [fetchUpcomingInvoice]);

  return (
    <UpcomingInvoicePresenter
      paymentType={paymentType}
      dueDate={dueDate}
      nextPaymentAttempt={nextPaymentAttempt}
      amountRemaining={amountRemaining}
      number={number}
    />
  );
};

const mapStateToProps = ({
  billing: {
    paymentType,
    upcomingInvoice: { dueDate, nextPaymentAttempt, amountRemaining, number },
  },
}) => ({ paymentType, dueDate, nextPaymentAttempt, amountRemaining, number });
const mapDispatchToProps = { fetchUpcomingInvoice };

export default connect(mapStateToProps, mapDispatchToProps)(UpcomingInvoice);
