import PropTypes from "prop-types";
import React from "react";
import { FaSpinner } from "react-icons/fa";
import { Tooltip } from "react-tippy";
import Toggle from "react-toggle";
import { Button } from "reactstrap";

import {
  COMPLETED_VERIFICATION_DELETE_DISABLED,
  COMPLETED_VERIFICATION_DELETE_ENABLED,
  REMINDERS_DISABLED,
  REMINDERS_ENABLED,
} from "../../../constants/lang/en";
import DashboardLayout from "../../dashboard/Presenters/Layout";
import Info from "../../icons/InfoIcon";
import StepLayout from "../../misc/StepLayout";
import SettingsModal from "./SettingsModal";

const Settings = ({
  email,
  agencyName,
  companyEmail,
  saveAgency,
  deleteCompletedChecksInDays,
  sendVerificationReminders,
  fetching,
}) => {
  const [showSettingsModal, setShowSettingsModal] = React.useState(false);

  const handleDeletedSettings = () => {
    saveAgency(
      { deleteCompletedChecksInDays: deleteCompletedChecksInDays ? null : 7 },
      deleteCompletedChecksInDays
        ? COMPLETED_VERIFICATION_DELETE_DISABLED
        : COMPLETED_VERIFICATION_DELETE_ENABLED
    );
  };

  const handleReminderSettings = () => {
    saveAgency(
      { sendVerificationReminders: !sendVerificationReminders },
      sendVerificationReminders ? REMINDERS_DISABLED : REMINDERS_ENABLED
    );
  };

  return (
    <DashboardLayout>
      <StepLayout>
        <section>
          <div className="d-flex align-items-center mb-4">
            <h4 className="mb-0">Settings</h4>
          </div>
          <div className="d-flex align-items-center mb-3">
            <span>Trading Name:</span>
            <strong className="ml-2">{agencyName}</strong>
            <Button
              color="link"
              className="ml-2 mt-1 p-0 flex-shrink-0"
              onClick={() => setShowSettingsModal(true)}
            >
              Change
            </Button>
          </div>

          <div className="d-flex align-items-center mb-3">
            <label className="d-flex align-items-center cursor-pointer mt-2">
              <Toggle
                onChange={handleDeletedSettings}
                defaultChecked={!!deleteCompletedChecksInDays}
                icons={false}
                disabled={fetching}
              />
              <span className="mx-2">Completed verifications deleted</span>
            </label>
            <Tooltip
              title="Verifications to be automatically deleted seven calendar days after completion."
              position="top"
              size="small"
              className="mt-n1 text-muted"
            >
              <Info />
            </Tooltip>
            {fetching && <FaSpinner className="ml-2 spin text-secondary" />}
          </div>
          <div className="d-flex align-items-center mb-3">
            <label className="d-flex align-items-center cursor-pointer mt-2">
              <Toggle
                onChange={handleReminderSettings}
                checked={!!sendVerificationReminders}
                icons={false}
                disabled={fetching}
              />
              <span className="mx-2">Incomplete verification reminders</span>
            </label>
            <Tooltip
              title="Your clients will receive a reminder 24 hours and 72 hours after you initiate a verification."
              position="bottom"
              size="small"
              className="mt-n1 text-muted"
            >
              <Info />
            </Tooltip>
            {fetching && <FaSpinner className="ml-2 spin text-secondary" />}
          </div>
        </section>
      </StepLayout>

      {showSettingsModal && (
        <SettingsModal
          showModal={showSettingsModal}
          toggleModal={() => setShowSettingsModal((state) => !state)}
        />
      )}
    </DashboardLayout>
  );
};

Settings.propTypes = {
  fetching: PropTypes.bool.isRequired,
  saveAgency: PropTypes.func.isRequired,
};

export default Settings;
