import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { FaBars } from "react-icons/fa";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

import { isUserLoggedIn } from "../helpers/token";
import logo from "../images/logo-white.png";
import routes from "../routes";
import { actions as userActions } from "../store/slices/user";
import { getDashboardNavs } from "./dashboard/Presenters/Nav";
import Logout from "./icons/LogoutIcon";
import LinkWrap from "./misc/LinkWrap";

const { signOut, fetchUser } = userActions;

const Layout = ({ location, user, fetchUser, signOut, children }) => {
  const [navOpened, setNavState] = useState(false);
  const [greeting, setGreeting] = useState();
  const { showOutsourcedMenu, showComplianceMenu, industry } = user;

  const dashboardNavs = useMemo(
    () =>
      getDashboardNavs({
        showOutsourcedMenu,
        showComplianceMenu,
        industry,
        isAdmin: user.role === "ADMIN",
      }),
    [showOutsourcedMenu, showComplianceMenu, industry, user.role]
  );

  useEffect(() => {
    if (isUserLoggedIn()) {
      fetchUser();
    }
  }, [fetchUser]);

  useEffect(() => {
    if (!user || !user.agencyName) return;

    const currentTime = new Date().getHours();
    if (currentTime >= 5 && currentTime <= 11) {
      setGreeting(`Good Morning, ${user.agencyName || ""}`);
    } else if (currentTime >= 12 && currentTime <= 16) {
      setGreeting(`Good Afternoon, ${user.agencyName || ""}`);
    } else {
      setGreeting(`Good Evening, ${user.agencyName || ""}`);
    }
  }, [setGreeting, user]);

  return (
    <div className="site-content">
      <header
        className="site-header"
        style={
          user && user.dashboardBrand && user.dashboardBrand.nav_bg
            ? { backgroundColor: user.dashboardBrand.nav_bg }
            : undefined
        }
      >
        <div className="container-fluid">
          <div className="header-left-menu d-none d-md-block text-white pl-3 ml-1 text-sm">
            {greeting}
          </div>
          <div className="header-logo text-left text-md-center">
            <Link to="/">
              <img src={logo} alt="Realaml" width="120" />
            </Link>
          </div>
          <div className="header-right-menu text-right">
            {location.pathname.includes(
              "/verify/"
            ) ? null : isUserLoggedIn() ? (
              <div className="d-flex align-items-center justify-content-end">
                <Dropdown
                  isOpen={navOpened}
                  toggle={() => setNavState(!navOpened)}
                  className="dashboard-mobile-nav"
                >
                  <DropdownToggle className="btn-sm bg-white border-white text-dark dashboard-mobile-toggler">
                    <div className="d-flex align-items-center">
                      <FaBars style={{ lineHeight: 1 }} />
                      <span className="d-none d-lg-inline ml-2 font-weight-bolder">
                        Menu
                      </span>
                    </div>
                  </DropdownToggle>
                  <DropdownMenu right>
                    {dashboardNavs.map((nav) =>
                      nav.label === "Settings" &&
                      user.role !== "ADMIN" ? null : (
                        <DropdownItem
                          key={nav.label}
                          tag={nav.isExternal ? "a" : LinkWrap}
                          to={!nav.isExternal ? nav.href : undefined}
                          href={nav.isExternal ? nav.href : undefined}
                          target={nav.isExternal ? "_blank" : undefined}
                          active={nav.href === location.pathname}
                          rel={
                            nav.isExternal ? "noopener noreferrer" : undefined
                          }
                          className={
                            nav.onlyInDropdown ? "" : "d-block d-lg-none"
                          }
                        >
                          {nav.icon}
                          <span className="font-weight-bolder">
                            {nav.label}
                          </span>
                        </DropdownItem>
                      )
                    )}
                    <DropdownItem onClick={signOut} tag="button">
                      <Logout />
                      <span className="font-weight-bolder">Logout</span>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            ) : location.pathname === routes.signIn ? (
              // <Link
              //   to={routes.signUp}
              //   className="btn btn-sm btn-secondary bg-white border-white text-dark text-uppercase"
              // >
              //   Join
              // </Link>
              <div />
            ) : (
              <Link
                to={routes.signIn}
                className="btn btn-sm btn-secondary bg-white border-white text-dark text-uppercase"
              >
                Log In
              </Link>
            )}
          </div>
        </div>
      </header>
      <main>{children}</main>
      <footer className="site-footer" />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
  signOut: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = { signOut, fetchUser };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));
