import React from "react";

const Settings = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24.19 24.19"
    xmlSpace="preserve"
  >
    <path
      d="M12.7 1.6c.46 0 .84.38.84.84v2.55c.92.2 1.78.56 2.54 1.07l1.82-1.82a.84.84 0 0 1 .59-.24c.21 0 .43.08.59.24l.86.86c.33.33.33.86 0 1.18l-1.83 1.83c.5.76.87 1.61 1.06 2.53h2.58c.46 0 .84.38.84.84v1.22c0 .46-.38.84-.84.84h-2.56c-.18.93-.54 1.8-1.04 2.57l1.8 1.8c.33.33.33.86 0 1.18l-.86.86c-.16.16-.38.24-.59.24s-.43-.08-.59-.24l-1.77-1.77c-.77.53-1.65.91-2.59 1.11v2.47c0 .46-.38.84-.84.84h-1.22c-.46 0-.84-.38-.84-.84v-2.44c-.96-.18-1.86-.56-2.65-1.08l-1.71 1.71c-.16.16-.38.24-.59.24s-.43-.08-.59-.24l-.86-.86a.827.827 0 0 1 0-1.18l1.71-1.71c-.53-.79-.91-1.69-1.1-2.66H2.44c-.46 0-.84-.38-.84-.84v-1.22c0-.46.38-.84.84-.84h2.43c.2-.96.58-1.84 1.11-2.62L4.24 6.28a.827.827 0 0 1 0-1.18l.86-.86c.16-.16.38-.24.59-.24s.43.08.59.24L8.04 6c.78-.5 1.65-.86 2.6-1.04V2.43c0-.46.38-.84.84-.84h1.22m0-1.59h-1.22c-1.34 0-2.44 1.09-2.44 2.44v1.3c-.24.09-.48.18-.71.29l-.92-.92c-.46-.46-1.07-.71-1.72-.71s-1.26.25-1.72.71l-.86.86c-.95.95-.95 2.5 0 3.45l.87.87c-.12.25-.23.5-.32.76H2.43c-1.34 0-2.44 1.09-2.44 2.44v1.22c0 1.34 1.09 2.44 2.44 2.44h1.2c.1.27.2.53.32.78l-.85.85c-.95.95-.95 2.5 0 3.45l.86.86c.46.46 1.07.71 1.72.71s1.26-.25 1.72-.71l.86-.86c.25.12.51.22.77.31v1.22c0 1.34 1.09 2.44 2.44 2.44h1.22c1.34 0 2.44-1.09 2.44-2.44v-1.28c.25-.09.49-.19.72-.31l.91.91c.46.46 1.07.71 1.72.71s1.26-.25 1.72-.71l.86-.86c.46-.46.71-1.07.71-1.72s-.25-1.26-.71-1.72l-.95-.95c.1-.22.19-.45.28-.68h1.35c1.34 0 2.44-1.09 2.44-2.44v-1.22c0-1.34-1.09-2.44-2.44-2.44h-1.38c-.08-.22-.17-.44-.27-.65l.98-.98c.46-.46.71-1.07.71-1.72 0-.65-.25-1.26-.71-1.72l-.86-.86c-.46-.46-1.07-.71-1.72-.71-.65 0-1.26.25-1.72.71l-.96.96c-.22-.1-.44-.2-.67-.28V2.44C15.14 1.1 14.05 0 12.7 0Z"
      fill="currentColor"
      strokeWidth="0"
    />
    <circle
      cx={12.09}
      cy={12.09}
      r={4.02}
      fill="none"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeWidth="1.6px"
    />
  </svg>
);

export default Settings;
