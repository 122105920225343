import "react-tippy/dist/tippy.css";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import React, { Component } from "react";
import ReactGA from "react-ga4";
import { Route, Router, Switch } from "react-router";

import AccountUnlock from "./components/auth/AccountUnlock";
import ForgotPassword from "./components/auth/ForgotPassword";
import ProfileCompleteForm from "./components/auth/ProfileCompleteForm";
import ResetPassword from "./components/auth/ResetPassword";
import SignIn from "./components/auth/SignIn";
import SignUp from "./components/auth/SignUp";
import VerifyEmail from "./components/auth/VerifyEmail";
import AuthRoute from "./components/AuthRoute";
import Billing from "./components/dashboard/Billing";
import ChangePassword from "./components/dashboard/ChangePassword";
import OutSourced from "./components/dashboard/OutSourced";
import PaymentModal from "./components/dashboard/PaymentModal";
import PartialAndFailedChecks from "./components/dashboard/Presenters/PartialAndFailedChecks";
import NotFound from "./components/errors/NotFound";
import ServerError from "./components/errors/ServerError";
import VerificationCancelled from "./components/errors/VerificationCancelled";
import VerificationExpired from "./components/errors/VerificationExpired";
import Home from "./components/Home";
import Layout from "./components/Layout";
import RouteTracker from "./components/misc/RouteTracker";
import NonAuthRoute from "./components/NonAuthRoute";
import AgencyUsers from "./components/pages/agencyUsers";
import CheckDownload from "./components/pages/checkDownload";
import CheckHistory from "./components/pages/checkHistory";
import Checks from "./components/pages/checks";
import ChecksExternal from "./components/pages/checksExternal";
import CheckVideoDownload from "./components/pages/checkVideoDownload";
import Compliance from "./components/pages/compliance";
import FaceMatch from "./components/pages/facematch";
import FaceMatchQuickID from "./components/pages/facematchQuickID";
import ActionStepIntegration from "./components/pages/integrations/actionStep";
import ZapierIntegration from "./components/pages/integrations/zapier";
import Marketplace from "./components/pages/marketplace";
import OutsourcedHistory from "./components/pages/outsourcedHistory";
import Pep from "./components/pages/pep";
import Profile from "./components/pages/profile";
import QuickId from "./components/pages/quickId";
import QuickIdBankMatch from "./components/pages/quickIDBankMatch";
import QuickIdFaceMatch from "./components/pages/quickIdFaceMatch";
import Settings from "./components/pages/settings";
import Verification from "./components/verification";
import history from "./history";
import routes from "./routes";

const gaID =
  process.env.REACT_APP_GA4_MEASUREMENT_ID !== "NA"
    ? process.env.REACT_APP_GA4_MEASUREMENT_ID
    : null;

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT || "production",
    release: process.env.REACT_APP_COMMIT_ID,
    integrations: [new Integrations.BrowserTracing()],
  });
}

if (gaID) {
  ReactGA.initialize(gaID);
}

export default class App extends Component {
  state = {
    error: null,
  };

  componentDidCatch(error, errorInfo) {
    if (process.env.REACT_APP_SENTRY_DSN) {
      this.setState({ error });
      Sentry.withScope((scope) => {
        Object.keys(errorInfo).forEach((key) => {
          scope.setExtra(key, errorInfo[key]);
        });
        Sentry.captureException(error);
      });
    } else {
      console.error(error);
    }
  }

  render() {
    return (
      <Router history={history}>
        <Layout>
          <Switch>
            {/* Old Routes, redirect to new routes */}
            <Route
              path="/v/:id"
              component={() => {
                window.location.href = `${
                  process.env.REACT_APP_USER_APP_URL
                }/${window.location.pathname.replace("/v/", "")}`;
                return null;
              }}
            />
            <Route
              path="/checks/nz-quick-id"
              component={() => {
                window.location.href = `${window.location.href.replace(
                  "/checks/nz-quick-id",
                  "/checks/quick-id"
                )}`;
                return null;
              }}
            />
            <Route
              path="checks/nz-quick-id-face-match"
              component={() => {
                window.location.href = `${window.location.href.replace(
                  "checks/nz-quick-id-face-match",
                  "/checks/quick-id-face-match"
                )}`;
                return null;
              }}
            />
            <Route
              path="/checks/overseas-quick-id"
              component={() => {
                window.location.href = `${window.location.href.replace(
                  "/checks/overseas-quick-id",
                  "/checks/quick-id"
                )}`;
                return null;
              }}
            />
            <Route
              path="/checks/overseas-quick-id-face-match"
              component={() => {
                window.location.href = `${window.location.href.replace(
                  "/checks/overseas-quick-id-face-match",
                  "/checks/quick-id-face-match"
                )}`;
                return null;
              }}
            />

            {/* New routes */}
            <Route path={routes.home} component={Home} exact />

            <NonAuthRoute path={routes.signIn} component={SignIn} />
            <NonAuthRoute path={routes.signUp} component={SignUp} />
            <NonAuthRoute path={routes.signUpOld} component={SignUp} />
            <Route path={routes.forgotPassword} component={ForgotPassword} />
            <Route path={routes.resetPassword} component={ResetPassword} />
            <Route path={routes.accountUnlock} component={AccountUnlock} />
            <Route path={routes.verifyEmail} component={VerifyEmail} />

            <AuthRoute path={routes.checks} component={Checks} exact />
            <AuthRoute path={routes.quickId} component={QuickId} />
            <AuthRoute
              path={routes.quickIdFaceMatch}
              component={QuickIdFaceMatch}
            />
            <AuthRoute
              path={routes.faceMatchQuickId}
              component={FaceMatchQuickID}
            />
            <AuthRoute
              path={routes.quickIdBankMatch}
              component={QuickIdBankMatch}
            />
            <AuthRoute path={routes.faceMatch} component={FaceMatch} />
            <AuthRoute path={routes.pep} component={Pep} />
            <AuthRoute path={routes.outSourced} component={OutSourced} />
            <AuthRoute
              path={routes.checksExternal}
              component={ChecksExternal}
            />
            <AuthRoute
              path={routes.partialAndFailedChecks}
              component={PartialAndFailedChecks}
            />
            <AuthRoute path={routes.checksHistory} component={CheckHistory} />
            <AuthRoute
              path={routes.outSourcedHistory}
              component={OutsourcedHistory}
            />
            <AuthRoute path={routes.compliance} component={Compliance} />
            <AuthRoute path={routes.marketplace} component={Marketplace} />
            <AuthRoute
              path={routes.checksVideo1Download}
              component={CheckVideoDownload}
            />
            <AuthRoute
              path={routes.checksVideo2Download}
              component={CheckVideoDownload}
            />
            <AuthRoute
              path={routes.filesDownload}
              component={CheckVideoDownload}
            />
            <AuthRoute path={routes.checksDownload} component={CheckDownload} />
            <AuthRoute
              path={routes.outsourcedChecksDownload}
              component={CheckDownload}
            />
            <AuthRoute
              path={routes.checksAuditTrailDownload}
              component={CheckDownload}
            />
            <AuthRoute
              path={routes.outsourcedChecksAuditTrailDownload}
              component={CheckDownload}
            />
            <AuthRoute path={routes.profile} component={Profile} />
            <AuthRoute path={routes.settings} component={Settings} />
            <AuthRoute path={routes.agencyUsers} component={AgencyUsers} />
            <AuthRoute
              path={routes.changePassword}
              component={ChangePassword}
            />
            <AuthRoute path={routes.billing} component={Billing} />

            <AuthRoute
              path={routes.integrationActionStep}
              component={ActionStepIntegration}
            />

            <AuthRoute
              path={routes.integrationZapierAuthorize}
              component={ZapierIntegration}
            />

            <Route path={routes.verify} component={Verification} />

            <Route
              path={routes.verificationCancelled}
              component={VerificationCancelled}
            />
            <Route
              path={routes.verificationExpired}
              component={VerificationExpired}
            />
            <Route path={routes.notFound} component={NotFound} />
            <Route path={routes.serverError} component={ServerError} />
            <Route component={NotFound} />
          </Switch>

          <ProfileCompleteForm />
          <PaymentModal />
        </Layout>
        <Route component={RouteTracker} />
      </Router>
    );
  }
}
