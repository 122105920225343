// import * as serviceWorker from './serviceWorker';
import "react-toggle/style.css";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import "./styles/index.scss";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import ReduxToastr from "react-redux-toastr";

import App from "./App";
import initAxios from "./initAxios";
import store from "./store";

initAxios();

ReactDOM.render(
  <Provider store={store}>
    <App />
    <ReduxToastr
      timeOut={7500}
      transitionIn="bounceInDown"
      transitionOut="bounceOutUp"
    />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
