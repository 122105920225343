import PropTypes from "prop-types";
import RcIf from "rc-if";
import React from "react";
import { FaMinusCircle } from "react-icons/fa";

import resultTypes, { FAIL } from "../../../constants/results";
import FailIcon from "../../icons/FailIcon";
import PassIcon from "../../icons/PassIcon";
import ReviewIcon from "../../icons/ReviewIcon";

const Result = ({ result, size }) => {
  let resultType = resultTypes.find((r) => r.key === result);
  if (!resultType) {
    resultType = resultTypes.find((r) => r.key === FAIL);
  }

  return (
    <span
      className={`badge badge-flex badge-${resultType.type} ${
        size === "lg" ? "badge-lg" : "badge-outline"
      }`}
    >
      <span className="mr-2">
        <RcIf if={resultType.type === "success"}>
          <PassIcon width={size === "lg" ? "20px" : "16px"} />
        </RcIf>
        <RcIf if={resultType.type === "danger"}>
          <FailIcon width={size === "lg" ? "20px" : "16px"} />
        </RcIf>
        <RcIf if={resultType.type === "warning"}>
          <ReviewIcon width={size === "lg" ? "20px" : "16px"} />
        </RcIf>
        <RcIf if={resultType.type === "secondary"}>
          <FaMinusCircle size={size === "lg" ? "20px" : "16px"} />
        </RcIf>
      </span>
      {resultType.label}
    </span>
  );
};

Result.propTypes = {
  result: PropTypes.string.isRequired,
  size: PropTypes.string,
};

export default Result;
