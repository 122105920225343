import { createAction, createReducer } from "@reduxjs/toolkit";
import { toastr } from "react-redux-toastr";

import AuthApi from "../../api/auth";
import {
  ACCOUNT_EXISTS,
  ACCOUNT_UNLOCK_FAILED,
  ACCOUNT_UNLOCK_SUCCESS,
  DATA_ERROR,
  DEFAULT_ERROR,
  EMAIL_NOT_VERIFIED,
  ERROR_WITH_CONTACT_INFO,
  INVALID_AUTH_DETAILS,
  MOBILE_CODE_NOT_VALID,
  PASSWORDS_NO_MATCH,
  PASSWORD_CHANGE_SUCCESS,
  PASSWORD_RESET_FAILED,
  RESET_LINK_INVALID,
  RESET_PASSWORD_SUCCESS,
  UNLOCK_LINK_INVALID,
} from "../../constants/lang/en";
import { trackGaRecommendedEvent } from "../../helpers/ga";
import { getReferrer } from "../../helpers/referrer";
import logToSentryOrConsole from "../../helpers/sentry";
import { getToken, setAuthTokens } from "../../helpers/token";
import history from "../../history";
import routes from "../../routes";
import { actions as userActions } from "./user";

const { fetchUser } = userActions;

const INITIAL_STATE = {
  fetching: false,
  fetchError: "",
  fetchCompleted: false,
  forgotPasswordEmailSent: false,
  token: "",
  email: "",
  password: "",
};

const fetchAuthStarted = createAction("fetchAuthStarted");
const fetchAuthCompleted = createAction("fetchAuthCompleted");
const fetchAuthFailed = createAction("fetchAuthFailed");
const forgotPasswordEmailSent = createAction("forgotPasswordEmailSent");
const forgotPasswordEmailReset = createAction("forgotPasswordEmailReset");

const errorHandler = (dispatch, errorTitle = "", errorMsg = DEFAULT_ERROR) => {
  toastr.error(errorTitle, errorMsg);
  dispatch(fetchAuthFailed(errorMsg));
};

const signIn =
  (data, { redirectTo = routes.checks }, onNoCode) =>
  async (dispatch) => {
    const email = data.agentEmail?.trim();
    const password = data.agentPassword?.trim();
    dispatch(fetchAuthStarted({ email, password }));
    try {
      const result = await AuthApi.signIn(
        {
          username: email,
          password,
          code: data.code,
        },
        redirectTo
      );

      if (!result || !result.data) {
        errorHandler(dispatch, "Sign In Failed");
        return;
      }

      setAuthTokens(result.data.access_token, result.data.refresh_token);
      dispatch(fetchAuthCompleted());
      history.push(redirectTo);
      dispatch(fetchUser({ followDefaultPasswordRedirect: true }));
    } catch (error) {
      if (error && error.response && error.response.status >= 500) {
        logToSentryOrConsole(error);
        errorHandler(dispatch, "", ERROR_WITH_CONTACT_INFO);
      } else if (error && error.response && error.response.status === 400) {
        if (onNoCode) {
          onNoCode();
        } else {
          errorHandler(dispatch, "", MOBILE_CODE_NOT_VALID);
        }
        dispatch(fetchAuthCompleted());
      } else if (error && error.response && error.response.status === 403) {
        errorHandler(dispatch, "Sign In Failed", EMAIL_NOT_VERIFIED);
      } else {
        var remaining_login_attempt =
          error.response && error.response.data
            ? error.response.data.remaining_login_attempt
            : false;
        var remaining_login_attempt_count =
          typeof remaining_login_attempt !== "number"
            ? 0
            : remaining_login_attempt;
        if (remaining_login_attempt_count) {
          errorHandler(
            dispatch,
            "Sign In Failed",
            `${INVALID_AUTH_DETAILS} You have ${remaining_login_attempt_count} attempt(s) left.`
          );
        } else {
          errorHandler(
            dispatch,
            "New Sign In Locked",
            "Your account has been temporarily locked due to too many incorrect login attempts. To unlock your account, please check your email for detailed instructions. For further assistance or any inquiries, contact us at support@realaml.com. Thank you for your cooperation."
          );
        }
      }
    }
  };

const signUp = (data) => async (dispatch) => {
  const email = data.agentEmail?.trim();
  dispatch(fetchAuthStarted({ email }));
  try {
    const result = await AuthApi.signUp({
      username: email,
      email,
      agencyName: data.agencyName?.trim(),
      referrer: getReferrer(),
    });

    if (!result || !result.data) {
      errorHandler(dispatch, "Signup Failed");
      return;
    }

    setAuthTokens(result.data.access_token, result.data.refresh_token);
    dispatch(fetchAuthCompleted());
    trackGaRecommendedEvent("sign_up");
    history.push(routes.checks);
    dispatch(fetchUser());
  } catch (error) {
    if (error && error.response && error.response.status === 400) {
      let errorMsg = DATA_ERROR;
      if (error.response.data) {
        const usernameError = error.response.data.username;
        if (
          usernameError &&
          ((Array.isArray(usernameError) &&
            usernameError.includes(
              "A user with that username already exists."
            )) ||
            usernameError === "A user with that username already exists.")
        ) {
          history.push(routes.signIn);
          errorMsg = ACCOUNT_EXISTS;
        }
      }
      errorHandler(dispatch, "Signup Failed", errorMsg);
    } else {
      logToSentryOrConsole(error);
      errorHandler(dispatch, "Signup Failed");
    }
  }
};

const forgotPassword = (data) => async (dispatch) => {
  const email = data.agentEmail?.trim();
  dispatch(fetchAuthStarted({ email }));
  try {
    await AuthApi.forgotPassword({ username: email });
    dispatch(fetchAuthCompleted());
    dispatch(forgotPasswordEmailSent());
    if (data.resent) {
      toastr.success("", RESET_PASSWORD_SUCCESS);
    }
  } catch (error) {
    if (error && error.response && [400, 404].includes(error.response.status)) {
      dispatch(fetchAuthCompleted());
      dispatch(forgotPasswordEmailSent());
    } else {
      logToSentryOrConsole(error);
      errorHandler(dispatch);
    }
  }
};

const resetForgotPasswordState = () => (dispatch) => {
  dispatch(forgotPasswordEmailReset());
};

const checkResetPassword = () => async (dispatch) => {
  dispatch(fetchAuthStarted());
  try {
    await AuthApi.checkResetPassword({ token: getToken() });
    dispatch(fetchAuthCompleted());
  } catch (error) {
    errorHandler(dispatch, "", RESET_LINK_INVALID);
    history.push(routes.forgotPassword);
  }
};

const resetPassword = (data) => async (dispatch) => {
  dispatch(fetchAuthStarted());
  if (data.newPassword !== data.confirmPassword) {
    errorHandler(dispatch, "", PASSWORDS_NO_MATCH);
    return;
  }
  try {
    const result = await AuthApi.resetPassword({
      token: getToken(),
      password: data.newPassword?.trim(),
    });
    dispatch(fetchAuthCompleted());
    toastr.success("", PASSWORD_CHANGE_SUCCESS);
    if (!result || !result.data) {
      history.push(routes.signIn);
    } else {
      setAuthTokens(result.data.access_token, result.data.refresh_token);
      history.push(routes.checks);
      dispatch(fetchUser());
    }
  } catch (error) {
    logToSentryOrConsole(error);
    errorHandler(dispatch, "", PASSWORD_RESET_FAILED);
  }
};

const checkAccountUnlock = () => async (dispatch) => {
  dispatch(fetchAuthStarted());
  try {
    await AuthApi.checkAccountUnlock({ token: getToken() });
    dispatch(fetchAuthCompleted());
  } catch (error) {
    errorHandler(dispatch, "", UNLOCK_LINK_INVALID);
    history.push(routes.signIn);
  }
};

const accountUnlock = (data) => async (dispatch) => {
  dispatch(fetchAuthStarted());
  if (data.newPassword !== data.confirmPassword) {
    errorHandler(dispatch, "", PASSWORDS_NO_MATCH);
    return;
  }
  try {
    const result = await AuthApi.accountUnlock({
      token: getToken(),
      password: data.newPassword?.trim(),
    });
    dispatch(fetchAuthCompleted());
    toastr.success("", ACCOUNT_UNLOCK_SUCCESS);
    if (!result || !result.data) {
      history.push(routes.signIn);
    } else {
      setAuthTokens(result.data.access_token, result.data.refresh_token);
      history.push(routes.checks);
      dispatch(fetchUser());
    }
  } catch (error) {
    logToSentryOrConsole(error);
    errorHandler(dispatch, "", ACCOUNT_UNLOCK_FAILED);
  }
};

export const actions = {
  signIn,
  signUp,
  forgotPassword,
  resetForgotPasswordState,
  checkResetPassword,
  resetPassword,
  checkAccountUnlock,
  accountUnlock,
};

export const reducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(fetchAuthStarted, (state, { payload = {} }) => {
      state.fetching = true;
      state.fetchError = "";
      state.fetchCompleted = false;
      if (payload.email) {
        state.email = payload.email;
      }
      if (payload.password) {
        state.password = payload.password;
      }
    })
    .addCase(fetchAuthCompleted, (state) => {
      state.fetching = false;
      state.fetchCompleted = true;
    })
    .addCase(fetchAuthFailed, (state, { payload }) => {
      state.fetching = false;
      state.fetchError = payload;
    })
    .addCase(forgotPasswordEmailSent, (state) => {
      state.forgotPasswordEmailSent = true;
    })
    .addCase(forgotPasswordEmailReset, (state) => {
      state.forgotPasswordEmailSent = false;
    });
});
