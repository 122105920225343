import { Formik } from "formik";
import PropTypes from "prop-types";
import RcIf from "rc-if";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { isValidPhoneNumber } from "react-phone-number-input";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import { Button } from "reactstrap";
import * as Yup from "yup";

import { FACEMATCH, QUICK_ID } from "../../../constants/checkTypes";
import { MOBILE_LABEL } from "../../../constants/lang/en";
import {
  SUPPORTED_COUNTRIES,
  getVerificationValidationSchema,
} from "../../../constants/supportedCountries";
import {
  EMAIL_MSG,
  EMAIL_OR_MOBILE_MSG,
} from "../../../constants/validationHelpers";
import Field from "../../form/Field";
import IdentityFields from "../../form/IdentityFields";
import PhoneField from "../../form/PhoneField";
import SimpleDate from "../../form/SimpleDate";
import Trash from "../../icons/TrashIcon";
import Upload from "../../icons/UploadIcon";
import FormikScrollToErrors from "../../misc/FormikScrollToError";
import Loading from "../../misc/Loading";
import Address from "../../verification/Presenters/Address";
import ConsentCheckbox from "../../verification/Presenters/ConsentCheckbox";
import Ids from "../../verification/Presenters/Ids";

const getValidationRules = (
  includeFaceMatch,
  quickIDAlgorithm,
  sources = {}
) => {
  const shape = getVerificationValidationSchema(sources, quickIDAlgorithm);
  if (includeFaceMatch) {
    shape.email = Yup.string().trim().optional().email(EMAIL_MSG);
    shape.phone = Yup.string().trim().optional();
  }
  return Yup.object().shape(shape);
};

const getInitialValues = (includeFaceMatch, countryCode) => {
  const initialValues = {
    checkType: QUICK_ID,
    firstName: "",
    middleName: "",
    lastName: "",
    birthDate: "",
    addressLine1: "",
    unitNo: "",
    addressLine2: "",
    city: "",
    state: "",
    postalCode: "",
    countryCode: countryCode || "",
    nationalId: "",
    nationalIdSecondary: "",
    nationalIdType: "",
    nationalIdSecondaryType: "",
    nationalIdTertiary: "",
    nationalIdTertiaryType: "",
    consent: false,
    nationalIdCountryCode: countryCode || "",
  };
  if (includeFaceMatch) {
    initialValues.phone = "";
    initialValues.email = "";
  }
  return initialValues;
};

const SelfCheckForm = ({
  fetching,
  resetCheck,
  addCheckAndVerify,
  checkType,
  defaultCountryCode,
  quickIDAlgorithm,
  allowMixAndMatch,
  allowedCountries,
  sources,
}) => {
  const [files, setFiles] = useState([]);

  const onDropAccepted = useCallback((acceptedFiles) => {
    const parsedFiles = acceptedFiles.map((file) => ({
      originalFile: file,
      id: `${Date.now()}-${Math.random()}`,
      name: file.name,
      sizeInKb: `${(file.size / 1000).toFixed(2)}kb`,
    }));
    setFiles((files) => [...files, ...parsedFiles]);
  }, []);

  const onDropRejected = useCallback(() => {
    toastr.error(
      "",
      "Please upload pdf, png or jpg files which are less than 20 MB."
    );
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/jpg, image/png, application/pdf",
    maxSize: 25000001,
    multiple: true,
    onDropAccepted,
    onDropRejected,
  });

  const deleteFile = (id) => {
    const newFiles = files.filter((f) => f.id !== id);
    setFiles(newFiles);
  };

  const includeFaceMatch = checkType.includes(FACEMATCH);

  const onSubmit = (data, { setFieldError }) => {
    if (includeFaceMatch && !data.phone && !data.email) {
      setFieldError("email", EMAIL_OR_MOBILE_MSG);
      setFieldError("phone", EMAIL_OR_MOBILE_MSG);
      return;
    }
    if (data.phone && !isValidPhoneNumber(data.phone)) {
      setFieldError("phone", "Please enter a valid phone number");
      return;
    }
    addCheckAndVerify(
      {
        ...data,
        checkType,
      },
      files
    );
  };

  const filteredSortedCountryList = React.useMemo(() => {
    if (!sources) return [];

    return Object.values(SUPPORTED_COUNTRIES)
      .filter(
        (country) =>
          !country.hidden &&
          (!allowedCountries ||
            !allowedCountries.length ||
            allowedCountries.includes(country.code))
      )
      .map((country) => ({
        value: country.code,
        label: country.description,
      }));
  }, [sources, allowedCountries]);

  return !sources ? (
    <Loading />
  ) : (
    <div>
      <Formik
        validationSchema={getValidationRules(
          includeFaceMatch,
          quickIDAlgorithm,
          sources
        )}
        onSubmit={onSubmit}
        initialValues={getInitialValues(includeFaceMatch, defaultCountryCode)}
        enableReinitialize
      >
        {({
          handleSubmit,
          errors,
          values,
          submitCount,
          setFieldValue,
          isSubmitting,
          isValidating,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <FormikScrollToErrors
              errors={errors}
              isSubmitting={isSubmitting}
              isValidating={isValidating}
            />
            {checkType !== FACEMATCH && (
              <Field
                name="countryCode"
                error={errors.countryCode}
                submitCount={submitCount}
                label="Where does your client live?"
                component="select"
              >
                <option value="" hidden>
                  Choose from {filteredSortedCountryList.length} countries
                </option>

                {filteredSortedCountryList.map((country) => (
                  <option key={country.value} value={country.value}>
                    {country.label}
                  </option>
                ))}
              </Field>
            )}

            <fieldset>
              <legend>Identity</legend>
              {values.countryCode === "CN" ? (
                <>
                  <p className="small text-primary mb-2">
                    Name input must be in simplified Chinese.
                  </p>
                  <div className="form-row">
                    <div className="col-6">
                      <Field
                        name="firstName"
                        error={errors.firstName}
                        submitCount={submitCount}
                        label="Full Name"
                        autoComplete="full-name"
                        placeholder="张伟"
                      />
                    </div>
                    <div className="col-6">
                      <SimpleDate
                        name="birthDate"
                        value={values.birthDate}
                        setFieldValue={setFieldValue}
                        error={errors.birthDate}
                        submitCount={submitCount}
                        label="Date of Birth"
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <p className="small text-primary mb-2">
                    Enter name exactly as it appears on identity documents.
                  </p>
                  <IdentityFields
                    errors={errors}
                    submitCount={submitCount}
                    birthDate={values.birthDate}
                    setFieldValue={setFieldValue}
                    runUniquenessCheck
                  />
                </>
              )}
            </fieldset>

            <Address />

            <Ids
              sources={sources}
              quickIDAlgorithm={quickIDAlgorithm}
              allowMixAndMatch={allowMixAndMatch}
              defaultCountryCode={defaultCountryCode}
            />

            <RcIf if={includeFaceMatch}>
              <fieldset>
                <legend>FaceMatch</legend>
                <p className="small text-primary mb-3">
                  Enter your client's email address and/or{" "}
                  {MOBILE_LABEL.toLowerCase()} number to begin FaceMatch
                </p>
                <Field
                  name="email"
                  error={errors.email}
                  submitCount={submitCount}
                  label="Email address"
                  type="email"
                />
                <PhoneField
                  name="phone"
                  value={values.phone}
                  setFieldValue={setFieldValue}
                  error={errors.phone}
                  submitCount={submitCount}
                  label={`${MOBILE_LABEL} Number`}
                  checkCountryCode={values.countryCode}
                />
              </fieldset>
            </RcIf>

            {defaultCountryCode === "CA" && (
              <fieldset className="mb-5">
                <legend>Upload Documents (optional)</legend>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <Button color="secondary" block type="button">
                    <span className="btn-icon">
                      <Upload />
                    </span>
                    Click to upload (max 10 files)
                  </Button>
                </div>

                <RcIf if={!!files.length}>
                  <ul className="list-unstyled mb-0 mt-3">
                    {files.map((file) => (
                      <li className="mt-2" key={file.id}>
                        <div className="border rounded d-flex p-2 align-items-center small">
                          <div className="flex-grow-1">
                            <span>{file.name}</span>
                            <span className="text-muted small ml-2">
                              {file.sizeInKb}
                            </span>
                          </div>
                          <div className="pl-2">
                            <Button
                              color="link"
                              className="p-0 text-muted"
                              size="sm"
                              onClick={() => deleteFile(file.id)}
                            >
                              <Trash />
                            </Button>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </RcIf>
              </fieldset>
            )}

            <ConsentCheckbox error={errors.consent} submitCount={submitCount} />

            <div className="row align-items-center mt-4">
              <div className="col-6">
                <Button color="primary" onClick={resetCheck} outline block>
                  Start Again
                </Button>
              </div>
              <div className="col-6 text-right">
                <Button
                  color="primary"
                  className="px-5"
                  type="submit"
                  disabled={fetching}
                  block
                >
                  Run ID Check
                </Button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

SelfCheckForm.propTypes = {
  fetching: PropTypes.bool.isRequired,
  resetCheck: PropTypes.func.isRequired,
  addCheckAndVerify: PropTypes.func.isRequired,
  checkType: PropTypes.string,
  defaultCountryCode: PropTypes.string,
  quickIDAlgorithm: PropTypes.string.isRequired,
  allowMixAndMatch: PropTypes.bool.isRequired,
  allowedCountries: PropTypes.arrayOf(PropTypes.string),
};

SelfCheckForm.defaultProps = {
  checkType: QUICK_ID,
};

const mapStateToProps = ({ user }) => ({
  allowedCountries: user.allowedCountries,
  sources: user.sources,
});

export default connect(mapStateToProps)(SelfCheckForm);
