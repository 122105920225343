const API_URL = `${process.env.REACT_APP_API_URL}`;

export const SESSION_URL = `${API_URL}/session/`;
export const TOKEN_REFRESH_URL = `${API_URL}/token/refresh/`;

export const USER_URL = `${API_URL}/user/`;
export const USER_PASSWORD_URL = `${API_URL}/user/password/`;
export const USER_UNLOCK_URL = `${API_URL}/user/unlock/`;
export const USER_EMAIL_URL = `${API_URL}/user/email/`;
export const USER_MOBILE_URL = `${API_URL}/user/mobile/`;

export const AGENCY_URL = `${API_URL}/agency/`;
export const AGENCY_USERS_URL = `${API_URL}/agency/users/`;

export const BILLING_URL = `${API_URL}/agency/billing/`;
export const BILLING_INVOICES_URL = `${API_URL}/agency/billing/invoices/`;
export const BILLING_UPCOMING_INVOICES_URL = `${API_URL}/agency/billing/invoices/upcoming/`;

export const VERIFICATION_GROUP_URL = `${API_URL}/verification-groups/`;
export const OUTSOURCED_VERIFICATION_GROUP_URL = `${API_URL}/outsourced-verification-groups/`;
export const getVerificationGroupUrl = (verificationId) =>
  `${API_URL}/verification-group/${verificationId}/`;
export const getVerificationUrl = (verificationId) =>
  `${API_URL}/verification-group/${verificationId}/data/`;
export const SEND_VERIFICATION_URL = `${API_URL}/integrations/send-external-link/`;
export const getOutsourcedVerificationUrl = (verificationId) =>
  `${API_URL}/outsourced-verification-group/${verificationId}/outsourced-data/`;
export const getVerificationDetailUrl = (verificationDataId) =>
  `${API_URL}/verification/${verificationDataId}/`;
export const getVerificationDisableMonitoringUrl = (verificationDataId) =>
  `${API_URL}/verification/${verificationDataId}/monitoring-cancel`;
export const getOutsourcedVerificationDetailUrl = (verificationDataId) =>
  `${API_URL}/outsourced-verification/${verificationDataId}/`;
export const getVerificationResendUrl = (verificationDataId) =>
  `${API_URL}/verification/${verificationDataId}/resend/`;
export const getRunVerificationUrl = (verificationDataId) =>
  `${API_URL}/run-verification/${verificationDataId}/`;

export const getCompaniesSearchUrl = (query) =>
  `${API_URL}/companies/search/${query}/`;
