import React from "react";
import { connect } from "react-redux";

import { actions as outsourcedHistoryActions } from "../../../store/slices/outsourcedHistory";
import OutsourcedHistoryPresenter from "./presenter";

const { fetchOutsourcedHistory, deleteOutsourcedCheck } =
  outsourcedHistoryActions;

const OutsourcedHistory = ({
  fetchOutsourcedHistory,
  updateCheckGroup,
  outsourcedHistory,
  checkFetching,
  deleteOutsourcedCheck,
  namePriority,
  dashboardCanDelete,
  dashboardCanEdit,
  dashboardShowPrice,
  isAdmin,
}) => (
  <OutsourcedHistoryPresenter
    fetchHistory={fetchOutsourcedHistory}
    updateCheckGroup={updateCheckGroup}
    history={outsourcedHistory}
    checkFetching={checkFetching || outsourcedHistory.fetching}
    deleteCheck={deleteOutsourcedCheck}
    namePriority={namePriority}
    dashboardCanDelete={dashboardCanDelete}
    dashboardCanEdit={dashboardCanEdit}
    dashboardShowPrice={dashboardShowPrice}
    isAdmin={isAdmin}
  />
);

const mapStateToProps = ({ outsourcedHistory, check, user }) => ({
  outsourcedHistory,
  checkFetching: check.fetching,
  namePriority:
    user && user.dashboardBrand
      ? user.dashboardBrand.outsourced_history_name_priority
      : true,
  dashboardCanDelete: user.dashboardCanDelete,
  dashboardCanEdit: user.dashboardCanEdit,
  dashboardShowPrice: user.dashboardShowPrice,
  isAdmin: user.role === "ADMIN",
});
const mapDispatchToProps = {
  fetchOutsourcedHistory,
  deleteOutsourcedCheck,
};

export default connect(mapStateToProps, mapDispatchToProps)(OutsourcedHistory);
