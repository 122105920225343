import { createAction, createReducer } from "@reduxjs/toolkit";
import { toastr } from "react-redux-toastr";

import CheckApi from "../../api/check";
import OutsourcedHistoryApi from "../../api/outsourcedHistory";
import { uploadFiles } from "../../helpers/files";
import logToSentryOrConsole from "../../helpers/sentry";

const INITIAL_STATE = {
  fetching: false,
  fetchError: "",
  fetchCompleted: false,
  totalRecords: 0,
  checks: [],
  queries: {},
};

const fetchOutsourcedHistoryStarted = createAction(
  "fetchOutsourcedHistoryStarted"
);
const fetchOutsourcedHistoryCompleted = createAction(
  "fetchOutsourcedHistoryCompleted"
);
const fetchOutsourcedHistoryFailed = createAction(
  "fetchOutsourcedHistoryFailed"
);
const saveQueries = createAction("saveQueries");
const setCheckHistory = createAction("setCheckHistory");

const fetchOutsourcedHistory = (queries) => async (dispatch, getState) => {
  let finalQueries;
  if (queries) {
    finalQueries = queries;
  } else {
    const {
      outsourcedHistory: { queries: queriesFromState },
    } = getState();
    finalQueries = queriesFromState;
  }

  dispatch(fetchOutsourcedHistoryStarted());
  dispatch(saveQueries(finalQueries));

  try {
    const result = await OutsourcedHistoryApi.fetchOutsourcedHistory(
      finalQueries
    );

    if (!result || !result.data) {
      return;
    }

    dispatch(setCheckHistory(result.data));
    dispatch(fetchOutsourcedHistoryCompleted());
  } catch (error) {
    dispatch(fetchOutsourcedHistoryFailed());
    logToSentryOrConsole(error);
  }
};

const updateOutsourcedCheck = (id, data, files) => async (dispatch) => {
  dispatch(fetchOutsourcedHistoryStarted());
  await CheckApi.updateOutsourcedCheck(id, data);
  if (files && files.length)
    await uploadFiles(id, files, "outsourced-verification");
  dispatch(fetchOutsourcedHistoryCompleted());
  dispatch(fetchOutsourcedHistory());
  toastr.success("Verification updated successfully.");
};

const deleteOutsourcedCheck = (id) => async (dispatch) => {
  dispatch(fetchOutsourcedHistoryStarted());
  await CheckApi.deleteOutsourcedCheck(id);
  dispatch(fetchOutsourcedHistoryCompleted());
  dispatch(fetchOutsourcedHistory());
  toastr.success("Verification deleted successfully.");
};

export const actions = {
  fetchOutsourcedHistory,
  deleteOutsourcedCheck,
  updateOutsourcedCheck,
};

export const reducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(fetchOutsourcedHistoryStarted, (state) => {
      state.fetching = true;
      state.fetchError = "";
      state.fetchCompleted = false;
    })
    .addCase(fetchOutsourcedHistoryCompleted, (state) => {
      state.fetching = false;
      state.fetchCompleted = true;
    })
    .addCase(fetchOutsourcedHistoryFailed, (state, { payload }) => {
      state.fetching = false;
      state.fetchError = payload;
    })
    .addCase(setCheckHistory, (state, { payload }) => {
      state.checks = payload.data;
      state.isAdminOrManager = payload.is_admin_or_manager;
      state.totalRecords = payload.total_records;
    })
    .addCase(saveQueries, (state, { payload }) => {
      state.queries = payload;
    });
});
