import React from "react";

const MarketplaceIcon = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 32 32"
    xmlSpace="preserve"
    fillRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit={2}
    clipRule="evenodd"
  >
    <path
      color="currentColor"
      d="M29 28V15.241a4.495 4.495 0 0 0 2-3.741c0-.415-1.034-4.744-1.633-7.169A3 3 0 0 0 26.442 2C22.1 2 9.899 2 5.557 2.003a2.996 2.996 0 0 0-2.92 2.329C2.034 6.756 1 11.085 1 11.5c0 1.559.794 2.934 2 3.741V28H2a1 1 0 0 0 0 2h28a1 1 0 0 0 0-2zm-2-12.028a4.346 4.346 0 0 1-.5.028c-1.713 0-2.816-.897-3.5-1.901C22.316 15.103 21.213 16 19.5 16c-1.714 0-2.817-.898-3.502-1.903C15.313 15.102 14.21 16 12.5 16c-1.714 0-2.817-.898-3.502-1.903C8.313 15.102 7.21 16 5.5 16c-.169 0-.336-.009-.5-.028V28h7v-4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v4h7zM14 24h4v4h-4zm9.987-12.645a1 1 0 0 0-1.974 0s-.118.698-.527 1.399C21.129 13.365 20.529 14 19.5 14s-1.629-.635-1.986-1.246c-.409-.701-.527-1.399-.527-1.399a.999.999 0 0 0-1.974-.002s-.12.699-.53 1.4C14.126 13.364 13.526 14 12.5 14c-1.029 0-1.629-.635-1.986-1.246-.409-.701-.527-1.399-.527-1.399a.999.999 0 0 0-1.974-.002s-.12.699-.53 1.4C7.126 13.364 6.526 14 5.5 14A2.5 2.5 0 0 1 3 11.5c0-.388 1.017-4.431 1.58-6.694a.997.997 0 0 1 .978-.803h.001C9.9 4 22.101 4 26.442 4a1 1 0 0 1 .976.782l.005.022C27.983 7.068 29 11.111 29 11.5a2.5 2.5 0 0 1-2.5 2.5c-1.029 0-1.629-.635-1.986-1.246-.409-.701-.527-1.399-.527-1.399z"
    />
  </svg>
);

export default MarketplaceIcon;
