import React from "react";
import { connect } from "react-redux";

import { actions } from "../../store/slices/auth";
import ForgotPasswordPresenter from "./Presenters/ForgotPassword";

const { forgotPassword, resetForgotPasswordState } = actions;

const ForgotPassword = ({ forgotPassword, resetForgotPasswordState, auth }) => (
  <ForgotPasswordPresenter
    {...auth}
    forgotPassword={forgotPassword}
    resetForgotPasswordState={resetForgotPasswordState}
  />
);

const mapStateToProps = ({ auth }) => ({ auth });
const mapDispatchToProps = { forgotPassword, resetForgotPasswordState };

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
