import { createAction, createReducer } from "@reduxjs/toolkit";

import HistoryApi from "../../api/history";
import logToSentryOrConsole from "../../helpers/sentry";

const INITIAL_STATE = {
  fetching: false,
  fetchError: "",
  fetchCompleted: false,
  totalRecords: 0,
  checks: [],
  queries: {},
};

const fetchHistoryStarted = createAction("fetchHistoryStarted");
const fetchHistoryCompleted = createAction("fetchHistoryCompleted");
const fetchHistoryFailed = createAction("fetchHistoryFailed");
const saveQueries = createAction("saveQueries");
const setCheckHistory = createAction("setCheckHistory");

const fetchHistory = (queries) => async (dispatch, getState) => {
  let finalQueries;
  if (queries) {
    finalQueries = queries;
  } else {
    const {
      history: { queries: queriesFromState },
    } = getState();
    finalQueries = queriesFromState;
  }

  dispatch(fetchHistoryStarted());
  dispatch(saveQueries(finalQueries));

  try {
    const result = await HistoryApi.fetchHistory(finalQueries);

    if (!result || !result.data) {
      return;
    }

    dispatch(setCheckHistory(result.data));
    dispatch(fetchHistoryCompleted());
  } catch (error) {
    dispatch(fetchHistoryFailed());
    logToSentryOrConsole(error);
  }
};

export const actions = { fetchHistory };

export const reducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(fetchHistoryStarted, (state) => {
      state.fetching = true;
      state.fetchError = "";
      state.fetchCompleted = false;
    })
    .addCase(fetchHistoryCompleted, (state) => {
      state.fetching = false;
      state.fetchCompleted = true;
    })
    .addCase(fetchHistoryFailed, (state, { payload }) => {
      state.fetching = false;
      state.fetchError = payload;
    })
    .addCase(setCheckHistory, (state, { payload }) => {
      state.checks = payload.data;
      state.isAdminOrManager = payload.is_admin_or_manager;
      state.totalRecords = payload.total_records;
    })
    .addCase(saveQueries, (state, { payload }) => {
      state.queries = payload;
    });
});
