import React from "react";

const BankMatch = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 484 484">
    <path d="M242 0C108.6 0 0 108.6 0 242s108.6 242 242 242 242-108.6 242-242S375.4 0 242 0zm0 433.9c-105.8 0-191.9-86.1-191.9-191.9S136.2 50.1 242 50.1 433.9 136.2 433.9 242 347.8 433.9 242 433.9z" />
    <path d="M283.3 228.4c-11.7-6.5-24.1-11.3-36.4-16.4-7.1-2.9-13.9-6.4-19.9-11.2-11.8-9.5-9.6-24.8 4.3-30.9 3.9-1.7 8-2.3 12.2-2.5 16.1-.9 31.3 2.1 45.9 9.1 7.2 3.5 9.6 2.4 12.1-5.1 2.6-8 4.8-16.1 7.1-24.1 1.6-5.4-.4-9-5.5-11.2-9.3-4.1-18.9-7.1-29-8.6-13.1-2.1-13.1-2.1-13.2-15.3-.1-18.7-.1-18.7-18.7-18.6-2.7 0-5.4-.1-8.1 0-8.7.3-10.2 1.8-10.4 10.5-.1 3.9 0 7.9 0 11.8-.1 11.7-.1 11.5-11.3 15.5-27 9.8-43.7 28.2-45.5 57.7-1.6 26.1 12 43.7 33.4 56.5 13.2 7.9 27.8 12.6 41.8 18.8 5.5 2.4 10.7 5.2 15.2 9 13.5 11.1 11 29.6-5 36.6-8.6 3.7-17.6 4.7-26.8 3.5-14.3-1.8-28-5.5-40.8-12.2-7.5-3.9-9.7-2.9-12.3 5.3-2.2 7.1-4.2 14.2-6.1 21.3-2.6 9.6-1.7 11.9 7.5 16.3 11.6 5.6 24.1 8.5 36.8 10.6 9.9 1.6 10.3 2 10.4 12.3 0 4.7.1 9.4.1 14 .1 5.9 2.9 9.3 9 9.4 6.9.1 13.8.1 20.7 0 5.6-.1 8.5-3.2 8.6-8.9 0-6.4.3-12.8.1-19.2-.3-6.5 2.5-9.8 8.8-11.5 14.4-3.9 26.7-11.7 36.2-23.2 26-32 16-78.7-21.2-99.3z" />
  </svg>
);

export default BankMatch;
