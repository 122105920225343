import { useFormikContext } from "formik";
import React from "react";

import { MOBILE_LABEL } from "../../../../constants/lang/en";
import { SUPPORTED_COUNTRIES } from "../../../../constants/supportedCountries";
import Field from "../../../form/Field";

const GlobalNationalId = () => {
  const { values, errors, submitCount } = useFormikContext();
  const showPhoneField = ["CA", "US"].includes(values.nationalIdCountryCode);

  return (
    <div className={showPhoneField ? "form-row" : ""}>
      {values.nationalIdCountryCode !== "CA" && (
        <div className={showPhoneField ? "col-6" : ""}>
          <Field
            name="nationalId"
            error={errors.nationalId}
            submitCount={submitCount}
            label={
              SUPPORTED_COUNTRIES[values.nationalIdCountryCode]
                .nationalIdDescription || "National Id"
            }
            placeholder={
              SUPPORTED_COUNTRIES[values.nationalIdCountryCode].placeholder ||
              ""
            }
          />
        </div>
      )}
      {showPhoneField && (
        <div className="col-6 position-relative">
          <Field
            name="nationalIdSecondary"
            label={`${MOBILE_LABEL} Number`}
            autoComplete="tel-national"
            error={errors.nationalIdSecondary}
            submitCount={submitCount}
            type="tel"
            placeholder={
              values.nationalIdCountryCode === "CA"
                ? "(506) 234-5678"
                : values.nationalIdCountryCode === "US"
                ? "(201) 555-0123"
                : "0123456789"
            }
            fieldClassName="pl-5"
          />
          <img
            className="position-absolute"
            alt={`${values.nationalIdCountryCode} Flag`}
            src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${values.nationalIdCountryCode}.svg`}
            style={{ width: "24px", left: "16px", top: "26px" }}
          />
        </div>
      )}
    </div>
  );
};

GlobalNationalId.propTypes = {};

export default GlobalNationalId;
