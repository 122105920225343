import { IntercomAPI } from "react-intercom";

const intercomId = process.env.REACT_APP_INTERCOM_ID;
const intercomAvailable = intercomId && intercomId !== "NA";

export const triggerPaymentFailedEvent = () => {
  if (intercomAvailable) {
    IntercomAPI("trackEvent", "payment-failed");
  } else {
    console.log('Intercom event "payment-failed" will be sent in live mode');
  }
};

export const triggerNewPaymentEvent = ({ paymentType }) => {
  if (intercomAvailable) {
    IntercomAPI("trackEvent", "new-payment-added", { paymentType });
  } else {
    console.log(
      `Intercom event "new-payment-added" with metadata "{paymentType: ${paymentType}}" will be sent in live mode`
    );
  }
};
