import RcIf, { RcElse } from "rc-if";
import React from "react";
import { connect } from "react-redux";

import { PEP_COPY } from "../../../constants/checkTypes";
import { actions as checksActions } from "../../../store/slices/check";
import { actions as verificationActions } from "../../../store/slices/verification";
import DashboardLayout from "../../dashboard/Presenters/Layout";
import PepForm from "../../dashboard/Presenters/PepForm";
import PepResult from "../../dashboard/Presenters/PepResult";
import StepHeader from "../../misc/StepHeader";
import StepLayout from "../../misc/StepLayout";
import PepPresenter from "./presenter";

const { startNewCheck, resetCheck, goToNextCheck, addCheckAndVerify } =
  checksActions;

const { handleResetVerifyState } = verificationActions;

const Pep = ({
  startNewCheck,
  resetCheck,
  goToNextCheck,
  addCheckAndVerify,
  handleResetVerifyState,
  check: { draft, fetching: fetchingCheck },
  verification: {
    fetching: fetchingVerification,
    verifying,
    pepResult,
    pdfUrl,
    verifyError,
  },
}) => {
  const fetching = fetchingCheck || verifying || fetchingVerification;
  const draftReference = draft.reference ? (
    <span>
      <strong>Reference</strong>: {draft.reference}
    </span>
  ) : null;
  const activeCheck = draft.checks[draft.activeCheck];

  return (
    <DashboardLayout>
      <StepLayout>
        <StepHeader text={`${PEP_COPY} Check`} subHeader={draftReference} />
        <RcIf if={draft.totalChecks > 0}>
          <RcIf
            if={
              !fetching &&
              !verifyError &&
              !!activeCheck &&
              !!activeCheck.resultAvailable
            }
          >
            <PepResult
              fetching={fetching}
              pepResult={pepResult}
              pdfUrl={pdfUrl}
              activeCheck={draft.activeCheck}
              totalChecks={draft.totalChecks}
              onVerificationComplete={goToNextCheck}
              handleResetVerifyState={handleResetVerifyState}
            />
            <RcElse>
              <PepForm
                fetching={fetching}
                activeCheck={draft.activeCheck}
                totalChecks={draft.totalChecks}
                resetCheck={resetCheck}
                addCheckAndVerify={addCheckAndVerify}
              />
            </RcElse>
          </RcIf>
          <RcElse>
            <PepPresenter
              startNewCheck={startNewCheck}
              reference={draft.reference}
              totalChecks={draft.totalChecks}
              type={draft.type}
              notes={draft.notes}
              fetching={fetching}
            />
          </RcElse>
        </RcIf>
      </StepLayout>
    </DashboardLayout>
  );
};

const mapStateToProps = ({ check, verification }) => ({ check, verification });
const mapDispatchToProps = {
  startNewCheck,
  resetCheck,
  goToNextCheck,
  addCheckAndVerify,
  handleResetVerifyState,
};

export default connect(mapStateToProps, mapDispatchToProps)(Pep);
