import { Formik } from "formik";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import * as Yup from "yup";

import {
  PASSWORD_MSG,
  REQUIRED_MSG,
} from "../../../constants/validationHelpers";
import { PASSWORD_REGEX } from "../../../constants/validationRegex";
import routes from "../../../routes";
import PasswordField from "../../form/PasswordField";
import FormikScrollToErrors from "../../misc/FormikScrollToError";
import StepHeader from "../../misc/StepHeader";
import StepLayout from "../../misc/StepLayout";

const VALIDATION_RULES = Yup.object().shape({
  confirmPassword: Yup.string().required(REQUIRED_MSG),
  newPassword: Yup.string()
    .required(REQUIRED_MSG)
    .matches(PASSWORD_REGEX, PASSWORD_MSG),
});

const INITIAL_VALUES = { newPassword: "", confirmPassword: "" };

const AccountUnlock = ({ fetching, checkAccountUnlock, accountUnlock }) => {
  useEffect(() => {
    checkAccountUnlock();
  }, [checkAccountUnlock]);

  return (
    <StepLayout>
      <StepHeader text="Unlock your account and set your new password" />
      <Formik
        validationSchema={VALIDATION_RULES}
        onSubmit={accountUnlock}
        initialValues={INITIAL_VALUES}
      >
        {({
          errors,
          handleSubmit,
          submitCount,
          isSubmitting,
          isValidating,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <FormikScrollToErrors
              errors={errors}
              isSubmitting={isSubmitting}
              isValidating={isValidating}
            />
            <PasswordField
              name="newPassword"
              error={errors.newPassword}
              submitCount={submitCount}
              label="Password"
              autoComplete="new-password"
              autoFocus
              disabled={fetching}
            />
            <PasswordField
              name="confirmPassword"
              error={errors.confirmPassword}
              submitCount={submitCount}
              label="Confirm Password"
              autoComplete="new-password"
              disabled={fetching}
            />
            <Button
              type="submit"
              color="primary"
              size="lg"
              block
              disabled={fetching}
            >
              Unlock and Set New Password
            </Button>
          </form>
        )}
      </Formik>
      <div className="text-right mt-3">
        <Link to={routes.signIn} className="btn btn-link">
          Back to the login page
        </Link>
      </div>
    </StepLayout>
  );
};

AccountUnlock.propTypes = {
  checkAccountUnlock: PropTypes.func.isRequired,
  accountUnlock: PropTypes.func.isRequired,
  fetching: PropTypes.bool.isRequired,
};

export default AccountUnlock;
