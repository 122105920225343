import axios from "axios";

import { getDefaultHeaders } from "../helpers/token";
import {
  AGENCY_URL,
  USER_EMAIL_URL,
  USER_MOBILE_URL,
  USER_PASSWORD_URL,
  USER_URL,
} from "./apiUrls";

const updateUser = async ({
  companyEmail,
  firstName,
  lastName,
  sendEmailAttachment,
  twoFactorAuthEnabled,
  mobile,
}) =>
  await axios.put(
    USER_URL,
    {
      email: companyEmail,
      first_name: firstName,
      last_name: lastName,
      userprofile: {
        // send_email_attachment: sendEmailAttachment,
        two_factor_auth_enabled: twoFactorAuthEnabled,
        mobile,
      },
    },
    getDefaultHeaders()
  );

const updateAgency = async ({
  name,
  deleteCompletedChecksInDays,
  sendVerificationReminders,
}) =>
  await axios.patch(
    AGENCY_URL,
    {
      name,
      delete_completed_checks_in_days: deleteCompletedChecksInDays,
      send_verification_reminders: sendVerificationReminders,
    },
    getDefaultHeaders()
  );

const sendMobileCode = async ({ mobile }) =>
  await axios.post(USER_MOBILE_URL, { mobile }, getDefaultHeaders());

const checkAndSaveMobile = async ({ mobile, code }) =>
  await axios.put(USER_MOBILE_URL, { mobile, code }, getDefaultHeaders());

const changePassword = async ({ password, oldPassword }) =>
  await axios.patch(
    USER_PASSWORD_URL,
    { password, oldPassword },
    getDefaultHeaders()
  );

const sendEmailVerificationToken = async ({ username }) =>
  axios.post(USER_EMAIL_URL, { username });

const verifyEmail = async ({ token }) =>
  await axios.put(USER_EMAIL_URL, { token });

const apis = {
  updateUser,
  updateAgency,
  changePassword,
  sendMobileCode,
  checkAndSaveMobile,
  sendEmailVerificationToken,
  verifyEmail,
};
export default apis;
