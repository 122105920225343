import React from "react";

import Invoices from "../Invoices";
import UpcomingInvoice from "../UpcomingInvoice";
import BillingInfo from "./BillingInfo";

const Billing = ({
  agencyName,
  paymentType,
  cardLast4,
  cardType,
  cardExpirationDate,
  setShowPaymentModal,
  paymentInReview,
}) => (
  <div className="site-table site-table--dash">
    <div className="mb-4">
      <h1 className="h4 text-center mb-3 d-lg-none">Billing</h1>
      <div className="row">
        <div className="col-sm-6">
          <UpcomingInvoice />
        </div>
        <div className="col-sm-6">
          <BillingInfo
            paymentType={paymentType}
            agencyName={agencyName}
            cardLast4={cardLast4}
            cardType={cardType}
            cardExpirationDate={cardExpirationDate}
            setShowPaymentModal={setShowPaymentModal}
            paymentInReview={paymentInReview}
          />
        </div>
      </div>
      <div className="mt-5">
        <Invoices />
      </div>
    </div>
  </div>
);

export default Billing;
