import PropTypes from "prop-types";
import RcIf, { RcElse } from "rc-if";
import React from "react";
import { Tooltip } from "react-tippy";

import currencyFormatter from "../../../helpers/currency";
import { timestampToSimpleDate } from "../../../helpers/date";
import Attachment from "../../icons/AttachmentIcon";

const Invoices = ({ invoices }) => (
  <section className="container-fluid xxs">
    <h2 className="h5 text-muted text-center">Historical</h2>
    <RcIf if={!!invoices.length}>
      <ul className="list-group">
        {invoices.map(({ number, date, amount_due, status, invoice_pdf }) => (
          <li
            className="list-group-item d-flex align-items-center justify-content-between"
            key={number}
          >
            <div className="d-flex align-items-center">
              <RcIf if={!!invoice_pdf}>
                <div className="lead mr-3">
                  <Tooltip title="Download Invoice" position="top" size="small">
                    <a
                      href={invoice_pdf}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Attachment />
                    </a>
                  </Tooltip>
                </div>
              </RcIf>
              <div>
                <RcIf if={!!date}>
                  <p className="text-muted mb-0">
                    {timestampToSimpleDate(date * 1000)}
                  </p>
                </RcIf>
                <p className="text-muted small mb-0">#{number}</p>
              </div>
              <span className="badge badge-secondary text-uppercase ml-3">
                {status}
              </span>
            </div>
            <div className="lead text-muted">
              {currencyFormatter(amount_due / 100)}
            </div>
          </li>
        ))}
      </ul>
      <RcElse>
        <p className="text-center text-muted mt-4">You have no history.</p>
      </RcElse>
    </RcIf>
  </section>
);

Invoices.propTypes = {
  invoices: PropTypes.arrayOf(PropTypes.object),
};

export default Invoices;
