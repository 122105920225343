import axios from "axios";
import qs from "query-string";

import { getDefaultHeaders } from "../helpers/token";
import { OUTSOURCED_VERIFICATION_GROUP_URL } from "./apiUrls";

const fetchOutsourcedHistory = async (queries) =>
  await axios.get(
    `${OUTSOURCED_VERIFICATION_GROUP_URL}?${qs.stringify(queries)}`,
    getDefaultHeaders()
  );

const apis = { fetchOutsourcedHistory };
export default apis;
