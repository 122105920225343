import React, { useMemo } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { ListGroup, ListGroupItem } from "reactstrap";

import camsSeal from "../../../images/CAMS-Seal-300x235.png";
import routes from "../../../routes";
import Billing from "../../icons/BillingIcon";
import CheckHistory from "../../icons/CheckHistoryIcon";
import Compliance from "../../icons/ComplianceIcon";
import MarketplaceIcon from "../../icons/MarketplaceIcon";
import OutSourcedHistory from "../../icons/OutsourcedHistoryIcon";
import OutSourced from "../../icons/OutSourcedIcon";
import Profile from "../../icons/ProfileIcon";
import Question from "../../icons/QuestionIcon";
import RunChecks from "../../icons/RunChecksIcon";
import Settings from "../../icons/SettingsIcon";
import UsersIcon from "../../icons/UsersIcon";
import LinkWrap from "../../misc/LinkWrap";

export const getDashboardNavs = ({
  showOutsourcedMenu,
  showComplianceMenu,
  industry,
  isAdmin,
}) =>
  [
    {
      label: "Run Checks",
      href: routes.checks,
      icon: <RunChecks />,
      onlyInDropdown: false,
      activeHrefs: [
        routes.checks,
        routes.nzQuickId,
        routes.nzQuickIdFaceMatch,
        routes.quickId,
        routes.quickIdFaceMatch,
        routes.quickIdBankMatch,
        routes.faceMatchQuickId,
        routes.faceMatch,
        routes.pep,
      ],
    },
    showOutsourcedMenu
      ? {
          label: "Outsource Checks",
          href: routes.outSourced,
          icon: <OutSourced />,
          onlyInDropdown: false,
        }
      : undefined,
    !window.location.href.startsWith(process.env.REACT_APP_PROD_CA_SITE_URL) ||
    industry === "Legal"
      ? {
          label: "Marketplace",
          href: routes.marketplace,
          icon: <MarketplaceIcon />,
          onlyInDropdown: false,
          className: "nav-new-badge",
        }
      : undefined,
    showComplianceMenu
      ? {
          label: "Compliance",
          href: routes.compliance,
          icon: <Compliance />,
          onlyInDropdown: false,
        }
      : undefined,
    {
      label: "Check History",
      href: routes.checksHistory,
      icon: <CheckHistory />,
      onlyInDropdown: false,
    },
    showOutsourcedMenu
      ? {
          label: "Outsourced History",
          href: routes.outSourcedHistory,
          icon: <OutSourcedHistory />,
          onlyInDropdown: false,
        }
      : undefined,
    {
      label: "Help Centre",
      href: "https://intercom.help/realaml/en/collections/1872531-user-guides",
      icon: <Question />,
      onlyInDropdown: true,
      isExternal: true,
    },
    {
      label: "Profile",
      href: routes.profile,
      icon: <Profile />,
      onlyInDropdown: true,
    },
    isAdmin
      ? {
          label: "Settings",
          href: routes.settings,
          icon: <Settings />,
          onlyInDropdown: true,
        }
      : undefined,
    isAdmin
      ? {
          label: "Users",
          href: routes.agencyUsers,
          icon: <UsersIcon />,
          onlyInDropdown: true,
        }
      : undefined,
    {
      label: "Billing",
      href: routes.billing,
      icon: <Billing />,
      onlyInDropdown: true,
    },
  ].filter((m) => m);

const DashboardNav = ({
  location,
  role,
  showOutsourcedMenu,
  showComplianceMenu,
  industry,
}) => {
  const dashboardNavs = useMemo(
    () =>
      getDashboardNavs({
        showOutsourcedMenu,
        showComplianceMenu,
        industry,
        isAdmin: role === "ADMIN",
      }),
    [showOutsourcedMenu, showComplianceMenu, role]
  );

  return (
    <>
      <ListGroup>
        {dashboardNavs.map((nav) =>
          nav.onlyInDropdown ? null : (
            <ListGroupItem
              active={
                nav.activeHrefs
                  ? nav.activeHrefs.includes(location.pathname)
                  : location.pathname === nav.href
              }
              to={nav.href}
              tag={LinkWrap}
              action
              key={nav.label}
              className={`font-weight-bolder ${nav.className || ""}`}
            >
              {nav.icon}
              {nav.label}
            </ListGroupItem>
          )
        )}
      </ListGroup>
      {location.pathname === routes.compliance && (
        <div className="text-center py-4">
          <img src={camsSeal} alt="Realaml" width="150px" />
        </div>
      )}
    </>
  );
};

const mapStateToProps = ({ user }) => ({
  industry: user.industry,
  showOutsourcedMenu: user.showOutsourcedMenu,
  showComplianceMenu: user.showComplianceMenu,
  role: user.role,
});

export default withRouter(connect(mapStateToProps)(DashboardNav));
