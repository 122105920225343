import { createAction, createReducer } from "@reduxjs/toolkit";
import moment from "moment";
import { toastr } from "react-redux-toastr";

import VerificationApi from "../../api/verification";
import {
  DATA_ERROR,
  DEFAULT_ERROR,
  PAYMENT_ERROR_FOR_CUSTOMER,
  THIRD_PARTY_ERROR,
} from "../../constants/lang/en";
import parseDate from "../../helpers/date";
import { uiFormatDate } from "../../helpers/date";
import { constructDownloadUrl } from "../../helpers/files";
import logToSentryOrConsole from "../../helpers/sentry";
import { getSignatureKey } from "../../helpers/token";
import history from "../../history";
import routes from "../../routes";

const getSignatureKeyWithState = (getState) => {
  const signatureKeyFromUrl = getSignatureKey();
  if (signatureKeyFromUrl) {
    return signatureKeyFromUrl;
  } else {
    const {
      check: { draft },
    } = getState();
    const activeCheck = draft.checks[draft.activeCheck];
    if (activeCheck && activeCheck.signatureKey) {
      return activeCheck.signatureKey;
    }
  }
};

const INITIAL_STATE = {
  fetching: false,
  fetchError: "",
  fetchCompleted: false,
  verifying: false,
  verifyError: "",
  verifyCompleted: false,
  facematchPresent: false,
  fetchVerifying: false,
  fetchVerifyError: "",
  fetchVerifyCompleted: false,
  facematchVerifyCompleted: false,
  facematchVerifyStarted: false,
  facematchVerifyError: "",
  sources: {},
};

const fetchVerificationStarted = createAction("fetchVerificationStarted");
const fetchVerificationCompleted = createAction("fetchVerificationCompleted");
const fetchVerificationFailed = createAction("fetchVerificationFailed");
const verifyStarted = createAction("verifyStarted");
const verifyCompleted = createAction("verifyCompleted");
const simpleVerifyCompleted = createAction("simpleVerifyCompleted");
const facematchVerifyStarted = createAction("facematchVerifyStarted");
const facematchVerifyFailed = createAction("facematchVerifyFailed");
const facematchVerifyCompleted = createAction("facematchVerifyCompleted");
const verifyFailed = createAction("verifyFailed");
const resetVerifyState = createAction("resetVerifyState");

const errorHandler = (
  dispatch,
  error,
  errorMsg = DEFAULT_ERROR,
  actionToCall = verifyFailed
) => {
  if (error && error.response && error.response.status === 403) {
    if (
      error.response.data &&
      error.response.data.reason === "Verification has been cancelled"
    ) {
      dispatch(actionToCall());
      history.push(routes.verificationCancelled);
    } else {
      dispatch(actionToCall());
      history.push(routes.verificationExpired);
    }
  } else if (
    error &&
    error.response &&
    [401, 404].includes(error.response.status)
  ) {
    dispatch(actionToCall());
    history.push(routes.notFound);
  } else if (error && error.response && error.response.status === 402) {
    toastr.error("", PAYMENT_ERROR_FOR_CUSTOMER);
    dispatch(actionToCall(PAYMENT_ERROR_FOR_CUSTOMER));
  } else if (error && error.response && error.response.status === 400) {
    toastr.error("", DATA_ERROR);
    dispatch(actionToCall(DATA_ERROR));
  } else if (error && error.response && error.response.status === 504) {
    toastr.error("", THIRD_PARTY_ERROR);
    dispatch(actionToCall(THIRD_PARTY_ERROR));
    logToSentryOrConsole(error);
  } else {
    toastr.error("", errorMsg);
    dispatch(actionToCall(errorMsg));
    logToSentryOrConsole(error);
  }
};

const fetchVerification = () => async (dispatch, getState) => {
  const signatureKey = getSignatureKeyWithState(getState);
  if (!signatureKey) {
    return;
  }
  dispatch(fetchVerificationStarted());
  try {
    const result = await VerificationApi.fetchVerification({ signatureKey });

    if (!result || !result.data) {
      return;
    }

    dispatch(fetchVerificationCompleted(result.data));
  } catch (error) {
    errorHandler(dispatch, error, undefined, fetchVerificationFailed);
  }
};

const parseVerificationResult = (result, signatureKey, dispatch) => {
  dispatch(
    verifyCompleted({
      ...result,
      signatureKey,
      pdfUrl: constructDownloadUrl(signatureKey),
    })
  );
};

const runQuickId = (data) => async (dispatch, getState) => {
  const signatureKey = getSignatureKeyWithState(getState);
  if (!signatureKey) {
    return;
  }
  dispatch(verifyStarted());
  try {
    const {
      verification: { email },
    } = getState();
    const result = await VerificationApi.verify({
      signatureKey,
      email: email,
      firstName: data.firstName?.trim(),
      middleName: data.middleName?.trim(),
      lastName: data.lastName?.trim(),
      birthDate: parseDate(data.birthDate)?.trim(),
      unitNo: data.unitNo?.trim(),
      addressLine1: data.addressLine1?.trim(),
      addressLine2: data.addressLine2?.trim(),
      postalCode: data.postalCode?.trim(),
      city: data.city?.trim(),
      state: data.state?.trim(),
      countryCode: data.countryCode,
      nationalId: data.nationalId?.trim(),
      nationalIdSecondary: data.nationalIdSecondary?.trim(),
      nationalIdTertiary: data.nationalIdTertiary?.trim(),
      nationalIdType: data.nationalIdType?.trim(),
      nationalIdSecondaryType: data.nationalIdSecondaryType?.trim(),
      nationalIdTertiaryType: data.nationalIdTertiaryType?.trim(),
      nationalIdCountryCode: data.nationalIdCountryCode?.trim(),
    });

    if (!result || !result.data) {
      return;
    }

    parseVerificationResult(result.data, signatureKey, dispatch);
  } catch (error) {
    errorHandler(dispatch, error);
  }
};

const verifyPep =
  ({ signatureKey, birthDate }) =>
  async (dispatch) => {
    dispatch(verifyStarted());
    try {
      const result = await VerificationApi.verifyPep({
        birthDate,
        signatureKey,
      });

      if (!result || !result.data) {
        return;
      }

      parseVerificationResult(result.data, signatureKey, dispatch);
    } catch (error) {
      errorHandler(dispatch, error);
    }
  };

const generateResult =
  ({ signatureKey }) =>
  async (dispatch) => {
    dispatch(verifyStarted());
    try {
      const result = await VerificationApi.generateResult({ signatureKey });
      dispatch(
        simpleVerifyCompleted({
          signatureKey,
          pdfUrl: constructDownloadUrl(signatureKey),
        })
      );
    } catch (error) {
      errorHandler(dispatch, error);
    }
  };

const verifyCompany =
  ({ signatureKey, companyNZBN, companySearchId }) =>
  async (dispatch) => {
    dispatch(verifyStarted());
    try {
      const result = await VerificationApi.verifyCompany({
        companyNZBN,
        companySearchId,
        signatureKey,
      });

      if (!result || !result.data) {
        return;
      }

      parseVerificationResult(result.data, signatureKey, dispatch);
    } catch (error) {
      errorHandler(dispatch, error);
    }
  };

const handleResetVerifyState = () => (dispatch) => {
  dispatch(resetVerifyState());
};

export const actions = {
  fetchVerification,
  runQuickId,
  verifyPep,
  generateResult,
  verifyCompany,
  handleResetVerifyState,
};

export const reducer = createReducer({ ...INITIAL_STATE }, (builder) => {
  builder
    .addCase(fetchVerificationStarted, (state) => {
      state.fetching = true;
      state.fetchCompleted = false;
      state.fetchError = "";
      state.verifyCompleted = false;
    })
    .addCase(fetchVerificationCompleted, (state, { payload }) => {
      state.firstName = payload.first_name;
      state.lastName = payload.last_name;
      state.middleName = payload.middle_name;
      state.email = payload.email;
      state.birthDate = payload.birth_date
        ? moment(payload.birth_date).format(uiFormatDate)
        : "";
      state.unitNo = payload.unit_no || "";
      state.addressLine1 = payload.address_line_1 || "";
      state.addressLine2 = payload.address_line_2 || "";
      state.city = payload.city || "";
      state.state = payload.state || "";
      state.postalCode = payload.postal_code || "";
      state.country = payload.country;
      state.type = payload.type;
      state.fetching = false;
      state.fetchCompleted = true;
      state.verifyCompleted = !!payload.verification_completed;
      state.facematchPresent = !!payload.facematch_present;
      state.overallResult = payload.overall_result;
      state.sources = payload.sources;
    })
    .addCase(fetchVerificationFailed, (state, { payload }) => {
      state.fetching = false;
      state.fetchError = payload;
    })
    .addCase(verifyStarted, (state) => {
      state.verifying = true;
      state.verifyError = "";
      state.verifyCompleted = false;
    })
    .addCase(verifyCompleted, (state, { payload }) => {
      state.pdfUrl = payload.pdfUrl;
      state.signatureKey = payload.signature_key;
      state.overallResult = payload.overall_result;
      state.nameResult = payload.name_result;
      state.birthDateResult = payload.birth_date_result;
      state.addressResult = payload.address_result;
      state.pepResult = payload.pep_result;
      state.companyResult = payload.company_result;
      state.verifying = false;
      state.verifyCompleted = !!payload.verification_completed;
      state.facematchPresent = !!payload.facematch_present;
      state.facematchStep = payload.facematch_step;
    })
    .addCase(simpleVerifyCompleted, (state, { payload }) => {
      state.pdfUrl = payload.pdfUrl;
      state.signatureKey = payload.signature_key;
      state.verifying = false;
      state.verifyCompleted = true;
    })
    .addCase(verifyFailed, (state, { payload }) => {
      state.verifyError = payload;
      state.verifying = false;
    })
    .addCase(facematchVerifyStarted, (state) => {
      state.facematchVerifyStarted = true;
      state.facematchVerifyError = "";
      state.facematchVerifyCompleted = false;
    })
    .addCase(facematchVerifyCompleted, (state) => {
      state.facematchVerifyStarted = false;
      state.facematchVerifyError = "";
      state.facematchVerifyCompleted = true;
    })
    .addCase(facematchVerifyFailed, (state, { payload }) => {
      state.facematchVerifyError = payload;
      state.facematchVerifyStarted = false;
      state.facematchVerifyCompleted = false;
    })
    .addCase(resetVerifyState, () => ({ ...INITIAL_STATE }));
});
