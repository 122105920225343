const useCopyToClipboard = () => {
  const copy = async (text) => {
    if (!navigator || !navigator.clipboard) {
      window.open(text);
      return;
    }

    try {
      await navigator.clipboard.writeText(text);
      alert("Link copied!");
    } catch (error) {
      window.open(text);
    }
  };

  return copy;
};

export default useCopyToClipboard;
