export const QUICK_ID = "quick_id";
export const QUICK_ID_BANKMATCH = "quick_id_bankmatch";
export const QUICK_ID_FACEMATCH = "quick_id_facematch";
export const FACEMATCH_QUICK_ID = "facematch_quick_id";
export const FACEMATCH = "facematch";
export const PEP = "pep";
export const PEP_COMPANY = "pep_company";
export const COMPANY = "company";
export const OS_TRUST_PARTNERSHIP = "trust_partnership";
export const OS_COMPANY = "company";
export const OS_OTHER = "other";

export const PEP_COPY = process.env.REACT_APP_PEP_LABEL || "PEP";

const checkTypes = [
  {
    key: QUICK_ID,
    label: "Quick IDV",
    quickId: true,
  },
  {
    key: QUICK_ID_BANKMATCH,
    label: "Quick IDV + BankMatch",
    quickId: true,
  },
  {
    key: QUICK_ID_FACEMATCH,
    label: "Face IDV",
    quickId: true,
  },
  {
    key: FACEMATCH_QUICK_ID,
    label: "Face IDV",
    quickId: true,
  },
  {
    key: FACEMATCH,
    label: "FaceMatch",
  },
  {
    key: PEP,
    label: `${PEP_COPY} Check`,
  },
  {
    key: PEP_COMPANY,
    label: `${PEP_COPY} Company Check`,
  },
  {
    key: OS_TRUST_PARTNERSHIP,
    label: "OutSourced - Trust / Partnership",
    outsourced: true,
  },
  {
    key: OS_COMPANY,
    label: "OutSourced - Company",
    outsourced: true,
  },
  {
    key: OS_OTHER,
    label: "OutSourced - Other",
    outsourced: true,
  },
];

export default checkTypes;

export const orderTypes = [
  {
    label: "By Newest Date",
    key: "-created_at",
  },
  {
    label: "By Oldest Date",
    key: "created_at",
  },
];

export const checksStatusTypes = [
  {
    label: "All Statuses",
    key: "",
  },
  {
    label: "Pending",
    key: "pending",
  },
  {
    label: "Completed",
    key: "completed",
  },
  {
    label: "Passed",
    key: "completed_passed",
    subMenu: true,
  },
  {
    label: "In Review",
    key: "completed_review",
    subMenu: true,
  },
  {
    label: "Failed",
    key: "completed_failed",
    subMenu: true,
  },
  {
    label: "Monitored",
    key: "monitored",
  },
];

export const outsourcedStatusTypes = [
  {
    label: "All Statuses",
    key: "",
  },
  {
    label: "Submitted",
    key: "submitted",
  },
  {
    label: "Finalising",
    key: "finalizing",
  },
  {
    label: "In Progress",
    key: "in_progress",
  },
  {
    label: "Completed",
    key: "completed",
  },
];

export const checksTypesDropdown = [
  // {
  //   label: "Quick IDV + BankMatch",
  //   key: [QUICK_ID_BANKMATCH],
  // },
  {
    label: "Face IDV",
    key: [QUICK_ID_FACEMATCH, FACEMATCH_QUICK_ID],
  },
  { label: "Quick IDV", key: [QUICK_ID] },
  ...checkTypes
    .filter((ct) => !ct.quickId && !ct.outsourced)
    .map((ct) => ({ ...ct, key: [ct.key] })),
];

export const outsourcedTypesDropdown = checkTypes
  .filter((ct) => ct.outsourced)
  .map((ct) => ({ label: ct.label, key: [ct.key] }));
