import { Formik } from "formik";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import * as Yup from "yup";

import { EMAIL_MSG, REQUIRED_MSG } from "../../../constants/validationHelpers";
import Field from "../../form/Field";
import FormikScrollToErrors from "../../misc/FormikScrollToError";

const validationShape = {
  username: Yup.string().required(REQUIRED_MSG).email(EMAIL_MSG),
  role: Yup.string().required(REQUIRED_MSG),
};

const CreateEditModal = ({
  toggleModalState,
  isLoading,
  username,
  role,
  addAgencyUsers,
}) => {
  const isCreateModal = !username;

  const handleSubmit = useCallback(
    async (data) => {
      await addAgencyUsers(data, isCreateModal);
      toggleModalState();
    },
    [isCreateModal, toggleModalState, addAgencyUsers]
  );

  return (
    <Modal isOpen={true} toggle={() => toggleModalState((s) => !s)}>
      <ModalHeader toggle={() => toggleModalState((s) => !s)} />
      <ModalBody>
        <p className="h5 text-center mb-3">
          {isCreateModal ? "Create" : "Edit"} User
        </p>
        <Formik
          validationSchema={Yup.object().shape(validationShape)}
          onSubmit={handleSubmit}
          initialValues={{
            username: username || "",
            role: role || "ADMIN",
          }}
        >
          {({
            handleSubmit,
            errors,
            submitCount,
            isSubmitting,
            isValidating,
          }) => (
            <form noValidate onSubmit={handleSubmit} className="mt-5">
              <FormikScrollToErrors
                errors={errors}
                isSubmitting={isSubmitting}
                isValidating={isValidating}
              />
              <Field
                name="username"
                error={errors.username}
                submitCount={submitCount}
                label="Email"
                type="username"
                readOnly={!isCreateModal}
              />
              <Field
                name="role"
                error={errors.role}
                submitCount={submitCount}
                label="Role"
                component="select"
              >
                <option value="ADMIN">Admin</option>
                <option value="MANAGER">Manager</option>
                <option value="MEMBER">Member</option>
              </Field>
              <div className="text-center">
                <Button
                  color="primary"
                  className="px-5 mt-3"
                  type="submit"
                  disabled={isLoading}
                  block
                >
                  {isCreateModal ? "Add" : "Update"} User
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

CreateEditModal.propTypes = {
  toggleModalState: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  username: PropTypes.string,
  role: PropTypes.string,
  addAgencyUsers: PropTypes.func.isRequired,
};

export default CreateEditModal;
