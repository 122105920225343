import { useFormikContext } from "formik";
import React, { useEffect, useMemo } from "react";

import { SUPPORTED_COUNTRIES } from "../../../constants/supportedCountries";
import AddressGlobal from "./AddressGlobal";

const Address = () => {
  const { values, setFieldValue } = useFormikContext();
  const { countryCode } = values;
  useEffect(() => {
    if (["IN", "CN"].includes(countryCode)) {
      setFieldValue("addressLine1", "");
      setFieldValue("unitNo", "");
      setFieldValue("addressLine2", "");
      setFieldValue("city", "");
      setFieldValue("state", "");
      setFieldValue("postalCode", "");
    }
  }, [countryCode, setFieldValue]);

  const countryName = useMemo(
    () => SUPPORTED_COUNTRIES[countryCode]?.name || "",
    [countryCode]
  );

  return !["IN", "CN"].includes(values.countryCode) ? (
    <fieldset>
      <legend>{countryName} Address</legend>
      <AddressGlobal />
    </fieldset>
  ) : null;
};

export default Address;
