import React from "react";
import { connect } from "react-redux";

import { OS_TRUST_PARTNERSHIP } from "../../constants/checkTypes";
import { actions as checksActions } from "../../store/slices/check";
import StepHeader from "../misc/StepHeader";
import StepLayout from "../misc/StepLayout";
import DashboardLayout from "./Presenters/Layout";
import OutSourcedPresenter from "./Presenters/OutSourced";

const { addOutSourced, resetCheck } = checksActions;

const OutSourced = ({
  addOutSourced,
  resetCheck,
  fetching,
  fetchCompleted,
  defaultFacematchForOutsourced,
}) => {
  React.useEffect(resetCheck, [resetCheck]);
  return (
    <DashboardLayout>
      <StepLayout>
        <StepHeader text="Outsource Checks" />
        <OutSourcedPresenter
          fetching={fetching}
          resetCheck={resetCheck}
          addOutSourced={addOutSourced}
          fetchCompleted={fetchCompleted}
          initialValues={{
            checkType: OS_TRUST_PARTNERSHIP,
            name: "",
            country: "",
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            isFacematchRequired:
              defaultFacematchForOutsourced === true
                ? "yes"
                : defaultFacematchForOutsourced === false
                ? "no"
                : "",
            isCompanyPepRequired: "",
            natureOfTransaction: "",
            reference: "",
            notes: "",
          }}
        />
      </StepLayout>
    </DashboardLayout>
  );
};

const mapStateToProps = ({ check: { fetching, fetchCompleted }, user }) => ({
  fetching,
  fetchCompleted,
  defaultFacematchForOutsourced: user.defaultFacematchForOutsourced,
});
const mapDispatchToProps = { addOutSourced, resetCheck };

export default connect(mapStateToProps, mapDispatchToProps)(OutSourced);
