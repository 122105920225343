import React from "react";

const Attachment = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 19.1 21.72"
    xmlSpace="preserve"
    width="16"
  >
    <g fill="currentColor">
      <path d="M18.04 11.47a.7.7 0 0 1 0 1l-7.27 7.28a6.1 6.1 0 0 1-4.4 1.82 6.19 6.19 0 0 1-4.4-10.56l9.57-9.57A4.37 4.37 0 0 1 14.61.15a4.3 4.3 0 0 1 3.07 1.29 4.35 4.35 0 0 1 0 6.17l-9.46 9.5a2.54 2.54 0 0 1-1.78.75 2.42 2.42 0 0 1-1.79-.75 2.56 2.56 0 0 1-.75-1.78 2.4 2.4 0 0 1 .75-1.79l7.21-7.21a.71.71 0 0 1 1 1l-7.21 7.21a1.11 1.11 0 1 0 1.57 1.57l9.55-9.53a2.93 2.93 0 0 0-4.14-4.14l-9.66 9.57a4.787 4.787 0 0 0 6.8 6.74l7.28-7.28a.73.73 0 0 1 .99 0Z" />
      <path d="M6.37 21.72a6.41 6.41 0 0 1-4.5-1.86A6.34 6.34 0 0 1 0 15.34a6.24 6.24 0 0 1 1.87-4.44l9.56-9.57A4.58 4.58 0 0 1 14.61 0a4.53 4.53 0 0 1 3.16 7.72l-9.44 9.55a2.73 2.73 0 0 1-1.89.79 2.53 2.53 0 0 1-1.9-.8 2.69 2.69 0 0 1-.77-1.93 2.57 2.57 0 0 1 .8-1.9l7.2-7.16a.86.86 0 0 1 1.2 1.16l-7.2 7.22a1 1 0 0 0-.28.68 1 1 0 0 0 1.64.67l9.48-9.53a2.72 2.72 0 0 0 .81-2 2.78 2.78 0 0 0-2.77-2.78 2.75 2.75 0 0 0-2 .82l-9.6 9.56a4.62 4.62 0 0 0 6.53 6.54l7.29-7.29a.87.87 0 0 1 .62-.25.87.87 0 0 1 .62.25.84.84 0 0 1 0 1.22l-7.31 7.28a6.28 6.28 0 0 1-4.43 1.9ZM14.61.27a4.23 4.23 0 0 0-3 1.25l-9.53 9.59A6 6 0 0 0 .3 15.34a6 6 0 0 0 1.78 4.3 6.11 6.11 0 0 0 4.29 1.78 5.94 5.94 0 0 0 4.24-1.78l7.32-7.28a.53.53 0 0 0 0-.78.57.57 0 0 0-.41-.17.57.57 0 0 0-.41.17l-7.28 7.28a4.95 4.95 0 0 1-7-7l9.6-9.57a3.08 3.08 0 0 1 5.34 2.22 3.09 3.09 0 0 1-.9 2.18l-9.54 9.58a1.28 1.28 0 0 1-1.78 0 1.27 1.27 0 0 1 0-1.79l7.22-7.21a.56.56 0 0 0 0-.79.55.55 0 0 0-.79 0l-7.21 7.17a2.24 2.24 0 0 0-.71 1.68A2.37 2.37 0 0 0 4.77 17a2.24 2.24 0 0 0 1.68.71A2.41 2.41 0 0 0 8.13 17l9.44-9.49a4.21 4.21 0 0 0 0-6A4.08 4.08 0 0 0 14.61.27Z" />
    </g>
  </svg>
);

export default Attachment;
