import RcIf, { RcElse } from "rc-if";
import React, { useEffect } from "react";
import { connect } from "react-redux";

import { actions } from "../../../store/slices/user";
import Loading from "../../misc/Loading";
import ProfilePresenter from "./Presenter";

const { fetchUser, saveUser } = actions;

const Profile = ({ fetchUser, saveUser, user }) => {
  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  return (
    <RcIf if={!!user.id}>
      <ProfilePresenter {...user} saveUser={saveUser} />
      <RcElse>
        <Loading />
      </RcElse>
    </RcIf>
  );
};

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = { fetchUser, saveUser };

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
