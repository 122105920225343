import moment from "moment";

export const uiFormatDate =
  process.env.REACT_APP_UI_DATE_FORMAT || "DD/MM/YYYY";

const toDateString = (dateString) => {
  if (!dateString) {
    return "";
  }
  const parsedDate = moment(dateString, uiFormatDate);
  if (parsedDate.isValid()) {
    return parsedDate.format("YYYY-MM-DD");
  } else {
    return "";
  }
};

export default toDateString;

export const timestampToSimpleDate = (timestamp) => {
  if (!timestamp) {
    return "";
  }
  const parsedDate = moment(timestamp);
  if (parsedDate.isValid()) {
    return parsedDate.format("Do MMM");
  } else {
    return "";
  }
};

export const formatDateTimeSimple = (dateTimeString) => {
  if (!dateTimeString) {
    return "";
  }
  const parsedDate = moment(dateTimeString);
  if (parsedDate.isValid()) {
    return parsedDate.format("MMM Do YYYY, h:mm a");
  } else {
    return "";
  }
};

export const formatDateTime = (dateTimeString) => {
  if (!dateTimeString) {
    return "";
  }
  const parsedDate = moment(dateTimeString);
  if (parsedDate.isValid()) {
    return parsedDate.format("dddd, MMMM Do YYYY, h:mm a");
  } else {
    return "";
  }
};

export const isDateValidForLogs = (dateTimeString) => {
  if (!dateTimeString) {
    return false;
  }
  const parsedDate = moment(dateTimeString);
  return parsedDate.isValid() && parsedDate.isAfter(moment("2024-07-27"));
};

export const formatDate = (dateTimeString) => {
  if (!dateTimeString) {
    return "";
  }
  const parsedDate = moment(dateTimeString, uiFormatDate);
  if (parsedDate.isValid()) {
    return parsedDate.format("MMMM Do, YYYY");
  } else {
    return "";
  }
};
