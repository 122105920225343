import axios from "axios";
import queryString from "query-string";
import React, { useCallback, useState } from "react";
import { Button } from "reactstrap";

import logToSentryOrConsole from "../../../../helpers/sentry";
import { getDefaultHeaders } from "../../../../helpers/token";
import zapLogo from "../../../../images/zapier-logo_black.png";
import StepLayout from "../../..//misc/StepLayout";
import DashboardLayout from "../../../dashboard/Presenters/Layout";
import StepHeader from "../../../misc/StepHeader";

const ZapierIntegration = () => {
  const [loading, setLoading] = useState(false);
  const params = queryString.parse(location.search);

  const sendRequestToBackend = useCallback(
    async (allow) => {
      try {
        setLoading(true);
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/integrations/oauth/authorize/`,
          { ...params, allow },
          getDefaultHeaders()
        );
        if (response && response.data && response.data.redirect_uri) {
          window.location.replace(response.data.redirect_uri);
        } else {
          setLoading(false);
        }
      } catch (error) {
        logToSentryOrConsole(error);
        setLoading(false);
      }
    },
    [params]
  );

  return (
    <DashboardLayout>
      <StepLayout>
        <StepHeader text="Authorize Zapier?" />
        <div className="text-center">
          <div>
            <img src={zapLogo} alt="Zapier Logo" width="120px" />
          </div>
          <h5 className="my-4">Zapier requires the following permissions</h5>
          <p className="mb-1 font-italic">Create new checks</p>
          <p className="mb-1 font-italic">Access completed checks</p>
        </div>

        <div className="row align-items-center mt-4">
          <div className="col-6">
            <Button
              color="primary"
              outline
              block
              onClick={() => sendRequestToBackend(false)}
              disabled={loading}
            >
              Cancel
            </Button>
          </div>
          <div className="col-6 text-right">
            <Button
              color="primary"
              block
              onClick={() => sendRequestToBackend(true)}
              disabled={loading}
            >
              Authorize
            </Button>
          </div>
        </div>
      </StepLayout>
    </DashboardLayout>
  );
};

export default ZapierIntegration;
