import PropTypes from "prop-types";
import React from "react";
import MaskedInput from "react-text-mask";

import { REQUIRED_MSG } from "../../constants/validationHelpers";
import { formatDate, uiFormatDate } from "../../helpers/date";

const SimpleDate = ({
  name,
  error,
  submitCount,
  value,
  setFieldValue,
  label,
}) => (
  <div className="form-group abs-label">
    <MaskedInput
      id={name}
      name={name}
      value={value ? value : ""}
      onChange={(e) => setFieldValue(name, e.target.value)}
      placeholder={uiFormatDate.toUpperCase()}
      mask={uiFormatDate
        .split("")
        .map((r) => (["/", "-"].includes(r) ? r : /\d/))}
      className={`form-control ${
        !!error && submitCount > 0 ? "is-invalid" : ""
      }`}
      guide={false}
      autoComplete="bday"
    />
    <label htmlFor={name}>{label}</label>
    {!error && !!value && (
      <p className="small text-muted my-1">{formatDate(value)}</p>
    )}
    {!!error && error !== REQUIRED_MSG && submitCount > 0 && (
      <p className="small text-danger">{error}</p>
    )}
  </div>
);

SimpleDate.propTypes = {
  name: PropTypes.string.isRequired,
  error: PropTypes.string,
  submitCount: PropTypes.number.isRequired,
  value: PropTypes.string,
  setFieldValue: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

export default SimpleDate;
