import Cookies from "js-cookie";
import React from "react";
import { toastr } from "react-redux-toastr";
import { Redirect, Route } from "react-router";

import { LOGGED_IN_MSG } from "../constants/lang/en";
import { isUserLoggedIn, setAuthTokens } from "../helpers/token";
import history from "../history";
import routes from "../routes";

const NonAuthRoute = ({ component: Component, ...restProps }) => {
  if (isUserLoggedIn()) {
    toastr.info("", LOGGED_IN_MSG);

    if (
      window.location.href.startsWith(process.env.REACT_APP_PROD_CA_SITE_URL)
    ) {
      const postRedirectCookie = Cookies.get("post_redirect");
      if (postRedirectCookie) {
        Cookies.remove("post_redirect", {
          secure: true,
          domain: ".realaml.com",
        });
        const { accessToken, refreshToken, redirectTo } =
          JSON.parse(postRedirectCookie);
        if (accessToken && refreshToken) {
          setAuthTokens(accessToken, refreshToken);
          if (redirectTo) {
            history.push(redirectTo);
            return null;
          }
        }
      }
    }

    return (
      <Route
        {...restProps}
        render={() => <Redirect to={{ pathname: routes.checks }} />}
      />
    );
  } else {
    return (
      <Route {...restProps} render={(props) => <Component {...props} />} />
    );
  }
};

export default NonAuthRoute;
