import React from "react";

const RunChecks = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 23.44 23.44"
    xmlSpace="preserve"
  >
    <path
      fill="currentColor"
      d="M11.71 23.44a11.72 11.72 0 1 1 11.72-11.72 11.73 11.73 0 0 1-11.72 11.72Zm0-21.84a10.12 10.12 0 1 0 10.13 10.12A10.12 10.12 0 0 0 11.71 1.6Z"
    />
    <path
      fill="currentColor"
      d="M9.51 11.76V9.17a.59.59 0 0 1 .88-.51l2.24 1.3 2.25 1.28a.59.59 0 0 1 0 1l-2.25 1.29-2.24 1.3a.59.59 0 0 1-.88-.51Z"
    />
  </svg>
);

export default RunChecks;
