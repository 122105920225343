import React from "react";

const Edit = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 23.3 20.25"
    xmlSpace="preserve"
    width="20"
  >
    <path
      fill="currentColor"
      d="m10.64 12.66 1.07 1.08a.26.26 0 0 1-.11.43l-1.47.39-1.47.39a.26.26 0 0 1-.32-.31l.4-1.47.39-1.47a.26.26 0 0 1 .43-.12Z"
    />
    <path
      fill="currentColor"
      d="M15.15 20.25H2.35A2.36 2.36 0 0 1 0 17.89V5.22a2.36 2.36 0 0 1 2.35-2.36h12.8a2.36 2.36 0 0 1 2.35 2.36v12.67a2.36 2.36 0 0 1-2.35 2.36ZM2.35 4.47a.76.76 0 0 0-.75.76v12.66a.76.76 0 0 0 .75.76h12.8a.75.75 0 0 0 .75-.76V5.22a.75.75 0 0 0-.75-.75Z"
    />
    <path
      fill="currentColor"
      d="m22.93 1.12-.76-.76a1.24 1.24 0 0 0-1.75 0l-1.09 1.11 2.51 2.5 1.09-1.09a1.24 1.24 0 0 0 0-1.76ZM18.64 2.15l-3.55 3.54-5.89 5.9 2.51 2.51 5.9-5.89 3.55-3.55-2.52-2.51z"
    />
  </svg>
);

export default Edit;
