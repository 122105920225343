import { Formik } from "formik";
import PropTypes from "prop-types";
import React from "react";
import { isValidPhoneNumber } from "react-phone-number-input";
import { connect } from "react-redux";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import * as Yup from "yup";

import { MOBILE_LABEL } from "../../../constants/lang/en";
import {
  EMAIL_OR_MOBILE_MSG,
  MOBILE_MSG,
} from "../../../constants/validationHelpers";
import { actions as checkActions } from "../../../store/slices/check";
import Field from "../../form/Field";
import IdentityFields from "../../form/IdentityFields";
import PhoneField from "../../form/PhoneField";
import FormikScrollToErrors from "../../misc/FormikScrollToError";

const { updateCheckContacts } = checkActions;

const validationShape = {
  firstName: Yup.string(),
  middleName: Yup.string(),
  lastName: Yup.string(),
  phone: Yup.string(),
  email: Yup.string(),
};

const ContactUpdateModal = ({
  setContactUpdateModalOpened,
  updateCheckContacts,
  fetching,
  id,
  phone,
  email,
  started,
  firstName,
  middleName,
  lastName,
}) => {
  const handleSubmit = (data, { setFieldError }) => {
    if (data.phone && !isValidPhoneNumber(data.phone)) {
      setFieldError("phone", MOBILE_MSG);
      return;
    }
    if (!data.email && !data.phone) {
      setFieldError("email", EMAIL_OR_MOBILE_MSG);
      setFieldError("phone", EMAIL_OR_MOBILE_MSG);
      return;
    }

    updateCheckContacts({ ...data, id });
    setContactUpdateModalOpened(false);
  };

  return (
    <Modal isOpen={true} toggle={() => setContactUpdateModalOpened((s) => !s)}>
      <ModalHeader toggle={() => setContactUpdateModalOpened((s) => !s)} />
      <ModalBody>
        <p className="h5 text-center mb-3">Edit Contact Info</p>
        <Formik
          validationSchema={Yup.object().shape(validationShape)}
          onSubmit={handleSubmit}
          enableReinitialize={true}
          initialValues={{
            phone: phone || "",
            email: email || "",
            firstName: firstName || "",
            middleName: middleName || "",
            lastName: lastName || "",
          }}
        >
          {({
            handleSubmit,
            errors,
            submitCount,
            isSubmitting,
            isValidating,
            values,
            setFieldValue,
          }) => (
            <form noValidate onSubmit={handleSubmit} className="mt-5">
              <FormikScrollToErrors
                errors={errors}
                isSubmitting={isSubmitting}
                isValidating={isValidating}
              />
              {!started && (
                <IdentityFields
                  errors={errors}
                  submitCount={submitCount}
                  setFieldValue={setFieldValue}
                  autoComplete={false}
                  autoFocusFirstName
                  showDateOfBirth={false}
                />
              )}
              <PhoneField
                name="phone"
                value={values.phone}
                setFieldValue={setFieldValue}
                error={errors.phone}
                submitCount={submitCount}
                label={`${MOBILE_LABEL} Number`}
              />
              <Field
                name="email"
                error={errors.email}
                submitCount={submitCount}
                label="Email"
                type="email"
              />
              <div className="text-center">
                <Button
                  color="primary"
                  className="px-5 mt-3"
                  type="submit"
                  disabled={fetching}
                  block
                >
                  Save & Resend
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

ContactUpdateModal.propTypes = {
  setContactUpdateModalOpened: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  email: PropTypes.string,
  phone: PropTypes.string,
  started: PropTypes.bool,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  middleName: PropTypes.string,
};

const mapStateToProps = ({ check }) => ({
  fetching: check.fetching,
});
const mapDispatchToProps = {
  updateCheckContacts,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactUpdateModal);
