import React from "react";

import Layout from "./Layout";

const VerificationCancelled = () => (
  <Layout
    bodyText="This check has been cancelled by your agent. For any queries please get in
    touch with your agent."
  />
);

export default VerificationCancelled;
