export const DEFAULT_ERROR = "Something went wrong, please try again.";
export const ERROR_REFRESH_WITH_CONTACT_INFO =
  "Something went wrong, please refresh your page and try again. If the error persists please use the icon at the bottom right of this screen to contact us.";
export const ERROR_WITH_CONTACT_INFO =
  "Something went wrong, please try again. If the error persists please use the icon at the bottom right of this screen to contact us.";
export const INVALID_AUTH_DETAILS = "Invalid username and/or password.";
export const EMAIL_NOT_VERIFIED =
  "Your email is not verified, please enter the 4 digit code sent to your email.";
export const DATA_ERROR =
  "Something is wrong with the data you entered, please try again with correct data.";
export const ACCOUNT_EXISTS =
  "This email address has already been registered. Please login using this email.";
export const NOT_AUTHORISED = "Please login to continue.";
export const RESET_LINK_INVALID =
  "Reset link is invalid or expired. Please reset your password again.";
export const UNLOCK_LINK_INVALID =
  "Unlock link is invalid or expired. Please contact us at support@realaml.com.";
export const PASSWORDS_NO_MATCH =
  "New password and confirm password doesn't match.";
export const PASSWORD_RESET_FAILED =
  "Failed to reset password. please use the icon at the bottom right of this screen to contact us.";
export const ACCOUNT_UNLOCK_FAILED =
  "Failed to unlock account. please use the icon at the bottom right of this screen to contact us.";
export const LOGGED_IN_MSG =
  "You are logged-in. Taking you to Realaml dashboard.";

export const PROFILE_UPDATED = "Your profile successfully updated.";
export const SETTINGS_UPDATED = "Your settings successfully updated.";
export const VERIFY_EMAIL_SUCCESS =
  "Verification email was successfully re-sent. Please check your email and follow the instructions.";
export const EMAIL_VERIFIED = "Email successfully verified";
export const RESET_PASSWORD_SUCCESS =
  "Reset password email re-sent successfully";
export const VERIFY_EMAIL_FAILED =
  "Email verification failed. Retry using the button below or contact support@realaml.com.";
export const CHANGE_DEFAULT_PASSWORD =
  "You are using the default password generated by Realaml. Please change your password.";
export const CHANGE_EXPIRED_PASSWORD =
  "Your current password is older than 90 days and has expired. For security reasons, you will need to create a new password.";
export const PASSWORD_CHANGE_SUCCESS = "Password successfully reset.";
export const ACCOUNT_UNLOCK_SUCCESS =
  "Account unlocked and password successfully reset.";
export const THIRD_PARTY_ERROR =
  "Third party connection error, please try again later.";
export const COMPLETE_PROFILE = "Please complete your profile to run checks.";
export const PAYMENT_IN_REVIEW =
  "Your account is marked for manual review. It may take up to 2 hours for us to complete the review. If you have any questions, please use the icon at the bottom right of this screen to contact us.";
export const PAYMENT_ERROR_FOR_CUSTOMER = DEFAULT_ERROR;

export const TWO_FACTOR_AUTH_ENABLED = "Two-factor authentication enabled.";
export const TWO_FACTOR_AUTH_DISABLED = "Two-factor authentication disabled.";
export const SEND_EMAIL_ATTACHMENTS_ENABLED =
  "Completed check report will be sent via email.";
export const SEND_EMAIL_ATTACHMENTS_DISABLED =
  "Completed check report will NOT be sent via email.";
export const COMPLETED_VERIFICATION_DELETE_ENABLED =
  "Completed verifications will be deleted after 7 days.";
export const COMPLETED_VERIFICATION_DELETE_DISABLED =
  "Completed verifications will not be deleted.";
export const REMINDERS_ENABLED =
  "Reminders will be sent to incompleted verifications.";
export const REMINDERS_DISABLED =
  "Reminders will not be sent to incompleted verifications.";

export const MOBILE_LABEL = window.location.href.startsWith(
  process.env.REACT_APP_PROD_CA_SITE_URL || "NA"
)
  ? "Cell"
  : "Mobile";
export const MOBILE_NOT_VALID = `We couldn't send SMS to the number provided. Please re-enter correct ${MOBILE_LABEL.toLowerCase()} number.`;
export const MOBILE_CODE_NOT_VALID = "The code you entered is invalid.";
export const MOBILE_UPDATED = `Your ${MOBILE_LABEL.toLowerCase()} number was successfully verified.`;
export const MOBILE_CODE_RESENT = "Verification code successfully resent.";

export const ACTION_STEP_INTEGRATION_SUCCESS =
  "Action Step integration enabled.";

export const REPORT_GENERATE_SUCCESS =
  "Report will be generate and emailed to you soon.";

export const CAPTCHA_ENABLE_MESSAGE = `Please click the "I'm not a robot" button to continue`;

export const DATES_REQUIRED = "Please select both start and end dates";
export const DATES_MAX_INTERVAL =
  "Please select dates within a maximum range of one year";
