import React, { useMemo } from "react";

import { getBgColor } from "../../helpers/color";

const Pep = ({ type, width = 40, height = 40 }) => {
  const bgColor = useMemo(() => getBgColor(type), [type]);
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 48.91 48.91"
      xmlSpace="preserve"
      className={`text-${type || "primary"}`}
      width={width}
      height={height}
    >
      <circle
        cx={24.45}
        cy={24.45}
        r={24.45}
        fill={bgColor}
        strokeWidth={0}
        transform="rotate(-13.81 24.478 24.456)"
      />
      <circle
        cx={22.82}
        cy={22.35}
        r={8.04}
        fill="none"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeWidth="1.6px"
      />
      <path
        d="m34.17 34.17-5.91-5.91"
        fill="none"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeWidth="1.6px"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default Pep;
