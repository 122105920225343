import PropTypes from "prop-types";
import React from "react";

const FormikScrollToErrors = ({ errors, isSubmitting, isValidating }) => {
  React.useEffect(() => {
    const keys = Object.keys(errors);
    if (keys.length > 0 && isSubmitting && !isValidating) {
      const errorElement = document.querySelector(`[name="${keys[0]}"]`);
      if (errorElement) {
        errorElement.focus();
      }
    }
  }, [errors, isSubmitting, isValidating]);

  return null;
};

FormikScrollToErrors.propTypes = {
  errors: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isValidating: PropTypes.bool.isRequired,
};

export default FormikScrollToErrors;
