import { Formik } from "formik";
import React from "react";
import { connect } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Button } from "reactstrap";
import * as Yup from "yup";

import { REQUIRED_MSG } from "../../../constants/validationHelpers";
import { actions } from "../../../store/slices/user";
import Field from "../../form/Field";
import FormikScrollToErrors from "../../misc/FormikScrollToError";

const { saveAgency } = actions;

const VALIDATION_RULES = Yup.object().shape({
  name: Yup.string().trim().required(REQUIRED_MSG),
});

const SettingsModal = ({
  showModal,
  toggleModal,
  fetching,
  saveAgency,
  agencyName,
}) => {
  const handleSaveAgency = (data) => {
    saveAgency(data, undefined, toggleModal);
  };

  return (
    <Modal isOpen={showModal} toggle={toggleModal} backdrop="static">
      <ModalHeader toggle={toggleModal}>Your Settings</ModalHeader>
      <ModalBody>
        <Formik
          validationSchema={VALIDATION_RULES}
          onSubmit={handleSaveAgency}
          initialValues={{
            name: agencyName,
          }}
          enableReinitialize={true}
        >
          {({
            handleSubmit,
            errors,
            submitCount,
            isSubmitting,
            isValidating,
          }) => (
            <form noValidate onSubmit={handleSubmit} className="mb-4">
              <FormikScrollToErrors
                errors={errors}
                isSubmitting={isSubmitting}
                isValidating={isValidating}
              />
              <Field
                name="name"
                error={errors.name}
                submitCount={submitCount}
                label="Trading Name"
                autoComplete="organization"
                autoFocus
              />
              <Button
                color="primary"
                block
                size="lg"
                disabled={fetching}
                type="submit"
              >
                Save
              </Button>
            </form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

const mapStateToProps = ({ user }) => ({ ...user });
const mapDispatchToProps = { saveAgency };

export default connect(mapStateToProps, mapDispatchToProps)(SettingsModal);
