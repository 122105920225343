import React from "react";
import { connect } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import { actions as billingActions } from "../../store/slices/billing";
import BillingForm from "./BillingForm";

const { setShowPaymentModal } = billingActions;

const PaymentModal = ({ setShowPaymentModal, showPaymentModal }) => (
  <Modal
    isOpen={showPaymentModal}
    toggle={() => setShowPaymentModal(!showPaymentModal)}
  >
    <ModalHeader toggle={() => setShowPaymentModal(!showPaymentModal)} />
    <ModalBody>
      <BillingForm heading="" showPricing={false} />
    </ModalBody>
  </Modal>
);

const mapStateToProps = ({ billing: { showPaymentModal } }) => ({
  showPaymentModal,
});
const mapDispatchToProps = { setShowPaymentModal };

export default connect(mapStateToProps, mapDispatchToProps)(PaymentModal);
