import { getExampleNumber } from "libphonenumber-js";
import examples from "libphonenumber-js/examples.mobile.json";
import PropTypes from "prop-types";
import React, { useEffect, useMemo } from "react";
import PhoneInput, { formatPhoneNumber } from "react-phone-number-input";
import { connect } from "react-redux";

import { REQUIRED_MSG } from "../../constants/validationHelpers";

const PhoneField = ({
  name,
  error,
  submitCount,
  value,
  setFieldValue,
  forceShowErrors,
  label,
  defaultCountryCode,
  checkCountryCode,
  autoComplete = "off",
}) => {
  const [countryCode, setCountryCode] = React.useState(
    checkCountryCode || defaultCountryCode || ""
  );
  const showErrors = forceShowErrors || submitCount > 0;
  const placeholder = useMemo(() => {
    const exampleNumber = getExampleNumber(countryCode, examples);
    return exampleNumber ? formatPhoneNumber(exampleNumber.number) : "";
  }, [countryCode]);
  useEffect(() => {
    if (checkCountryCode) setCountryCode(checkCountryCode);
  }, [checkCountryCode]);

  return (
    <div className="form-group abs-label">
      <div
        className={`form-control ${!!error && showErrors ? "is-invalid" : ""}`}
      >
        <PhoneInput
          id={name}
          name={name}
          value={value ? value : ""}
          onChange={(value) => setFieldValue(name, value)}
          displayInitialValueAsLocalNumber
          placeholder={placeholder}
          autoComplete={autoComplete}
          defaultCountry={countryCode}
          onCountryChange={setCountryCode}
        />
      </div>
      <label htmlFor={name}>{label}</label>
      {!!value && value.startsWith("+86") && (
        <p className="small text-primary mt-2">
          Chinese mobile carriers may block texts from abroad. To ensure
          receipt, send the SMS or email to yourself and select the "Copy Link"
          option in the Check History and send via WeChat.
        </p>
      )}
      {!!error && error !== REQUIRED_MSG && showErrors && (
        <p className="small text-danger">{error}</p>
      )}
    </div>
  );
};

PhoneField.propTypes = {
  name: PropTypes.string.isRequired,
  error: PropTypes.string,
  submitCount: PropTypes.number.isRequired,
  value: PropTypes.string,
  setFieldValue: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  forceShowErrors: PropTypes.bool,
  defaultCountryCode: PropTypes.string,
  checkCountryCode: PropTypes.string,
};

const mapStateToProps = ({ user }) => ({
  defaultCountryCode: user.defaultCountryCode,
});

export default connect(mapStateToProps)(PhoneField);
