import axios from "axios";

import captureException from "./sentry";
import { getDefaultHeaders } from "./token";

export const uploadFiles = async (
  signatureKey,
  files,
  modelType = "verification"
) => {
  if (!files || !files.length || !signatureKey) return Promise.resolve();

  const originalFiles = files.map((file) => file.originalFile);
  const mimeTypes = originalFiles.map((file) => file.type);

  const urlResponse = await axios.get(
    `${
      process.env.REACT_APP_API_URL
    }/files/upload/${signatureKey}/?contentTypes=${mimeTypes.join(
      ","
    )}&modelType=${modelType}`,
    getDefaultHeaders()
  );
  const urls = urlResponse.data;

  const requestData = [];
  await Promise.all(
    urls.map((url, index) => {
      const file = files[index];
      const originalFile = file.originalFile;
      requestData.push({
        name: file.name,
        file_name: url[1],
        use_in_final_report: true, // TODO: make this user selectable
      });
      return uploadToS3(url[0], originalFile);
    })
  );

  await axios.post(
    `${process.env.REACT_APP_API_URL}/${modelType}/${signatureKey}/files/`,
    requestData,
    getDefaultHeaders()
  );
};

export const uploadToS3 = async (signedUrl, file) => {
  if (!file) return Promise.resolve();

  let retryCount = 3;
  while (retryCount > 0) {
    try {
      await axios.put(signedUrl, file);
      break;
    } catch (error) {
      captureException(error);
    }
    retryCount -= 1;
  }
};

export const constructDownloadUrl = (signatureKey) =>
  signatureKey ? `/checks/download/${signatureKey}` : "/";

export const constructAuditTrailDownloadUrl = (signatureKey) =>
  signatureKey ? `/checks/download-audit-trail/${signatureKey}` : "/";

export const constructOutsourcedDownloadUrl = (signatureKey) =>
  signatureKey ? `/outsourced-checks/download/${signatureKey}` : "/";

export const constructOutsourcedAuditTrailDownloadUrl = (signatureKey) =>
  signatureKey
    ? `/outsourced-checks/download-audit-trail/${signatureKey}`
    : "/";
