import queryString from "query-string";
import React from "react";
import { useLocation } from "react-router";

import { QUICK_ID_FACEMATCH } from "../../../constants/checkTypes";
import ChecksForm from "../checks/Form";

const QuickIDFaceMatch = () => {
  const location = useLocation();
  const queries = queryString.parse(location.search);

  return (
    <ChecksForm
      title="Face IDV"
      checkType={QUICK_ID_FACEMATCH}
      type={queries.type}
    />
  );
};

export default QuickIDFaceMatch;
