import { configureStore } from "@reduxjs/toolkit";
import { reducer as toastrReducer } from "react-redux-toastr";

import { reducer as authReducer } from "./slices/auth";
import { reducer as billingReducer } from "./slices/billing";
import { reducer as checkReducer } from "./slices/check";
import { reducer as companyReducer } from "./slices/company";
import { reducer as historyReducer } from "./slices/history";
import { reducer as outsourcedHistoryReducer } from "./slices/outsourcedHistory";
import { reducer as userReducer } from "./slices/user";
import { reducer as verificationReducer } from "./slices/verification";

const store = configureStore({
  reducer: {
    auth: authReducer,
    verification: verificationReducer,
    user: userReducer,
    billing: billingReducer,
    check: checkReducer,
    company: companyReducer,
    history: historyReducer,
    outsourcedHistory: outsourcedHistoryReducer,
    toastr: toastrReducer,
  },
});

export default store;
