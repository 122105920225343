import React from "react";
import { connect } from "react-redux";

import { actions } from "../../store/slices/auth";
import ResetPasswordPresenter from "./Presenters/ResetPassword";

const { checkResetPassword, resetPassword } = actions;

const ResetPassword = ({ checkResetPassword, resetPassword, auth }) => (
  <ResetPasswordPresenter
    {...auth}
    resetPassword={resetPassword}
    checkResetPassword={checkResetPassword}
  />
);

const mapStateToProps = ({ auth }) => ({ auth });
const mapDispatchToProps = { checkResetPassword, resetPassword };

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
