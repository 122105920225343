import PropTypes from "prop-types";
import RcIf, { RcElse } from "rc-if";
import React from "react";
import { connect } from "react-redux";

import { SELF as SELF_CHECK } from "../../../constants/checkGroupType";
import { QUICK_ID } from "../../../constants/checkTypes";
import { actions } from "../../../store/slices/check";
import CheckForm from "../../dashboard/Presenters/CheckForm";
import CheckGroupForm from "../../dashboard/Presenters/CheckGroupForm";
import DashboardLayout from "../../dashboard/Presenters/Layout";
import SelfCheck from "../../dashboard/SelfCheck";
import StepHeader from "../../misc/StepHeader";
import StepLayout from "../../misc/StepLayout";

const { startNewCheck, goToPrevCheck, resetCheck, addCheckUser } = actions;

const ChecksForm = ({
  title,
  checkType,
  type,
  defaultCountryCode,
  allowedCheckGroupTypes,
  startNewCheck,
  goToPrevCheck,
  resetCheck,
  addCheckUser,
  check: { draft, fetching, fetchError },
}) => {
  const draftReference = draft.reference ? (
    <span>
      <strong>Reference</strong>: {draft.reference}
    </span>
  ) : null;
  return (
    <RcIf if={!!draft.id && draft.type === SELF_CHECK}>
      <SelfCheck
        title={title}
        checkType={checkType}
        defaultCountryCode={defaultCountryCode}
      />
      <RcElse>
        <DashboardLayout>
          <StepLayout>
            <StepHeader subHeader={draftReference} text={title} />
            <RcIf if={draft.totalChecks > 0}>
              <CheckForm
                addCheckUser={addCheckUser}
                goToPrevCheck={goToPrevCheck}
                resetCheck={resetCheck}
                check={{
                  ...draft.checks[draft.activeCheck],
                  QUICK_ID,
                }}
                groupType={draft.type}
                activeCheck={draft.activeCheck}
                totalChecks={draft.totalChecks}
                fetching={fetching}
                fetchError={fetchError}
                checkType={checkType}
                defaultCountryCode={defaultCountryCode}
              />
              <RcElse>
                <CheckGroupForm
                  startNewCheck={startNewCheck}
                  reference={draft.reference}
                  totalChecks={draft.totalChecks}
                  type={type}
                  notes={draft.notes}
                  fetching={fetching}
                  allowedCheckGroupTypes={allowedCheckGroupTypes}
                />
              </RcElse>
            </RcIf>
          </StepLayout>
        </DashboardLayout>
      </RcElse>
    </RcIf>
  );
};

const mapStateToProps = ({ check, user }) => ({
  check,
  defaultCountryCode: user.defaultCountryCode,
});
const mapDispatchToProps = {
  startNewCheck,
  goToPrevCheck,
  resetCheck,
  addCheckUser,
};

ChecksForm.propTypes = {
  title: PropTypes.node.isRequired,
  checkType: PropTypes.string.isRequired,
  type: PropTypes.string,
  defaultCountryCode: PropTypes.string,
  allowedCheckGroupTypes: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChecksForm);
