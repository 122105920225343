import queryString from "query-string";
import React from "react";
import { useLocation } from "react-router";

import { QUICK_ID_BANKMATCH } from "../../../constants/checkTypes";
import ChecksForm from "../checks/Form";

const QuickIdBankMatch = () => {
  const location = useLocation();
  const queries = queryString.parse(location.search);

  return (
    <ChecksForm
      title="Quick IDV + BankMatch"
      checkType={QUICK_ID_BANKMATCH}
      type={queries.type}
    />
  );
};

export default QuickIdBankMatch;
