import React from "react";

import { SUPPORTED_COUNTRIES } from "../../../constants/supportedCountries";
import Loading from "../../misc/Loading";
import StepLayout from "../../misc/StepLayout";
import QuickId from "./QuickId";
import Results from "./Results";

const countriesMap = {};
Object.values(SUPPORTED_COUNTRIES).forEach((c) => {
  countriesMap[c.name] = c.code;
});

const VerificationPresenter = ({
  firstName,
  lastName,
  middleName,
  birthDate = "",
  unitNo = "",
  addressLine1 = "",
  addressLine2 = "",
  city = "",
  state = "",
  postalCode = "",
  fetching,
  verifying,
  verifyCompleted,
  facematchPresent,
  runQuickId,
  overallResult,
  nameResult,
  birthDateResult,
  addressResult,
  pepResult,
  pdfUrl,
  country,
  updateCheckUserAndResend,
  facematchStep,
  sources,
  quickIDAlgorithm,
  allowMixAndMatch,
}) => {
  const countryCode = country === "New Zealand" ? "NZ" : countriesMap[country];
  return (
    <StepLayout>
      {fetching ? (
        <Loading />
      ) : verifyCompleted ? (
        <Results
          overallResult={overallResult}
          nameResult={nameResult}
          birthDateResult={birthDateResult}
          addressResult={addressResult}
          pepResult={pepResult}
          pdfUrl={pdfUrl}
          includeFaceMatch={
            facematchStep !== "COMPLETED" ? facematchPresent : false
          }
          updateCheckUserAndResend={updateCheckUserAndResend}
        />
      ) : (
        <QuickId
          firstName={firstName}
          lastName={lastName}
          middleName={middleName}
          birthDate={birthDate}
          unitNo={unitNo}
          addressLine1={addressLine1}
          addressLine2={addressLine2}
          city={city}
          state={state}
          postalCode={postalCode}
          verifying={verifying}
          runQuickId={runQuickId}
          countryCode={countryCode}
          sources={sources}
          quickIDAlgorithm={quickIDAlgorithm}
          allowMixAndMatch={allowMixAndMatch}
        />
      )}
    </StepLayout>
  );
};

export default VerificationPresenter;
