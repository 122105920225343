import { Formik } from "formik";
import queryString from "query-string";
import React, { useCallback, useState } from "react";
import { isValidPhoneNumber } from "react-phone-number-input";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useLocation } from "react-router";
import { Button } from "reactstrap";
import * as Yup from "yup";

import checkApi from "../../../api/check";
import { MOBILE_LABEL } from "../../../constants/lang/en";
import { ERROR_WITH_CONTACT_INFO } from "../../../constants/lang/en";
import {
  EMAIL_MSG,
  EMAIL_OR_MOBILE_MSG,
  MOBILE_MSG,
  REQUIRED_MSG,
} from "../../../constants/validationHelpers";
import logToSentryOrConsole from "../../../helpers/sentry";
import history from "../../../history";
import routes from "../../../routes";
import DashboardLayout from "../../dashboard/Presenters/Layout";
import Field from "../../form/Field";
import IdentityFields from "../../form/IdentityFields";
import PhoneField from "../../form/PhoneField";
import FormikScrollToErrors from "../../misc/FormikScrollToError";
import StepHeader from "../../misc/StepHeader";
import StepLayout from "../../misc/StepLayout";

const initialValues = (link, title) => ({
  firstName: "",
  lastName: "",
  link,
  title,
  email: "",
  phone: "",
  reference: "",
  notes: "",
});

const ChecksExternal = ({ defaultCountryCode }) => {
  const isCa = defaultCountryCode === "CA";
  const [loading, setLoading] = useState(false);
  const [fetchError, setFetchError] = useState("");
  const location = useLocation();
  const queries = queryString.parse(location.search);
  const mobileErrorMsg = MOBILE_MSG;
  const mobileError = fetchError === "Invalid phone" ? mobileErrorMsg : "";

  const submitHandler = useCallback(async (data, { setFieldError }) => {
    if (!data.phone && !data.email) {
      setFieldError("email", EMAIL_OR_MOBILE_MSG);
      setFieldError("phone", EMAIL_OR_MOBILE_MSG);
      return;
    }
    if (data.phone && !isValidPhoneNumber(data.phone)) {
      setFieldError("phone", mobileErrorMsg);
      return;
    }
    setLoading(true);

    try {
      await checkApi.sendExternalLink(data);
      toastr.success("Link sent successfully.");
      setLoading(false);
      history.push(routes.checks);
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.status === 400 &&
        error.response.data &&
        error.response.data.reason === "Invalid phone"
      ) {
        setFetchError(error.response.data.reason);
      } else {
        logToSentryOrConsole(error);
        toastr.error("", ERROR_WITH_CONTACT_INFO);
      }
      setLoading(false);
    }
  }, []);

  return (
    <DashboardLayout>
      <StepLayout>
        <StepHeader text={queries.title} />
        <div>
          <Formik
            validationSchema={Yup.object().shape({
              firstName: Yup.string().trim().required(REQUIRED_MSG),
              lastName: Yup.string().trim().required(REQUIRED_MSG),
              link: Yup.string().trim().required(REQUIRED_MSG),
              title: Yup.string().trim().required(REQUIRED_MSG),
              email: Yup.string().trim().optional().email(EMAIL_MSG),
              phone: Yup.string().trim().optional(),
              reference: Yup.string().trim().optional(),
              notes: Yup.string().trim().optional(),
            })}
            onSubmit={submitHandler}
            initialValues={initialValues(queries.link, queries.title)}
            enableReinitialize={true}
          >
            {({
              handleSubmit,
              errors,
              submitCount,
              setFieldValue,
              values,
              isSubmitting,
              isValidating,
              resetCheck,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <FormikScrollToErrors
                  errors={errors}
                  isSubmitting={isSubmitting}
                  isValidating={isValidating}
                />
                <Field
                  name="reference"
                  error={errors.reference}
                  submitCount={submitCount}
                  autoFocus
                  label={`${
                    isCa ? "File/Client/Matter Number" : "Reference"
                  } (optional)`}
                  placeholder={isCa ? "" : "e.g. File Number or Tag."}
                />
                <Field
                  name="notes"
                  error={errors.notes}
                  submitCount={submitCount}
                  component="textarea"
                  label={`${isCa ? "Matter Description" : "Notes"} (optional)`}
                  placeholder={
                    isCa
                      ? "This will only appear on PDF reports and in the Check History"
                      : "Your notes will only appear on PDF reports and in the Check History."
                  }
                />
                <IdentityFields
                  errors={errors}
                  submitCount={submitCount}
                  autoComplete={false}
                  autoFocusFirstName
                  showDateOfBirth={false}
                  setFieldValue={setFieldValue}
                  showMiddleName={false}
                />
                <Field
                  name="email"
                  error={errors.email}
                  submitCount={submitCount}
                  label="Client email address"
                  type="email"
                />
                <PhoneField
                  name="phone"
                  value={values.phone}
                  setFieldValue={setFieldValue}
                  forceShowErrors={!!mobileError}
                  error={errors.phone || mobileError}
                  submitCount={submitCount}
                  label={`${MOBILE_LABEL} Number`}
                  checkCountryCode={values.countryCode}
                />

                <div className="row align-items-center">
                  <div className="col-6">
                    <Button color="primary" onClick={resetCheck} outline block>
                      Start Again
                    </Button>
                  </div>
                  <div className="col-6">
                    <Button
                      color="primary"
                      className="px-5"
                      type="submit"
                      disabled={loading}
                      block
                    >
                      Send
                    </Button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </StepLayout>
    </DashboardLayout>
  );
};

const mapStateToProps = ({ user: { defaultCountryCode } }) => ({
  defaultCountryCode,
});

export default connect(mapStateToProps)(ChecksExternal);
