import React from "react";
import { Redirect, Route } from "react-router";

import { isUserLoggedIn } from "../helpers/token";
import routes from "../routes";

const AuthRoute = ({ component: Component, ...restProps }) => (
  <Route
    {...restProps}
    render={(props) =>
      isUserLoggedIn() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: routes.signIn,
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

export default AuthRoute;
