import React from "react";

const Eye = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 27.85 19.57"
    xmlSpace="preserve"
    width={18}
  >
    <path
      fill="currentColor"
      d="M13.92 15.11a5.33 5.33 0 1 1 5.33-5.32 5.33 5.33 0 0 1-5.33 5.32Zm0-9.05a3.73 3.73 0 1 0 3.73 3.73 3.74 3.74 0 0 0-3.73-3.73Z"
    />
    <path
      fill="currentColor"
      d="M13.92 19.57C6.24 19.57 0 15.19 0 9.79S6.24 0 13.92 0s13.92 4.39 13.92 9.79-6.24 9.78-13.92 9.78Zm0-18C7.13 1.57 1.6 5.24 1.6 9.76s5.52 8.21 12.32 8.21 12.32-3.67 12.32-8.18S20.72 1.6 13.92 1.6Z"
    />
  </svg>
);

export default Eye;
