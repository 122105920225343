import { Field as FormIkField, Formik } from "formik";
import PropTypes from "prop-types";
import qs from "query-string";
import RcIf from "rc-if";
import React, { useRef } from "react";
import { isValidPhoneNumber } from "react-phone-number-input";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import * as Yup from "yup";

import {
  EMAIL as TYPE_EMAIL,
  SMS as TYPE_SMS,
} from "../../../constants/checkGroupType";
import {
  FACEMATCH,
  PEP_COPY,
  QUICK_ID,
  QUICK_ID_BANKMATCH,
} from "../../../constants/checkTypes";
import { MOBILE_LABEL } from "../../../constants/lang/en";
import { NATIONAL_ID_NOT_AVAILABLE } from "../../../constants/nationalIdType";
import { SUPPORTED_COUNTRIES } from "../../../constants/supportedCountries";
import {
  EMAIL_MSG,
  EMAIL_OR_MOBILE_MSG,
  MOBILE_MSG,
  REQUIRED_MSG,
} from "../../../constants/validationHelpers";
import Field from "../../form/Field";
import IdentityFields from "../../form/IdentityFields";
import PhoneField from "../../form/PhoneField";
import FormikScrollToErrors from "../../misc/FormikScrollToError";

const validationRules = ({ groupType }) => {
  let rules = {
    firstName: Yup.string().trim().required(REQUIRED_MSG),
    lastName: Yup.string().when("countryCode", {
      is: (countryCode) => countryCode !== "CN",
      then: Yup.string().trim().required(REQUIRED_MSG),
    }),
    middleName: Yup.string(),
    checkType: Yup.string().trim().required(REQUIRED_MSG),
  };
  if (groupType === TYPE_EMAIL || groupType === TYPE_SMS) {
    rules.email = Yup.string().trim().optional().email(EMAIL_MSG);
    rules.phone = Yup.string().trim().optional();
  }
  return Yup.object().shape(rules);
};

const initialValues = (
  {
    firstName = "",
    lastName = "",
    middleName = "",
    checkType = QUICK_ID,
    email = "",
    phone = "",
    runPep,
  },
  groupType,
  countryCode = "",
  runPepDefault
) => {
  let values = {
    firstName,
    lastName,
    middleName,
    checkType,
    countryCode,
    runPep: typeof runPep === "boolean" ? runPep : runPepDefault,
  };
  if (groupType === TYPE_EMAIL || groupType === TYPE_SMS) {
    values.email = email;
    values.phone = phone;
  }
  return values;
};

const CheckForm = ({
  addCheckUser,
  resetCheck,
  check,
  activeCheck,
  totalChecks,
  groupType,
  fetching,
  fetchError,
  checkType,
  defaultCountryCode,
  allowedCountries,
  sources,
  pepForFacematch,
}) => {
  const mobileErrorMsg = MOBILE_MSG;
  const mobileError = fetchError === "Invalid phone" ? mobileErrorMsg : "";
  const firstNameRefs = useRef(null);
  const isPlusCheck = location.pathname.includes("plus");

  const submitHandler = (data, { resetForm, setFieldError }) => {
    if (
      (groupType === TYPE_EMAIL || groupType === TYPE_SMS) &&
      !data.phone &&
      !data.email
    ) {
      setFieldError("email", EMAIL_OR_MOBILE_MSG);
      setFieldError("phone", EMAIL_OR_MOBILE_MSG);
      return;
    }
    if (data.phone && !isValidPhoneNumber(data.phone)) {
      setFieldError("phone", mobileErrorMsg);
      return;
    }
    const queries = qs.parse(location.search);
    addCheckUser(
      { ...data, checkType, runPlusChecks: isPlusCheck },
      queries.ids === "2"
    );

    resetForm();
    if (firstNameRefs.current) {
      firstNameRefs.current.focus();
    }
  };

  const filteredSortedCountryList = React.useMemo(() => {
    if (!sources) return [];

    let filteredCountries = Object.values(SUPPORTED_COUNTRIES)
      .filter(
        (country) =>
          !country.hidden &&
          (!allowedCountries ||
            !allowedCountries.length ||
            allowedCountries.includes(country.code)) &&
          sources[country.code.toLowerCase()] !== NATIONAL_ID_NOT_AVAILABLE
      )
      .map((country) => ({
        value: country.code,
        label: country.description,
      }));

    // Only CA have PLUS check features
    // We can enable it for US soon
    if (isPlusCheck) {
      filteredCountries = filteredCountries.filter((c) =>
        ["CA"].includes(c.value)
      );
    }

    return filteredCountries;
  }, [sources, allowedCountries, isPlusCheck]);

  return (
    <div>
      <h3 className="text-center h6 mb-4">
        Client: {activeCheck + 1} of {totalChecks}
      </h3>
      <Formik
        validationSchema={validationRules({ groupType })}
        onSubmit={submitHandler}
        initialValues={initialValues(
          check,
          groupType,
          isPlusCheck
            ? "CA"
            : checkType === FACEMATCH
            ? null
            : defaultCountryCode,
          checkType === FACEMATCH ? pepForFacematch.includes("YES") : false
        )}
        enableReinitialize={true}
      >
        {({
          handleSubmit,
          errors,
          submitCount,
          setFieldValue,
          values,
          isSubmitting,
          isValidating,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <FormikScrollToErrors
              errors={errors}
              isSubmitting={isSubmitting}
              isValidating={isValidating}
            />
            {![FACEMATCH, QUICK_ID_BANKMATCH].includes(checkType) &&
              !isPlusCheck && (
                <Field
                  name="countryCode"
                  error={errors.countryCode}
                  submitCount={submitCount}
                  label="Where does your client live?"
                  component="select"
                >
                  <option value="">Client to choose</option>
                  {filteredSortedCountryList.map((country) => (
                    <option key={country.value} value={country.value}>
                      {country.label}
                    </option>
                  ))}
                </Field>
              )}

            <IdentityFields
              errors={errors}
              submitCount={submitCount}
              autoComplete={false}
              autoFocusFirstName
              innerFirstNameRef={firstNameRefs}
              showDateOfBirth={false}
              setFieldValue={setFieldValue}
              runUniquenessCheck
            />

            <RcIf if={groupType === TYPE_EMAIL || groupType === TYPE_SMS}>
              <>
                <Field
                  name="email"
                  error={errors.email}
                  submitCount={submitCount}
                  label={`Client ${activeCheck + 1} email address`}
                  type="email"
                />
                <PhoneField
                  name="phone"
                  value={values.phone}
                  setFieldValue={setFieldValue}
                  forceShowErrors={!!mobileError}
                  error={errors.phone || mobileError}
                  submitCount={submitCount}
                  label={`${MOBILE_LABEL} Number`}
                  checkCountryCode={values.countryCode}
                />
              </>
            </RcIf>

            {checkType === FACEMATCH && pepForFacematch !== "NO_REQUIRED" && (
              <div className="mb-4">
                <div className="custom-control custom-checkbox">
                  <FormIkField
                    component="input"
                    type="checkbox"
                    name="runPep"
                    id="runPep"
                    disabled={pepForFacematch.includes("REQUIRED")}
                    className={`custom-control-input ${
                      errors.runPep && submitCount > 0 ? "is-invalid" : ""
                    }`}
                  />
                  <label className="custom-control-label" htmlFor="runPep">
                    + Run {PEP_COPY} Check
                  </label>
                </div>
              </div>
            )}

            <div className="row align-items-center">
              <div className="col-6">
                <Button color="primary" onClick={resetCheck} outline block>
                  Start Again
                </Button>
              </div>
              <div className="col-6">
                <Button
                  color="primary"
                  className="px-5"
                  type="submit"
                  disabled={fetching}
                  block
                >
                  Send
                </Button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

CheckForm.propTypes = {
  addCheckUser: PropTypes.func.isRequired,
  goToPrevCheck: PropTypes.func,
  resetCheck: PropTypes.func.isRequired,
  check: PropTypes.object,
  activeCheck: PropTypes.number.isRequired,
  totalChecks: PropTypes.number.isRequired,
  groupType: PropTypes.string.isRequired,
  fetching: PropTypes.bool.isRequired,
  fetchError: PropTypes.string,
  checkType: PropTypes.string.isRequired,
  defaultCountryCode: PropTypes.string,
  allowedCountries: PropTypes.arrayOf(PropTypes.string),
  pepForFacematch: PropTypes.string.isRequired,
};

CheckForm.defaultProps = {
  check: {
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    type: "",
  },
  groupType: TYPE_EMAIL,
  checkType: QUICK_ID,
};

const mapStateToProps = ({ user }) => ({
  allowedCountries: user.allowedCountries,
  pepForFacematch: user.pepForFacematch,
  sources: user.sources,
});

export default connect(mapStateToProps)(CheckForm);
