import PropTypes from "prop-types";
import RcIf, { RcElse } from "rc-if";
import React, { Component, useState } from "react";
import { toastr } from "react-redux-toastr";
import {
  CardElement,
  Elements,
  StripeProvider,
  injectStripe,
} from "react-stripe-elements";
import Toggle from "react-toggle";
import { Button } from "reactstrap";

class StripeElement extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    stripe: PropTypes.object.isRequired,
    ccBtnText: PropTypes.string,
    baBtnText: PropTypes.string,
  };

  static defaultProps = {
    ccBtnText: "Complete Your Account",
    baBtnText: "Complete Your Account",
  };

  state = {
    btnDisabled: false,
    payViaCard: true,
  };

  handleBillingTypeChange = () =>
    this.setState(({ payViaCard }) => ({ payViaCard: !payViaCard }));
  handleBillingTypeWithValueChange = (value) =>
    this.setState({ payViaCard: value });

  submit = async () => {
    const { onSubmit, stripe } = this.props;
    if (!this.state.payViaCard) {
      onSubmit();
    } else {
      this.setState({ btnDisabled: true });
      let { token, error } = await stripe.createToken();
      this.setState({ btnDisabled: false });
      if (token) {
        onSubmit(token.id);
      } else if (error && error.message) {
        toastr.error("Card Error", error.message);
      } else {
        toastr.error(
          "Card Error",
          "Please check your card details, something is not right."
        );
      }
    }
  };

  render() {
    const { btnDisabled, payViaCard } = this.state;
    const { isSubmitting, ccBtnText, baBtnText } = this.props;

    return (
      <div>
        {/* <div className="form-group">
          <div
            className="d-flex align-items-center"
            role="group"
            aria-label="payment-type"
          >
            <div className="text-sm flex-grow-1">
              <Button
                onClick={() => this.handleBillingTypeWithValueChange(true)}
                color="link"
                block
                className={`text-right p-0 ${payViaCard ? "" : "text-muted"}`}
                type="button"
              >
                Credit Card
              </Button>
            </div>
            <div className="mx-3 text-sm text-right">
              <Toggle
                onChange={this.handleBillingTypeChange}
                checked={!payViaCard}
                icons={false}
                className="always-on"
              />
            </div>
            <div className="text-sm flex-grow-1">
              <Button
                onClick={() => this.handleBillingTypeWithValueChange(false)}
                color="link"
                block
                className={`text-left p-0 ${payViaCard ? "text-muted" : ""}`}
                type="button"
              >
                Monthly Invoice
              </Button>
            </div>
          </div>
        </div> */}

        <h3 className="text-center h4 mb-4">Credit Card</h3>

        <div className="form-group">
          <RcIf if={payViaCard}>
            <div className="stripe-element">
              <CardElement
                hidePostalCode={true}
                style={{
                  base: {
                    color: "#495057",
                    fontFamily:
                      '"Lato", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif"',
                    fontSize: "16px",
                    fontSmoothing: "antialiased",
                    "::placeholder": {
                      color: "#6c757d",
                    },
                  },
                  invalid: {
                    color: "#495057",
                    iconColor: "#d45644",
                  },
                }}
              />
            </div>
            <RcElse>
              <p className="small text-center">
                Monthly invoicing requires a manual review which may take up to
                2 hours.
              </p>
              <p className="small text-center">
                Invoices are sent at the end of the month and payable by the
                20th of the following month.
              </p>
            </RcElse>
          </RcIf>
        </div>

        <Button
          color="primary"
          block
          size="lg"
          onClick={this.submit}
          type="button"
          disabled={btnDisabled || isSubmitting}
        >
          {payViaCard ? ccBtnText : baBtnText}
        </Button>
      </div>
    );
  }
}

const InjectedStripeElement = injectStripe(StripeElement);

const StipeElement = (props) => {
  const [stripe] = useState(window.Stripe(process.env.REACT_APP_STRIPE_KEY));

  return (
    <StripeProvider stripe={stripe}>
      <Elements>
        <InjectedStripeElement {...props} />
      </Elements>
    </StripeProvider>
  );
};

export default StipeElement;
