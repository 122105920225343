import PropTypes from "prop-types";
import React from "react";

const StepLayout = ({ children }) => (
  <div className="container-fluid xs p-0 position-relative">
    <div className="site-form">{children}</div>
  </div>
);

StepLayout.propTypes = {
  children: PropTypes.node,
};

export default StepLayout;
