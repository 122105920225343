import axios from "axios";
import RcIf, { RcElse } from "rc-if";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";

import { AGENCY_USERS_URL } from "../../../api/apiUrls";
import NotFound from "../../../components/errors/NotFound";
import { ERROR_REFRESH_WITH_CONTACT_INFO } from "../../../constants/lang/en";
import logToSentryOrConsole from "../../../helpers/sentry";
import { getDefaultHeaders } from "../../../helpers/token";
import { actions } from "../../../store/slices/user";
import Loading from "../../misc/Loading";
import AgencyUsersPresenter from "./Presenter";

const { fetchUser } = actions;

const AgencyUsers = ({ fetchUser, user }) => {
  const [agencyUsers, setAgencyUsers] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const fetchAgencyUsers = useCallback(async () => {
    try {
      setLoading(true);
      const userResponse = await axios.get(
        AGENCY_USERS_URL,
        getDefaultHeaders()
      );
      setAgencyUsers(userResponse.data);
    } catch (error) {
      toastr.error(ERROR_REFRESH_WITH_CONTACT_INFO);
      logToSentryOrConsole(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const addAgencyUsers = useCallback(
    async ({ username, role }, isCreate = false) => {
      try {
        setLoading(true);
        await axios.post(
          AGENCY_USERS_URL,
          { username, role },
          getDefaultHeaders()
        );
        toastr.success(`User ${isCreate ? "added" : "updated"} successfully.`);
      } catch (error) {
        toastr.error(ERROR_REFRESH_WITH_CONTACT_INFO);
        logToSentryOrConsole(error);
      } finally {
        await fetchAgencyUsers();
        setLoading(false);
      }
    },
    []
  );

  const deleteAgencyUsers = useCallback(async (username) => {
    try {
      setLoading(true);
      await axios.delete(
        `${AGENCY_USERS_URL}${encodeURIComponent(username)}/`,
        getDefaultHeaders()
      );
      toastr.success("User deleted successfully.");
    } catch (error) {
      toastr.error(ERROR_REFRESH_WITH_CONTACT_INFO);
      logToSentryOrConsole(error);
    } finally {
      await fetchAgencyUsers();
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchAgencyUsers();
    fetchUser();
  }, [fetchUser]);

  return (
    <RcIf if={!!user.id}>
      <>
        {user.role === "ADMIN" ? (
          <AgencyUsersPresenter
            userUsername={user.email}
            isLoading={isLoading}
            agencyUsers={agencyUsers}
            addAgencyUsers={addAgencyUsers}
            deleteAgencyUsers={deleteAgencyUsers}
          />
        ) : (
          <NotFound />
        )}
      </>
      <RcElse>
        <Loading />
      </RcElse>
    </RcIf>
  );
};

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = { fetchUser };

export default connect(mapStateToProps, mapDispatchToProps)(AgencyUsers);
