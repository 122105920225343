import queryString from "query-string";

export const setReferrer = () => {
  const { referrer: qsReferrer } = queryString.parse(window.location.search);
  if (qsReferrer) {
    localStorage.setItem("referrer", qsReferrer);
  } else {
    const referrer = document.referrer;
    if (referrer && !referrer.includes(window.location.hostname)) {
      let parsedReferrer = referrer.match(
        // eslint-disable-next-line
        /^https?:\/\/([^\/?#]+)(?:[\/?#]|$)/i,
        ""
      );
      parsedReferrer = parsedReferrer && parsedReferrer[1];
      if (parsedReferrer) {
        localStorage.setItem("referrer", parsedReferrer);
      } else {
        localStorage.setItem("referrer", referrer.replace(/https?:\/\//i, ""));
      }
    }
  }
};

export const getReferrer = () => localStorage.getItem("referrer") || "";
