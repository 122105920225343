import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React from "react";

import {
  NATIONAL_ID_DRIVER_LICENCE,
  NATIONAL_ID_GOVT_TAX_NUMBER,
  NATIONAL_ID_VOTER_ID_NUMBER,
} from "../../../../constants/nationalIdType";
import Field from "../../../form/Field";

const InId = ({ quickIDAlgorithm }) => {
  const { values, errors, submitCount } = useFormikContext();
  const isSecondIdMandatory = quickIDAlgorithm !== "1x1";

  return (
    <>
      <div>
        <Field
          name="nationalIdType"
          error={errors.nationalIdType}
          submitCount={submitCount}
          label="Select Indian document 1"
          component="select"
        >
          <Options excludedType={values.nationalIdSecondaryType || ""} />
        </Field>
        <div style={{ marginTop: "-1rem" }}>
          <Field
            name="nationalId"
            error={errors.nationalId}
            submitCount={submitCount}
            label={
              IN_ID_TYPES[values.nationalIdType]
                ? IN_ID_TYPES[values.nationalIdType].label
                : ""
            }
            placeholder={
              IN_ID_TYPES[values.nationalIdType]
                ? IN_ID_TYPES[values.nationalIdType].placeholder
                : ""
            }
          />
        </div>
      </div>
      {isSecondIdMandatory && (
        <div>
          <Field
            name="nationalIdSecondaryType"
            error={errors.nationalIdSecondaryType}
            submitCount={submitCount}
            label="Select Indian document 2"
            component="select"
          >
            <Options excludedType={values.nationalIdType || ""} />
          </Field>
          <div style={{ marginTop: "-1rem" }}>
            <Field
              name="nationalIdSecondary"
              error={errors.nationalIdSecondary}
              submitCount={submitCount}
              label={
                IN_ID_TYPES[values.nationalIdSecondaryType]
                  ? IN_ID_TYPES[values.nationalIdSecondaryType].label
                  : ""
              }
              placeholder={
                IN_ID_TYPES[values.nationalIdSecondaryType]
                  ? IN_ID_TYPES[values.nationalIdSecondaryType].placeholder
                  : ""
              }
            />
          </div>
        </div>
      )}
    </>
  );
};

InId.propTypes = {
  quickIDAlgorithm: PropTypes.string.isRequired,
};

export default InId;

const Options = ({ excludedType = "" }) => (
  <>
    <option>Select an option</option>
    {Object.keys(IN_ID_TYPES).map(
      (key) =>
        excludedType !== key && (
          <option value={key} key={key}>
            {IN_ID_TYPES[key].label}
          </option>
        )
    )}
  </>
);

const IN_ID_TYPES = {
  [NATIONAL_ID_GOVT_TAX_NUMBER]: {
    label: "Pan Card",
    placeholder: "ABCDE1234F",
  },
  [NATIONAL_ID_DRIVER_LICENCE]: {
    label: "Driver Licence",
    placeholder: "TN7512345678901",
  },
  [NATIONAL_ID_VOTER_ID_NUMBER]: {
    label: "Electoral ID (EPIC)",
    placeholder: "ABC1234567",
  },
};
