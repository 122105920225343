const ROUTES = {
  home: "/",
  signIn: "/sign-in",
  signUp: "/sign-up",
  signUpOld: "/sign-up-4d90a966",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password",
  accountUnlock: "/unlock-account",
  verifyEmail: "/verify-email",
  checks: "/checks",
  pep: "/checks/pep",
  quickId: "/checks/quick-id",
  quickIdFaceMatch: "/checks/quick-id-face-match",
  faceMatchQuickId: "/checks/face-match-quick-id",
  quickIdBankMatch: "/checks/quick-id-bank-match",
  faceMatch: "/checks/face-match",
  entityChecks: "/checks/company",
  outSourced: "/checks/outsourced",
  checksExternal: "/checks/external",
  partialAndFailedChecks: "/checks/partial-and-failed",
  checksHistory: "/checks/history",
  outSourcedHistory: "/outsourced/history",
  compliance: "/compliance",
  marketplace: "/marketplace",
  checksDownload: "/checks/download/:signatureKey",
  outsourcedChecksDownload: "/outsourced-checks/download/:signatureKey",
  checksAuditTrailDownload: "/checks/download-audit-trail/:signatureKey",
  outsourcedChecksAuditTrailDownload:
    "/outsourced-checks/download-audit-trail/:signatureKey",
  filesDownload: "/files/download/:signatureKey",
  checksVideo1Download: "/checks/download/:signatureKey/liveness-video1",
  checksVideo2Download: "/checks/download/:signatureKey/liveness-video2",
  profile: "/profile",
  settings: "/settings",
  agencyUsers: "/users",
  changePassword: "/change-password",
  billing: "/billing",
  verify: "/verify/:signatureKey",
  verificationCancelled: "/verification-cancelled",
  verificationExpired: "/verification-expired",
  integrationActionStep: "/integrations/action-step",
  integrationZapierAuthorize: "/integrations/zapier/oauth-authorize/",
  notFound: "/not-found",
  serverError: "/server-error",
};

export default ROUTES;
