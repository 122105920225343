import React, { useMemo } from "react";

import { getBgColor } from "../../helpers/color";

const OutSourced = ({ type }) => {
  const bgColor = useMemo(() => getBgColor(type), [type]);
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 48.91 48.91"
      xmlSpace="preserve"
      className={`text-${type || "primary"} outsourced-icon`}
      width={43}
      height={43}
    >
      <circle
        cx={25}
        cy={24.73}
        r={24.45}
        fill={bgColor}
        transform="rotate(-13.81 23.6 26.863)"
      />
      <g fill="currentColor">
        <path d="M31.16 35.64a2.38 2.38 0 0 1-1.42-.46l-5.29-3.81-5.27 3.81a2.44 2.44 0 0 1-3.86-2v-17a2.43 2.43 0 0 1 2.43-2.42h13.4a2.44 2.44 0 0 1 2.44 2.44v17a2.43 2.43 0 0 1-2.43 2.43Zm-6.71-6.24 6.21 4.48a.83.83 0 0 0 1.32-.67v-17a.84.84 0 0 0-.84-.84H17.76a.83.83 0 0 0-.83.82v17a.84.84 0 0 0 1.33.68Z" />
        <path d="M28.5 19.72h-8.25a.8.8 0 1 1 0-1.6h8.25a.8.8 0 0 1 0 1.6Z" />
      </g>
    </svg>
  );
};

export default OutSourced;
