import axios from "axios";
import Cookies from "js-cookie";

import { getDefaultHeaders } from "../helpers/token";
import {
  SESSION_URL,
  USER_PASSWORD_URL,
  USER_UNLOCK_URL,
  USER_URL,
} from "./apiUrls";

const signIn = async ({ username, password, code }, redirectTo) => {
  var response = null;
  var responseCa = null;

  try {
    response = await axios.post(SESSION_URL, { username, password, code });
  } catch (error) {
    // Got 401 and no remaining_login_attempt so hit CA servers
    if (
      window.location.href.startsWith(process.env.REACT_APP_PROD_SITE_URL) &&
      error &&
      error.response &&
      error.response.status === 401 &&
      (!error.response.data ||
        typeof error.response.data.remaining_login_attempt != "number")
    ) {
      try {
        responseCa = await axios.post(
          `${process.env.REACT_APP_PROD_CA_API_URL}/session/`,
          { username, password, code }
        );
        if (!responseCa || !responseCa.data) {
          return responseCa;
        }
        Cookies.set(
          "post_redirect",
          JSON.stringify({
            accessToken: responseCa.data.access_token,
            refreshToken: responseCa.data.refresh_token,
            redirectTo,
          }),
          {
            secure: true,
            domain: ".realaml.com",
          }
        );
        window.location.replace(
          `${process.env.REACT_APP_PROD_CA_SITE_URL}/sign-in`
        );
        await new Promise((resolve) => setTimeout(resolve, 2000));
      } catch (errorCa) {
        // 2FA enabled so return CA response
        if (
          errorCa &&
          errorCa.response &&
          [400, 401].includes(errorCa.response.status)
        ) {
          throw errorCa;
        }
      }
    }

    throw error;
  }

  return response;
};

const signUp = async ({ username, email, agencyName, referrer = "" }) =>
  await axios.post(USER_URL, {
    username,
    email,
    agency_set: [
      {
        name: agencyName,
        referrer,
      },
    ],
  });

const forgotPassword = async ({ username }) =>
  axios.post(USER_PASSWORD_URL, { username });

const checkResetPassword = async ({ token }) =>
  axios.get(`${USER_PASSWORD_URL}?token=${token}`);

const resetPassword = async ({ token, password }) =>
  await axios.put(USER_PASSWORD_URL, { key: token, password });

const checkAccountUnlock = async ({ token }) =>
  axios.get(`${USER_UNLOCK_URL}?token=${token}`);

const accountUnlock = async ({ token, password }) =>
  await axios.put(USER_UNLOCK_URL, { key: token, password });

const checkSession = async () => await axios.get(USER_URL, getDefaultHeaders());

const apis = {
  signIn,
  signUp,
  forgotPassword,
  checkResetPassword,
  resetPassword,
  checkSession,
  checkAccountUnlock,
  accountUnlock,
};

export default apis;
