import React, { useMemo } from "react";

import { getBgColor } from "../../helpers/color";

const FaceMatch = ({ type, width = 40, height = 40 }) => {
  const bgColor = useMemo(() => getBgColor(type), [type]);
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 48.91 48.91"
      xmlSpace="preserve"
      className={`text-${type || "primary"}`}
      width={width}
      height={height}
    >
      <circle cx={24.45} cy={24.45} r={24.45} fill={bgColor} />
      <g fill="currentColor">
        <path d="M24.34 36.1a11.72 11.72 0 1 1 11.72-11.72A11.74 11.74 0 0 1 24.34 36.1Zm0-21.84a10.12 10.12 0 1 0 10.11 10.12 10.13 10.13 0 0 0-10.11-10.12Z" />
        <path d="M23.05 27.78a.77.77 0 0 1-.56-.24l-2.41-2.4a.8.8 0 0 1 1.13-1.13l2.41 2.44a.81.81 0 0 1-.57 1.37Z" />
        <path d="M23.19 27.68a.77.77 0 0 1-.56-.24.79.79 0 0 1 0-1.13l4.82-4.86a.81.81 0 0 1 1.14 1.14l-4.83 4.86a.8.8 0 0 1-.57.23Z" />
      </g>
    </svg>
  );
};

export default FaceMatch;
