import React, { useEffect } from "react";
import { connect } from "react-redux";

import { actions } from "../../store/slices/user";
import ChangePasswordPresenter from "./Presenters/ChangePassword";

const { fetchUser, changeUserPassword } = actions;

const ChangePassword = ({ fetchUser, changeUserPassword, user }) => {
  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  return (
    <ChangePasswordPresenter
      {...user}
      changeUserPassword={changeUserPassword}
    />
  );
};

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = { fetchUser, changeUserPassword };

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
