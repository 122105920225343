import { createAction, createReducer } from "@reduxjs/toolkit";

import BillingApi from "../../api/billing";
import { triggerNewPaymentEvent } from "../../helpers/intercom";
import logToSentryOrConsole from "../../helpers/sentry";
import { actions as userActions } from "./user";

const { fetchUser } = userActions;

const INITIAL_STATE = {
  fetching: false,
  fetchError: "",
  fetchCompleted: false,
  card: {
    last4: "",
    type: "",
    expirationDate: "",
  },
  upcomingInvoice: {
    number: "",
    amountRemaining: 0,
    dueDate: 0,
    nextPaymentAttempt: 0,
  },
  paymentType: null,
  fetchedPayment: false,
  invoices: [],
  showBillingModal: false,
};

const fetchBillingStarted = createAction("fetchBillingStarted");
const fetchBillingCompleted = createAction("fetchBillingCompleted");
const fetchBillingFailed = createAction("fetchBillingFailed");
const setCreditCardInfo = createAction("setCreditCardInfo");
const setUpcomingInvoiceInfo = createAction("setUpcomingInvoiceInfo");
const setPaymentType = createAction("setPaymentType");
const setInvoices = createAction("setInvoices");
const handleShowPaymentModal = createAction("handleShowPaymentModal");

const fetchCreditCard = () => async (dispatch) => {
  dispatch(fetchBillingStarted());
  try {
    const result = await BillingApi.fetchCreditCard();

    if (!result || !result.data) {
      return;
    }

    dispatch(setCreditCardInfo(result.data));
    dispatch(fetchBillingCompleted());
  } catch (error) {
    if (error && error.response && error.response.status === 404) {
      dispatch(setPaymentType(null));
      dispatch(fetchBillingFailed());
    } else {
      logToSentryOrConsole(error);
    }
  }
};

const addCreditCard = ({ stripeTokenId }) => async (dispatch) => {
  dispatch(fetchBillingStarted());
  try {
    const result = await BillingApi.addCreditCard({
      stripeToken: stripeTokenId,
    });

    if (!result || !result.data) {
      return;
    }

    const data = result.data;
    triggerNewPaymentEvent({
      paymentType: data.card_last4 ? "CARD" : "INVOICE",
    });
    dispatch(fetchUser());
    dispatch(setCreditCardInfo(data));
    dispatch(fetchBillingCompleted());
    dispatch(handleShowPaymentModal(false));
  } catch (error) {
    logToSentryOrConsole(error);
  }
};

const fetchUpcomingInvoice = () => async (dispatch) => {
  dispatch(fetchBillingStarted());
  try {
    const result = await BillingApi.fetchUpcomingInvoice();

    if (!result || !result.data) {
      return;
    }

    dispatch(setUpcomingInvoiceInfo(result.data));
    dispatch(fetchBillingCompleted());
  } catch (error) {
    logToSentryOrConsole(error);
  }
};

const fetchInvoices = () => async (dispatch) => {
  dispatch(fetchBillingStarted());
  try {
    const result = await BillingApi.fetchInvoices();

    if (!result || !result.data) {
      return;
    }

    dispatch(setInvoices(result.data));
    dispatch(fetchBillingCompleted());
  } catch (error) {
    logToSentryOrConsole(error);
  }
};

const setShowPaymentModal = (state) => (dispatch) => {
  dispatch(handleShowPaymentModal(state));
};

export const actions = {
  fetchCreditCard,
  addCreditCard,
  fetchUpcomingInvoice,
  fetchInvoices,
  setShowPaymentModal,
};

export const reducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(fetchBillingStarted, (state) => {
      state.fetching = true;
      state.fetchError = "";
      state.fetchCompleted = false;
    })
    .addCase(fetchBillingCompleted, (state) => {
      state.fetching = false;
      state.fetchCompleted = true;
    })
    .addCase(fetchBillingFailed, (state, { payload }) => {
      state.fetching = false;
      state.fetchError = payload;
    })
    .addCase(setCreditCardInfo, (state, { payload }) => {
      const { card_type, card_last4, expiration } = payload;
      if (card_last4) {
        state.card.type = card_type;
        state.card.last4 = card_last4;
        state.card.expirationDate = expiration;
        state.paymentType = "CARD";
      } else {
        state.paymentType = "INVOICE";
      }
      state.fetchedPayment = true;
    })
    .addCase(setUpcomingInvoiceInfo, (state, { payload }) => {
      const {
        number = "",
        amount_remaining = 0,
        due_date = 0,
        next_payment_attempt = 0,
      } = payload;
      state.upcomingInvoice.number = number;
      state.upcomingInvoice.amountRemaining = amount_remaining / 100;
      state.upcomingInvoice.dueDate = due_date * 1000;
      state.upcomingInvoice.nextPaymentAttempt = next_payment_attempt * 1000;
    })
    .addCase(setPaymentType, (state, { payload }) => {
      state.paymentType = payload;
      state.fetchedPayment = true;
    })
    .addCase(setInvoices, (state, { payload }) => {
      state.invoices = payload;
    })
    .addCase(handleShowPaymentModal, (state, { payload }) => {
      state.showPaymentModal = payload;
    });
});
