import { Formik } from "formik";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import * as Yup from "yup";

import Field from "../../form/Field";
import FormikScrollToErrors from "../../misc/FormikScrollToError";

const VALIDATION_RULES = Yup.object().shape({
  reference: Yup.string(),
  notes: Yup.string(),
});

const ReferenceNotesUpdateModal = ({
  setReferenceNotesUpdateModalOpened,
  reference,
  notes,
  fetching,
  updateCheckGroup,
  verificationGroupId,
  defaultCountryCode,
}) => {
  const handleSubmit = (data) => {
    updateCheckGroup({ ...data, id: verificationGroupId });
    setReferenceNotesUpdateModalOpened(false);
  };
  const isCa = defaultCountryCode === "CA";

  return (
    <Modal
      isOpen={true}
      toggle={() => setReferenceNotesUpdateModalOpened((s) => !s)}
    >
      <ModalHeader
        toggle={() => setReferenceNotesUpdateModalOpened((s) => !s)}
      />
      <ModalBody>
        <p className="h5 text-center mb-3">Edit Check</p>
        <Formik
          validationSchema={VALIDATION_RULES}
          onSubmit={handleSubmit}
          enableReinitialize={true}
          initialValues={{ reference, notes }}
        >
          {({
            handleSubmit,
            errors,
            submitCount,
            isSubmitting,
            isValidating,
          }) => (
            <form noValidate onSubmit={handleSubmit} className="mt-5">
              <FormikScrollToErrors
                errors={errors}
                isSubmitting={isSubmitting}
                isValidating={isValidating}
              />
              <Field
                name="reference"
                error={errors.reference}
                submitCount={submitCount}
                autoFocus
                label={`${
                  isCa ? "File/Client/Matter Number" : "Reference"
                } (optional)`}
                placeholder={isCa ? "" : "e.g. File Number or Tag."}
              />
              <Field
                name="notes"
                error={errors.notes}
                submitCount={submitCount}
                component="textarea"
                label={`${isCa ? "Matter Description" : "Notes"} (optional)`}
                placeholder={
                  isCa
                    ? "This will only appear on PDF reports and in the Check History"
                    : "Your notes will only appear on PDF reports and in the Check History."
                }
              />
              <div className="text-center">
                <Button
                  color="primary"
                  className="px-5 mt-3"
                  type="submit"
                  disabled={fetching}
                  block
                >
                  Save
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

ReferenceNotesUpdateModal.propTypes = {
  setReferenceNotesUpdateModalOpened: PropTypes.func.isRequired,
  reference: PropTypes.string.isRequired,
  notes: PropTypes.string.isRequired,
  fetching: PropTypes.bool.isRequired,
  updateCheckGroup: PropTypes.func.isRequired,
  verificationGroupId: PropTypes.number.isRequired,
};

const mapStateToProps = ({ user: { defaultCountryCode } }) => ({
  defaultCountryCode,
});

export default connect(mapStateToProps)(ReferenceNotesUpdateModal);
