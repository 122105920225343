import { Formik } from "formik";
import PropTypes from "prop-types";
import RcIf, { RcElse } from "rc-if";
import React, { useState } from "react";
import { useCallback } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import * as Yup from "yup";

import {
  PASSWORD_MSG,
  REQUIRED_MSG,
} from "../../../constants/validationHelpers";
import { PASSWORD_REGEX } from "../../../constants/validationRegex";
import PasswordField from "../../form/PasswordField";
import StripeElement from "../../form/StipeElement";
import FormikScrollToErrors from "../../misc/FormikScrollToError";
import Pricing from "../../misc/Pricing";

const getValidationRules = ({ passwordInComplete }) => {
  const validations = {};
  if (passwordInComplete) {
    validations.agentPassword = Yup.string()
      .required(REQUIRED_MSG)
      .matches(PASSWORD_REGEX, PASSWORD_MSG);
  }
  return Yup.object().shape(validations);
};

const getInitialValues = ({ passwordInComplete }) => {
  const values = {};
  if (passwordInComplete) {
    values.agentPassword = "";
  }
  return values;
};

const ProfileCompleteForm = ({
  fetching,
  completeProfile,
  handleSetInCompleteProfile,
  sendEmailVerificationToken,
  showPricing,
  paymentInComplete,
  passwordInComplete,
  emailNotVerified,
  defaultCountryCode,
}) => {
  const [emailVerifyClicked, setEmailVerifyClicked] = useState(false);
  const [stripeTokenId, setStripeTokenId] = useState("");
  const [showPaymentModal, setShowPaymentModal] = useState(
    paymentInComplete || passwordInComplete || emailNotVerified
  );

  React.useEffect(() => {
    setShowPaymentModal(
      paymentInComplete || passwordInComplete || emailNotVerified
    );
  }, [
    paymentInComplete,
    passwordInComplete,
    emailNotVerified,
    setShowPaymentModal,
  ]);

  const verifyEmail = useCallback(() => {
    sendEmailVerificationToken();
    setEmailVerifyClicked(true);
  });

  return (
    <Modal isOpen={showPaymentModal} backdrop="static" size="xl">
      <ModalHeader
        toggle={() => {
          setShowPaymentModal(!showPaymentModal);
          handleSetInCompleteProfile({});
        }}
      />
      <ModalBody>
        <div className="row">
          <div className={showPricing ? "col-xl-6" : "col-12"}>
            <h2 className="h3 text-center mb-3">Instantly Run Your Check</h2>
            <div className="site-form mb-3">
              {emailNotVerified && (
                <h3 className="h6 text-center mb-3">
                  {emailVerifyClicked ? (
                    <>
                      Please close this popup after your email is successfully
                      verified.
                    </>
                  ) : (
                    <>
                      Email verification failed. Retry using the button below or
                      contact{" "}
                      <a href="mailto:support@realaml.com" target="_blank">
                        support@realaml.com
                      </a>
                      .
                      <br />
                      <button
                        className="btn btn-sm btn-outline-primary mt-2"
                        onClick={verifyEmail}
                      >
                        Verify Email
                      </button>
                    </>
                  )}
                </h3>
              )}
              {(passwordInComplete || paymentInComplete) && (
                <Formik
                  validationSchema={getValidationRules({
                    passwordInComplete,
                  })}
                  onSubmit={(data) =>
                    completeProfile({ ...data, stripeTokenId })
                  }
                  initialValues={getInitialValues({
                    passwordInComplete,
                  })}
                >
                  {({
                    handleSubmit,
                    errors,
                    submitCount,
                    isSubmitting,
                    isValidating,
                  }) => (
                    <form noValidate onSubmit={(e) => e.preventDefault()}>
                      <FormikScrollToErrors
                        errors={errors}
                        isSubmitting={isSubmitting}
                        isValidating={isValidating}
                      />
                      <RcIf if={passwordInComplete}>
                        <PasswordField
                          name="agentPassword"
                          error={errors.agentPassword}
                          submitCount={submitCount}
                          label="Create Password"
                          autoComplete="new-password"
                        />
                      </RcIf>

                      <RcIf if={paymentInComplete}>
                        <>
                          <div className="checkout">
                            <StripeElement
                              onSubmit={(token) => {
                                setStripeTokenId(token);
                                handleSubmit();
                              }}
                              isSubmitting={fetching}
                              ccBtnText="Complete Your Profile"
                              baBtnText="Complete Your Profile"
                            />
                          </div>
                        </>
                        <RcElse>
                          <Button
                            color="primary"
                            block
                            size="lg"
                            type="button"
                            disabled={fetching}
                            className="mb-4"
                            onClick={handleSubmit}
                          >
                            Complete Your Profile
                          </Button>

                          {paymentInComplete && (
                            <>
                              <hr className="mt-4" />
                              <p className="text-center small">
                                By adding a payment method I agree to the{" "}
                                <a
                                  href="https://realaml.com/terms-and-conditions/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Terms &amp; Conditions
                                </a>{" "}
                                and{" "}
                                <a
                                  href="https://realaml.com/privacy-policy/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Privacy Policy
                                </a>
                              </p>
                            </>
                          )}
                        </RcElse>
                      </RcIf>
                    </form>
                  )}
                </Formik>
              )}
              <p className="text-center mb-0 mt-2">
                Call us 7 days a week{" "}
                {defaultCountryCode === "CA" ? (
                  <a href="tel:+12267410268" className="link-inverse">
                    <b>+1 226 741 0268</b>
                  </a>
                ) : (
                  <a href="tel:+6448894788" className="link-inverse">
                    <b>+64 4 889 4788</b>
                  </a>
                )}
              </p>
            </div>
          </div>
          <RcIf if={showPricing}>
            <div className="col-xl-6">
              <Pricing mini={true} />
            </div>
          </RcIf>
        </div>
      </ModalBody>
    </Modal>
  );
};

ProfileCompleteForm.propTypes = {
  showPricing: PropTypes.bool,
  completeProfile: PropTypes.func.isRequired,
  handleSetInCompleteProfile: PropTypes.func.isRequired,
  sendEmailVerificationToken: PropTypes.func.isRequired,
  fetching: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  paymentInComplete: PropTypes.bool.isRequired,
  passwordInComplete: PropTypes.bool.isRequired,
  emailNotVerified: PropTypes.bool.isRequired,
  defaultCountryCode: PropTypes.string.isRequired,
};

ProfileCompleteForm.defaultProps = {
  heading: "Instantly Run Your Check",
  showPricing: true,
};

export default ProfileCompleteForm;
