import React from "react";
import { FaSpinner } from "react-icons/fa";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { FACEMATCH_QUICK_ID, PEP_COPY } from "../../../constants/checkTypes";
import routes from "../../../routes";
import { actions as checksActions } from "../../../store/slices/check";
import DashboardLayout from "../../dashboard/Presenters/Layout";
import CustomFormIcon from "../../icons/CustomFormIcon";
import FaceIDVIcon from "../../icons/FaceIDVIcon";
import FaceMatchIcon from "../../icons/FaceMatchIcon";
import PepIcon from "../../icons/PepIcon";
import QuickIDIcon from "../../icons/QuickIDIcon";
import StepHeader from "../../misc/StepHeader";
import StepLayout from "../../misc/StepLayout";

const { resetCheck } = checksActions;

const Checks = ({
  resetCheck,
  defaultQuickIDFacematchCombo,
  defaultCountryCode,
  industry,
  dashboardMenus,
  dashboardMenusExternalLinksTop,
  dashboardMenusExternalLinksBottom,
  sources,
  quickIdvPricing,
  faceIdvPricing,
  facematchPricing,
  pepPricing,
  pepForFacematch,
}) => {
  React.useEffect(resetCheck, [resetCheck]);
  const caSource = React.useMemo(
    () => (!sources ? null : sources.ca),
    [sources]
  );

  return (
    <DashboardLayout>
      <StepLayout>
        {dashboardMenus.length ? (
          <>
            <StepHeader
              text={
                defaultCountryCode === "CA"
                  ? `Select a ${
                      industry === "Legal" ? "Law Society" : "FINTRAC"
                    } Compliant Check`
                  : "Select a check type"
              }
              className="mb-3"
            />

            {!dashboardMenusExternalLinksTop.length &&
              !dashboardMenusExternalLinksBottom.length &&
              window.location.href.startsWith(
                process.env.REACT_APP_PROD_CA_SITE_URL
              ) && (
                <div className="d-box-container">
                  <DBoxLinkContent
                    link="https://realamlnz.wistia.com/medias/0tlx2iwzim"
                    title="Client Intake Form"
                    description="Watch how Realaml's Client Intake Form automatically triggers your preferred identity verification."
                    type="OPEN"
                    position="TOP"
                    newBadge
                  />
                </div>
              )}

            {!!dashboardMenusExternalLinksTop.length && (
              <>
                {[
                  ...Array(
                    Math.ceil(dashboardMenusExternalLinksTop.length / 2)
                  ),
                ]
                  .map((_, i) =>
                    dashboardMenusExternalLinksTop.slice(2 * i, 2 + 2 * i)
                  )
                  .map((chunk) => (
                    <div className="d-box-container">
                      {chunk.map((externalLinks) => (
                        <DBoxLinkContent
                          link={externalLinks.link}
                          title={externalLinks.title}
                          description={externalLinks.description}
                          type={externalLinks.type}
                          position={externalLinks.position}
                        />
                      ))}
                    </div>
                  ))}
              </>
            )}
            {dashboardMenus.includes("FM_QID") && (
              <div className="d-box-container">
                {defaultQuickIDFacematchCombo === FACEMATCH_QUICK_ID ? (
                  <div className="d-box-wrap mt-3">
                    <DBox
                      pepForFacematch={pepForFacematch}
                      quickIdvPricing={quickIdvPricing}
                      faceIdvPricing={faceIdvPricing}
                      facematchPricing={facematchPricing}
                      pepPricing={pepPricing}
                      defaultCountryCode={defaultCountryCode}
                      type="FaceMatchQuickID"
                    />
                  </div>
                ) : (
                  <div className="d-box-wrap mt-3">
                    <DBox
                      pepForFacematch={pepForFacematch}
                      quickIdvPricing={quickIdvPricing}
                      faceIdvPricing={faceIdvPricing}
                      facematchPricing={facematchPricing}
                      pepPricing={pepPricing}
                      defaultCountryCode={defaultCountryCode}
                      type="QuickIDFaceMatch"
                    />
                  </div>
                )}
              </div>
            )}
            <div className="d-box-container">
              {dashboardMenus.includes("QID") && (
                <div className="d-box-wrap mt-3">
                  <DBox
                    pepForFacematch={pepForFacematch}
                    quickIdvPricing={quickIdvPricing}
                    faceIdvPricing={faceIdvPricing}
                    facematchPricing={facematchPricing}
                    pepPricing={pepPricing}
                    defaultCountryCode={defaultCountryCode}
                    type="QuickID"
                  />
                </div>
              )}
              {dashboardMenus.includes("FM") && (
                <div className="d-box-wrap mt-3">
                  <DBox
                    pepForFacematch={pepForFacematch}
                    quickIdvPricing={quickIdvPricing}
                    faceIdvPricing={faceIdvPricing}
                    facematchPricing={facematchPricing}
                    pepPricing={pepPricing}
                    defaultCountryCode={defaultCountryCode}
                    type="FaceMatch"
                  />
                </div>
              )}
              {dashboardMenus.includes("PEP") && (
                <div className="d-box-wrap mt-3">
                  <DBox
                    pepForFacematch={pepForFacematch}
                    quickIdvPricing={quickIdvPricing}
                    faceIdvPricing={faceIdvPricing}
                    facematchPricing={facematchPricing}
                    pepPricing={pepPricing}
                    defaultCountryCode={defaultCountryCode}
                    type="Pep"
                  />
                </div>
              )}
            </div>
            {!!dashboardMenusExternalLinksBottom.length && (
              <>
                {[
                  ...Array(
                    Math.ceil(dashboardMenusExternalLinksBottom.length / 2)
                  ),
                ]
                  .map((_, i) =>
                    dashboardMenusExternalLinksBottom.slice(2 * i, 2 + 2 * i)
                  )
                  .map((chunk) => (
                    <div className="d-box-container">
                      {chunk.map((externalLinks) => (
                        <DBoxLinkContent
                          link={externalLinks.link}
                          title={externalLinks.title}
                          description={externalLinks.description}
                          type={externalLinks.type}
                          position={externalLinks.position}
                        />
                      ))}
                    </div>
                  ))}
              </>
            )}
          </>
        ) : (
          <div className="text-center my-4">
            <FaSpinner size="32" className="spin text-secondary" />
          </div>
        )}
      </StepLayout>
    </DashboardLayout>
  );
};

const mapStateToProps = ({ user }) => ({
  defaultQuickIDFacematchCombo: user.defaultQuickIDFacematchCombo,
  defaultCountryCode: user.defaultCountryCode,
  dashboardMenus: user.dashboardMenus,
  dashboardMenusExternalLinksTop: user.dashboardMenusExternalLinksTop,
  dashboardMenusExternalLinksBottom: user.dashboardMenusExternalLinksBottom,
  sources: user.sources,
  quickIdvPricing: user.quickIdvPricing,
  faceIdvPricing: user.faceIdvPricing,
  facematchPricing: user.facematchPricing,
  pepPricing: user.pepPricing,
  pepForFacematch: user.pepForFacematch,
  industry: user.industry,
});
const mapDispatchToProps = { resetCheck };

export default connect(mapStateToProps, mapDispatchToProps)(Checks);

const DBox = ({
  type,
  defaultCountryCode,
  quickIdvPricing,
  faceIdvPricing,
  facematchPricing,
  pepPricing,
  pepForFacematch,
}) => {
  const [active, setActive] = React.useState(false);

  return (
    <div className={`d-box ${active ? "active" : ""}`}>
      {type === "Pep" ? (
        <Link className="d-box_content" to={routes.pep}>
          <DBoxContent
            pepForFacematch={pepForFacematch}
            quickIdvPricing={quickIdvPricing}
            faceIdvPricing={faceIdvPricing}
            facematchPricing={facematchPricing}
            pepPricing={pepPricing}
            defaultCountryCode={defaultCountryCode}
            type={type}
          />
        </Link>
      ) : type === "FaceMatch" ? (
        <Link className="d-box_content" to={`${routes.faceMatch}?type=EMAIL`}>
          <DBoxContent
            pepForFacematch={pepForFacematch}
            quickIdvPricing={quickIdvPricing}
            faceIdvPricing={faceIdvPricing}
            facematchPricing={facematchPricing}
            pepPricing={pepPricing}
            defaultCountryCode={defaultCountryCode}
            type={type}
          />
        </Link>
      ) : defaultCountryCode === "CA" && type === "QuickID" ? (
        <Link to={`${routes.quickId}?type=SELF`} className="d-box_content">
          <DBoxContent
            pepForFacematch={pepForFacematch}
            quickIdvPricing={quickIdvPricing}
            faceIdvPricing={faceIdvPricing}
            facematchPricing={facematchPricing}
            pepPricing={pepPricing}
            defaultCountryCode={defaultCountryCode}
            type={type}
          />
        </Link>
      ) : defaultCountryCode === "CA" && type === "FaceMatchQuickID" ? (
        <Link
          to={`${routes.faceMatchQuickId}?type=EMAIL`}
          className="d-box_content"
        >
          <DBoxContent
            pepForFacematch={pepForFacematch}
            quickIdvPricing={quickIdvPricing}
            faceIdvPricing={faceIdvPricing}
            facematchPricing={facematchPricing}
            pepPricing={pepPricing}
            defaultCountryCode={defaultCountryCode}
            type={type}
          />
        </Link>
      ) : (
        <>
          <button className="d-box_content" onClick={() => setActive(true)}>
            <DBoxContent
              pepForFacematch={pepForFacematch}
              quickIdvPricing={quickIdvPricing}
              faceIdvPricing={faceIdvPricing}
              facematchPricing={facematchPricing}
              pepPricing={pepPricing}
              defaultCountryCode={defaultCountryCode}
              type={type}
            />
          </button>
          <div className="d-box_hover">
            <div className="d-box_hover-header">
              {type === "QuickIDFaceMatch" ? (
                <>Face IDV</>
              ) : type === "FaceMatchQuickID" ? (
                <>Face IDV</>
              ) : (
                <>Quick IDV</>
              )}
            </div>
            <div className="d-box_hover-label">Select an option below</div>
            <nav>
              <ul className="list-unstyled">
                {type === "QuickIDFaceMatch" && (
                  <>
                    {defaultCountryCode !== "CA" && (
                      <li>
                        <Link
                          to={`${routes.quickIdFaceMatch}?type=SELF`}
                          className="d-box_hover-link"
                        >
                          I'll <span className="text-primary">RUN</span> the
                          Quick IDV myself &amp;{" "}
                          <span className="text-primary">SEND</span> the
                          FaceMatch to my client(s)
                        </Link>
                      </li>
                    )}
                    <li>
                      <Link
                        to={`${routes.quickIdFaceMatch}?type=EMAIL`}
                        className="d-box_hover-link"
                      >
                        I'll <span className="text-primary">SEND</span> both
                        checks to my client(s)
                      </Link>
                    </li>
                  </>
                )}
                {type === "FaceMatchQuickID" && (
                  <>
                    {defaultCountryCode !== "CA" && (
                      <li>
                        <Link
                          to={`${routes.quickIdFaceMatch}?type=SELF`}
                          className="d-box_hover-link"
                        >
                          I'll <span className="text-primary">RUN</span> the
                          Quick IDV myself &amp;{" "}
                          <span className="text-primary">SEND</span> the
                          FaceMatch to my client(s)
                        </Link>
                      </li>
                    )}
                    <li>
                      <Link
                        to={`${routes.faceMatchQuickId}?type=EMAIL`}
                        className="d-box_hover-link"
                      >
                        I'll <span className="text-primary">SEND</span> both
                        checks to my client(s)
                      </Link>
                    </li>
                  </>
                )}
                {type === "QuickID" && (
                  <>
                    <li>
                      <Link
                        to={`${routes.quickId}?type=SELF`}
                        className="d-box_hover-link"
                      >
                        I'll <span className="text-primary">RUN</span> myself
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`${routes.quickId}?type=EMAIL`}
                        className="d-box_hover-link"
                      >
                        I'll <span className="text-primary">SEND</span> to my
                        client(s)
                      </Link>
                    </li>
                  </>
                )}
              </ul>
            </nav>
          </div>
        </>
      )}
    </div>
  );
};

const DBoxContent = ({
  type,
  defaultCountryCode,
  pepForFacematch,
  quickIdvPricing,
  faceIdvPricing,
  facematchPricing,
  pepPricing,
}) => (
  <>
    <div className="d-box_icon">
      {type === "QuickIDFaceMatch" ? (
        <>
          <FaceIDVIcon width={50} height={50} />
        </>
      ) : type === "FaceMatchQuickID" ? (
        <>
          <FaceIDVIcon width={50} height={50} />
        </>
      ) : // ) : type === "QuickIDBankMatch" ? (
      //   <div>
      //     <span className="position-relative" style={{ left: "-44px" }}>
      //       <QuickIDIcon width={50} height={50} />
      //     </span>
      //     <span className="position-relative" style={{ left: "32px" }}>
      //       <BankMatchIcon width={50} height={50} />
      //     </span>
      //   </div>
      type === "FaceMatch" ? (
        <FaceMatchIcon width={50} height={50} />
      ) : type === "QuickID" ? (
        <QuickIDIcon width={50} height={50} />
      ) : (
        <PepIcon width={50} height={50} />
      )}
    </div>
    <h5 className="d-box_title">
      {type === "QuickIDFaceMatch"
        ? "Face IDV"
        : type === "FaceMatchQuickID"
        ? "Face IDV"
        : type === "FaceMatch"
        ? "FaceMatch"
        : type === "QuickID"
        ? "Quick IDV"
        : `${PEP_COPY} Check`}
    </h5>
    <p className="d-box_copy">
      {["QuickIDFaceMatch", "FaceMatchQuickID"].includes(type) ? (
        <>
          {defaultCountryCode === "CA" ? (
            <>
              Dual Process or Credit File Method
              <br />+ Biometric Facial Recognition
              {/* <br />
              <span className="text-muted text-xs">{faceIdvPricing}</span> */}
            </>
          ) : (
            <>
              Verify name, DOB, address, {PEP_COPY} status
              <br />+ biometric facial recognition
            </>
          )}
        </>
      ) : type === "FaceMatch" ? (
        <>
          {defaultCountryCode === "CA" ? (
            <>
              Biometric Facial Recognition
              {/* <br />
              <span className="text-muted text-xs">{facematchPricing}</span> */}
            </>
          ) : (
            <>Biometric facial recognition</>
          )}
        </>
      ) : type === "QuickID" ? (
        <>
          {defaultCountryCode === "CA" ? (
            <>
              Dual Process or Credit File Method
              {/* <br />
              <span className="text-muted text-xs">{quickIdvPricing}</span> */}
            </>
          ) : (
            <>
              Verify name, DOB, address
              <br />
              &amp; {PEP_COPY} status
            </>
          )}
        </>
      ) : (
        <>Verify {PEP_COPY} status</>
      )}
    </p>
  </>
);

const DBoxLinkContent = ({
  link,
  title,
  type,
  description,
  position,
  newBadge,
}) => {
  const [active, setActive] = React.useState(false);
  return (
    <div className={`d-box-wrap mt-3 ${active ? "active" : ""}`}>
      {type === "OPEN" ? (
        <a
          className="d-box_content"
          href={link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="d-box_icon">
            <CustomFormIcon width={50} height={50} />
          </div>
          <h5
            className={`d-box_title ${
              newBadge ? "nav-new-badge top-negative" : ""
            }`}
          >
            {title}
          </h5>
          <p className="d-box_copy">{description}</p>
        </a>
      ) : type === "INLINE_OPEN" ? (
        <Link to={link} className="d-box_content">
          <div className="d-box_icon">
            <CustomFormIcon width={50} height={50} />
          </div>
          <h5
            className={`d-box_title ${
              newBadge ? "nav-new-badge top-negative" : ""
            }`}
          >
            {title}
          </h5>
          <p className="d-box_copy">{description}</p>
        </Link>
      ) : type === "SEND" ? (
        <Link
          to={`${routes.checksExternal}?link=${encodeURIComponent(
            link
          )}&title=${title}`}
          className="d-box_content"
        >
          <div className="d-box_icon">
            <CustomFormIcon width={50} height={50} />
          </div>
          <h5
            className={`d-box_title ${
              newBadge ? "nav-new-badge top-negative" : ""
            }`}
          >
            {title}
          </h5>
          <p className="d-box_copy">{description}</p>
        </Link>
      ) : (
        <>
          <button className="d-box_content" onClick={() => setActive(true)}>
            <div className="d-box_icon">
              <CustomFormIcon width={50} height={50} />
            </div>
            <h5
              className={`d-box_title ${
                newBadge ? "nav-new-badge top-negative" : ""
              }`}
            >
              {title}
            </h5>
            <p className="d-box_copy">{description}</p>
          </button>
          <div className="d-box_hover">
            {/* <div className="d-box_hover-header">
          {title}
        </div>
        <div className="d-box_hover-label">Select an option below</div> */}
            <div className="d-box_hover-label pt-0">{title}</div>
            <nav>
              <ul className="list-unstyled">
                <li>
                  <a
                    href={link}
                    className="d-box_hover-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    I'll complete myself
                  </a>
                </li>
                <li>
                  <Link
                    to={`${routes.checksExternal}?link=${encodeURIComponent(
                      link
                    )}&title=${title}`}
                    className="d-box_hover-link"
                  >
                    I'll send to my client
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </>
      )}
    </div>
  );
};
