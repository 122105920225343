import { Formik } from "formik";
import moment from "moment";
import PropTypes from "prop-types";
import qs from "query-string";
import React from "react";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import * as Yup from "yup";

import { ALL_COUNTRIES } from "../../../constants/allCountries";
import {
  PEP as TYPE_PEP,
  PEP_COMPANY as TYPE_PEP_COMPANY,
} from "../../../constants/checkTypes";
import {
  BIRTH_DATE_MSG,
  DATE_MSG,
  REQUIRED_MSG,
} from "../../../constants/validationHelpers";
import { DATE_REGEX } from "../../../constants/validationRegex";
import { uiFormatDate } from "../../../helpers/date";
import Field from "../../form/Field";
import IdentityFields from "../../form/IdentityFields";
import FormikScrollToErrors from "../../misc/FormikScrollToError";

const VALIDATION_RULES = Yup.object().shape({
  firstName: Yup.string()
    .when("checkType", {
      is: TYPE_PEP,
      then: Yup.string().trim().required(REQUIRED_MSG),
    })
    .when("checkType", {
      is: TYPE_PEP_COMPANY,
      then: Yup.string().trim().required(REQUIRED_MSG),
    }),
  middleName: Yup.string(),
  lastName: Yup.string()
    .when("checkType", {
      is: TYPE_PEP,
      then: Yup.string().trim().required(REQUIRED_MSG),
    })
    .when("checkType", {
      is: TYPE_PEP_COMPANY,
      then: Yup.string().trim(),
    }),
  birthDate: Yup.string()
    .when("checkType", {
      is: TYPE_PEP,
      then: Yup.string()
        .trim()
        .required(REQUIRED_MSG)
        .matches(DATE_REGEX, { message: DATE_MSG })
        .test("isValidBirthDate", BIRTH_DATE_MSG, (value) =>
          value
            ? moment()
                .subtract(16, "years")
                .isAfter(moment(value, uiFormatDate))
            : true
        ),
    })
    .when("checkType", {
      is: TYPE_PEP_COMPANY,
      then: Yup.string(),
    }),
  countryCode: Yup.string().trim().optional(),
});

const getInitialValues = ({
  identifierConsent = "",
  identifierFirstName = "",
  identifierLastName = "",
}) => ({
  firstName: "",
  lastName: "",
  middleName: "",
  checkType:
    qs.parse(location.search).pepType === "individuals"
      ? TYPE_PEP
      : TYPE_PEP_COMPANY,
  birthDate: "",
  identifierConsent,
  identifierFirstName,
  identifierLastName,
  countryCode: "",
});

const PepForm = ({
  fetching,
  activeCheck,
  totalChecks,
  resetCheck,
  addCheckAndVerify,
  allowedCountries,
}) => {
  const submitHandler = (data) => {
    addCheckAndVerify(data);
  };

  const filteredSortedCountryList = React.useMemo(() => {
    return Object.values(ALL_COUNTRIES).map((country) => ({
      value: country.code,
      label: country.name,
    }));
  }, [allowedCountries]);

  return (
    <div>
      <h3 className="text-center h6 mb-4">
        Client: {activeCheck + 1} of {totalChecks}
      </h3>
      <Formik
        validationSchema={VALIDATION_RULES}
        onSubmit={submitHandler}
        initialValues={getInitialValues({})}
      >
        {({
          handleSubmit,
          errors,
          values,
          submitCount,
          setFieldValue,
          isSubmitting,
          isValidating,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <FormikScrollToErrors
              errors={errors}
              isSubmitting={isSubmitting}
              isValidating={isValidating}
            />
            <Field
              name="countryCode"
              error={errors.countryCode}
              submitCount={submitCount}
              label={
                qs.parse(location.search).pepType !== "individuals"
                  ? "Your client's country of domicile (optional)"
                  : "Where does your client live? (optional)"
              }
              component="select"
            >
              <option value=""></option>
              {filteredSortedCountryList.map((country) => (
                <option key={country.value} value={country.value}>
                  {country.label}
                </option>
              ))}
            </Field>
            <IdentityFields
              errors={errors}
              submitCount={submitCount}
              autoComplete={false}
              autoFocusFirstName
              showMiddleName={values.checkType === TYPE_PEP}
              showDateOfBirth={values.checkType === TYPE_PEP}
              firstNameLabel={
                values.checkType === TYPE_PEP_COMPANY
                  ? "Entity name"
                  : undefined
              }
              lastNameLabel={
                values.checkType === TYPE_PEP_COMPANY
                  ? "Entity number (optional)"
                  : undefined
              }
              showFirstNameWarning={false}
              birthDate={values.birthDate}
              setFieldValue={setFieldValue}
              runUniquenessCheck
            />

            <div className="row align-items-center mt-4">
              <div className="col-6">
                <Button color="primary" onClick={resetCheck} outline block>
                  Start Again
                </Button>
              </div>
              <div className="col-6 text-right">
                <Button
                  color="primary"
                  className="px-5"
                  type="submit"
                  disabled={fetching}
                  block
                >
                  Run Check
                </Button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

PepForm.propTypes = {
  fetching: PropTypes.bool.isRequired,
  activeCheck: PropTypes.number.isRequired,
  totalChecks: PropTypes.number.isRequired,
  resetCheck: PropTypes.func.isRequired,
  addCheckAndVerify: PropTypes.func.isRequired,
  allowedCountries: PropTypes.arrayOf(PropTypes.string),
};

const mapStateToProps = ({ user }) => ({
  allowedCountries: user.allowedCountries,
});

export default connect(mapStateToProps)(PepForm);
