export const NATIONAL_ID_NOT_AVAILABLE = "NA";
export const NATIONAL_ID_AVAILABLE_REQUIRED = "required";
export const NATIONAL_ID_AVAILABLE_OPTIONAL = "optional";

export const NATIONAL_ID_GOVT_TAX_NUMBER = "TAX";
export const NATIONAL_ID_PASSPORT = "PASS";
export const NATIONAL_ID_MEDICAL_CARD = "MED";
export const NATIONAL_ID_DRIVER_LICENCE = "DRV";
export const NATIONAL_ID_VISA_NUMBER = "VISA";
export const NATIONAL_ID_VEHICLE_PLATE_NUMBER = "PLATE";
export const NATIONAL_ID_VEHICLE_VIN = "VIN";
export const NATIONAL_ID_VOTER_ID_NUMBER = "VOTE";
export const NATIONAL_ID_BIRTH_CERTIFICATE = "BIRTH";
