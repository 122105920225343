import React from "react";

const Download = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 30.29 21.53"
    xmlSpace="preserve"
    width="24"
  >
    <path
      fill="currentColor"
      d="M15.06 11.24h2.59a.58.58 0 0 1 .51.88l-1.29 2.24-1.3 2.25a.58.58 0 0 1-1 0l-1.3-2.25-1.31-2.19a.59.59 0 0 1 .51-.88Z"
    />
    <path
      fill="currentColor"
      d="M24.49 21.53H6.82A7.48 7.48 0 0 1 0 14.08a7.38 7.38 0 0 1 5.37-7.17 10.46 10.46 0 0 1 20.11 1.81 6.54 6.54 0 0 1-.95 12.8ZM15.15 1.6A8.82 8.82 0 0 0 6.7 7.83l-.14.44-.45.09a5.79 5.79 0 0 0-4.51 5.72 5.86 5.86 0 0 0 5.29 5.85h17.48a4.94 4.94 0 0 0 .28-9.76l-.59-.1-.08-.59a8.89 8.89 0 0 0-8.83-7.88Z"
    />
    <path
      fill="currentColor"
      d="M15.06 12.85a.8.8 0 0 1-.8-.8V5.86a.8.8 0 0 1 .8-.8.8.8 0 0 1 .8.8v6.19a.8.8 0 0 1-.8.8Z"
    />
  </svg>
);

export default Download;
