import React, { useMemo } from "react";

import { getBgColor } from "../../helpers/color";

const QuickID = ({ type, width = 40, height = 40 }) => {
  const bgColor = useMemo(() => getBgColor(type), [type]);
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 48.91 48.91"
      xmlSpace="preserve"
      className={`text-${type || "primary"}`}
      width={width}
      height={height}
    >
      <circle cx={24.45} cy={24.45} r={24.45} fill={bgColor} />
      <path
        fill="currentColor"
        d="M23.99 36.28a1.76 1.76 0 0 1-.45-.07 1.59 1.59 0 0 1-1.08-1.53v-7.36h-5.34a1.5 1.5 0 0 1-1.33-.83 1.64 1.64 0 0 1 .11-1.72c2.17-3.08 6.13-8.66 8.24-11.41a1.44 1.44 0 0 1 1.66-.51 1.58 1.58 0 0 1 1 1.57l-.24 7.39h5.32a1.52 1.52 0 0 1 1.33.83 1.67 1.67 0 0 1-.12 1.72L25.2 35.63a1.49 1.49 0 0 1-1.21.65Zm1.24-21.7c-2.13 2.8-5.92 8.14-8 11.12h5.33a1.55 1.55 0 0 1 1.52 1.59v7.14l7.76-11h-5.31a1.48 1.48 0 0 1-1.07-.46 1.63 1.63 0 0 1-.45-1.19Zm-.46-.73.64.49Zm0 0Z"
      />
    </svg>
  );
};

export default QuickID;
