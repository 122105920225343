import PropTypes from "prop-types";
import React from "react";

import Field from "../../../form/Field";
import SimpleDate from "../../../form/SimpleDate";

const NzPassport = ({ values, errors, submitCount, setFieldValue }) => {
  return (
    <div className="form-row">
      <div className="col-8">
        <Field
          name="nationalId"
          error={errors.nationalId}
          submitCount={submitCount}
          label="Passport"
          placeholder="LM123456"
        />
      </div>

      <div className="col-4">
        <SimpleDate
          name="nationalIdSecondary"
          value={values.nationalIdSecondary}
          setFieldValue={setFieldValue}
          error={errors.nationalIdSecondary}
          submitCount={submitCount}
          label="Expiry"
        />
      </div>
    </div>
  );
};

NzPassport.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  submitCount: PropTypes.number.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default NzPassport;
