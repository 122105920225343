import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import { actions as outsourcedCheckActions } from "../../../store/slices/outsourcedHistory";
import OutSourcedPresenter from "../../dashboard/Presenters/OutSourced";

const { updateOutsourcedCheck } = outsourcedCheckActions;

const UpdateModal = ({
  setUpdateModalOpened,
  outsourcedCheck,
  fetching,
  updateOutsourcedCheck,
}) => {
  const handleSubmit = (data, files) => {
    updateOutsourcedCheck(outsourcedCheck.signature_key, data, files);
    setUpdateModalOpened(false);
  };

  return (
    <Modal isOpen={true} toggle={() => setUpdateModalOpened((s) => !s)}>
      <ModalHeader toggle={() => setUpdateModalOpened((s) => !s)} />
      <ModalBody>
        <p className="h5 text-center mb-3">Edit Check</p>
        <OutSourcedPresenter
          fetching={fetching}
          addOutSourced={handleSubmit}
          initialValues={{
            checkType: outsourcedCheck.type,
            name: outsourcedCheck.name,
            country: outsourcedCheck.country,
            firstName: outsourcedCheck.contact_first_name,
            lastName: outsourcedCheck.contact_last_name,
            email: outsourcedCheck.email,
            phone: outsourcedCheck.phone,
            isFacematchRequired:
              outsourcedCheck.is_facematch_required === true
                ? "yes"
                : outsourcedCheck.is_facematch_required === false
                ? "no"
                : "",
            isCompanyPepRequired:
              outsourcedCheck.is_company_pep_required === true
                ? "yes"
                : outsourcedCheck.is_company_pep_required === false
                ? "no"
                : "",
            natureOfTransaction: outsourcedCheck.nature_of_transaction,
            reference: outsourcedCheck.reference,
            notes: outsourcedCheck.notes,
          }}
        />
      </ModalBody>
    </Modal>
  );
};

UpdateModal.propTypes = {
  setUpdateModalOpened: PropTypes.func.isRequired,
  outsourcedCheck: PropTypes.object.isRequired,
};

const mapStateToProps = ({ outsourcedHistory: { fetching } }) => ({
  fetching,
});
const mapDispatchToProps = { updateOutsourcedCheck };

export default connect(mapStateToProps, mapDispatchToProps)(UpdateModal);
