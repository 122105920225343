import React from "react";

const ReviewIcon = ({ width = "16px" }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 23.44 23.44"
    xmlSpace="preserve"
    width={width}
  >
    <path
      d="M11.73 23.44c-.22 0-.44 0-.66-.02C4.62 23.06-.34 17.52.02 11.07.38 4.62 5.9-.36 12.37.02c6.45.36 11.41 5.9 11.05 12.36-.35 6.23-5.53 11.07-11.7 11.07ZM11.71 1.6c-5.32 0-9.79 4.18-10.09 9.56-.31 5.57 3.97 10.36 9.54 10.67 5.57.32 10.36-3.97 10.67-9.54.31-5.57-3.97-10.36-9.54-10.67-.19-.01-.38-.02-.57-.02Z"
      fill="currentColor"
      strokeWidth="0"
    />
    <path
      d="M15.63 10.6H8.5c-.44 0-.8-.36-.8-.8s.36-.8.8-.8h7.13c.44 0 .8.36.8.8s-.36.8-.8.8ZM12.64 14.42H8.5c-.44 0-.8-.36-.8-.8s.36-.8.8-.8h4.14c.44 0 .8.36.8.8s-.36.8-.8.8Z"
      fill="currentColor"
      strokeWidth="0"
    />
  </svg>
);

export default ReviewIcon;
