import moment from "moment";
import * as Yup from "yup";

import { uiFormatDate } from "../helpers/date";
import {
  NATIONAL_ID_AVAILABLE_REQUIRED,
  NATIONAL_ID_DRIVER_LICENCE,
  NATIONAL_ID_GOVT_TAX_NUMBER,
  NATIONAL_ID_PASSPORT,
  NATIONAL_ID_VOTER_ID_NUMBER,
} from "./nationalIdType";
import {
  AU_LICENCE_NUMBER_MSG,
  AU_PASSPORT_MSG,
  BIRTH_DATE_MSG,
  CN_LICENCE_MSG,
  CN_PASSPORT_MSG,
  CN_RESIDENT_CARD_MSG,
  DATE_MSG,
  EXPIRY_DATE_MSG,
  IN_LICENCE_NUMBER_MSG,
  IN_PAN_MSG,
  IN_VOTER_ID_MSG,
  NZ_LICENCE_NUMBER_MSG,
  NZ_LICENCE_VERSION_MSG,
  NZ_PASSPORT_MSG,
  NZ_POSTAL_CODE_MSG,
  REQUIRED_MSG,
} from "./validationHelpers";
import {
  AU_LICENCE_NUMBER_REGEX,
  CN_LICENCE_NUMBER_REGEX,
  CN_PASSPORT_REGEX,
  CN_RESIDENT_CARD_REGEX,
  DATE_REGEX,
  IN_LICENCE_NUMBER_REGEX,
  IN_PAN_REGEX,
  IN_VOTER_ID_REGEX,
  NZ_LICENCE_NUMBER_REGEX,
  NZ_LICENCE_VERSION_REGEX,
  NZ_POSTAL_CODE_REGEX,
  PASSPORT_REGEX,
} from "./validationRegex";

const CA_NATIONAL_ID_DESCRIPTION = "Social Insurance Number (SIN)";
const SG_NATIONAL_ID_DESCRIPTION = "National Registration Identity Card";
const HK_NATIONAL_ID_DESCRIPTION = "Id Card Number";
const SOCIAL_SECURITY_NUMBER_DESCRIPTION = "Social Security Number";
const CPR_NUMBER_DESCRIPTION = "CPR Number";
const ID_CODE_NUMBER = "Clave Única de Registro de Población (CURP)";
const ID_NUMBER = "ID Number";

export const getCountriesSupportNationalId = () =>
  Object.keys(SUPPORTED_COUNTRIES).filter(
    (key) => SUPPORTED_COUNTRIES[key].nationalIdAvailable
  );

export const getCountriesRequireNationalId = () =>
  Object.keys(SUPPORTED_COUNTRIES).filter(
    (key) => SUPPORTED_COUNTRIES[key].nationalIdRequired
  );

export const SUPPORTED_COUNTRIES = {
  AT: {
    description: "AT - Austria",
    code: "AT",
    name: "Austria",
    nationalIdAvailable: false,
  },
  AU: {
    description: "AU - Australia",
    code: "AU",
    name: "Australia",
    nationalIdAvailable: true,
  },
  BR: {
    description: "BR - Brazil",
    code: "BR",
    name: "Brazil",
    nationalIdAvailable: false,
  },
  CA: {
    description: "CA - Canada",
    code: "CA",
    name: "Canada",
    nationalIdDescription: CA_NATIONAL_ID_DESCRIPTION,
    validationRegex: /^\d{9}|\d{3}-\d{3}-\d{3}$/,
    placeholder: "123456789",
    nationalIdAvailable: false,
  },
  CH: {
    description: "CH - Switzerland",
    code: "CH",
    name: "Switzerland",
    nationalIdAvailable: false,
  },
  CN: {
    description: "CN - China",
    code: "CN",
    name: "China",
    nationalIdAvailable: true,
  },
  DE: {
    description: "DE - Germany",
    code: "DE",
    name: "Germany",
    nationalIdAvailable: false,
  },
  DK: {
    description: "DK - Denmark",
    code: "DK",
    name: "Denmark",
    nationalIdDescription: CPR_NUMBER_DESCRIPTION,
    validationRegex: /^\d{10}$/,
    placeholder: "1234567890",
    nationalIdRequired: true,
    nationalIdAvailable: true,
  },
  ES: {
    description: "ES - Spain",
    code: "ES",
    name: "Spain",
    nationalIdAvailable: false,
  },
  FI: {
    description: "FI - Finland",
    code: "FI",
    name: "Finland",
    nationalIdAvailable: false,
  },
  FR: {
    description: "FR - France",
    code: "FR",
    name: "France",
    nationalIdAvailable: false,
  },
  GB: {
    description: "GB - United Kingdom",
    code: "GB",
    name: "United Kingdom",
    nationalIdAvailable: false,
  },
  // HK: {
  //   description: "HK - Hong Kong",
  //   code: "HK",
  //   name: "Hong Kong",
  //   nationalIdDescription: HK_NATIONAL_ID_DESCRIPTION,
  //   validationRegex: /^[A-Za-z]{1,2}[0-9]{6}\(?[0-9A-Za-z]\)?$/,
  //   placeholder: "A123456(B)",
  //   nationalIdRequired: true,
  //   nationalIdAvailable: true
  // },
  IN: {
    description: "IN - India",
    code: "IN",
    name: "India",
    nationalIdAvailable: true,
  },
  IT: {
    description: "IT - Italy",
    code: "IT",
    name: "Italy",
    nationalIdAvailable: false,
  },
  MX: {
    description: "MX - Mexico",
    code: "MX",
    name: "Mexico",
    nationalIdDescription: ID_CODE_NUMBER,
    validationRegex: /^\D{4}\d{6}\D{6}\d{2}$/,
    placeholder: "ABCD123456ABCDEF12",
    nationalIdRequired: true,
    nationalIdAvailable: true,
  },
  NL: {
    description: "NL - Netherlands",
    code: "NL",
    name: "Netherlands",
    nationalIdAvailable: false,
  },
  NO: {
    description: "NO - Norway",
    code: "NO",
    name: "Norway",
    nationalIdAvailable: false,
  },
  NZ: {
    description: "NZ - New Zealand",
    code: "NZ",
    name: "New Zealand",
    nationalIdAvailable: true,
  },
  SE: {
    description: "SE - Sweden",
    code: "SE",
    name: "Sweden",
    nationalIdAvailable: false,
  },
  SG: {
    description: "SG - Singapore",
    code: "SG",
    name: "Singapore",
    nationalIdDescription: SG_NATIONAL_ID_DESCRIPTION,
    validationRegex: /^[SsFfTtGgMm]\d{7}[A-Za-z]$/,
    placeholder: "S0000001I",
    nationalIdRequired: true,
    hidden: true,
    nationalIdAvailable: true,
  },
  US: {
    description: "US - United States of America",
    code: "US",
    name: "United States of America",
    nationalIdDescription: SOCIAL_SECURITY_NUMBER_DESCRIPTION,
    validationRegex: /^\d{9}$/,
    placeholder: "123456789",
    nationalIdRequired: true,
    nationalIdAvailable: true,
  },
  ZA: {
    description: "ZA - South Africa",
    code: "ZA",
    name: "South Africa",
    nationalIdDescription: ID_NUMBER,
    validationRegex: /^\d{13}$/,
    placeholder: "YYMMDDSSSSCAZ",
    nationalIdRequired: true,
    nationalIdAvailable: true,
  },
};

export const countriesWithNationalIdRegex = Object.keys(
  SUPPORTED_COUNTRIES
).filter((key) => SUPPORTED_COUNTRIES[key].validationRegex);

export const AU_LICENCE_STATES = [
  { value: "NSW", label: "New South Wales" },
  { value: "QLD", label: "Queensland" },
  { value: "SA", label: "South Australia" },
  { value: "TAS", label: "Tasmania" },
  { value: "VIC", label: "Victoria" },
  { value: "WA", label: "Western Australia" },
  { value: "ACT", label: "Australian Capital Territory" },
  { value: "JBT", label: "Jervis Bay Territory" },
  { value: "NT", label: "	Northern Territory" },
];

export const AU_LICENCE_STATES_WTIH_CARD_NUMBER = [
  "NSW",
  "QLD",
  "ACT",
  "SA",
  "WA",
  "TAS",
  "NT",
];

const nzDlValidation = Yup.string().matches(NZ_LICENCE_NUMBER_REGEX, {
  message: NZ_LICENCE_NUMBER_MSG,
});
const nzPpValidation = Yup.string().matches(PASSPORT_REGEX, {
  message: NZ_PASSPORT_MSG,
});
const auDlValidation = Yup.string().matches(AU_LICENCE_NUMBER_REGEX, {
  message: AU_LICENCE_NUMBER_MSG,
});
const auPpValidation = Yup.string().matches(PASSPORT_REGEX, {
  message: AU_PASSPORT_MSG,
});

const getNationalIdValidationSchema = (sources) =>
  Yup.string()
    .when(["nationalIdCountryCode", "nationalIdType"], {
      is: (nationalIdCountryCode, nationalIdType) =>
        nationalIdCountryCode === "NZ" &&
        nationalIdType === NATIONAL_ID_DRIVER_LICENCE,
      then:
        sources.nz_DRV === NATIONAL_ID_AVAILABLE_REQUIRED
          ? nzDlValidation.required(NZ_LICENCE_NUMBER_MSG)
          : nzDlValidation.notRequired(),
    })
    .when(["nationalIdCountryCode", "nationalIdType"], {
      is: (nationalIdCountryCode, nationalIdType) =>
        nationalIdCountryCode === "NZ" &&
        nationalIdType === NATIONAL_ID_PASSPORT,
      then:
        sources.nz_PASS === NATIONAL_ID_AVAILABLE_REQUIRED
          ? nzPpValidation.required(NZ_PASSPORT_MSG)
          : nzPpValidation.notRequired(),
    })
    .when(["nationalIdCountryCode", "nationalIdType"], {
      is: (nationalIdCountryCode, nationalIdType) =>
        nationalIdCountryCode === "AU" &&
        nationalIdType === NATIONAL_ID_DRIVER_LICENCE,
      then:
        sources.au_DRV === NATIONAL_ID_AVAILABLE_REQUIRED
          ? auDlValidation.required(AU_LICENCE_NUMBER_MSG)
          : auDlValidation.notRequired(),
    })
    .when(["nationalIdCountryCode", "nationalIdType"], {
      is: (nationalIdCountryCode, nationalIdType) =>
        nationalIdCountryCode === "AU" &&
        nationalIdType === NATIONAL_ID_PASSPORT,
      then:
        sources.au_PASS === NATIONAL_ID_AVAILABLE_REQUIRED
          ? auPpValidation.required(AU_PASSPORT_MSG)
          : auPpValidation.notRequired(),
    })
    .when("nationalIdCountryCode", {
      is: (v) => v === "IN",
      then: Yup.string()
        .trim()
        .required(REQUIRED_MSG)
        .when("nationalIdType", {
          is: (nationalIdType) =>
            nationalIdType === NATIONAL_ID_GOVT_TAX_NUMBER,
          then: Yup.string().matches(IN_PAN_REGEX, { message: IN_PAN_MSG }),
        })
        .when("nationalIdType", {
          is: (nationalIdType) => nationalIdType === NATIONAL_ID_DRIVER_LICENCE,
          then: Yup.string().matches(IN_LICENCE_NUMBER_REGEX, {
            message: IN_LICENCE_NUMBER_MSG,
          }),
        })
        .when("nationalIdType", {
          is: (nationalIdType) =>
            nationalIdType === NATIONAL_ID_VOTER_ID_NUMBER,
          then: Yup.string().matches(IN_VOTER_ID_REGEX, {
            message: IN_VOTER_ID_MSG,
          }),
        }),
    })
    .when("nationalIdCountryCode", {
      is: (v) => v === "CN",
      then: Yup.string()
        .trim()
        .required(REQUIRED_MSG)
        .when("nationalIdType", {
          is: (nationalIdType) =>
            nationalIdType === NATIONAL_ID_GOVT_TAX_NUMBER,
          then: Yup.string().matches(CN_RESIDENT_CARD_REGEX, {
            message: CN_RESIDENT_CARD_MSG,
          }),
        })
        .when("nationalIdType", {
          is: (nationalIdType) => nationalIdType === NATIONAL_ID_DRIVER_LICENCE,
          then: Yup.string().matches(CN_LICENCE_NUMBER_REGEX, {
            message: CN_LICENCE_MSG,
          }),
        })
        .when("nationalIdType", {
          is: (nationalIdType) => nationalIdType === NATIONAL_ID_PASSPORT,
          then: Yup.string().matches(CN_PASSPORT_REGEX, {
            message: CN_PASSPORT_MSG,
          }),
        }),
    })
    .when("nationalIdCountryCode", {
      is: (nationalIdCountryCode) =>
        getCountriesRequireNationalId().includes(nationalIdCountryCode),
      then: Yup.string().trim().required(REQUIRED_MSG),
    });

const getNationalIdSecondaryValidationSchema = (quickIDAlgorithm) =>
  Yup.string()
    .when(["nationalIdCountryCode", "nationalIdType"], {
      is: (nationalIdCountryCode, nationalIdType) =>
        nationalIdCountryCode === "NZ" &&
        nationalIdType === NATIONAL_ID_DRIVER_LICENCE,
      then: Yup.string()
        .matches(NZ_LICENCE_VERSION_REGEX, {
          message: NZ_LICENCE_VERSION_MSG,
        })
        .when("nationalId", {
          is: (v) => !!v,
          then: Yup.string().trim().required(REQUIRED_MSG),
        }),
    })
    .when(["nationalIdCountryCode", "nationalIdType"], {
      is: (nationalIdCountryCode, nationalIdType) =>
        nationalIdCountryCode === "NZ" &&
        nationalIdType === NATIONAL_ID_PASSPORT,
      then: Yup.string()
        .matches(DATE_REGEX, { message: DATE_MSG })
        .test("isValidExpiryDate", EXPIRY_DATE_MSG, (value) =>
          value ? moment().isBefore(moment(value, uiFormatDate)) : true
        )
        .when("nationalId", {
          is: (v) => !!v,
          then: Yup.string().trim().required(REQUIRED_MSG),
        }),
    })
    .when(["nationalIdCountryCode", "nationalIdType"], {
      is: (nationalIdCountryCode, nationalIdType) =>
        nationalIdCountryCode === "AU" &&
        nationalIdType === NATIONAL_ID_DRIVER_LICENCE,
      then: Yup.string().when("nationalId", {
        is: (v) => !!v,
        then: Yup.string().trim().required(REQUIRED_MSG),
      }),
    })
    .when("nationalIdCountryCode", {
      is: (v) => ["CA", "US"].includes(v),
      then: Yup.string().trim().required(REQUIRED_MSG),
    })
    .when("nationalIdCountryCode", {
      is: (v) => v === "IN" && quickIDAlgorithm !== "1x1",
      then: Yup.string()
        .trim()
        .required(REQUIRED_MSG)
        .when("nationalIdSecondaryType", {
          is: (nationalIdSecondaryType) =>
            nationalIdSecondaryType === NATIONAL_ID_GOVT_TAX_NUMBER,
          then: Yup.string().matches(IN_PAN_REGEX, { message: IN_PAN_MSG }),
        })
        .when("nationalIdSecondaryType", {
          is: (nationalIdSecondaryType) =>
            nationalIdSecondaryType === NATIONAL_ID_DRIVER_LICENCE,
          then: Yup.string().matches(IN_LICENCE_NUMBER_REGEX, {
            message: IN_LICENCE_NUMBER_MSG,
          }),
        })
        .when("nationalIdSecondaryType", {
          is: (nationalIdSecondaryType) =>
            nationalIdSecondaryType === NATIONAL_ID_VOTER_ID_NUMBER,
          then: Yup.string().matches(IN_VOTER_ID_REGEX, {
            message: IN_VOTER_ID_MSG,
          }),
        }),
    })
    .when("nationalIdCountryCode", {
      is: (v) => v === "CN" && quickIDAlgorithm !== "1x1",
      then: Yup.string()
        .trim()
        .required(REQUIRED_MSG)
        .when("nationalIdSecondaryType", {
          is: (nationalIdSecondaryType) =>
            nationalIdSecondaryType === NATIONAL_ID_GOVT_TAX_NUMBER,
          then: Yup.string().matches(CN_RESIDENT_CARD_REGEX, {
            message: CN_RESIDENT_CARD_MSG,
          }),
        })
        .when("nationalIdSecondaryType", {
          is: (nationalIdSecondaryType) =>
            nationalIdSecondaryType === NATIONAL_ID_DRIVER_LICENCE,
          then: Yup.string().matches(CN_LICENCE_NUMBER_REGEX, {
            message: CN_LICENCE_MSG,
          }),
        })
        .when("nationalIdSecondaryType", {
          is: (nationalIdSecondaryType) =>
            nationalIdSecondaryType === NATIONAL_ID_PASSPORT,
          then: Yup.string().matches(CN_PASSPORT_REGEX, {
            message: CN_PASSPORT_MSG,
          }),
        }),
    });

export const getNationalIdTertiaryValidationSchema = () =>
  Yup.string().when(
    ["nationalIdCountryCode", "nationalIdType", "nationalIdSecondary"],
    {
      is: (nationalIdCountryCode, nationalIdType, nationalIdSecondary) =>
        nationalIdCountryCode === "AU" &&
        nationalIdType === NATIONAL_ID_DRIVER_LICENCE &&
        AU_LICENCE_STATES_WTIH_CARD_NUMBER.includes(nationalIdSecondary || ""),
      then: Yup.string().when("nationalIdType", {
        is: (v) => !!v,
        then: Yup.string().trim().required(REQUIRED_MSG),
      }),
    }
  );

export const getVerificationValidationSchema = (sources, quickIDAlgorithm) => ({
  countryCode: Yup.string().trim().required(REQUIRED_MSG),
  firstName: Yup.string().trim().required(REQUIRED_MSG),
  middleName: Yup.string(),
  lastName: Yup.string().when("countryCode", {
    is: (v) => v !== "CN",
    then: Yup.string().trim().required(REQUIRED_MSG),
  }),
  addressLine1: Yup.string().when("countryCode", {
    is: (v) => !["CN", "IN"].includes(v),
    then: Yup.string().trim().required(REQUIRED_MSG),
  }),
  unitNo: Yup.string(),
  addressLine2: Yup.string(),
  city: Yup.string().when("countryCode", {
    is: (v) => !["CN", "IN", "HK"].includes(v),
    then: Yup.string().trim().required(REQUIRED_MSG),
  }),
  postalCode: Yup.string()
    .when("countryCode", {
      is: (v) => !["CN", "IN", "HK"].includes(v),
      then: Yup.string().trim().required(REQUIRED_MSG),
    })
    .when("countryCode", {
      is: "NZ",
      then: Yup.string().matches(NZ_POSTAL_CODE_REGEX, {
        message: NZ_POSTAL_CODE_MSG,
      }),
    }),
  birthDate: Yup.string()
    .trim()
    .required(REQUIRED_MSG)
    .matches(DATE_REGEX, { message: DATE_MSG })
    .test("isValidBirthDate", BIRTH_DATE_MSG, (value) =>
      value
        ? moment().subtract(16, "years").isAfter(moment(value, uiFormatDate))
        : true
    ),
  nationalId: getNationalIdValidationSchema(sources),
  nationalIdSecondary: getNationalIdSecondaryValidationSchema(quickIDAlgorithm),
  nationalIdTertiary: getNationalIdTertiaryValidationSchema(),
  nationalIdType: Yup.string().when("nationalIdCountryCode", {
    is: (v) => ["CN", "IN"].includes(v),
    then: Yup.string().trim().required(REQUIRED_MSG),
  }),
  nationalIdSecondaryType: Yup.string().when("nationalIdCountryCode", {
    is: (v) => ["CN", "IN"].includes(v) && quickIDAlgorithm !== "1x1",
    then: Yup.string().trim().required(REQUIRED_MSG),
  }),
  consent: Yup.bool()
    .required()
    .test(
      "consent",
      "You have to agree with our Terms and Conditions!",
      (value) => value === true
    ),
});
