import React from "react";

const FailIcon = ({ width = "16ps" }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 23.44 23.44"
    xmlSpace="preserve"
    width={width}
  >
    <path
      d="M11.72 23.44C5.26 23.44 0 18.18 0 11.72S5.26 0 11.72 0s11.72 5.26 11.72 11.72-5.26 11.72-11.72 11.72Zm0-21.84C6.14 1.6 1.6 6.14 1.6 11.72s4.54 10.12 10.12 10.12 10.12-4.54 10.12-10.12S17.3 1.6 11.72 1.6Z"
      strokeWidth="0"
      fill="currentColor"
    />
    <path
      d="M8.95 15.01c-.2 0-.41-.08-.57-.23a.803.803 0 0 1 0-1.13l5.54-5.54c.31-.31.82-.31 1.13 0 .31.31.31.82 0 1.13l-5.54 5.54c-.16.16-.36.23-.57.23Z"
      strokeWidth="0"
      fill="currentColor"
    />
    <path
      d="M14.49 15.01c-.21 0-.41-.08-.57-.23L8.38 9.23a.803.803 0 0 1 0-1.13c.31-.31.82-.31 1.13 0l5.54 5.55c.31.31.31.82 0 1.13-.16.16-.36.23-.57.23Z"
      strokeWidth="0"
      fill="currentColor"
    />
  </svg>
);

export default FailIcon;
