import { Formik, Field as FormikField } from "formik";
import PropTypes from "prop-types";
import qs from "query-string";
import RcIf, { RcElse } from "rc-if";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, ButtonGroup } from "reactstrap";
import * as Yup from "yup";

import checkGroupTypes, {
  EMAIL as TYPE_EMAIL,
  SELF as TYPE_SELF,
  SMS as TYPE_SMS,
} from "../../../constants/checkGroupType";
import { REQUIRED_MSG } from "../../../constants/validationHelpers";
import history from "../../../history";
import Field from "../../form/Field";
import FormikScrollToErrors from "../../misc/FormikScrollToError";

const VALIDATION_RULES = Yup.object().shape({
  reference: Yup.string(),
  type: Yup.string().trim().required(REQUIRED_MSG),
  totalChecks: Yup.string().trim().required(REQUIRED_MSG),
  notes: Yup.string(),
});

const CheckGroupForm = ({
  startNewCheck,
  reference,
  totalChecks,
  notes,
  type,
  fetching,
  checkType,
  allowedCheckGroupTypes,
  defaultCountryCode,
  faceMatchNumberOfDocs,
  pepCompanyAllowed,
}) => {
  const isCa = defaultCountryCode === "CA";
  const isCaOrAu = ["CA", "AU"].includes(defaultCountryCode);
  const [ids, setIds] = useState("1");
  useEffect(() => {
    if (isCaOrAu) setIds(faceMatchNumberOfDocs === "2IDs" ? "2" : "1");
  }, [faceMatchNumberOfDocs, isCaOrAu]);
  useEffect(() => {
    const queries = qs.parse(location.search);
    queries.ids = ids;
    history.replace(`?${qs.stringify(queries)}`);
  }, [ids]);

  const [pepType, setPepType] = useState("individuals");
  useEffect(() => {
    const queries = qs.parse(location.search);
    queries.pepType = pepType;
    history.replace(`?${qs.stringify(queries)}`);
  }, [pepType]);

  return (
    <Formik
      validationSchema={VALIDATION_RULES}
      onSubmit={startNewCheck}
      enableReinitialize={true}
      initialValues={{
        reference,
        totalChecks: String(totalChecks || "1"),
        notes,
        type: checkType !== "QuickID" ? TYPE_SELF : type || TYPE_SELF,
      }}
    >
      {({ handleSubmit, errors, submitCount, isSubmitting, isValidating }) => (
        <form noValidate onSubmit={handleSubmit}>
          <FormikScrollToErrors
            errors={errors}
            isSubmitting={isSubmitting}
            isValidating={isValidating}
          />

          {isCaOrAu && location.pathname.includes("face") && (
            <ButtonGroup className="mb-3 w-100">
              {faceMatchNumberOfDocs !== "2IDs" && (
                <Button
                  className="border-secondary font-weight-normal btn-hover-primary no-load"
                  outline={ids === "2"}
                  color={ids === "2" ? "secondary" : "primary"}
                  onClick={() => setIds("1")}
                >
                  1 ID
                </Button>
              )}
              {faceMatchNumberOfDocs !== "1ID" && (
                <Button
                  className="border-secondary font-weight-normal btn-hover-primary no-load"
                  outline={ids === "1"}
                  color={ids === "1" ? "secondary" : "primary"}
                  onClick={() => setIds("2")}
                >
                  2 IDs
                </Button>
              )}
            </ButtonGroup>
          )}

          {pepCompanyAllowed && location.pathname.includes("pep") && (
            <ButtonGroup className="mb-3 w-100">
              <Button
                className="border-secondary font-weight-normal btn-hover-primary no-load"
                outline={pepType !== "individuals"}
                color={pepType !== "individuals" ? "secondary" : "primary"}
                onClick={() => setPepType("individuals")}
              >
                Individual
              </Button>
              <Button
                className="border-secondary font-weight-normal btn-hover-primary no-load"
                outline={pepType === "individuals"}
                color={pepType === "individuals" ? "secondary" : "primary"}
                onClick={() => setPepType("non-individuals")}
              >
                Non-individual
              </Button>
            </ButtonGroup>
          )}

          <Field
            name="totalChecks"
            error={errors.totalChecks}
            submitCount={submitCount}
            label={`${
              checkType === "QuickID"
                ? "How many clients need to be verified?"
                : "How many clients?"
            }`}
            component="select"
          >
            <option>Select a number (0-10)</option>
            {Array.from(Array(10)).map((v, i) => (
              <option key={i} value={`${i + 1}`}>
                {i + 1}
              </option>
            ))}
          </Field>
          <RcIf if={checkType === "QuickID"}>
            <Field
              name="type"
              error={errors.type}
              submitCount={submitCount}
              label="How will you identify your client(s)?"
              component="select"
              srOnly={!!type}
            >
              <option>Select your preference</option>
              {checkGroupTypes
                .filter((ct) => allowedCheckGroupTypes.includes(ct.key))
                .map((ct, i) => (
                  <option key={i} value={ct.key}>
                    {ct.label}
                  </option>
                ))}
            </Field>
            <RcElse>
              <FormikField
                name="type"
                type="hidden"
                data-errors={errors.type}
              />
            </RcElse>
          </RcIf>
          <Field
            name="reference"
            error={errors.reference}
            submitCount={submitCount}
            label={`${
              isCa ? "File/Client/Matter Number" : "Reference"
            } (optional)`}
            placeholder={isCa ? "" : "e.g. File Number or Tag."}
          />
          <Field
            name="notes"
            error={errors.notes}
            submitCount={submitCount}
            component="textarea"
            label={`${isCa ? "Matter Description" : "Notes"} (optional)`}
            placeholder={
              isCa
                ? "This will only appear on PDF reports and in the Check History"
                : "Your notes will only appear on PDF reports and in the Check History."
            }
          />
          <div className="text-center">
            <Button
              color="primary"
              className="px-5"
              type="submit"
              disabled={fetching}
            >
              {checkType === "QuickID" ? "Enter ID Details" : "Next"}
            </Button>
          </div>
          <hr className="mt-4" />
          <p className="text-center small mb-0">
            By running checks I agree to the{" "}
            <a
              href="https://realaml.com/terms-and-conditions/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms &amp; Conditions
            </a>{" "}
            and{" "}
            <a
              href="https://realaml.com/privacy-policy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
          </p>
        </form>
      )}
    </Formik>
  );
};

CheckGroupForm.propTypes = {
  startNewCheck: PropTypes.func.isRequired,
  reference: PropTypes.string.isRequired,
  totalChecks: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  notes: PropTypes.string.isRequired,
  fetching: PropTypes.bool.isRequired,
  checkType: PropTypes.string.isRequired,
  allowedCheckGroupTypes: PropTypes.arrayOf(PropTypes.string),
};

CheckGroupForm.defaultProps = {
  checkType: "QuickID",
  allowedCheckGroupTypes: [TYPE_SELF, TYPE_EMAIL, TYPE_SMS],
};

const mapStateToProps = ({
  user: { defaultCountryCode, faceMatchNumberOfDocs, pepCompanyAllowed },
}) => ({
  defaultCountryCode,
  faceMatchNumberOfDocs,
  pepCompanyAllowed,
});

export default connect(mapStateToProps)(CheckGroupForm);
