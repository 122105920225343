import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";

import { VERIFY_EMAIL_SUCCESS } from "../../../constants/lang/en";
import Loading from "../../misc/Loading";
import StepHeader from "../../misc/StepHeader";
import StepLayout from "../../misc/StepLayout";

const VerifyEmail = ({ fetching, verifyEmail, sendEmailVerificationToken }) => {
  const [emailVerifyClicked, setEmailVerifyClicked] = useState(false);

  const resendVerifyEmail = useCallback(() => {
    sendEmailVerificationToken();
    setEmailVerifyClicked(true);
  });

  useEffect(() => {
    verifyEmail();
  }, [verifyEmail]);

  return (
    <StepLayout>
      <StepHeader text="Verify your email" />
      {fetching ? (
        <div>
          <Loading />
        </div>
      ) : (
        <div>
          <h3 className="h6 text-center mb-3">
            {emailVerifyClicked ? (
              <>{VERIFY_EMAIL_SUCCESS}</>
            ) : (
              <>
                Email verification failed. Retry using the button below or
                contact{" "}
                <a href="mailto:support@realaml.com" target="_blank">
                  support@realaml.com
                </a>
                .
                <br />
                <button
                  className="btn btn-primary mt-4"
                  onClick={resendVerifyEmail}
                >
                  Verify email
                </button>
              </>
            )}
          </h3>
        </div>
      )}
    </StepLayout>
  );
};

VerifyEmail.propTypes = {
  verifyEmail: PropTypes.func.isRequired,
  sendEmailVerificationToken: PropTypes.func.isRequired,
  fetching: PropTypes.bool.isRequired,
};

export default VerifyEmail;
