import { Field } from "formik";
import PropTypes from "prop-types";
import React from "react";

const ConsentCheckbox = ({ error, submitCount }) => (
  <div className="site-form__consent-message">
    <div className="custom-control custom-checkbox">
      <Field
        component="input"
        type="checkbox"
        name="consent"
        id="consent"
        className={`custom-control-input ${
          error && submitCount > 0 ? "is-invalid" : ""
        }`}
      />
      <label className="custom-control-label" htmlFor="consent">
        I confirm that I am authorised to provide the details presented to be
        passed and checked with the document issuer, official record holder, a
        credit bureau, and authorised third parties for verifying my or my
        client's name, date of birth, and address. My data may also be shared
        with telecommunications or mobile operators for identity verification
        purposes. Those details may include name, address, and device details.
      </label>
    </div>
  </div>
);

ConsentCheckbox.propTypes = {
  error: PropTypes.string,
  submitCount: PropTypes.number.isRequired,
};

export default ConsentCheckbox;
