import PropTypes from "prop-types";
import React from "react";
import { FaCaretLeft, FaCaretRight } from "react-icons/fa";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
} from "reactstrap";

import {
  checksStatusTypes,
  checksTypesDropdown,
  orderTypes,
} from "../../../constants/checkTypes";
import useDebounce from "../../../hooks/useDebounce";
import DashboardLayout from "../../dashboard/Presenters/Layout";
import Eye from "../../icons/EyeIcon";
import Filter from "../../icons/FilterIcon";
import Refresh from "../../icons/RefreshIcon";
import ReportIcon from "../../icons/ReportIcon";
import Sort from "../../icons/SortIcon";
import Loading from "../../misc/Loading";
import Check from "./check";
import ReportsModal from "./reportsModal";

const statusTypes = checksStatusTypes;
export const checkTypesDropdown = checksTypesDropdown;

const CheckHistory = ({
  history,
  fetchHistory,
  updateCheckGroup,
  checkFetching,
  resendCheck,
  deleteCheck,
  disableMonitoring,
  approveFaceMatch,
  approveQuickId,
  resetCheck,
  namePriority,
  showSecondaryIDCopy,
  dashboardShowPrice,
  dashboardCanDelete,
  dashboardCanEdit,
  dashboardCanApprove,
  dashboardCanReset,
  dashboardCanManageDocs,
  isAdmin,
}) => {
  const perPage = 25;
  const [page, setPage] = React.useState(1);
  const [query, setQuery] = React.useState("");
  const [orderBy, setOrderBy] = React.useState("-created_at");
  const [typeFilter, setTypeFilter] = React.useState();
  const [statusFilter, setStatusFilter] = React.useState();
  const [reportsModalOpened, setReportsModalOpened] = React.useState(false);

  const [statusDropdown, setStatusDropdown] = React.useState(false);
  const statusDropdownToggle = () =>
    setStatusDropdown((prevState) => !prevState);
  const [typeDropdown, setTypeDropdown] = React.useState(false);
  const typeDropdownToggle = () => setTypeDropdown((prevState) => !prevState);
  const [orderDropdown, setOrderDropdown] = React.useState(false);
  const orderDropdownToggle = () => setOrderDropdown((prevState) => !prevState);

  const refetchHistory = (defaultPage = 1) => {
    const queries = {
      query,
      order_by: orderBy,
      per_page: perPage,
      page: defaultPage,
      status_filter: statusFilter || undefined,
    };
    if (typeFilter && typeFilter.length) {
      queries.type_filter = typeFilter;
    }
    fetchHistory(queries);
  };

  const debouncedQuery = useDebounce(query, 500);

  const changePage = (page) => {
    setPage(page);
    refetchHistory(page);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleResendCheck = (signatureKey) => {
    resendCheck(signatureKey, () => refetchHistory(page));
  };

  const handleResetAndRedoCheck = (signatureKey, isSelfCheck) => {
    resetCheck(signatureKey, isSelfCheck, () => refetchHistory(page));
  };

  React.useEffect(() => {
    changePage(1);
    // eslint-disable-next-line
  }, [debouncedQuery, orderBy, typeFilter, statusFilter]);

  const selectedCheckType = checkTypesDropdown.find(
    (ct) => ct.key === typeFilter
  );
  const selectedOrderType = orderTypes.find((ot) => ot.key === orderBy);
  const selectedStatusType =
    statusTypes.find((st) => st.key === statusFilter) || statusTypes[0];
  const totalPages = Math.ceil(history.totalRecords / perPage);
  const fetching = history.fetching || checkFetching;

  return (
    <DashboardLayout>
      <section className="site-table site-table--dash check-history">
        <div className="position-relative">
          <h2 className="text-center h4 mb-3 d-lg-none">Check History</h2>
          <div className="check-history-header">
            <div className="check-history-header-item">
              <Button
                size="sm"
                color="light"
                className="mr-2"
                onClick={() => refetchHistory(page)}
              >
                <Refresh />
              </Button>
              <Input
                className="mr-2"
                type="text"
                placeholder="Search..."
                bsSize="sm"
                value={query}
                onChange={(e) => setQuery(e.currentTarget.value)}
              />
            </div>
            <div className="check-history-header-item">
              <Dropdown
                isOpen={typeDropdown}
                toggle={typeDropdownToggle}
                size="sm"
              >
                <DropdownToggle caret color="" className="btn-light mx-1">
                  <Filter />
                  <span className="ml-2">
                    {selectedCheckType ? selectedCheckType.label : "All Checks"}
                  </span>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => setTypeFilter("")}>
                    All Checks
                  </DropdownItem>
                  {checkTypesDropdown.map((ct) => (
                    <DropdownItem
                      key={ct.key}
                      onClick={() => setTypeFilter(ct.key)}
                      active={typeFilter === ct.key}
                    >
                      {ct.label}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
              <Dropdown
                isOpen={statusDropdown}
                toggle={statusDropdownToggle}
                size="sm"
              >
                <DropdownToggle caret color="" className="btn-light mx-1">
                  <Eye />
                  <span className="ml-2">{selectedStatusType.label}</span>
                </DropdownToggle>
                <DropdownMenu>
                  {statusTypes.map((st) => (
                    <DropdownItem
                      key={st.key}
                      onClick={() => setStatusFilter(st.key)}
                      active={statusFilter === st.key}
                      className={
                        st.subMenu
                          ? `pl-5 text-sm sub-menu ${
                              st.key === "completed_passed" ? "first-child" : ""
                            }`
                          : undefined
                      }
                    >
                      {st.label}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
              <Dropdown
                isOpen={orderDropdown}
                toggle={orderDropdownToggle}
                size="sm"
              >
                <DropdownToggle caret color="" className="btn-light mx-1">
                  <Sort />
                  <span className="ml-2">{selectedOrderType.label}</span>
                </DropdownToggle>
                <DropdownMenu>
                  {orderTypes.map((ot) => (
                    <DropdownItem
                      key={ot.key}
                      onClick={() => setOrderBy(ot.key)}
                      active={orderBy === ot.key}
                    >
                      {ot.label}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
              {isAdmin && (
                <Button
                  size="sm"
                  color="light"
                  onClick={() => setReportsModalOpened(true)}
                >
                  <ReportIcon />
                  <span className="ml-2">Reports</span>
                </Button>
              )}
            </div>
          </div>

          <div
            className={`site-table__inner p-0 ${
              fetching ? "site-table__inner--loading" : ""
            }`}
          >
            {fetching && (
              <div className="site-table--loading">
                <Loading />
              </div>
            )}
            {!!history.checks && history.checks.length > 0
              ? history.checks.map((check) => (
                  <Check
                    key={check.signature_key}
                    check={check}
                    updateCheckGroup={updateCheckGroup}
                    checkFetching={checkFetching}
                    resendCheck={handleResendCheck}
                    deleteCheck={deleteCheck}
                    disableMonitoring={disableMonitoring}
                    approveFaceMatch={approveFaceMatch}
                    approveQuickId={approveQuickId}
                    resetCheck={handleResetAndRedoCheck}
                    isAdminOrManager={history.isAdminOrManager}
                    namePriority={namePriority}
                    showSecondaryIDCopy={showSecondaryIDCopy}
                    dashboardShowPrice={dashboardShowPrice}
                    dashboardCanDelete={dashboardCanDelete}
                    dashboardCanEdit={dashboardCanEdit}
                    dashboardCanApprove={dashboardCanApprove}
                    dashboardCanReset={dashboardCanReset}
                    dashboardCanManageDocs={dashboardCanManageDocs}
                  />
                ))
              : !fetching && (
                  <p className="text-center lead my-5">No results found</p>
                )}
          </div>

          {!!history.checks && history.checks.length > 0 && (
            <div className="check-history-footer">
              <Button
                size="sm"
                color="light"
                className="mx-2 no-load"
                onClick={() => changePage(page - 1)}
                disabled={page < 2}
                style={page < 2 ? { opacity: 0 } : {}}
              >
                <FaCaretLeft size="18" />
              </Button>
              Page {page} of {totalPages}
              <Button
                size="sm"
                color="light"
                className="mx-2 no-load"
                onClick={() => changePage(page + 1)}
                disabled={page >= totalPages}
                style={page >= totalPages ? { opacity: 0 } : {}}
              >
                <FaCaretRight size="18" />
              </Button>
            </div>
          )}
        </div>
      </section>

      {reportsModalOpened && (
        <ReportsModal setReportsModalOpened={setReportsModalOpened} />
      )}
    </DashboardLayout>
  );
};

CheckHistory.propTypes = {
  history: PropTypes.object.isRequired,
  fetchHistory: PropTypes.func.isRequired,
  checkFetching: PropTypes.bool.isRequired,
  updateCheckGroup: PropTypes.func.isRequired,
  resendCheck: PropTypes.func.isRequired,
  deleteCheck: PropTypes.func.isRequired,
  disableMonitoring: PropTypes.func.isRequired,
  approveFaceMatch: PropTypes.func.isRequired,
  approveQuickId: PropTypes.func.isRequired,
  resetCheck: PropTypes.func.isRequired,
  namePriority: PropTypes.bool.isRequired,
  showSecondaryIDCopy: PropTypes.bool.isRequired,
  dashboardShowPrice: PropTypes.bool.isRequired,
  dashboardCanDelete: PropTypes.bool.isRequired,
  dashboardCanEdit: PropTypes.bool.isRequired,
  dashboardCanApprove: PropTypes.bool.isRequired,
  dashboardCanReset: PropTypes.bool.isRequired,
  dashboardCanManageDocs: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

export default CheckHistory;
