import { Field } from "formik";
import PropTypes from "prop-types";
import React from "react";

import { REQUIRED_MSG } from "../../constants/validationHelpers";

const FieldWrap = ({
  name,
  error,
  submitCount,
  label,
  fieldClassName,
  srOnly,
  ...otherFieldProps
}) => (
  <div className={`form-group ${srOnly ? "sr-only" : "abs-label"}`}>
    <Field
      name={name}
      id={name}
      className={`form-control ${fieldClassName ? fieldClassName : ""} ${
        !!error && submitCount > 0 ? "is-invalid" : ""
      }`}
      {...otherFieldProps}
    />
    <label htmlFor={name}>{label}</label>
    {!!error && error !== REQUIRED_MSG && submitCount > 0 && (
      <p className="small text-danger">{error}</p>
    )}
  </div>
);

FieldWrap.prototypes = {
  name: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  submitCount: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  fieldClassName: PropTypes.string,
  srOnly: PropTypes.bool,
};

export default FieldWrap;
