import React from "react";

const Question = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 23.44 23.44"
    {...props}
  >
    <circle
      cx={11.72}
      cy={11.72}
      r={10.92}
      fill="none"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeWidth="1.6px"
    />
    <circle
      cx={12.07}
      cy={17.77}
      r={0.78}
      fill="currentColor"
      strokeWidth={0}
    />
    <path
      d="M16.56 8.87c0-2.25-2.01-4.08-4.49-4.08S7.58 6.62 7.58 8.87c0 .44.36.8.8.8s.8-.36.8-.8c0-1.37 1.29-2.48 2.89-2.48s2.89 1.11 2.89 2.48c0 1.07-.8 1.99-1.91 2.33a2.496 2.496 0 0 0-1.78 2.38v1.68c0 .44.36.8.8.8s.8-.36.8-.8v-1.1c0-.67.38-1.31 1-1.56 1.58-.63 2.69-2.06 2.69-3.72Z"
      fill="currentColor"
      strokeWidth={0}
    />
  </svg>
);

export default Question;
