import queryString from "query-string";
import React from "react";
import { useLocation } from "react-router";

import { QUICK_ID } from "../../../constants/checkTypes";
import ChecksForm from "../checks/Form";

const QuickID = () => {
  const location = useLocation();
  const queries = queryString.parse(location.search);

  return (
    <ChecksForm title="Quick IDV" checkType={QUICK_ID} type={queries.type} />
  );
};

export default QuickID;
