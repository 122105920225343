import PropTypes from "prop-types";
import React from "react";

import { AU_LICENCE_STATES } from "../../../../constants/supportedCountries";
import Field from "../../../form/Field";

const AuDriverLicence = ({ errors, submitCount, showCardNumber }) => {
  return (
    <div className="form-row">
      <div className={showCardNumber ? "col-md-4" : "col-md-7"}>
        <Field
          name="nationalId"
          error={errors.nationalId}
          submitCount={submitCount}
          label="Licence"
          placeholder="123456789"
        />
      </div>

      <div className={showCardNumber ? "col-md-4" : "col-md-5"}>
        <Field
          name="nationalIdSecondary"
          error={errors.nationalIdSecondary}
          submitCount={submitCount}
          label="Select State"
          component="select"
        >
          <option value="" hidden>
            Choose licence state
          </option>
          {AU_LICENCE_STATES.map((state) => (
            <option key={state.value} value={state.value}>
              {state.label}
            </option>
          ))}
        </Field>
      </div>

      {showCardNumber && (
        <div className="col-md-4">
          <Field
            name="nationalIdTertiary"
            error={errors.nationalIdTertiary}
            submitCount={submitCount}
            label="Card Number"
            placeholder="123456789"
          />
        </div>
      )}
    </div>
  );
};

AuDriverLicence.propTypes = {
  errors: PropTypes.object.isRequired,
  submitCount: PropTypes.number.isRequired,
  showCardNumber: PropTypes.bool.isRequired,
};

export default AuDriverLicence;
