import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { setAuthTokens } from "../../helpers/token";
import history from "../../history";
import routes from "../../routes";
import { actions } from "../../store/slices/auth";
import Loading from "../misc/Loading";
import SignInPresenter from "./Presenters/SignIn";

const { signIn } = actions;

const SignIn = ({ signIn, auth, location }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (
      window.location.href.startsWith(process.env.REACT_APP_PROD_CA_SITE_URL)
    ) {
      const postRedirectCookie = Cookies.get("post_redirect");
      if (postRedirectCookie) {
        Cookies.remove("post_redirect", {
          secure: true,
          domain: ".realaml.com",
        });
        const { accessToken, refreshToken, redirectTo } =
          JSON.parse(postRedirectCookie);
        if (accessToken && refreshToken) {
          setAuthTokens(accessToken, refreshToken);
          history.push(redirectTo ? redirectTo : routes.checks);
        }
      }
    }
    setLoading(false);
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <SignInPresenter
      fetching={auth.fetching}
      signIn={signIn}
      redirectTo={
        location.state && location.state.from ? location.state.from : ""
      }
    />
  );
};

const mapStateToProps = ({ auth }) => ({ auth });
const mapDispatchToProps = { signIn };

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SignIn));
