import React from "react";

const OutSourced = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 18.72 22.38"
    xmlSpace="preserve"
    className="outsourced-icon"
  >
    <path
      fill="currentColor"
      d="M16.24 22.38a2.48 2.48 0 0 1-1.45-.47l-5.43-3.92-5.43 3.92a2.48 2.48 0 0 1-3.93-2V2.47A2.48 2.48 0 0 1 2.47 0h13.76a2.5 2.5 0 0 1 2.49 2.49V19.9a2.48 2.48 0 0 1-1.35 2.21 2.54 2.54 0 0 1-1.13.27Zm-6.88-6.36 6.36 4.59a.88.88 0 0 0 .92.07.85.85 0 0 0 .48-.78V2.49a.89.89 0 0 0-.89-.89H2.47a.87.87 0 0 0-.87.87V19.9a.88.88 0 0 0 1.39.71Z"
    />
    <path
      fill="currentColor"
      d="M13.51 5.97H5.03a.8.8 0 1 1 0-1.6h8.48a.8.8 0 1 1 0 1.6Z"
    />
  </svg>
);

export default OutSourced;
