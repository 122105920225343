import { Formik } from "formik";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import * as Yup from "yup";

import { EMAIL_MSG, REQUIRED_MSG } from "../../../constants/validationHelpers";
import routes from "../../../routes";
import Field from "../../form/Field";
import PasswordField from "../../form/PasswordField";
import FormikScrollToErrors from "../../misc/FormikScrollToError";
import StepHeader from "../../misc/StepHeader";
import StepLayout from "../../misc/StepLayout";

const VALIDATION_RULES_LOGIN = Yup.object().shape({
  agentEmail: Yup.string().trim().required(REQUIRED_MSG).email(EMAIL_MSG),
  agentPassword: Yup.string().required(REQUIRED_MSG),
});

const VALIDATION_RULES_CODE = Yup.object().shape({
  code: Yup.string().trim().required(REQUIRED_MSG),
});

const INITIAL_VALUES = { agentEmail: "", agentPassword: "" };

const SignIn = ({ signIn, fetching, redirectTo = routes.checks }) => {
  const timer = React.useRef();
  const [resendTime, setResendTime] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const resetResendTimer = React.useCallback(() => {
    clearInterval(timer.current);
    setResendTime(60);
    timer.current = setInterval(() => {
      setResendTime((time) => time - 1);
    }, 1000);
    return () => clearInterval(timer.current);
  }, []);

  React.useEffect(() => {
    if (resendTime < 1 && timer.current) {
      clearInterval(timer.current);
    }
  }, [resendTime]);

  const handleSignIn = (data) => {
    setEmail(data.agentEmail);
    setPassword(data.agentPassword);
    signIn(data, { redirectTo }, () => {
      setPage(1);
      resetResendTimer();
    });
  };

  const handleSignInWithCode = (data) => {
    signIn(
      { ...data, agentEmail: email, agentPassword: password },
      { redirectTo }
    );
  };

  const handleResend = () => {
    signIn(
      { agentEmail: email, agentPassword: password },
      { redirectTo },
      () => {
        setPage(1);
        resetResendTimer();
      }
    );
  };

  return (
    <StepLayout>
      <StepHeader text="Log in to your account" />
      {page === 0 ? (
        <Formik
          validationSchema={VALIDATION_RULES_LOGIN}
          onSubmit={handleSignIn}
          initialValues={INITIAL_VALUES}
          enableReinitialize={true}
        >
          {({
            handleSubmit,
            errors,
            submitCount,
            isSubmitting,
            isValidating,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <FormikScrollToErrors
                errors={errors}
                isSubmitting={isSubmitting}
                isValidating={isValidating}
              />
              <Field
                name="agentEmail"
                error={errors.agentEmail}
                submitCount={submitCount}
                label="Email"
                type="email"
                autoFocus
                autoComplete="email"
              />
              <PasswordField
                name="agentPassword"
                error={errors.agentPassword}
                submitCount={submitCount}
                label="Password"
                autoComplete="current-password"
              />
              <Button
                type="submit"
                color="primary"
                size="lg"
                block
                disabled={fetching}
              >
                Login
              </Button>
              <div className="text-right mt-3">
                <Link to={routes.forgotPassword} className="btn btn-link">
                  Forgot Password?
                </Link>
              </div>
            </form>
          )}
        </Formik>
      ) : (
        <Formik
          validationSchema={VALIDATION_RULES_CODE}
          onSubmit={handleSignInWithCode}
          initialValues={{ code: "" }}
          enableReinitialize={true}
        >
          {({
            handleSubmit,
            errors,
            submitCount,
            isSubmitting,
            isValidating,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <FormikScrollToErrors
                errors={errors}
                isSubmitting={isSubmitting}
                isValidating={isValidating}
              />
              <Field
                name="code"
                error={errors.code}
                submitCount={submitCount}
                label="Verification Code"
                autoComplete="one-time-code"
                autoFocus
              />
              <p className="mb-2 text-center">
                A 7 digit code has been sent to your mobile.
              </p>
              <div className="mb-4 text-center">
                Didn’t get the code?
                <Button
                  type="button"
                  color="link"
                  disabled={fetching}
                  className="p-0 ml-1"
                  onClick={resendTime > 0 ? undefined : handleResend}
                >
                  Resend{resendTime > 0 ? ` in ${resendTime} seconds` : ""}
                </Button>
              </div>
              <Button
                type="submit"
                color="primary"
                size="lg"
                block
                disabled={fetching}
              >
                Verify and Login
              </Button>
            </form>
          )}
        </Formik>
      )}
    </StepLayout>
  );
};

SignIn.propTypes = {
  signIn: PropTypes.func.isRequired,
  fetching: PropTypes.bool.isRequired,
  redirectTo: PropTypes.string,
};

export default SignIn;
