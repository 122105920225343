import { Formik } from "formik";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import * as Yup from "yup";

import { PEP_COPY } from "../../../constants/checkTypes";
import { actions as checkActions } from "../../../store/slices/check";
import Field from "../../form/Field";
import FormikScrollToErrors from "../../misc/FormikScrollToError";

const { approvePep } = checkActions;

const PepApproveModal = ({
  setPepApproveModalOpened,
  approvePep,
  fetching,
  id,
}) => {
  const handleSubmit = (data) => {
    approvePep(id, data.notes);
    setPepApproveModalOpened(false);
  };
  const validationShape = {
    notes: Yup.string(),
  };

  return (
    <Modal isOpen={true} toggle={() => setPepApproveModalOpened((s) => !s)}>
      <ModalHeader toggle={() => setPepApproveModalOpened((s) => !s)} />
      <ModalBody>
        <p className="h5 text-center mb-3">
          Approve Potential {PEP_COPY} Match
        </p>
        <Formik
          validationSchema={Yup.object().shape(validationShape)}
          onSubmit={handleSubmit}
          enableReinitialize={true}
          initialValues={{ notes: "" }}
        >
          {({
            handleSubmit,
            errors,
            submitCount,
            isSubmitting,
            isValidating,
          }) => (
            <form noValidate onSubmit={handleSubmit} className="mt-5">
              <FormikScrollToErrors
                errors={errors}
                isSubmitting={isSubmitting}
                isValidating={isValidating}
              />
              <Field
                name="notes"
                error={errors.notes}
                submitCount={submitCount}
                label="Notes (optional)"
                component="textarea"
                placeholder="Your notes will only appear on PDF reports."
              />
              <div className="text-center">
                <Button
                  color="primary"
                  className="px-5 mt-3"
                  type="submit"
                  disabled={fetching}
                  block
                >
                  Approve
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

PepApproveModal.propTypes = {
  setPepApproveModalOpened: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

const mapStateToProps = ({ check }) => ({
  fetching: check.fetching,
});
const mapDispatchToProps = {
  approvePep,
};

export default connect(mapStateToProps, mapDispatchToProps)(PepApproveModal);
