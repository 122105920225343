import RcIf, { RcElse } from "rc-if";
import React, { useEffect } from "react";
import { connect } from "react-redux";

import NotFound from "../../../components/errors/NotFound";
import { actions } from "../../../store/slices/user";
import Loading from "../../misc/Loading";
import SettingsPresenter from "./Presenter";

const { fetchUser, saveAgency } = actions;

const Settings = ({ fetchUser, saveAgency, user }) => {
  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  return (
    <RcIf if={!!user.id}>
      <>
        {user.role === "ADMIN" ? (
          <SettingsPresenter {...user} saveAgency={saveAgency} />
        ) : (
          <NotFound />
        )}
      </>
      <RcElse>
        <Loading />
      </RcElse>
    </RcIf>
  );
};

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = { fetchUser, saveAgency };

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
