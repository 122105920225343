const parseGooglePlace = (place) => {
  const parsedAddress = {
    addressLine1: "",
    unitNo: "",
    addressLine2: "",
    city: "",
    state: "",
    postcode: "",
  };

  if (!place || !place.address_components) {
    return parsedAddress;
  }

  const addressComponents = {};
  place.address_components.forEach((addressComponent) => {
    addressComponent.types.forEach((type) => {
      addressComponents[type] = addressComponent.long_name;
    });
  });

  parsedAddress.addressLine1 = `${addressComponents.street_number || ""} ${
    addressComponents.route || ""
  }`.trim();
  parsedAddress.unitNo = addressComponents.subpremise || "";
  parsedAddress.addressLine2 = `${
    addressComponents.sublocality ||
    addressComponents.sublocality_level_1 ||
    addressComponents.sublocality_level_2 ||
    addressComponents.sublocality_level_3 ||
    addressComponents.sublocality_level_4 ||
    addressComponents.sublocality_level_5 ||
    addressComponents.neighborhood ||
    addressComponents.premise ||
    ""
  }`.trim();
  parsedAddress.city = `${addressComponents.locality || ""}`.trim();
  parsedAddress.state = `${
    addressComponents.administrative_area_level_1 ||
    addressComponents.administrative_area_level_2 ||
    addressComponents.administrative_area_level_3 ||
    addressComponents.administrative_area_level_4 ||
    ""
  }`.trim();
  parsedAddress.postalCode = `${addressComponents.postal_code || ""}`.trim();

  return parsedAddress;
};

export default parseGooglePlace;
