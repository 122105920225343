import { createAction, createReducer } from "@reduxjs/toolkit";
import { toastr } from "react-redux-toastr";

import CheckApi from "../../api/check";
import CompanyApi from "../../api/company";
import { SELF as TYPE_SELF } from "../../constants/checkGroupType";
import { COMPANY as COMPANY_TYPE } from "../../constants/checkTypes";
import { ERROR_WITH_CONTACT_INFO } from "../../constants/lang/en";
import logToSentryOrConsole from "../../helpers/sentry";
import { actions as verificationActions } from "./verification";

const { verifyCompany } = verificationActions;

const INITIAL_STATE = {
  fetching: false,
  fetchError: "",
  fetchCompleted: false,
  searchId: "",
  results: [],
};

const fetchCompanyStarted = createAction("fetchCompanyStarted");
const fetchCompanyCompleted = createAction("fetchCompanyCompleted");
const fetchCompanyFailed = createAction("fetchCompanyFailed");
const setSearchResult = createAction("setSearchResult");
const resetSearch = createAction("resetSearch");

const searchCompanies =
  ({ query }) =>
  async (dispatch) => {
    try {
      dispatch(fetchCompanyStarted());
      const result = await CompanyApi.searchCompanies({ query });
      dispatch(fetchCompanyCompleted());

      if (!result || !result.data) {
        return;
      }

      dispatch(setSearchResult(result.data));
    } catch (error) {
      logToSentryOrConsole(error);
      toastr.error("", ERROR_WITH_CONTACT_INFO);
      dispatch(fetchCompanyFailed());
    }
  };

const addCheckAndVerify =
  ({
    companyNZBN,
    companyCurrentName,
    companyCurrentAddress,
    companySearchId,
  }) =>
  async (dispatch) => {
    try {
      dispatch(fetchCompanyStarted());
      const verificationResult = await CheckApi.addVerificationGroup({
        reference: companyCurrentName,
        type: TYPE_SELF,
        notes: `New Zealand Business Number: ${companyNZBN}`,
      });

      if (
        !verificationResult ||
        !verificationResult.data ||
        !verificationResult.data.id
      ) {
        throw new Error("Something wrong with verification result");
      }

      const verificationUserResult = await CheckApi.addVerificationUser({
        firstName: "",
        lastName: "",
        checkType: COMPANY_TYPE,
        userVerificationId: verificationResult.data.id,
      });
      if (!verificationUserResult.data.signature_key) {
        throw new Error("Something wrong with verification data result");
      }

      dispatch(
        verifyCompany({
          signatureKey: verificationUserResult.data.signature_key,
          companyNZBN,
          companySearchId,
        })
      );

      dispatch(fetchCompanyCompleted());
    } catch (error) {
      logToSentryOrConsole(error);
      toastr.error("", ERROR_WITH_CONTACT_INFO);
      dispatch(fetchCompanyFailed());
    }
  };

const handleResetSearch = () => (dispatch) => {
  dispatch(resetSearch());
};

export const actions = {
  searchCompanies,
  addCheckAndVerify,
  handleResetSearch,
};

export const reducer = createReducer({ ...INITIAL_STATE }, (builder) => {
  builder
    .addCase(fetchCompanyStarted, (state) => {
      state.fetching = true;
      state.fetchError = "";
      state.fetchCompleted = false;
    })
    .addCase(fetchCompanyCompleted, (state) => {
      state.fetching = false;
      state.fetchCompleted = true;
    })
    .addCase(fetchCompanyFailed, (state, { payload }) => {
      state.fetching = false;
      state.fetchError = payload;
    })
    .addCase(
      setSearchResult,
      (state, { payload: { SearchId, MatchOnNameOnlyItems } }) => {
        state.searchId = SearchId;
        state.results = MatchOnNameOnlyItems;
      }
    )
    .addCase(resetSearch, () => ({ ...INITIAL_STATE }));
});
