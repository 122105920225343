import axios from "axios";
import queryString from "query-string";
import React from "react";
import { toastr } from "react-redux-toastr";
import { useParams } from "react-router-dom";

import { ERROR_REFRESH_WITH_CONTACT_INFO } from "../../../constants/lang/en";
import logToSentryOrConsole from "../../../helpers/sentry";
import { getDefaultHeaders } from "../../../helpers/token";
import Layout from "../../errors/Layout";

const CheckVideoDownload = () => {
  const { signatureKey } = useParams();
  const [downloading, setDownloading] = React.useState(true);

  React.useEffect(() => {
    const downloadFile = async () => {
      try {
        let response = null;
        const { fileName } = queryString.parse(location.search);
        if (fileName) {
          response = await axios.get(
            `${process.env.REACT_APP_API_URL}/files/download/${signatureKey}/file?file_name=${fileName}`,
            { ...getDefaultHeaders(), maxRedirects: 0 }
          );
        } else {
          const type = window.location.href.includes("liveness-video1")
            ? "liveness-video1"
            : "liveness-video2";
          response = await axios.get(
            `${process.env.REACT_APP_API_URL}/files/download/${signatureKey}/video?type=${type}`,
            { ...getDefaultHeaders(), maxRedirects: 0 }
          );
        }
        if (response && response.data) {
          setDownloading(false);
          window.location.href = response.data;
        } else {
          toastr.error(ERROR_REFRESH_WITH_CONTACT_INFO);
        }
      } catch (error) {
        toastr.error(ERROR_REFRESH_WITH_CONTACT_INFO);
        logToSentryOrConsole(error);
      }
    };
    downloadFile();
  }, [signatureKey]);

  return (
    <Layout
      subheading={
        downloading ? "Download in progress..." : "Download Completed"
      }
      showHomePageLink={false}
    />
  );
};

export default CheckVideoDownload;
