/*global richSnippetReviewsWidgets*/

import RcIf from "rc-if";
import React, { useEffect } from "react";
import { connect } from "react-redux";

import { PEP_COPY } from "../../constants/checkTypes";

const Pricing = ({ mini = false, defaultCountryCode }) => {
  useEffect(() => {
    if (!mini && typeof richSnippetReviewsWidgets !== "undefined") {
      richSnippetReviewsWidgets("carousel-inline-widget-360", {
        store: "realyou-co-nz",
        widgetName: "carousel-inline",
        primaryClr: "#f47e27",
        neutralClr: "#f4f4f4",
        reviewTextClr: "#2f2f2f",
        ratingTextClr: "#2f2f2f",
        layout: "fullWidth",
        numReviews: 21,
      });
    }
  }, [mini]);

  return (
    <div>
      <h2 className="h3 text-center mb-3">Realaml Rates</h2>
      <ul className="pricing-box p-3">
        {defaultCountryCode !== "CA" ? (
          <li className="price-item pt-0">
            <div className="price-item-header">
              <div className="price-item-name">FaceMatch</div>
              <div className="price-item-price">$4</div>
            </div>
            <div className="price-item-misc">Biometric facial recognition.</div>
          </li>
        ) : (
          <li className="price-item pt-0">
            <div className="price-item-header">
              <div className="price-item-name">FaceMatch</div>
              <div className="price-item-price">$5</div>
            </div>
            <div className="price-item-misc">Authentication method</div>
          </li>
        )}
        {defaultCountryCode !== "CA" ? (
          <li className="price-item pt-0">
            <div className="price-item-header">
              <div className="price-item-name">Quick IDV</div>
              <div className="price-item-price">$6</div>
            </div>
            <div className="price-item-misc">
              Verify name, DOB, address, {PEP_COPY} status + biometric facial
              recognition
            </div>
          </li>
        ) : (
          <li className="price-item pt-0">
            <div className="price-item-header">
              <div className="price-item-name">Quick IDV</div>
              <div className="price-item-price">$10</div>
            </div>
            <div className="price-item-misc">
              Dual process and/or credit file method
            </div>
          </li>
        )}
        {defaultCountryCode === "CA" && (
          <li className="price-item pt-0">
            <div className="price-item-header">
              <div className="price-item-name">Face IDV</div>
              <div className="price-item-price">$12.50</div>
            </div>
            <div className="price-item-misc">
              Dual process and/or credit file + authentication method
            </div>
          </li>
        )}
        {defaultCountryCode !== "CA" && (
          <li className="price-item">
            <div className="price-item-header">
              <div className="price-item-name">
                <abbr title="Politically Exposed Person">{PEP_COPY}</abbr> Check
              </div>
              <div className="price-item-price">$2</div>
            </div>
            <div className="price-item-misc">
              Politically exposed persons ({PEP_COPY}) check
            </div>
          </li>
        )}
        {defaultCountryCode !== "CA" && (
          <li className="price-item">
            <div className="price-item-header">
              <div className="price-item-name">Outsource Checks</div>
              <div className="price-item-price" />
            </div>
            <div className="price-item-misc price-item-misc-centered mt-1">
              <div className="price-item-misc-name">Overseas/Complex</div>
              <div className="price-item-misc-price">$100</div>
            </div>
            <div className="price-item-misc price-item-misc-centered mt-1">
              <div className="price-item-misc-name">Trusts</div>
              <div className="price-item-misc-price">$95</div>
            </div>
            <div className="price-item-misc price-item-misc-centered mt-1">
              <div className="price-item-misc-name">Companies</div>
              <div className="price-item-misc-price">$75</div>
            </div>
          </li>
        )}
        <li className="price-item-misc text-center mt-3">
          {defaultCountryCode === "CA" ? (
            <>
              Costs are charged in <abbr title="Canadian Dollars">CAD</abbr> (
              <abbr title="Harmonized Sales Tax">HST</abbr> free).
            </>
          ) : (
            <>
              Costs are exclusive of{" "}
              <abbr title="Goods and Service Tax">GST</abbr> and charged in{" "}
              <abbr title="New Zealand Dollars">NZD</abbr>.
            </>
          )}
        </li>
        <li className="price-item-misc text-center">
          Checks will be charged regardless of a pass or fail result.
        </li>
      </ul>

      <RcIf if={!mini}>
        <div className="d-flex justify-content-center">
          <ul className="checkmark-list m-0">
            <li>No contracts or monthly fees, cancel anytime</li>
            <li>PDF ID results stored for 5+ years</li>
            <li>Web based (no apps or selfies)</li>
          </ul>
        </div>
        <div className="d-flex justify-content-center mt-4 border-top py-4">
          <div id="carousel-inline-widget-360" />
        </div>
      </RcIf>
    </div>
  );
};

const mapStateToProps = ({ user }) => ({
  defaultCountryCode: user.defaultCountryCode,
});

export default connect(mapStateToProps)(Pricing);
