import PropTypes from "prop-types";
import RcIf from "rc-if";
import React from "react";
import { Link } from "react-router-dom";

import logo from "../../images/logo.png";

const Layout = ({
  subheading,
  image,
  bodyText,
  bodyText2,
  showHomePageLink,
}) => (
  <div className="container-fluid xs">
    <div className="mt-5 pt-5 pb-4 px-4">
      {image}
      {!!subheading && <h2 className="h3 mt-5">{subheading}</h2>}
    </div>
    <p className="lead px-4 pb-4">{bodyText}</p>
    {!!bodyText2 && <p className="lead px-4 pb-4">{bodyText2}</p>}
    <RcIf if={showHomePageLink}>
      <p className="lead pb-5 px-4">
        <Link to="/" className="btn btn-primary px-4">
          Take Me Home
        </Link>
      </p>
    </RcIf>
  </div>
);

Layout.propTypes = {
  subheading: PropTypes.node,
  image: PropTypes.node,
  bodyText: PropTypes.node,
  bodyText2: PropTypes.node,
  showHomePageLink: PropTypes.bool,
};

Layout.defaultProps = {
  subheading: "",
  image: (
    <img src={logo} alt="Realaml Logo" width="150" className="img-fluid" />
  ),
  bodyText: "",
  showHomePageLink: true,
};

export default Layout;
