import React from "react";
import { Button } from "reactstrap";

import DashboardLayout from "../../dashboard/Presenters/Layout";
import Download from "../../icons/DownloadIcon";
import StepHeader from "../../misc/StepHeader";
import StepLayout from "../../misc/StepLayout";

const Compliance = () => {
  return (
    <DashboardLayout>
      <StepLayout>
        <StepHeader text="Compliance" />
        <p>
          Book an obligation-free session below with one of our independent
          AML/CFT contractors to discuss any of the following:
        </p>
        <ul>
          <li>AML/CFT Management Consulting</li>
          <li>Staff Training</li>
          <li>Risk Assessments</li>
          <li>Compliance Programmes</li>
          <li>Pre-Audit Health Checks</li>
          <li>Independent Audits</li>
          <li>Post-Audit Remediation</li>
        </ul>
        <p>
          Our independent AML/CFT contractors include 'Certified Anti-Money
          Laundering Specialist' accredited members of the leading
          internationally recognised 'Association of Certified Anti-Money
          Laundering Specialists' (ACAMS).
        </p>
        <Button
          as="a"
          color="primary"
          className="px-5"
          block
          href="mailto:greg@acas.co.nz?subject=Book%20your%20FREE%2015-minute%20AML%20consultation&body=Hi%20Greg%2C%0D%0A%0D%0ACould%20you%20please%20let%20me%20know%20your%20availability%20for%20a%20FREE%2015-minute%20AML%20consultation."
        >
          Book your FREE 15-min AML consultation
        </Button>
      </StepLayout>
      <div className="container-fluid xs mt-3">
        <p className="text-center text-dark">
          Download MinterEllisonRuddWatts’ legal opinion that the independence
          requirement under the AML/CFT Act will be met.{" "}
          <a
            href="https://realaml.com/docs/MERW-advice-to-RealAML23892957.2.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className="text-primary position-relative"
            style={{ top: "-2px" }}
          >
            <Download size="22" />
          </a>
        </p>
      </div>
    </DashboardLayout>
  );
};

export default Compliance;
