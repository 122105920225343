import { Formik } from "formik";
import React, { useCallback, useEffect } from "react";
import {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Button } from "reactstrap";
import * as Yup from "yup";

import { MOBILE_CODE_RESENT, MOBILE_LABEL } from "../../../constants/lang/en";
import { REQUIRED_MSG } from "../../../constants/validationHelpers";
import { actions } from "../../../store/slices/user";
import Field from "../../form/Field";
import PhoneField from "../../form/PhoneField";
import FormikScrollToErrors from "../../misc/FormikScrollToError";

const { sendMobileCode, checkAndSaveMobile } = actions;

const VALIDATION_RULES_MOBILE = Yup.object().shape({
  mobile: Yup.string()
    .trim()
    .required(REQUIRED_MSG)
    .test("isValidPhone", "Please enter a valid phone number", (value) =>
      value ? isValidPhoneNumber(value) : true
    ),
});

const VALIDATION_RULES_CODE = Yup.object().shape({
  code: Yup.string().trim().required(REQUIRED_MSG),
});

const MobileModal = ({
  showModal,
  toggleModal,
  fetching,
  sendMobileCode,
  checkAndSaveMobile,
  mobile,
  onVerify,
}) => {
  const timer = React.useRef();
  const [resendTime, setResendTime] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [localMobile, setLocalMobile] = React.useState("");

  const resetResendTimer = useCallback(() => {
    clearInterval(timer.current);
    setResendTime(60);
    timer.current = setInterval(() => {
      setResendTime((time) => time - 1);
    }, 1000);
    return () => clearInterval(timer.current);
  }, []);

  useEffect(() => {
    if (resendTime < 1 && timer.current) {
      clearInterval(timer.current);
    }
  }, [resendTime]);

  const handleMobileFormSubmit = (data) => {
    setLocalMobile(data.mobile);
    sendMobileCode(data, () => setPage(1));
    resetResendTimer();
  };

  const handleMobileCodeResent = () => {
    handleMobileFormSubmit({ mobile: localMobile });
    toastr.success("", MOBILE_CODE_RESENT);
    resetResendTimer();
  };

  const handleVerify = () => {
    if (onVerify) onVerify();
    toggleModal();
  };

  const handleCodeFormSubmit = (data) => {
    checkAndSaveMobile(data, handleVerify);
  };

  return (
    <Modal isOpen={showModal} toggle={toggleModal} backdrop="static">
      <ModalHeader toggle={toggleModal}>
        {page === 0
          ? `Please enter your ${MOBILE_LABEL.toLowerCase()} number`
          : `Please verify your ${MOBILE_LABEL.toLowerCase()} number`}
      </ModalHeader>
      <ModalBody>
        {page === 0 ? (
          <Formik
            validationSchema={VALIDATION_RULES_MOBILE}
            onSubmit={handleMobileFormSubmit}
            initialValues={{ mobile }}
            enableReinitialize={true}
          >
            {({
              handleSubmit,
              errors,
              submitCount,
              isSubmitting,
              isValidating,
              setFieldValue,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit} className="mb-4">
                <FormikScrollToErrors
                  errors={errors}
                  isSubmitting={isSubmitting}
                  isValidating={isValidating}
                />
                <PhoneField
                  value={values.mobile}
                  setFieldValue={setFieldValue}
                  name="mobile"
                  error={errors.mobile}
                  submitCount={submitCount}
                  label={`${MOBILE_LABEL} Number`}
                  autoComplete="tel-national"
                  autoFocus
                />
                <p className="mb-4 text-center">
                  A 7 digit code will be sent to this number to verify
                  ownership.
                </p>
                <Button
                  color="primary"
                  block
                  size="lg"
                  disabled={fetching}
                  type="submit"
                >
                  Continue
                </Button>
              </form>
            )}
          </Formik>
        ) : (
          <Formik
            validationSchema={VALIDATION_RULES_CODE}
            onSubmit={handleCodeFormSubmit}
            initialValues={{ code: "", mobile: localMobile }}
            enableReinitialize={true}
          >
            {({
              handleSubmit,
              errors,
              submitCount,
              isSubmitting,
              isValidating,
            }) => (
              <form noValidate onSubmit={handleSubmit} className="mb-4">
                <FormikScrollToErrors
                  errors={errors}
                  isSubmitting={isSubmitting}
                  isValidating={isValidating}
                />
                <Field
                  name="code"
                  error={errors.code}
                  submitCount={submitCount}
                  label="Verification Code"
                  autoComplete="one-time-code"
                  autoFocus
                />
                <p className="mb-4 text-center">
                  Please enter the verification code sent to{" "}
                  {formatPhoneNumberIntl(localMobile)}
                  <br />
                  <Button
                    color="link"
                    disabled={fetching}
                    type="button"
                    className="p-0 ml-1 mt-2"
                    onClick={
                      resendTime > 0 ? undefined : handleMobileCodeResent
                    }
                  >
                    Resend{resendTime > 0 ? ` in ${resendTime} seconds` : ""}
                  </Button>
                </p>
                <Button
                  color="primary"
                  block
                  size="lg"
                  disabled={fetching}
                  type="submit"
                >
                  Verify {MOBILE_LABEL} Number
                </Button>
              </form>
            )}
          </Formik>
        )}
      </ModalBody>
    </Modal>
  );
};

const mapStateToProps = ({ user: { mobile, fetching, fetchError } }) => ({
  mobile,
  fetching,
  fetchError,
});
const mapDispatchToProps = { sendMobileCode, checkAndSaveMobile };

export default connect(mapStateToProps, mapDispatchToProps)(MobileModal);
