import React from "react";

import Layout from "./Layout";

const NotFound = () => (
  <Layout
    subheading={
      <>
        404. <span className="text-muted">That's an error.</span>
      </>
    }
    bodyText={
      <>
        The requested URL{" "}
        <mono className="px-2">{window.location.pathname}</mono> was not found
        on this server.
      </>
    }
  />
);

export default NotFound;
