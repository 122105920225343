import PropTypes from "prop-types";
import React from "react";
import { FaSpinner } from "react-icons/fa";

const Loading = ({ text }) => (
  <div className="text-center p-4 text-primary">
    <div className="display-4">
      <FaSpinner className="spin" />
    </div>
    <h3 className="h4 mt-4">{text}...</h3>
  </div>
);

Loading.propTypes = {
  text: PropTypes.string,
};

Loading.defaultProps = {
  text: "Loading",
};

export default Loading;
