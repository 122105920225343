import axios from "axios";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import qs from "query-string";
import RcIf, { RcElse } from "rc-if";
import React, { useCallback, useState } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";

import logToSentryOrConsole from "../../helpers/sentry";
import { getDefaultHeaders } from "../../helpers/token";
import SimpleDate from "../form/SimpleDate";
import Field from "./Field";

const IdentityFields = ({
  errors,
  submitCount,
  autoComplete,
  showMiddleName,
  showDateOfBirth,
  birthDate,
  autoFocusFirstName,
  innerFirstNameRef,
  innerMiddleNameRef,
  innerLastNameRef,
  setFieldValue,
  hint,
  firstNamePlaceholder,
  lastNamePlaceholder,
  middleNamePlaceholder,
  firstNameLabel,
  lastNameLabel,
  middleNameLabel,
  showFirstNameWarning,
  optionalBirthDate,
  runUniquenessCheck,
}) => {
  const { values } = useFormikContext();
  const [shownPopup, setShownPopup] = useState(false);
  const [duplicateAgencyNames, setDuplicateAgencyNames] = React.useState([]);

  const closeDuplicateModal = React.useCallback(
    () => setDuplicateAgencyNames([]),
    []
  );

  const checkForUniqueness = useCallback(async () => {
    if (!runUniquenessCheck || !values.firstName || !values.lastName) return;
    try {
      const queries = { query: `${values.firstName} ${values.lastName}` };
      const response = await axios.get(
        `${
          process.env.REACT_APP_API_URL
        }/verification-uniqueness/?${qs.stringify(queries)}`,
        { ...getDefaultHeaders() }
      );
      if (response && response.data) {
        setDuplicateAgencyNames(response.data);
      }
    } catch (error) {
      logToSentryOrConsole(error);
    }
  }, [runUniquenessCheck, values.firstName, values.lastName]);

  const handleFirstNameBlur = React.useCallback(() => {
    const firstName = values.firstName;
    if (showFirstNameWarning && !shownPopup && firstName) {
      const firstNameSplits = firstName.trim().split(" ");
      if (firstNameSplits.length > 1) {
        setShownPopup(true);
        alert(
          `Hi there, if ${firstName
            .replace(firstNameSplits[0], "")
            .trim()} is the middle name, please enter it in the Middle name field. Otherwise, the identity check may fail.`
        );
      }
    }
  }, [values.firstName, showFirstNameWarning, shownPopup]);

  const lastNameProps = React.useMemo(() => ({
    name: "lastName",
    error: errors.lastName,
    submitCount: submitCount,
    label: lastNameLabel,
    autoComplete: autoComplete ? "family-name" : "off",
    innerRef: innerLastNameRef,
    placeholder: lastNamePlaceholder,
    onBlur: checkForUniqueness,
  }));

  return (
    <>
      {!!hint && <p className="small text-primary mb-2">{hint}</p>}

      <div className="form-row">
        <div className="col-6">
          <Field
            name="firstName"
            error={errors.firstName}
            submitCount={submitCount}
            label={firstNameLabel}
            autoComplete={autoComplete ? "given-name" : "off"}
            innerRef={innerFirstNameRef}
            autoFocus={autoFocusFirstName}
            placeholder={firstNamePlaceholder}
            onBlur={handleFirstNameBlur}
          />
        </div>
        <div className="col-6">
          <RcIf if={showMiddleName}>
            <Field
              name="middleName"
              error={errors.middleName}
              submitCount={submitCount}
              label={middleNameLabel}
              autoComplete={autoComplete ? "additional-name" : "off"}
              innerRef={innerMiddleNameRef}
              placeholder={middleNamePlaceholder}
            />
            <RcElse>
              <Field {...lastNameProps} />
            </RcElse>
          </RcIf>
        </div>
      </div>

      <div className="form-row">
        <RcIf if={showMiddleName}>
          <div className="col-6">
            <Field {...lastNameProps} />
          </div>
        </RcIf>

        <RcIf if={showDateOfBirth}>
          <div className="col-6">
            <SimpleDate
              name="birthDate"
              value={birthDate}
              setFieldValue={setFieldValue}
              error={errors.birthDate}
              submitCount={submitCount}
              label={`Date of Birth${optionalBirthDate ? " (optional)" : ""}`}
            />
          </div>
        </RcIf>
      </div>

      <Modal
        isOpen={!!duplicateAgencyNames.length}
        toggle={closeDuplicateModal}
      >
        <ModalHeader toggle={closeDuplicateModal}>Duplicates found</ModalHeader>
        <ModalBody>
          <p>
            Verification with the name{" "}
            <strong>
              {values.firstName} {values.lastName}
            </strong>{" "}
            already found in the following agencies
          </p>
          <ul className="pl-4">
            {duplicateAgencyNames.map((agencyName, index) => (
              <li key={index}>
                <strong>{agencyName}</strong>
              </li>
            ))}
          </ul>

          <div className="row align-items-center">
            <div className="col-6">
              <Button
                color="primary"
                onClick={() => window.location.reload()}
                block
              >
                Cancel
              </Button>
            </div>
            <div className="col-6">
              <Button
                color="primary"
                onClick={closeDuplicateModal}
                block
                outline
              >
                Continue
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

IdentityFields.propTypes = {
  errors: PropTypes.object.isRequired,
  submitCount: PropTypes.number.isRequired,
  showMiddleName: PropTypes.bool,
  showDateOfBirth: PropTypes.bool,
  autoComplete: PropTypes.bool,
  innerFirstNameRef: PropTypes.object,
  innerLastNameRef: PropTypes.object,
  innerMiddleNameRef: PropTypes.object,
  autoFocusFirstName: PropTypes.bool.isRequired,
  birthDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  setFieldValue: PropTypes.func.isRequired,
  hint: PropTypes.string,
  firstNamePlaceholder: PropTypes.string,
  lastNamePlaceholder: PropTypes.string,
  middleNamePlaceholder: PropTypes.string,
  showFirstNameWarning: PropTypes.bool,
  optionalBirthDate: PropTypes.bool,
  runUniquenessCheck: PropTypes.bool,
};

IdentityFields.defaultProps = {
  showMiddleName: true,
  showDateOfBirth: true,
  autoComplete: true,
  autoFocusFirstName: false,
  firstNamePlaceholder: "",
  lastNamePlaceholder: "",
  middleNamePlaceholder: "",
  firstNameLabel: "First name",
  middleNameLabel: "Middle name",
  lastNameLabel: "Last name",
  showFirstNameWarning: true,
  optionalBirthDate: false,
  runUniquenessCheck: false,
};

export default IdentityFields;
