import PropTypes from "prop-types";
import React, { useCallback, useMemo } from "react";
import { Tooltip } from "react-tippy";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import ReadMoreReact from "read-more-react";

import checkTypes from "../../../constants/checkTypes";
import results, { PENDING as resultPending } from "../../../constants/results";
import { formatDateTime, isDateValidForLogs } from "../../../helpers/date";
import { constructOutsourcedDownloadUrl } from "../../../helpers/files";
import Ellipsis from "../../icons/EllipsisIcon";
import OutSourcedIcon from "../../icons/OutSourcedCheckHistoryIcon";
import LogsModal from "../checkHistory/logsModal";
import UpdateModal from "./updateModal";

const Check = ({
  check,
  deleteCheck,
  isAdminOrManager,
  namePriority,
  dashboardCanDelete,
  dashboardCanEdit,
  dashboardShowPrice,
}) => {
  const [actionsDropdown, setActionsDropdown] = React.useState(false);
  const [updateModalOpened, setUpdateModalOpened] = React.useState(false);
  const [logsModalOpened, setLogsModalOpened] = React.useState(false);

  const actionsDropdownToggle = useCallback(() => {
    setActionsDropdown((prevState) => !prevState);
  }, []);

  const handleDownloadCheck = useCallback(() => {
    window.open(constructOutsourcedDownloadUrl(check.signature_key));
  }, [check.signature_key]);

  const handleDeleteCheck = useCallback(() => {
    const confirm = window.confirm(
      "Are you sure you want to delete this check?"
    );
    if (confirm) {
      deleteCheck(check.signature_key);
    }
  }, [deleteCheck, check.signature_key]);

  const checkType = useMemo(() => {
    const checkTypeObj = checkTypes.find((ct) => ct.key === check.type);
    return checkTypeObj ? checkTypeObj.label.replace("OutSourced - ", "") : "";
  }, [check.type]);

  const getResultType = useCallback((key) => {
    const result = results.find((r) => r.key === key);
    return result ? result : results.find((r) => r.key === resultPending);
  }, []);
  const outsourcedResultType = useMemo(
    () => getResultType(check.status),
    [check.status]
  );

  const showReferenceFirst = !namePriority && check.reference;

  return (
    <div className="check-row">
      <div className="check-flex">
        <div className="check-flex-left">
          <h2 className="h6 text-secondary m-0">
            {checkType}{" "}
            {isAdminOrManager && (
              <span className="small">
                ({check.notify_email || check.agent_email})
              </span>
            )}
          </h2>

          <h3 className="h5 mb-0 mt-3">
            {showReferenceFirst ? check.reference : check.name}
          </h3>

          {(showReferenceFirst || !!check.reference) && (
            <p className="font-weight-bold mb-0 mt-3">
              {showReferenceFirst ? check.name : check.reference}
            </p>
          )}

          {!!check.notes && (
            <div style={{ whiteSpace: "pre-line" }} key={check.notes}>
              <ReadMoreReact
                text={check.notes}
                ideal={335}
                min={1}
                max={999999}
                readMoreText="See more..."
              />
            </div>
          )}
        </div>
        <div className="check-flex-right mr-0">
          <div className="check-flex-right-item">
            <span className="h6 text-secondary">
              {formatDateTime(check.created_at)}
            </span>
            {dashboardShowPrice && !!check.estimated_cost && (
              <span className="h6 ml-2 check-price">
                ${check.estimated_cost} {check.cost_tbc ? "(TBC)" : ""} + GST
              </span>
            )}
          </div>
          <div className="check-flex-right-item mt-4">
            <Tooltip
              title={`${
                check.overall_result
                  ? check.overall_result
                  : outsourcedResultType.label
              }`}
              position="top"
              size="small"
            >
              <span className="ml-3 result-icon-outsourced">
                <OutSourcedIcon
                  type={
                    check.overall_result === "FAIL"
                      ? "danger"
                      : outsourcedResultType.type
                  }
                />
              </span>
            </Tooltip>
            <Tooltip title="More Actions" position="top" size="small">
              <Dropdown
                isOpen={actionsDropdown}
                toggle={actionsDropdownToggle}
                size="sm"
                className="ml-4"
              >
                <DropdownToggle color="" className="text-muted p-0">
                  <Ellipsis />
                </DropdownToggle>
                <DropdownMenu right>
                  {!!check.final_report_present && (
                    <DropdownItem onClick={handleDownloadCheck}>
                      Download
                    </DropdownItem>
                  )}
                  {dashboardCanEdit && check.status !== "completed" && (
                    <DropdownItem onClick={() => setUpdateModalOpened(true)}>
                      Edit
                    </DropdownItem>
                  )}
                  {isDateValidForLogs(check.created_at) && (
                    <DropdownItem onClick={() => setLogsModalOpened(true)}>
                      Audit Trail
                    </DropdownItem>
                  )}
                  {dashboardCanDelete && (
                    <DropdownItem onClick={handleDeleteCheck}>
                      Delete
                    </DropdownItem>
                  )}
                </DropdownMenu>
              </Dropdown>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className="check-flex">
        {!!check.waiting_on && (
          <div className="mt-2 mt-md-4 check-flex-left-ordered mr-0 mr-md-4 pr-0 pr-md-4">
            <h3 className="h6">Waiting On</h3>
            <p className="mb-2">{check.waiting_on}</p>
          </div>
        )}
        {!!check.individuals?.length && (
          <div className="mt-4 check-flex-left-ordered">
            <h3 className="h6">Individual Results</h3>
            <div className="table-responsive" style={{ maxWidth: "460px" }}>
              <table className="table table-bordered table-sm small companies-result-table">
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Name</th>
                    <th>Country</th>
                    <th>Result</th>
                  </tr>
                </thead>
                <tbody>
                  {check.individuals.map((individual, index) => (
                    <tr key={index}>
                      <td>{individual.individual_type}</td>
                      <td>
                        {individual.first_name} {individual.last_name}
                      </td>
                      <td>{individual.country}</td>
                      <td>{individual.result || "-"}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
      {updateModalOpened && (
        <UpdateModal
          setUpdateModalOpened={setUpdateModalOpened}
          outsourcedCheck={check}
        />
      )}
      {logsModalOpened && (
        <LogsModal
          setLogsModalOpened={setLogsModalOpened}
          id={check.signature_key}
          type="outsource_key"
        />
      )}
    </div>
  );
};

Check.propTypes = {
  check: PropTypes.object.isRequired,
  deleteCheck: PropTypes.func.isRequired,
  isAdminOrManager: PropTypes.bool.isRequired,
  namePriority: PropTypes.bool.isRequired,
  dashboardShowPrice: PropTypes.bool.isRequired,
  dashboardCanDelete: PropTypes.bool.isRequired,
  dashboardCanEdit: PropTypes.bool.isRequired,
};

export default Check;
