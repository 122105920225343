import axios from "axios";
import { toastr } from "react-redux-toastr";

import {
  AGENCY_URL,
  SESSION_URL,
  TOKEN_REFRESH_URL,
  USER_PASSWORD_URL,
} from "./api/apiUrls";
import { NOT_AUTHORISED } from "./constants/lang/en";
import {
  getRefreshToken,
  removeAuthTokens,
  setAuthTokens,
} from "./helpers/token";
import history from "./history";
import routes from "./routes";

const initAxios = () => {
  axios.interceptors.response.use(
    // Return a successful response back to the calling service
    (response) => {
      return response;
    },

    (error) => {
      // Return any error which is not due to
      // authentication or
      // in login or Password change URL
      // back to the calling service
      if (
        !error ||
        !error.response ||
        !error.config ||
        error.response.status !== 401 ||
        [
          USER_PASSWORD_URL,
          SESSION_URL,
          `${process.env.REACT_APP_PROD_CA_API_URL}/session/`,
          AGENCY_URL,
        ].includes(error.config.url)
      ) {
        return new Promise((_resolve, reject) => {
          reject(error);
        });
      }

      // Logout user if token refresh didn't work
      if (
        (error && error.config && error.config.url === TOKEN_REFRESH_URL) ||
        (error &&
          error.response &&
          error.response.data &&
          ["user_inactive", "user_not_found"].includes(
            error.response.data.code
          ))
      ) {
        removeAuthTokens();
        history.push(routes.signIn);
        toastr.error(NOT_AUTHORISED);
        return new Promise((_resolve, reject) => {
          reject(error);
        });
      }

      // Try request again with new token
      const refreshToken = getRefreshToken();
      return axios
        .post(TOKEN_REFRESH_URL, { refresh: refreshToken })
        .then(({ data: { access } }) => {
          // Set tokens
          setAuthTokens(access, refreshToken);

          // New request with new token
          const config = error.config;
          config.headers["Authorization"] = `Bearer ${access}`;

          return new Promise((resolve, reject) => {
            axios
              .request(config)
              .then((response) => {
                resolve(response);
              })
              .catch((error) => {
                reject(error);
              });
          });
        })
        .catch((error) => {
          Promise.reject(error);
        });
    }
  );
};

export default initAxios;
