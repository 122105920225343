export const getBgColor = (type) =>
  type == "danger"
    ? "#fbecec"
    : type == "success"
    ? "#e5faef"
    : type == "warning"
    ? "#fbf6ea"
    : type == "info"
    ? "#f4e5fa"
    : "#EAF5FF";
