import { createAction, createReducer } from "@reduxjs/toolkit";
import axios from "axios";
import { toastr } from "react-redux-toastr";

import CheckApi from "../../api/check";
import { EMAIL as TYPE_EMAIL } from "../../constants/checkGroupType";
import {
  FACEMATCH,
  PEP_COPY,
  QUICK_ID,
  QUICK_ID_FACEMATCH,
  PEP as TYPE_PEP,
  PEP_COMPANY as TYPE_PEP_COMPANY,
} from "../../constants/checkTypes";
import { ERROR_WITH_CONTACT_INFO } from "../../constants/lang/en";
import { COMPLETE_PROFILE, PAYMENT_IN_REVIEW } from "../../constants/lang/en";
import parseDate from "../../helpers/date";
import { uploadFiles } from "../../helpers/files";
import { triggerPaymentFailedEvent } from "../../helpers/intercom";
import logToSentryOrConsole from "../../helpers/sentry";
import { getSignatureKey } from "../../helpers/token";
import { getDefaultHeaders } from "../../helpers/token";
import ROUTES from "../../routes";
import { actions as historyActions } from "./history";
import { actions as outsourcedHistoryActions } from "./outsourcedHistory";
import { actions as userActions } from "./user";
import { actions as verificationActions } from "./verification";

const { runQuickId, verifyPep, generateResult } = verificationActions;
const { fetchUser, handleSetInCompleteProfile } = userActions;
const { fetchHistory } = historyActions;
const { fetchOutsourcedHistory } = outsourcedHistoryActions;

const refetchHistory = (dispatch) => {
  if (window.location.pathname === ROUTES.checksHistory) {
    dispatch(fetchHistory());
  } else if (window.location.pathname === ROUTES.outSourcedHistory) {
    dispatch(fetchOutsourcedHistory());
  }
};

const INITIAL_DRAFT_STATE = {
  totalChecks: 0,
  activeCheck: 0,
  checks: [],
  reference: "",
  type: "",
  notes: "",
};

const INITIAL_STATE = {
  fetching: false,
  fetchError: "",
  fetchCompleted: false,
  draft: { ...INITIAL_DRAFT_STATE },
};

const fetchCheckStarted = createAction("fetchCheckStarted");
const fetchCheckCompleted = createAction("fetchCheckCompleted");
const fetchCheckFailed = createAction("fetchCheckFailed");
const setNewCheck = createAction("setNewCheck");
const setIndividualCheck = createAction("setIndividualCheck");
const nextCheck = createAction("nextCheck");
const prevCheck = createAction("prevCheck");
const resetDraftChecks = createAction("resetDraftChecks");
const resetCheck = createAction("resetCheck");
const updateDraftCheck = createAction("updateDraftCheck");
const setDraftCheckResult = createAction("setDraftCheckResult");

const startNewCheck =
  ({ totalChecks, reference, type, notes }) =>
  async (dispatch) => {
    dispatch(fetchCheckStarted());
    const result = await CheckApi.addVerificationGroup({
      reference,
      type,
      notes,
    });
    dispatch(fetchCheckCompleted());

    if (!result || !result.data) {
      return;
    }

    dispatch(
      setNewCheck({
        totalChecks: parseInt(totalChecks, 10),
        reference,
        type,
        notes,
        id: result.data.id,
      })
    );
  };

const updateCheckGroup =
  ({ reference, id, notes }) =>
  async (dispatch) => {
    dispatch(fetchCheckStarted());
    await CheckApi.updateVerificationGroup({
      reference,
      id,
      notes,
    });
    dispatch(fetchCheckCompleted());
    refetchHistory(dispatch);
    toastr.success("Changes saved.");
  };

const goToNextCheck = () => (dispatch, getState) => {
  const {
    check: { draft },
  } = getState();
  if (draft.activeCheck + 1 === draft.totalChecks) {
    dispatch(resetCheck());
  } else {
    dispatch(nextCheck());
  }
};

const goToPrevCheck = () => (dispatch) => {
  dispatch(prevCheck());
};

const handleResetCheck = () => (dispatch) => {
  dispatch(resetCheck());
};

const addCheckUser =
  (check, faceMatchCollectSecondaryId = false) =>
  async (dispatch, getState) => {
    dispatch(setIndividualCheck(check));
    dispatch(fetchCheckStarted());
    const {
      check: { draft },
    } = getState();
    try {
      const activeCheck = draft.checks[draft.activeCheck];
      const userVerificationId = draft.id;
      const {
        firstName,
        lastName,
        middleName,
        email,
        phone,
        checkType,
        countryCode,
        runPep,
        runPlusChecks,
      } = activeCheck;

      await CheckApi.addVerificationUser({
        firstName,
        lastName,
        middleName,
        email,
        phone,
        checkType,
        countryCode,
        userVerificationId,
        faceMatchCollectSecondaryId,
        runPep,
        runPlusChecks,
      });

      dispatch(fetchCheckCompleted());
      dispatch(fetchUser());
      toastr.success(
        `1 ${
          checkType === FACEMATCH
            ? "FaceMatch"
            : checkType === QUICK_ID
            ? "Quick IDV"
            : checkType === QUICK_ID_FACEMATCH
            ? "Face IDV"
            : ""
        } sent successfully.`
      );
      if (draft.activeCheck + 1 >= draft.totalChecks) {
        dispatch(resetDraftChecks());
      } else {
        dispatch(goToNextCheck());
      }
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.status === 400 &&
        error.response.data &&
        error.response.data.reason === "Invalid phone"
      ) {
        dispatch(
          setIndividualCheck({
            ...check,
            signatureKey: error.response.data.signature_key,
          })
        );
        dispatch(fetchCheckFailed(error.response.data.reason));
      } else if (error && error.response && error.response.status === 402) {
        toastr.error("", PAYMENT_IN_REVIEW);
        dispatch(fetchCheckFailed());
      } else if (error && error.response && error.response.status === 403) {
        toastr.error("", COMPLETE_PROFILE);
        if (error.response.data && error.response.data.payment) {
          triggerPaymentFailedEvent();
        }
        dispatch(fetchCheckFailed());
        dispatch(handleSetInCompleteProfile(error.response.data));
      } else {
        logToSentryOrConsole(error);
        toastr.error("", ERROR_WITH_CONTACT_INFO);
        dispatch(fetchCheckFailed());
      }
    }
  };

const resendCheck = (id, callback) => async (dispatch) => {
  dispatch(fetchCheckStarted());
  await CheckApi.resendCheck(id);
  dispatch(fetchCheckCompleted());
  if (callback) callback();
  toastr.success("Verification resent successfully.");
};

const deleteCheck = (id) => async (dispatch) => {
  dispatch(fetchCheckStarted());
  await CheckApi.deleteCheck(id);
  dispatch(fetchCheckCompleted());
  refetchHistory(dispatch);
  toastr.success("Verification deleted successfully.");
};

const disableMonitoring = (id) => async (dispatch) => {
  dispatch(fetchCheckStarted());
  await CheckApi.disableMonitoring(id);
  dispatch(fetchCheckCompleted());
  refetchHistory(dispatch);
  toastr.success("Monitoring disabled successfully.");
};

const approveFaceMatch = (id) => async (dispatch) => {
  dispatch(fetchCheckStarted());
  await CheckApi.approveFaceMatch(id);
  dispatch(fetchCheckCompleted());
  refetchHistory(dispatch);
  toastr.success("FaceMatch approved successfully.");
};

const approvePep = (id, notes) => async (dispatch) => {
  dispatch(fetchCheckStarted());
  await CheckApi.approvePep(id, notes);
  dispatch(fetchCheckCompleted());
  refetchHistory(dispatch);
  toastr.success(`${PEP_COPY} approved successfully.`);
};

const resetVerification =
  (id, isSelfCheckQuickId, callback) => async (dispatch) => {
    dispatch(fetchCheckStarted());
    await CheckApi.resetCheck(id);
    if (isSelfCheckQuickId) {
      setTimeout(
        () => window.open(`${process.env.REACT_APP_USER_APP_URL}/${id}`),
        1500
      );
    } else {
      await CheckApi.resendCheck(id);
    }
    if (callback) callback();
    toastr.success("Verification reset and re-sent successfully.");
    dispatch(fetchCheckCompleted());
  };

const updateCheckUserAndResend =
  (check, msg = "") =>
  async (dispatch, getState) => {
    const {
      check: { draft },
    } = getState();
    try {
      const activeCheck = draft.checks[draft.activeCheck];

      if (!check) {
        const signatureKey = getSignatureKey();
        dispatch(fetchCheckStarted());
        await CheckApi.resendCheck(signatureKey || activeCheck.signatureKey);
        dispatch(fetchCheckCompleted());
        return;
      }

      const updatedCheck = { ...activeCheck, ...check };
      dispatch(setIndividualCheck(updatedCheck));
      dispatch(fetchCheckStarted());
      const userVerificationDataId = updatedCheck.signatureKey;
      const { firstName, lastName, middleName, email, phone, countryCode } =
        updatedCheck;

      await CheckApi.updateVerificationUser({
        firstName,
        lastName,
        middleName,
        email,
        phone,
        countryCode,
        userVerificationDataId,
      });

      await CheckApi.resendCheck(userVerificationDataId);

      dispatch(fetchCheckCompleted());
      if (msg) toastr.success(msg);
      if (draft.activeCheck + 1 >= draft.totalChecks) {
        dispatch(resetDraftChecks());
      } else {
        dispatch(goToNextCheck());
      }
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.status === 400 &&
        error.response.data &&
        error.response.data.reason === "Invalid phone"
      ) {
        const check = draft.checks[draft.activeCheck];
        dispatch(
          setIndividualCheck({
            ...check,
            signatureKey: error.response.data.signature_key,
          })
        );
        dispatch(fetchCheckFailed(error.response.data.reason));
      } else {
        logToSentryOrConsole(error);
        toastr.error("", ERROR_WITH_CONTACT_INFO);
        dispatch(fetchCheckFailed());
      }
    }
  };

const addCheckAndVerify =
  (data, files, checkID = "") =>
  async (dispatch, getState) => {
    dispatch(fetchCheckStarted());
    const {
      check: { draft },
    } = getState();

    // Add Verification Data
    try {
      const result = await CheckApi.addVerificationUser({
        firstName: data.firstName,
        lastName: data.lastName,
        middleName: data.middleName,
        checkType: data.checkType,
        phone: data.phone,
        email: data.email,
        checkType: data.checkType,
        userVerificationId: checkID || draft.id,
        countryCode: data.countryCode,
      });

      if (!result || !result.data) {
        return;
      }

      const {
        userId,
        signature_key: signatureKey,
        firstName,
        lastName,
        middleName,
        phone,
        checkType,
      } = result.data;
      dispatch(
        setIndividualCheck({
          id: userId,
          signatureKey,
          firstName,
          lastName,
          middleName,
          phone,
          checkType,
        })
      );

      // Upload Files
      if (files && files.length) {
        await uploadFiles(signatureKey, files);
      }

      switch (data.checkType) {
        case QUICK_ID_FACEMATCH:
        case QUICK_ID: // Do Global verification
          dispatch(runQuickId({ signatureKey, ...data }));
          break;

        case TYPE_PEP: // Do PEP verification
        case TYPE_PEP_COMPANY:
          dispatch(
            verifyPep({
              signatureKey,
              birthDate: data.birthDate
                ? parseDate(data.birthDate).trim()
                : null,
            })
          );
          break;

        default:
          // Generate verification result
          dispatch(generateResult({ signatureKey }));
      }

      dispatch(setDraftCheckResult());
      dispatch(fetchCheckCompleted());
      dispatch(fetchUser());
    } catch (error) {
      if (error && error.response && error.response.status === 402) {
        toastr.error("", PAYMENT_IN_REVIEW);
        dispatch(fetchCheckFailed());
      } else if (error && error.response && error.response.status === 403) {
        toastr.error("", COMPLETE_PROFILE);
        if (error.response.data && error.response.data.payment) {
          triggerPaymentFailedEvent();
        }
        dispatch(fetchCheckFailed());
        dispatch(handleSetInCompleteProfile(error.response.data));
      } else {
        logToSentryOrConsole(error);
        toastr.error("", ERROR_WITH_CONTACT_INFO);
        dispatch(fetchCheckFailed());
      }
    }
  };

const addOutSourced = (data, files) => async (dispatch, getState) => {
  try {
    const result = await CheckApi.addVerificationGroup({
      reference: data.reference,
      type: TYPE_EMAIL,
      notes: data.notes,
    });

    if (result && result.data && result.data.id) {
      dispatch(fetchCheckStarted());
      // const { user: { id: userId }} = getState();

      const outsourcedResult = await CheckApi.addOutsourcedVerification({
        checkType: data.checkType,
        name: data.name,
        country: data.country,

        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phone: data.phone,
        natureOfTransaction: data.natureOfTransaction,
        isFacematchRequired: data.isFacematchRequired,
        isCompanyPepRequired: data.isCompanyPepRequired,

        userVerificationId: result.data.id,
      });

      if (!outsourcedResult || !outsourcedResult.data) return;

      // Upload Files
      if (files && files.length)
        await uploadFiles(
          outsourcedResult.data.signature_key,
          files,
          "outsourced-verification"
        );

      dispatch(fetchCheckCompleted());
      dispatch(fetchUser());
    }
  } catch (error) {
    if (error && error.response && error.response.status === 402) {
      toastr.error("", PAYMENT_IN_REVIEW);
      dispatch(fetchCheckFailed());
    } else if (error && error.response && error.response.status === 403) {
      toastr.error("", COMPLETE_PROFILE);
      if (error.response.data && error.response.data.payment) {
        triggerPaymentFailedEvent();
      }
      dispatch(fetchCheckFailed());
      dispatch(handleSetInCompleteProfile(error.response.data));
    } else {
      logToSentryOrConsole(error);
      toastr.error("", ERROR_WITH_CONTACT_INFO);
      dispatch(fetchCheckFailed());
    }
  }
};

const uploadVerificationFiles = (signatureKey, files) => async (dispatch) => {
  dispatch(fetchCheckStarted());
  try {
    await uploadFiles(signatureKey, files);
    dispatch(fetchCheckCompleted());
    refetchHistory(dispatch);
    toastr.success("File(s) uploaded successfully.");
  } catch (error) {
    logToSentryOrConsole(error);
    toastr.error("", ERROR_WITH_CONTACT_INFO);
    dispatch(fetchCheckFailed());
  }
};

const deleteVerificationFiles = (signatureKey, fileId) => async (dispatch) => {
  dispatch(fetchCheckStarted());
  try {
    await axios.delete(
      `${process.env.REACT_APP_API_URL}/verification/${signatureKey}/files/${fileId}/`,
      getDefaultHeaders()
    );
    dispatch(fetchCheckCompleted());
    refetchHistory(dispatch);
    toastr.success("File deleted successfully.");
  } catch (error) {
    logToSentryOrConsole(error);
    toastr.error("", ERROR_WITH_CONTACT_INFO);
    dispatch(fetchCheckFailed());
  }
};

const approveAddressResult = (signatureKey, files) => async (dispatch) => {
  dispatch(fetchCheckStarted());
  try {
    await uploadFiles(signatureKey, files);
    await CheckApi.approveAddress(signatureKey);
    dispatch(fetchCheckCompleted());
    refetchHistory(dispatch);
    toastr.success("Address attached successfully.");
  } catch (error) {
    logToSentryOrConsole(error);
    toastr.error("", ERROR_WITH_CONTACT_INFO);
    dispatch(fetchCheckFailed());
  }
};

const approveQuickId = (signatureKey) => async (dispatch) => {
  dispatch(fetchCheckStarted());
  try {
    await CheckApi.approveAddress(signatureKey);
    dispatch(fetchCheckCompleted());
    refetchHistory(dispatch);
    toastr.success("Quick IDV approved successfully.");
  } catch (error) {
    logToSentryOrConsole(error);
    toastr.error("", ERROR_WITH_CONTACT_INFO);
    dispatch(fetchCheckFailed());
  }
};

const updateCheckContacts =
  ({ id, email, phone, firstName, middleName, lastName }) =>
  async (dispatch) => {
    dispatch(fetchCheckStarted());
    try {
      await CheckApi.updateVerificationUser({
        email: email || "",
        phone: phone || "",
        firstName: firstName || "",
        middleName: middleName || "",
        lastName: lastName || "",
        userVerificationDataId: id,
      });
      await CheckApi.resendCheck(id);
      dispatch(fetchCheckCompleted());
      refetchHistory(dispatch);
      toastr.success("Changes saved and verification re-sent successfully.");
    } catch (error) {
      logToSentryOrConsole(error);
      toastr.error("", ERROR_WITH_CONTACT_INFO);
      dispatch(fetchCheckFailed());
    }
  };

export const actions = {
  startNewCheck,
  goToNextCheck,
  goToPrevCheck,
  resetCheck: handleResetCheck,
  addCheckUser,
  updateCheckUserAndResend,
  addCheckAndVerify,
  addOutSourced,
  resendCheck,
  deleteCheck,
  disableMonitoring,
  approveFaceMatch,
  approvePep,
  approveQuickId,
  resetVerification,
  updateCheckGroup,
  approveAddressResult,
  updateCheckContacts,
  uploadVerificationFiles,
  deleteVerificationFiles,
};

export const reducer = createReducer({ ...INITIAL_STATE }, (builder) => {
  builder
    .addCase(fetchCheckStarted, (state) => {
      state.fetching = true;
      state.fetchError = "";
      state.fetchCompleted = false;
    })
    .addCase(fetchCheckCompleted, (state) => {
      state.fetching = false;
      state.fetchCompleted = true;
    })
    .addCase(fetchCheckFailed, (state, { payload }) => {
      state.fetching = false;
      state.fetchError = payload;
    })
    .addCase(
      setNewCheck,
      (
        state,
        { payload: { id, totalChecks, reference, type, notes = "" } }
      ) => {
        state.draft.totalChecks = totalChecks;
        state.draft.reference = reference;
        state.draft.type = type;
        state.draft.notes = notes;
        state.draft.id = id;
      }
    )
    .addCase(
      updateDraftCheck,
      (state, { payload: { id, notes, reference, type } }) => {
        state.draft.reference = reference;
        state.draft.type = type;
        state.draft.notes = notes;
        state.draft.id = id;
      }
    )
    .addCase(setIndividualCheck, (state, { payload }) => {
      state.draft.checks[state.draft.activeCheck] = payload;
    })
    .addCase(nextCheck, (state) => {
      state.draft.activeCheck++;
    })
    .addCase(prevCheck, (state) => {
      state.draft.activeCheck--;
    })
    .addCase(setDraftCheckResult, (state) => {
      state.draft.checks[state.draft.activeCheck].resultAvailable = true;
    })
    .addCase(resetDraftChecks, (state) => {
      state.draft = { ...INITIAL_DRAFT_STATE };
    })
    .addCase(resetCheck, () => ({ ...INITIAL_STATE }));
});
