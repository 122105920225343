import { uiFormatDate } from "../helpers/date";
import { MOBILE_LABEL } from "./lang/en";

export const REQUIRED_MSG = "REQUIRED_MSG";
export const EMAIL_MSG = "Please enter a valid email address";
export const DATE_MSG = `Please enter a valid date in ${uiFormatDate.toUpperCase()} format`;
export const MOBILE_MSG = `Please enter a valid ${MOBILE_LABEL} number`;
export const EMAIL_OR_MOBILE_MSG = `Please enter a valid ${MOBILE_LABEL.toLowerCase()} number and/or email address`;
export const BIRTH_DATE_MSG = "Age must be at least 16 years old";
export const EXPIRY_DATE_MSG = "Please enter a future date";
export const PASSWORD_MSG =
  "Please enter a password with minimum of six characters with at least one letter and one number";

export const NZ_LICENCE_NUMBER_MSG =
  "Please enter a valid New Zealand driver licence number";
export const NZ_LICENCE_VERSION_MSG =
  "Please enter a valid New Zealand driver licence version number";
export const NZ_PASSPORT_MSG =
  "Please enter a valid New Zealand passport number";
export const NZ_POSTAL_CODE_MSG =
  "Please enter a valid New Zealand postal code";

export const AU_LICENCE_NUMBER_MSG =
  "Please enter a valid Australian driver licence number";
export const AU_PASSPORT_MSG =
  "Please enter a valid Australian passport number";

export const IN_PAN_MSG = "Please enter a valid Indian pan card number";
export const IN_LICENCE_NUMBER_MSG =
  "Please enter a valid Indian driver licence number";
export const IN_VOTER_ID_MSG = "Please enter a valid Indian voter ID number";

export const CN_RESIDENT_CARD_MSG =
  "Please enter a valid Chinese resident identity card number";
export const CN_LICENCE_MSG =
  "Please enter a valid chinese driver licence number";
export const CN_PASSPORT_MSG = "Please enter a valid chinese passport number";
