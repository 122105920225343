import queryString from "query-string";
import React from "react";
import { useLocation } from "react-router";

import { FACEMATCH_QUICK_ID } from "../../../constants/checkTypes";
import ChecksForm from "../checks/Form";

const FaceMatchQuickID = () => {
  const location = useLocation();
  const queries = queryString.parse(location.search);

  return (
    <ChecksForm
      title="Face IDV"
      checkType={FACEMATCH_QUICK_ID}
      type={queries.type}
    />
  );
};

export default FaceMatchQuickID;
