import React from "react";
import { connect } from "react-redux";

import { actions as checkActions } from "../../../store/slices/check";
import { actions as historyActions } from "../../../store/slices/history";
import CheckHistoryPresenter from "./presenter";

const { fetchHistory } = historyActions;
const {
  updateCheckGroup,
  resendCheck,
  deleteCheck,
  disableMonitoring,
  approveFaceMatch,
  approveQuickId,
  resetVerification,
} = checkActions;

const CheckHistory = ({
  fetchHistory,
  updateCheckGroup,
  history,
  checkFetching,
  resendCheck,
  deleteCheck,
  disableMonitoring,
  approveFaceMatch,
  approveQuickId,
  resetCheck,
  namePriority,
  defaultCountryCode,
  dashboardShowPrice,
  dashboardCanDelete,
  dashboardCanEdit,
  dashboardCanApprove,
  dashboardCanReset,
  dashboardCanManageDocs,
  isAdmin,
}) => {
  return (
    <CheckHistoryPresenter
      fetchHistory={fetchHistory}
      updateCheckGroup={updateCheckGroup}
      history={history}
      checkFetching={checkFetching}
      resendCheck={resendCheck}
      deleteCheck={deleteCheck}
      disableMonitoring={disableMonitoring}
      approveFaceMatch={approveFaceMatch}
      approveQuickId={approveQuickId}
      resetCheck={resetCheck}
      namePriority={namePriority}
      showSecondaryIDCopy={["AU", "CA"].includes(defaultCountryCode)}
      dashboardShowPrice={dashboardShowPrice}
      dashboardCanDelete={dashboardCanDelete}
      dashboardCanEdit={dashboardCanEdit}
      dashboardCanApprove={dashboardCanApprove}
      dashboardCanReset={dashboardCanReset}
      dashboardCanManageDocs={dashboardCanManageDocs}
      isAdmin={isAdmin}
    />
  );
};

const mapStateToProps = ({ history, check, user }) => ({
  history,
  checkFetching: check.fetching,
  namePriority:
    user && user.dashboardBrand
      ? user.dashboardBrand.check_history_name_priority
      : true,
  defaultCountryCode: user.defaultCountryCode,
  dashboardShowPrice: user.dashboardShowPrice,
  dashboardCanDelete: user.dashboardCanDelete,
  dashboardCanEdit: user.dashboardCanEdit,
  dashboardCanApprove: user.dashboardCanApprove,
  dashboardCanReset: user.dashboardCanReset,
  dashboardCanManageDocs: user.dashboardCanManageDocs,
  isAdmin: user.role === "ADMIN",
});
const mapDispatchToProps = {
  fetchHistory,
  updateCheckGroup,
  resendCheck,
  deleteCheck,
  disableMonitoring,
  approveFaceMatch,
  approveQuickId,
  resetCheck: resetVerification,
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckHistory);
