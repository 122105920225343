import React, { useEffect } from "react";
import { connect } from "react-redux";

import { actions as billingActions } from "../../store/slices/billing";
import InvoicesPresenter from "./Presenters/Invoices";

const { fetchInvoices } = billingActions;

const Invoices = ({ fetchInvoices, invoices }) => {
  useEffect(() => {
    fetchInvoices();
  }, [fetchInvoices]);

  return <InvoicesPresenter invoices={invoices} />;
};

const mapStateToProps = ({ billing: { invoices } }) => ({ invoices });
const mapDispatchToProps = { fetchInvoices };

export default connect(mapStateToProps, mapDispatchToProps)(Invoices);
