import React from "react";

const ReportIcon = () => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      height="20px"
      width="20px"
      viewBox="0 0 32 32"
      xmlSpace="preserve"
    >
      <path
        d="m16 25.05-3.25-3.25-.75.75 4.5 4.5 4.5-4.5-.75-.75L17 25.05V14h-1v11.05zM19.5 3h.5l6 7v18.009A1.998 1.998 0 0 1 24.003 30H8.998a2 2 0 0 1-1.997-2.007V5.008c0-1.109.897-2.007 2.003-2.007h10.497zM19 4H8.996C8.446 4 8 4.455 8 4.995v23.009c0 .55.455.995 1 .995h15c.552 0 1-.445 1-.993V10.999h-4.002A1.995 1.995 0 0 1 19 8.993V3.999zm1 .5v4.491c0 .557.451 1.009.997 1.009H24.7L20 4.5z"
        stroke="currentColor"
      />
    </svg>
  );
};

export default ReportIcon;
