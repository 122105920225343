import PropTypes from "prop-types";
import React from "react";

import DashboardNav from "./Nav";

const DashboardLayout = ({ children }) => (
  <div className="container-fluid dashboard-layout">
    {children}
    <div className="dashboard-nav d-none d-lg-block">
      <DashboardNav />
    </div>
  </div>
);

DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DashboardLayout;
