import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";

import DashboardLayout from "../../dashboard/Presenters/Layout";
import EditIcon from "../../icons/EditIcon";
import TrashIcon from "../../icons/TrashIcon";
import Loading from "../../misc/Loading";
import StepLayout from "../../misc/StepLayout";
import CreateEditModal from "./CreateEditModalState";

const AgencyUsersPresenter = ({
  isLoading,
  userUsername,
  agencyUsers,
  addAgencyUsers,
  deleteAgencyUsers,
}) => {
  const [createEditModalState, setCreateEditModalState] = useState(false);
  const [selectedUserName, setUsername] = useState("");
  const [selectedRole, setState] = useState("");

  const openCreateEditModal = useCallback((username, role) => {
    setUsername(username);
    setState(role);
    setCreateEditModalState(true);
  }, []);

  useEffect(() => {
    if (!createEditModalState) {
      setUsername("");
      setState("");
    }
  }, [createEditModalState]);

  const handleDeleteUser = useCallback(
    (username) => {
      if (
        window.confirm(
          "Are you sure you want to delete this user? They won't be able to access the checks."
        )
      ) {
        deleteAgencyUsers(username);
      }
    },
    [deleteAgencyUsers]
  );

  return (
    <DashboardLayout>
      <StepLayout>
        <section>
          <div className="d-flex align-items-center justify-content-between mb-4">
            <h4 className="mb-0">
              Users <small>({agencyUsers.length})</small>
            </h4>
            <div>
              <Button
                type="button"
                color="primary"
                size="sm"
                className="px-3"
                onClick={() => setCreateEditModalState(true)}
              >
                Add
              </Button>
            </div>
          </div>
          <div
            className={`site-table__inner p-0 ${
              isLoading ? "site-table__inner--loading" : ""
            }`}
          >
            {isLoading && (
              <div className="site-table--loading">
                <Loading />
              </div>
            )}
            <table className="table mb-0">
              <thead className="sr-only">
                <tr>
                  <th>Email</th>
                  <th>Role</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {agencyUsers.map((user) => (
                  <tr key={user.username}>
                    <td>{user.username}</td>
                    <td>{user.role}</td>
                    <td className="text-right">
                      {userUsername !== user.username ? (
                        <>
                          <Button
                            color="link"
                            className="p-0 text-muted"
                            size="sm"
                            onClick={() => {
                              openCreateEditModal(user.username, user.role);
                            }}
                            title="Edit user"
                          >
                            <EditIcon />
                          </Button>
                          <Button
                            color="link"
                            className="p-0 ml-2 text-muted"
                            size="sm"
                            onClick={() => handleDeleteUser(user.username)}
                            title="Remove user"
                          >
                            <TrashIcon />
                          </Button>
                        </>
                      ) : (
                        <div className="small text-muted text-uppercase">
                          Me
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </section>
        {createEditModalState && (
          <CreateEditModal
            isLoading={isLoading}
            username={selectedUserName}
            role={selectedRole}
            toggleModalState={setCreateEditModalState}
            addAgencyUsers={addAgencyUsers}
          />
        )}
      </StepLayout>
    </DashboardLayout>
  );
};

AgencyUsersPresenter.propTypes = {
  userUsername: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  agencyUsers: PropTypes.arrayOf(PropTypes.object),
  addAgencyUsers: PropTypes.func.isRequired,
  deleteAgencyUsers: PropTypes.func.isRequired,
};

export default AgencyUsersPresenter;
