import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React, { useCallback, useMemo } from "react";
import Toggle from "react-toggle";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";

import {
  NATIONAL_ID_AVAILABLE_REQUIRED,
  NATIONAL_ID_BIRTH_CERTIFICATE,
  NATIONAL_ID_DRIVER_LICENCE,
  NATIONAL_ID_GOVT_TAX_NUMBER,
  NATIONAL_ID_NOT_AVAILABLE,
  NATIONAL_ID_PASSPORT,
} from "../../../../constants/nationalIdType";
import {
  SUPPORTED_COUNTRIES,
  getCountriesSupportNationalId,
} from "../../../../constants/supportedCountries";
import Field from "../../../form/Field";
import AuId from "./AuId";
import CnId from "./CnId";
import GlobalNationalId from "./GlobalNationalId";
import InId from "./InId";
import NzId from "./NzId";

const Ids = ({
  sources,
  quickIDAlgorithm,
  allowMixAndMatch,
  defaultCountryCode,
}) => {
  const { values, errors, submitCount, setFieldValue } = useFormikContext();
  const [showAuId, setShowAuId] = React.useState(false);
  const { countryCode, nationalIdType, nationalIdCountryCode } = values;

  React.useEffect(() => {
    setFieldValue("nationalIdCountryCode", countryCode);
  }, [countryCode, setFieldValue]);

  React.useEffect(() => {
    if (["AU", "NZ"].includes(nationalIdCountryCode)) {
      setFieldValue("nationalIdType", NATIONAL_ID_DRIVER_LICENCE);
      setFieldValue("nationalIdSecondaryType", "");
      setFieldValue("nationalIdTertiaryType", "");
    } else {
      setFieldValue("nationalIdType", NATIONAL_ID_GOVT_TAX_NUMBER);
      setFieldValue("nationalIdSecondaryType", "");
      setFieldValue("nationalIdTertiaryType", "");
    }
  }, [nationalIdCountryCode, setFieldValue]);

  const revertNzIdToLicense = useCallback(
    () => setFieldValue("nationalIdType", NATIONAL_ID_DRIVER_LICENCE),
    [setFieldValue, nationalIdType]
  );
  const toggleAuId = useCallback(
    () => setShowAuId((state) => !state),
    [setShowAuId]
  );
  const resetCountry = useCallback(() => {
    setFieldValue("nationalIdCountryCode", "");
    setFieldValue("countryCode", "");
  });
  const countryName = useMemo(
    () => SUPPORTED_COUNTRIES[nationalIdCountryCode]?.name || "",
    [nationalIdCountryCode]
  );

  const mixAndMatchAvailable = useMemo(() => {
    if (
      allowMixAndMatch &&
      ["NZ", "AU"].includes(countryCode) &&
      ((values.countryCode === "NZ" &&
        sources.au_DRV !== NATIONAL_ID_NOT_AVAILABLE) ||
        (values.countryCode === "AU" &&
          sources.nz_DRV !== NATIONAL_ID_NOT_AVAILABLE))
    ) {
      return true;
    }

    return false;
  }, [allowMixAndMatch, sources, values.countryCode]);

  if (
    !getCountriesSupportNationalId().includes(values.nationalIdCountryCode) &&
    values.nationalIdCountryCode !== "CA"
  )
    return null;

  return (
    <fieldset>
      <legend>
        {values.nationalIdCountryCode !== "CA" && (
          <>
            {countryName} Identity Documents{" "}
            {getIdRequirement(
              sources,
              values.nationalIdCountryCode,
              values.nationalIdType
            )}
          </>
        )}
      </legend>
      {mixAndMatchAvailable && (
        <div className="mt-4">
          <Field
            name="nationalIdCountryCode"
            error={errors.nationalIdCountryCode}
            submitCount={submitCount}
            label="Verify your ID by selecting a country"
            component="select"
          >
            <option value="AU">Australia</option>
            <option value="NZ">New Zealand</option>
          </Field>
        </div>
      )}
      {nationalIdCountryCode === "NZ" ? (
        <NzId />
      ) : nationalIdCountryCode === "AU" ? (
        <>
          {sources.au_DRV === NATIONAL_ID_NOT_AVAILABLE &&
          sources.au_PASS === NATIONAL_ID_NOT_AVAILABLE ? (
            <div className="d-flex align-items-center mt-3 mb-5" role="group">
              <div className="text-sm mr-1">
                <Button
                  onClick={toggleAuId}
                  color="link"
                  block
                  className={`text-right p-0 ${showAuId ? "text-muted" : ""}`}
                  type="button"
                >
                  Australian Identity Documents
                </Button>
              </div>
              <div className="mx-3 text-sm text-right">
                <Toggle
                  onChange={toggleAuId}
                  checked={showAuId}
                  icons={false}
                />
              </div>
            </div>
          ) : (
            <AuId />
          )}
        </>
      ) : nationalIdCountryCode === "IN" ? (
        <InId quickIDAlgorithm={quickIDAlgorithm} />
      ) : nationalIdCountryCode === "CN" ? (
        <CnId quickIDAlgorithm={quickIDAlgorithm} />
      ) : (
        <GlobalNationalId />
      )}

      {sources.nz_PASS === NATIONAL_ID_NOT_AVAILABLE &&
        nationalIdType === NATIONAL_ID_PASSPORT &&
        nationalIdCountryCode === "NZ" && (
          <Modal isOpen={true} toggle={revertNzIdToLicense}>
            <ModalHeader toggle={revertNzIdToLicense}>
              DIA passport access
            </ModalHeader>
            <ModalBody>
              <p>
                In order to run DIA passport checks please complete our online
                application form -{" "}
                <a
                  href="https://form.jotform.com/210257117942856"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://form.jotform.com/210257117942856
                </a>
              </p>
            </ModalBody>
          </Modal>
        )}

      {sources.nz_BIRTH === NATIONAL_ID_NOT_AVAILABLE &&
        nationalIdType === NATIONAL_ID_BIRTH_CERTIFICATE &&
        nationalIdCountryCode === "NZ" && (
          <Modal isOpen={true} toggle={revertNzIdToLicense}>
            <ModalHeader toggle={revertNzIdToLicense}>
              Birth certificate access
            </ModalHeader>
            <ModalBody>
              <p>
                In order to run birth certificate checks please complete our
                online application form -{" "}
                <a
                  href="https://form.jotform.com/203147157665054"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://form.jotform.com/203147157665054
                </a>
              </p>
            </ModalBody>
          </Modal>
        )}

      {sources.au_PASS === NATIONAL_ID_NOT_AVAILABLE &&
        showAuId &&
        nationalIdCountryCode === "AU" && (
          <Modal isOpen={true} toggle={toggleAuId}>
            <ModalHeader toggle={toggleAuId}>
              DVS passport &amp; driver licence access
            </ModalHeader>
            <ModalBody>
              <p>
                In order to run DVS passport &amp; driver licence checks please
                complete our online application form -{" "}
                <a
                  href="https://form.jotform.com/203187383597063"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://form.jotform.com/203187383597063
                </a>
              </p>
            </ModalBody>
          </Modal>
        )}
    </fieldset>
  );
};

Ids.propTypes = {
  sources: PropTypes.object,
  quickIDAlgorithm: PropTypes.string.isRequired,
  allowMixAndMatch: PropTypes.bool.isRequired,
  defaultCountryCode: PropTypes.string.isRequired,
};

export default Ids;

const getIdRequirement = (sources, nationalIdCountryCode, nationalIdType) => {
  let key = `${nationalIdCountryCode.toLowerCase()}`;
  if (["AU", "NZ"].includes(nationalIdCountryCode))
    key += `_${nationalIdType.toUpperCase()}`;
  return sources[key] === NATIONAL_ID_AVAILABLE_REQUIRED
    ? "(Required)"
    : "(optional)";
};
