import { Formik } from "formik";
import PropTypes from "prop-types";
import RcIf, { RcElse } from "rc-if";
import React, { createRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { toastr } from "react-redux-toastr";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import * as Yup from "yup";

import { CAPTCHA_ENABLE_MESSAGE } from "../../../constants/lang/en";
import { EMAIL_MSG, REQUIRED_MSG } from "../../../constants/validationHelpers";
import emailSent from "../../../images/email-sent.png";
import routes from "../../../routes";
import Field from "../../form/Field";
import FormikScrollToErrors from "../../misc/FormikScrollToError";
import StepHeader from "../../misc/StepHeader";
import StepLayout from "../../misc/StepLayout";

const VALIDATION_RULES = Yup.object().shape({
  agentEmail: Yup.string().trim().required(REQUIRED_MSG).email(EMAIL_MSG),
});

const INITIAL_VALUES = { agentEmail: "" };

const ForgotPassword = ({
  forgotPassword,
  fetching,
  forgotPasswordEmailSent,
  resetForgotPasswordState,
}) => {
  const [email, setEmail] = useState("");
  const recaptchaRef = createRef();

  const submitHandler = (data) => {
    const recaptchaValue = recaptchaRef.current.getValue();
    if (recaptchaValue) {
      setEmail(data.agentEmail);
      forgotPassword(data);
    } else {
      toastr.error(CAPTCHA_ENABLE_MESSAGE);
    }
  };

  return (
    <StepLayout>
      <RcIf if={forgotPasswordEmailSent}>
        <div className="text-center">
          <h1 className="h2 mb-4">Email has been sent.</h1>
          <img src={emailSent} alt="Email Sent" width={80} />
          <p className="my-3">
            If an account linked to <b>{email}</b> exists in our system, a link
            to reset your password has been sent to this email address.
          </p>
          <p className="my-3">
            Check the email we've just sent you to complete the process.
          </p>
          <p className="my-3">
            Haven't received anything? Please check your Spam folder.
          </p>

          <Button
            color="primary"
            block
            onClick={() => forgotPassword({ agentEmail: email, resent: true })}
            type="button"
            size="lg"
            disabled={fetching}
          >
            Re-send Reset Email
          </Button>

          <div className="text-right mt-3">
            <Link
              to={routes.signIn}
              className="btn btn-link"
              onClick={resetForgotPasswordState}
            >
              Back to the login page
            </Link>
          </div>
        </div>
        <RcElse>
          <StepHeader text="Forgot Your Password?" />
          <Formik
            validationSchema={VALIDATION_RULES}
            onSubmit={submitHandler}
            initialValues={INITIAL_VALUES}
          >
            {({
              handleSubmit,
              errors,
              submitCount,
              isSubmitting,
              isValidating,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <FormikScrollToErrors
                  errors={errors}
                  isSubmitting={isSubmitting}
                  isValidating={isValidating}
                />
                <Field
                  name="agentEmail"
                  error={errors.agentEmail}
                  submitCount={submitCount}
                  label="Email Address"
                  type="email"
                  autoComplete="email"
                  autoFocus
                />
                <div className="site-form__consent-message">
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                  />
                </div>
                <Button
                  disabled={fetching}
                  type="submit"
                  color="primary"
                  block
                  size="lg"
                >
                  Send Reset Email
                </Button>
                <div className="text-right mt-3">
                  <Link to={routes.signIn} className="btn btn-link">
                    Back to the login page
                  </Link>
                </div>
              </form>
            )}
          </Formik>
        </RcElse>
      </RcIf>
    </StepLayout>
  );
};

ForgotPassword.propTypes = {
  forgotPassword: PropTypes.func.isRequired,
  resetForgotPasswordState: PropTypes.func.isRequired,
  fetching: PropTypes.bool.isRequired,
  forgotPasswordEmailSent: PropTypes.bool.isRequired,
};

export default ForgotPassword;
