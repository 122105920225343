import PropTypes from "prop-types";
import React from "react";

const StepHeader = ({ text, subHeader, className = "" }) => (
  <div className={`site-form__header ${className}`}>
    <h1 className="site-form__heading h5">{text}</h1>
    {subHeader}
  </div>
);

StepHeader.propTypes = {
  text: PropTypes.node.isRequired,
  subHeader: PropTypes.node,
};

StepHeader.defaultProps = {
  subHeader: null,
};

export default StepHeader;
