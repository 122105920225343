import * as Sentry from "@sentry/browser";

const captureException = (error) => {
  if (process.env.REACT_APP_SENTRY_DSN) {
    Sentry.captureException(error);
  } else {
    console.error(error);
  }
};

export default captureException;
