import axios from "axios";

import { getDefaultHeaders } from "../helpers/token";
import {
  BILLING_INVOICES_URL,
  BILLING_UPCOMING_INVOICES_URL,
  BILLING_URL,
} from "./apiUrls";

const fetchCreditCard = async () =>
  await axios.get(BILLING_URL, getDefaultHeaders());

const addCreditCard = async ({ stripeToken }) =>
  await axios.post(
    BILLING_URL,
    { stripe_token: stripeToken },
    getDefaultHeaders()
  );

const fetchUpcomingInvoice = async () =>
  await axios.get(BILLING_UPCOMING_INVOICES_URL, getDefaultHeaders());

const fetchInvoices = async () =>
  await axios.get(BILLING_INVOICES_URL, getDefaultHeaders());

const apis = {
  fetchCreditCard,
  addCreditCard,
  fetchUpcomingInvoice,
  fetchInvoices,
};

export default apis;
