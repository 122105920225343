export const SELF = "SELF";
export const EMAIL = "EMAIL";
export const SMS = "SMS";

const checkGroupTypes = [
  {
    key: SELF,
    label: "I'll RUN the Quick IDV checks myself",
    labelSecondary: "Self Check",
  },
  {
    key: EMAIL,
    label: "I'll EMAIL the checks to my client(s)",
    labelSecondary: "client Check",
  },
  {
    key: SMS,
    label: "I'll SMS the checks to my client(s)",
    labelSecondary: "client Check",
  },
];

export default checkGroupTypes;
