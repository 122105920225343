import { Formik } from "formik";
import PropTypes from "prop-types";
import RcIf, { RcElse } from "rc-if";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { isValidPhoneNumber } from "react-phone-number-input";
import { toastr } from "react-redux-toastr";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import * as Yup from "yup";

import {
  OS_COMPANY,
  OS_OTHER,
  OS_TRUST_PARTNERSHIP,
  PEP_COPY,
} from "../../../constants/checkTypes";
import { MOBILE_LABEL } from "../../../constants/lang/en";
import {
  EMAIL_MSG,
  MOBILE_MSG,
  REQUIRED_MSG,
} from "../../../constants/validationHelpers";
import routes from "../../../routes";
import Field from "../../form/Field";
import IdentityFields from "../../form/IdentityFields";
import PhoneField from "../../form/PhoneField";
import Trash from "../../icons/TrashIcon";
import Upload from "../../icons/UploadIcon";
import FormikScrollToErrors from "../../misc/FormikScrollToError";

const VALIDATION_RULES = Yup.object().shape(
  {
    checkType: Yup.string().trim().required(REQUIRED_MSG),
    name: Yup.string().trim().required(REQUIRED_MSG),
    country: Yup.string().trim().required(REQUIRED_MSG),
    firstName: Yup.string().trim().required(REQUIRED_MSG),
    lastName: Yup.string().trim().required(REQUIRED_MSG),
    email: Yup.string().trim().required(REQUIRED_MSG).email(EMAIL_MSG),
    phone: Yup.string().trim().required(REQUIRED_MSG),
    isFacematchRequired: Yup.string().trim().required(REQUIRED_MSG),
    isCompanyPepRequired: Yup.string().trim().required(REQUIRED_MSG),
    natureOfTransaction: Yup.string().trim().required(REQUIRED_MSG),
    reference: Yup.string().trim(),
    notes: Yup.string().trim(),
  },
  ["email", "phone"]
);

const OutSourced = ({
  fetching,
  fetchCompleted,
  resetCheck,
  addOutSourced,
  initialValues,
}) => {
  const [files, setFiles] = useState([]);

  const onDropAccepted = useCallback((acceptedFiles) => {
    const parsedFiles = acceptedFiles.map((file) => ({
      originalFile: file,
      id: `${Date.now()}-${Math.random()}`,
      name: file.name,
      sizeInKb: `${(file.size / 1000).toFixed(2)}kb`,
    }));
    setFiles((files) => [...files, ...parsedFiles]);
  }, []);

  const onDropRejected = useCallback(() => {
    toastr.error(
      "",
      "Please upload pdf, png or jpg files which are less than 20 MB."
    );
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/jpg, image/png, application/pdf",
    maxSize: 25000001,
    multiple: true,
    onDropAccepted,
    onDropRejected,
  });

  const deleteFile = (id) => {
    const newFiles = files.filter((f) => f.id !== id);
    setFiles(newFiles);
  };

  const submitHandler = (data, { setFieldError }) => {
    if (data.phone && !isValidPhoneNumber(data.phone)) {
      setFieldError("phone", MOBILE_MSG);
      return;
    }
    addOutSourced(data, files);
  };

  const reset = (resetForm) => {
    resetForm();
    setFiles([]);
    resetCheck();
  };

  return (
    <Formik
      validationSchema={VALIDATION_RULES}
      onSubmit={submitHandler}
      initialValues={initialValues}
      enableReinitialize={true}
    >
      {({
        handleSubmit,
        errors,
        values,
        submitCount,
        setFieldValue,
        resetForm,
        isSubmitting,
        isValidating,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <FormikScrollToErrors
            errors={errors}
            isSubmitting={isSubmitting}
            isValidating={isValidating}
          />
          <RcIf if={resetCheck && fetchCompleted}>
            <div className="text-center">
              <h3 className="h4">{values.name}</h3>
              <p className="big">
                Your outsourced check has been successfully submitted. You will
                receive further notifications via email and can view the status
                in the{" "}
                <Link to={routes.outSourcedHistory}>Outsourced History</Link>
              </p>
              <Button
                color="primary"
                type="button"
                className="px-5"
                onClick={reset.bind(null, resetForm)}
                block
              >
                Done
              </Button>
            </div>
            <RcElse>
              <Field
                name="checkType"
                error={errors.checkType}
                submitCount={submitCount}
                label="Type of Ownership?"
                component="select"
              >
                <option value={OS_TRUST_PARTNERSHIP}>
                  Trust / Partnership
                </option>
                <option value={OS_COMPANY}>Company</option>
                <option value={OS_OTHER}>Other</option>
              </Field>

              <Field
                name="name"
                error={errors.name}
                submitCount={submitCount}
                label="Your Client's Name"
              />

              <Field
                name="country"
                error={errors.country}
                submitCount={submitCount}
                label="Your Client's Country of Domicile"
              />

              <h3 className="h6">Contact Details</h3>

              <IdentityFields
                errors={errors}
                submitCount={submitCount}
                autoComplete={false}
                showMiddleName={false}
                showDateOfBirth={false}
                setFieldValue={setFieldValue}
                showFirstNameWarning={false}
              />

              <Field
                name="email"
                error={errors.email}
                submitCount={submitCount}
                label="Email"
              />

              <PhoneField
                name="phone"
                value={values.phone}
                setFieldValue={setFieldValue}
                error={errors.phone}
                submitCount={submitCount}
                label={`${MOBILE_LABEL} Number`}
              />

              <hr className="mb-4 pb-4" />

              <Field
                name="natureOfTransaction"
                error={errors.natureOfTransaction}
                submitCount={submitCount}
                label="Nature of the transaction?"
                placeholder="e.g. Property sale or loan"
              />

              <Field
                name="isFacematchRequired"
                error={errors.isFacematchRequired}
                submitCount={submitCount}
                label="Is FaceMatch required?"
                component="select"
              >
                <option></option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </Field>

              <Field
                name="isCompanyPepRequired"
                error={errors.isCompanyPepRequired}
                submitCount={submitCount}
                label={`Is non-individual ${PEP_COPY} check required?`}
                component="select"
              >
                <option></option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </Field>

              <Field
                name="reference"
                error={errors.reference}
                submitCount={submitCount}
                label="Reference (optional)"
                placeholder="e.g. File Number or Tag."
              />

              <Field
                name="notes"
                error={errors.notes}
                submitCount={submitCount}
                label="Notes (optional)"
                component="textarea"
                placeholder="Your notes will only appear on PDF reports and in the Check History."
              />

              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <Button color="secondary" block type="button">
                  <span className="btn-icon">
                    <Upload />
                  </span>
                  Upload Files (e.g. trust deed) - Optional
                </Button>
              </div>

              <RcIf if={!!files.length}>
                <ul className="list-unstyled mb-0 mt-3">
                  {files.map((file) => (
                    <li className="mt-2" key={file.id}>
                      <div className="border rounded d-flex p-2 align-items-center small">
                        <div className="flex-grow-1">
                          <span>{file.name}</span>
                          <span className="text-muted small ml-2">
                            {file.sizeInKb}
                          </span>
                        </div>
                        <div className="pl-2">
                          <Button
                            color="link"
                            className="p-0 text-muted"
                            size="sm"
                            onClick={() => deleteFile(file.id)}
                          >
                            <Trash />
                          </Button>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </RcIf>
              {resetCheck ? (
                <>
                  <div className="row align-items-center mt-4">
                    <div className="col-6">
                      <Button
                        color="primary"
                        outline
                        block
                        onClick={reset.bind(null, resetForm)}
                      >
                        Start Again
                      </Button>
                    </div>
                    <div className="col-6 text-right">
                      <Button
                        color="primary"
                        className="px-5"
                        type="submit"
                        disabled={fetching}
                        block
                      >
                        Request Check
                      </Button>
                    </div>
                  </div>
                  <hr className="mt-4" />
                  <p className="text-center small mb-0">
                    By requesting checks I agree to the{" "}
                    <a
                      href="https://realaml.com/terms-and-conditions/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms &amp; Conditions
                    </a>{" "}
                    and{" "}
                    <a
                      href="https://realaml.com/privacy-policy/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy Policy
                    </a>
                  </p>
                </>
              ) : (
                <Button
                  color="primary"
                  className="px-5 mt-4"
                  type="submit"
                  disabled={fetching}
                  block
                >
                  Save
                </Button>
              )}
            </RcElse>
          </RcIf>
        </form>
      )}
    </Formik>
  );
};

OutSourced.propTypes = {
  fetching: PropTypes.bool.isRequired,
  fetchCompleted: PropTypes.bool,
  addOutSourced: PropTypes.func.isRequired,
  resetCheck: PropTypes.func,
  initialValues: PropTypes.object.isRequired,
};

export default OutSourced;
