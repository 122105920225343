import React, { useEffect } from "react";
import { connect } from "react-redux";

import { actions as checkActions } from "../../store/slices/check";
import { actions } from "../../store/slices/verification";
import VerificationPresenter from "./Presenters";

const { fetchVerification, runQuickId } = actions;
const { updateCheckUserAndResend } = checkActions;

const ElectronicVerification = ({
  fetchVerification,
  verification,
  runQuickId,
  updateCheckUserAndResend,
  quickIDAlgorithm,
  allowMixAndMatch,
}) => {
  useEffect(() => {
    fetchVerification();
  }, [fetchVerification]);

  return (
    <VerificationPresenter
      {...verification}
      quickIDAlgorithm={quickIDAlgorithm}
      allowMixAndMatch={allowMixAndMatch}
      runQuickId={runQuickId}
      updateCheckUserAndResend={updateCheckUserAndResend}
    />
  );
};

const mapStateToProps = ({ verification, user }) => ({
  verification,
  quickIDAlgorithm: user.quickIDAlgorithm,
  allowMixAndMatch: user.allowMixAndMatch,
});
const mapDispatchToProps = {
  fetchVerification,
  runQuickId,
  updateCheckUserAndResend,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ElectronicVerification);
