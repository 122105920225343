import React from "react";
import { Button } from "reactstrap";

import StepHeader from "../../misc/StepHeader";
import StepLayout from "../../misc/StepLayout";
import DashboardLayout from "./Layout";

const PartialAndFailedChecks = () => (
  <DashboardLayout>
    <StepLayout>
      <StepHeader
        text={
          <span>
            Partial &amp; Failed{" "}
            <abbr title="Electronic Identity Verification">EIV</abbr> Checks
          </span>
        }
      />
      <p>
        The Realaml team can analyse your partial or failed{" "}
        <abbr title="Electronic Identity Verification">EIV</abbr> check(s) and
        in most cases, provide you with a pass result.
      </p>
      <p>Our standard fee of $6 per individual check will apply.</p>
      <p>
        Click the button below to avoid reverting to collecting documents
        face-to-face or waiting for certified documents in the post.
      </p>
      <Button
        color="primary"
        block
        size="lg"
        rel="noopener noreferrer"
        target="_blank"
        tag="a"
        href="https://realaml.paperform.co/"
      >
        Submit Partial or Failed{" "}
        <abbr title="Electronic Identity Verification">EIV</abbr> Check
      </Button>
    </StepLayout>
  </DashboardLayout>
);

export default PartialAndFailedChecks;
