import PropTypes from "prop-types";
import React, { useCallback, useMemo } from "react";
import { Tooltip } from "react-tippy";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import ReadMoreReact from "read-more-react";

import { SELF } from "../../../constants/checkGroupType";
import checkTypes, { PEP_COPY, QUICK_ID } from "../../../constants/checkTypes";
import results, {
  MATCH,
  POTENTIAL,
  PASS_ADDRESS_REVIEW as resultAddressPass,
  FAIL as resultFail,
  NA as resultNA,
  PARTIAL as resultPartial,
  PASS as resultPass,
  PENDING as resultPending,
} from "../../../constants/results";
import { formatDateTime, isDateValidForLogs } from "../../../helpers/date";
import { constructDownloadUrl } from "../../../helpers/files";
import useCopyToClipboard from "../../../hooks/useCopyToClipboard";
import Attachment from "../../icons/AttachmentIcon";
import BankMatchIcon from "../../icons/BankMatchIcon";
import Edit from "../../icons/EditIcon";
import Ellipsis from "../../icons/EllipsisIcon";
import FaceMatchIcon from "../../icons/FaceMatchIcon";
import PepIcon from "../../icons/PepIcon";
import QuickIDIcon from "../../icons/QuickIDIcon";
import ContactUpdateModal from "./contactUpdateModal";
import LogsModal from "./logsModal";
import PepApproveModal from "./pepApproveModal";
import ReferenceNotesUpdateModal from "./referenceNotesUpdateModal";
import UploadModal from "./uploadModal";

const Check = ({
  updateCheckGroup,
  check,
  checkFetching,
  resendCheck,
  deleteCheck,
  disableMonitoring,
  approveFaceMatch,
  approveQuickId,
  resetCheck,
  isAdminOrManager,
  namePriority,
  showSecondaryIDCopy,
  dashboardShowPrice,
  dashboardCanDelete,
  dashboardCanEdit,
  dashboardCanApprove,
  dashboardCanReset,
  dashboardCanManageDocs,
}) => {
  const [actionsDropdown, setActionsDropdown] = React.useState(false);
  const [uploadModalOpened, setUploadModalOpened] = React.useState(null);
  const [referenceNotesUpdateModalOpened, setReferenceNotesUpdateModalOpened] =
    React.useState(false);
  const [contactUpdateModalOpened, setContactUpdateModalOpened] =
    React.useState(false);
  const [pepApproveModalOpened, setPepApproveModalOpened] =
    React.useState(false);
  const [logsModalOpened, setLogsModalOpened] = React.useState(false);

  const copy = useCopyToClipboard();

  const actionsDropdownToggle = useCallback(() => {
    setActionsDropdown((prevState) => !prevState);
  }, []);

  const handleDownloadCheck = useCallback(() => {
    window.open(constructDownloadUrl(check.signature_key));
  }, [check.signature_key]);

  const handleResendCheck = useCallback(() => {
    resendCheck(check.signature_key);
  }, [resendCheck, check.signature_key]);

  const handleDisableMonitor = useCallback(() => {
    const confirm = window.confirm(
      "Are you sure you want to disable monitoring for this check?"
    );
    if (confirm) {
      disableMonitoring(check.signature_key);
    }
  }, [disableMonitoring, check.signature_key]);

  const handleDeleteCheck = useCallback(() => {
    const confirm = window.confirm(
      "Are you sure you want to delete this check?"
    );
    if (confirm) {
      deleteCheck(check.signature_key);
    }
  }, [deleteCheck, check.signature_key]);

  const handleApproveFaceMatch = useCallback(() => {
    const confirm = window.confirm(
      "Are you sure you want to approve this FaceMatch?"
    );
    if (confirm) {
      approveFaceMatch(check.signature_key);
    }
  }, [approveFaceMatch, check.signature_key]);

  const handleapproveQuickId = useCallback(() => {
    const confirm = window.confirm(
      "Are you sure you want to approve this Quick IDV?"
    );
    if (confirm) {
      approveQuickId(check.signature_key);
    }
  }, [approveQuickId, check.signature_key]);

  const handleResetAndRedo = useCallback(() => {
    const confirm = window.confirm(
      "Are you sure you want to reset this check? All results will be reset and the whole check has to be re-run."
    );
    if (confirm) {
      resetCheck(
        check.signature_key,
        check.type === SELF && check.checkType === QUICK_ID
      );
    }
  }, [check.signature_key, check.type, check.checkType]);

  const handleCopyLink = useCallback(() => {
    copy(`${process.env.REACT_APP_USER_APP_URL}/${check.signature_key}`);
  }, [check.signature_key]);

  const [checkType, hasQuickId, hasFaceMatch, hasPep, hasBankMatch] =
    useMemo(() => {
      const checkTypeObj = checkTypes.find((ct) => ct.key === check.checkType);
      const checkType = checkTypeObj
        ? `${checkTypeObj.label}${check.run_plus_checks ? " PLUS" : ""}`
        : "";
      return [
        showSecondaryIDCopy && !check.run_plus_checks
          ? checkType
              .replace(
                "FaceMatch",
                `FaceMatch ${
                  check.facematch_collect_secondary_id ? "2 IDs" : "1 ID"
                }`
              )
              .replace(
                "Face IDV",
                `Face IDV ${
                  check.facematch_collect_secondary_id ? "2 IDs" : "1 ID"
                }`
              )
          : checkType,
        checkType.includes("Quick IDV") || checkType.includes("Face IDV"),
        checkType.includes("FaceMatch") || checkType.includes("Face IDV"),
        checkType.includes("PEP") || check.facematch_run_pep,
        checkType.includes("BankMatch"),
      ];
    }, [check.checkType, check.facematch_run_pep]);

  const getResultType = useCallback((key) => {
    const result = results.find((r) => r.key === key);
    return result ? result : results.find((r) => r.key === resultPending);
  }, []);

  const overallResultType = useMemo(
    () => getResultType(check.overall_result),
    [check.overall_result]
  );

  const facematchResultType = useMemo(
    () =>
      getResultType(
        check.facematch_result
          ? check.facematch_result
          : check.overall_result === resultFail
          ? resultNA
          : resultPending
      ),
    [check.facematch_result, check.overall_result, check.force_facematch]
  );

  const bankMatchResultType = useMemo(
    () => getResultType(check.bankmatch_result || resultPending),
    [check.bankmatch_result]
  );

  const pepResultType = useMemo(
    () => getResultType(check.pep_result),
    [check.pep_result]
  );

  const showReferenceFirst = !namePriority && check.reference;

  return (
    <div className="check-row">
      <div className="check-flex">
        <div className="check-flex-left">
          <h2 className="h6 text-secondary m-0">
            {check.is_pep_monitored ? "Monitored " : ""}
            {checkType}{" "}
            {(check.quick_id_user_overrode_result ||
              check.pep_user_overrode_result ||
              check.facematch_user_overrode_result) && (
              <span className="small">(Status overrode) </span>
            )}
            {isAdminOrManager && (
              <span className="small">
                ({check.notify_email || check.agent_email})
              </span>
            )}
          </h2>

          <h3 className="h5 mb-0 mt-3">
            {showReferenceFirst
              ? check.reference
              : `${check.first_name} ${check.last_name}`}
            {(hasQuickId || hasFaceMatch || hasBankMatch) && (
              <small className="text-secondary ml-2 d-inline-flex align-items-center">
                {check.phone} {check.email}
                {dashboardCanEdit && !!(check.email || check.phone) && (
                  <Tooltip
                    title="Edit Contact Info"
                    position="top"
                    size="small"
                  >
                    <Button
                      size="sm"
                      color="link"
                      outline
                      onClick={() => setContactUpdateModalOpened(true)}
                      className="p-0 text-secondary ml-2"
                    >
                      <Edit />
                    </Button>
                  </Tooltip>
                )}
              </small>
            )}
          </h3>
          {(showReferenceFirst || !!check.reference) && (
            <p className="font-weight-bold mb-0 mt-3">
              {showReferenceFirst
                ? `${check.first_name} ${check.last_name}`
                : check.reference}
            </p>
          )}

          {!!check.notes && (
            <div style={{ whiteSpace: "pre-line" }} key={check.notes}>
              <ReadMoreReact
                text={check.notes}
                ideal={335}
                min={1}
                max={999999}
                readMoreText="See more..."
              />
            </div>
          )}
        </div>
        <div className="check-flex-right">
          <div className="check-flex-right-item">
            <span className="h6 text-secondary">
              {formatDateTime(check.created_at)}
            </span>
            {dashboardShowPrice && !!check.amount && (
              <span className="h6 ml-2 check-price">${check.amount}</span>
            )}
          </div>
          <div className="check-flex-right-item mt-4">
            {!!check.files?.length && (
              <Tooltip
                title={`Files: ${check.files.length}`}
                position="top"
                size="small"
              >
                <span className="mr-3 text-muted">
                  <Attachment />
                </span>
              </Tooltip>
            )}
            {hasQuickId && (
              <>
                <Tooltip
                  title={`Quick IDV: ${overallResultType.label}`}
                  position="top"
                  size="small"
                >
                  <span className="ml-2 result-icon">
                    <QuickIDIcon type={overallResultType.type} />
                  </span>
                </Tooltip>
                {check.pep_result &&
                  ![resultNA, resultPending].includes(check.pep_result) && (
                    <Tooltip
                      title={`${PEP_COPY} Check: ${pepResultType.label}`}
                      position="top"
                      size="small"
                    >
                      <span className="ml-2 result-icon">
                        <PepIcon type={pepResultType.type} />
                      </span>
                    </Tooltip>
                  )}
              </>
            )}
            {hasPep && (
              <Tooltip
                title={`${PEP_COPY} Check: ${pepResultType.label}`}
                position="top"
                size="small"
              >
                <span className="ml-2 result-icon">
                  <PepIcon type={pepResultType.type} />
                </span>
              </Tooltip>
            )}
            {hasFaceMatch && (
              <Tooltip
                title={`FaceMatch: ${facematchResultType.label}`}
                position="top"
                size="small"
              >
                <span className="ml-2 result-icon">
                  <FaceMatchIcon type={facematchResultType.type} />
                </span>
              </Tooltip>
            )}
            {hasBankMatch && (
              <Tooltip
                title={`BankMatch: ${bankMatchResultType.label}`}
                position="top"
                size="small"
              >
                <span className="ml-2 result-icon">
                  <BankMatchIcon type={bankMatchResultType.type} />
                </span>
              </Tooltip>
            )}
            <Tooltip title="More Actions" position="top" size="small">
              <Dropdown
                isOpen={actionsDropdown}
                toggle={actionsDropdownToggle}
                size="sm"
                className="ml-4"
              >
                <DropdownToggle color="" className="text-muted p-0">
                  <Ellipsis />
                </DropdownToggle>
                <DropdownMenu right>
                  {!!check.final_report_present && (
                    <DropdownItem onClick={handleDownloadCheck}>
                      Download
                    </DropdownItem>
                  )}

                  {dashboardCanManageDocs && (
                    <DropdownItem
                      onClick={() => setUploadModalOpened("documents")}
                    >
                      Manage Documents
                    </DropdownItem>
                  )}

                  {dashboardCanApprove && (
                    <>
                      {hasQuickId &&
                      check.overall_result === resultAddressPass ? (
                        <DropdownItem
                          onClick={() => setUploadModalOpened("address")}
                        >
                          Attach Address & Approve Quick IDV
                        </DropdownItem>
                      ) : hasQuickId &&
                        (check.overall_result === resultPartial ||
                          check.overall_result === resultFail) ? (
                        <DropdownItem onClick={handleapproveQuickId}>
                          Approve Quick IDV
                        </DropdownItem>
                      ) : null}
                      {hasFaceMatch &&
                        check.facematch_verification_completed &&
                        check.facematch_result !== resultPass && (
                          <DropdownItem onClick={handleApproveFaceMatch}>
                            Approve FaceMatch
                          </DropdownItem>
                        )}

                      {[POTENTIAL, MATCH].includes(pepResultType.key) && (
                        <DropdownItem
                          onClick={() => setPepApproveModalOpened(true)}
                        >
                          Approve {PEP_COPY}
                        </DropdownItem>
                      )}
                    </>
                  )}

                  {((hasQuickId && !check.completed) ||
                    (hasFaceMatch &&
                      !check.facematch_verification_completed)) && (
                    <DropdownItem onClick={handleResendCheck}>
                      Resend Check
                    </DropdownItem>
                  )}

                  {dashboardCanReset && (
                    <>
                      {((hasQuickId &&
                        check.completed &&
                        [resultPartial, resultFail].includes(
                          check.overall_result
                        )) ||
                        (hasFaceMatch &&
                          check.facematch_verification_completed &&
                          check.facematch_result !== resultPass)) && (
                        <DropdownItem onClick={handleResetAndRedo}>
                          Reset &{" "}
                          {check.type === SELF && check.checkType === QUICK_ID
                            ? "Redo"
                            : "Resend"}{" "}
                          Check
                        </DropdownItem>
                      )}
                    </>
                  )}

                  <DropdownItem onClick={handleCopyLink}>
                    Copy Link
                  </DropdownItem>

                  {dashboardCanEdit && (
                    <>
                      <DropdownItem
                        onClick={() => setReferenceNotesUpdateModalOpened(true)}
                      >
                        Edit Check
                      </DropdownItem>

                      {!!(check.email || check.phone) && (
                        <DropdownItem
                          onClick={() => setContactUpdateModalOpened(true)}
                        >
                          Edit Contact Info
                        </DropdownItem>
                      )}
                    </>
                  )}

                  {isDateValidForLogs(check.created_at) && (
                    <DropdownItem onClick={() => setLogsModalOpened(true)}>
                      Audit Trail
                    </DropdownItem>
                  )}

                  {dashboardCanDelete && (
                    <>
                      {check.is_pep_monitored && !!check.pep_result && (
                        <DropdownItem onClick={handleDisableMonitor}>
                          Disable Monitoring
                        </DropdownItem>
                      )}
                      <DropdownItem onClick={handleDeleteCheck}>
                        Delete
                      </DropdownItem>
                    </>
                  )}
                </DropdownMenu>
              </Dropdown>
            </Tooltip>
          </div>
        </div>
      </div>
      {!!uploadModalOpened && (
        <UploadModal
          setUploadModalOpened={setUploadModalOpened}
          type={uploadModalOpened}
          id={check.signature_key}
          existingFiles={check.files}
        />
      )}
      {referenceNotesUpdateModalOpened && (
        <ReferenceNotesUpdateModal
          setReferenceNotesUpdateModalOpened={
            setReferenceNotesUpdateModalOpened
          }
          notes={check.notes || ""}
          reference={check.reference || ""}
          updateCheckGroup={updateCheckGroup}
          fetching={checkFetching}
          verificationGroupId={check.verification_group_id}
        />
      )}
      {contactUpdateModalOpened && (
        <ContactUpdateModal
          setContactUpdateModalOpened={setContactUpdateModalOpened}
          id={check.signature_key}
          email={check.email}
          phone={check.phone}
          started={check.started}
          firstName={check.first_name}
          middleName={check.middle_name}
          lastName={check.last_name}
        />
      )}
      {pepApproveModalOpened && (
        <PepApproveModal
          setPepApproveModalOpened={setPepApproveModalOpened}
          id={check.signature_key}
        />
      )}
      {logsModalOpened && (
        <LogsModal
          setLogsModalOpened={setLogsModalOpened}
          id={check.signature_key}
        />
      )}
    </div>
  );
};

Check.propTypes = {
  check: PropTypes.object.isRequired,
  updateCheckGroup: PropTypes.func.isRequired,
  checkFetching: PropTypes.bool.isRequired,
  resendCheck: PropTypes.func.isRequired,
  deleteCheck: PropTypes.func.isRequired,
  disableMonitoring: PropTypes.func.isRequired,
  approveFaceMatch: PropTypes.func.isRequired,
  approveQuickId: PropTypes.func.isRequired,
  resetCheck: PropTypes.func.isRequired,
  isAdminOrManager: PropTypes.bool.isRequired,
  namePriority: PropTypes.bool.isRequired,
  showSecondaryIDCopy: PropTypes.bool.isRequired,
  dashboardShowPrice: PropTypes.bool.isRequired,
  dashboardCanDelete: PropTypes.bool.isRequired,
  dashboardCanEdit: PropTypes.bool.isRequired,
  dashboardCanApprove: PropTypes.bool.isRequired,
  dashboardCanReset: PropTypes.bool.isRequired,
  dashboardCanManageDocs: PropTypes.bool.isRequired,
};

export default Check;
