import PropTypes from "prop-types";
import RcIf, { RcElse } from "rc-if";
import React from "react";
import { Button } from "reactstrap";

const BillingInfo = ({
  agencyName,
  paymentType,
  cardLast4,
  cardType,
  cardExpirationDate,
  setShowPaymentModal,
  paymentInReview,
}) => (
  <section>
    <RcIf if={paymentType === "INVOICE"}>
      <div className="text-center mt-5 pt-4">
        <p className="lead">You are subscribed to monthly invoicing.</p>
        <RcIf if={paymentInReview}>
          <p>
            Your account is marked for manual review. It may take up to 2
            business days for us to complete the review. If you want to run
            checks instantly, please choose Credit Card payment method.
          </p>
          <RcElse>
            <p>You will receive an invoice at the end of your billing cycle.</p>
          </RcElse>
        </RcIf>
        <Button
          color="primary"
          size="sm"
          outline
          onClick={() => setShowPaymentModal(true)}
        >
          Change Payment
        </Button>
      </div>
      <RcElse>
        <h2 className="h5 text-muted text-center">Your Card</h2>
        <div className="billing-card">
          <div className="billing-card__icons">
            <div className="billing-card__chip" />
            <div
              className={`billing-card__type billing-card__type--${
                cardType ? cardType.toLowerCase().replace(" ", "-") : ""
              }`}
            />
          </div>
          <div className="billing-card__section">
            <p className="billing-card__number">
              **** **** **** {cardLast4 || "xxxx"}
            </p>
          </div>
          <div className="billing-card__section">
            <p className="billing-card__label">Name</p>
            <p className="billing-card__name">{agencyName || "Agency Name"}</p>
          </div>
          <div className="billing-card__section">
            <p className="billing-card__exp-date">
              <span>Expiry Date</span>: {cardExpirationDate || "mm/YY"}
            </p>
          </div>
        </div>
        <div className="text-center mt-4">
          <Button
            color="primary"
            size="sm"
            outline
            onClick={() => setShowPaymentModal(true)}
          >
            Change Payment
          </Button>
        </div>
      </RcElse>
    </RcIf>
  </section>
);

BillingInfo.propTypes = {
  agencyName: PropTypes.string,
  cardLast4: PropTypes.string,
  cardType: PropTypes.string,
  cardExpirationDate: PropTypes.string,
  paymentType: PropTypes.string,
  setShowPaymentModal: PropTypes.func.isRequired,
  paymentInReview: PropTypes.bool.isRequired,
};

export default BillingInfo;
