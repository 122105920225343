import React from "react";

const Logout = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 21.97 23.37"
    xmlSpace="preserve"
    width={16}
  >
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="1.6px"
      d="M15.37 3.19c3.43 1.64 5.8 5.14 5.8 9.19 0 5.62-4.56 10.18-10.18 10.18S.8 18.01.8 12.38c0-4.05 2.37-7.55 5.8-9.19M10.95 10.23V.8"
    />
  </svg>
);

export default Logout;
